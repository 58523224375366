import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DetailRowService,
  EditService,
  GridModule,
  PagerModule,
  PageService,
  PdfExportService,
  ReorderService,
  ResizeService,
  SearchService,
  SortService,
  ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import {
  CarouselModule,
  ContextMenuModule,
  TabModule,
  ToolbarModule,
  SidebarModule,
  TreeViewModule,
} from '@syncfusion/ej2-angular-navigations';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import {
  DropDownListAllModule,
  DropDownListModule,
  MultiSelectAllModule,
} from '@syncfusion/ej2-angular-dropdowns';
import {
  MaskedTextBoxModule,
  NumericTextBoxAllModule,
  NumericTextBoxModule,
  UploaderModule,
  TextBoxModule,
} from '@syncfusion/ej2-angular-inputs';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import {
  ProgressButtonModule,
  DropDownButtonModule,
} from '@syncfusion/ej2-angular-splitbuttons';

import {
  HtmlEditorService,
  ImageService,
  LinkService,
  RichTextEditorModule,
  ToolbarService as ToolbarServiceRte,
} from '@syncfusion/ej2-angular-richtexteditor';
import {
  AccumulationChartAllModule,
  ChartAllModule,
} from '@syncfusion/ej2-angular-charts';
import {
  CircularGaugeAllModule,
  GaugeTooltipService,
} from '@syncfusion/ej2-angular-circulargauge';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { ArticleCardComponent } from './components/article-card/article-card.component';
import { LatestEnhancementComponent } from './components/latest-enhancement/latest-enhancement.component';
import { HorizonIconModule, HorizonDocIconModule } from '@horizon/core-icons';
import { HorizonSectionSubHeaderModule } from '@horizon/core-layouts';
import { HorizonComponentsDmCoreModule } from '@horizon/dm-core';
import { HorizonComponentsCoreModule } from '@horizon/core';
import { HorizonDropdownModule } from '@horizon/core-dropdowns';
import { HorizonFooterModule } from '@horizon/core-layouts';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [ArticleCardComponent, LatestEnhancementComponent, FooterComponent],
  imports: [
    TranslateModule,
    CarouselModule,
    CommonModule,
    ReactiveFormsModule,
    GridModule,
    PagerModule,
    ButtonModule,
    TabModule,
    ToolbarModule,
    SidebarModule,
    TreeViewModule,
    CheckBoxModule,
    MultiSelectAllModule,
    NumericTextBoxModule,
    NumericTextBoxAllModule,
    DialogModule,
    TooltipModule,
    DatePickerModule,
    DropDownListAllModule,
    FormsModule,
    MaskedTextBoxModule,
    DropDownListModule,
    UploaderModule,
    TextBoxModule,
    ProgressButtonModule,
    DropDownButtonModule,
    RichTextEditorModule,
    ChartAllModule,
    CircularGaugeAllModule,
    ContextMenuModule,
    ProgressBarModule,
    AccumulationChartAllModule,
    //Horizon Components Library
    HorizonIconModule,
    HorizonSectionSubHeaderModule,
    HorizonComponentsCoreModule,
    HorizonComponentsDmCoreModule,
    HorizonDropdownModule,
    HorizonDocIconModule,
    HorizonFooterModule
  ],
  providers: [
    PageService,
    SortService,
    PdfExportService,
    EditService,
    ToolbarService,
    SearchService,
    ReorderService,
    ResizeService,
    DetailRowService,
    TranslatePipe,
    ToolbarServiceRte,
    LinkService,
    ImageService,
    HtmlEditorService,
    GaugeTooltipService,
    DatePipe,
  ],
  exports: [ArticleCardComponent, LatestEnhancementComponent,FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
