<div class="sidebar-menu" id="menu-wrapper">
  <!-- #region HEADER -->
  <!-- ----------------------------------------------------------------------------------------------- -->
  <horizon-sidebar-menu *ngIf="!isOnAcceptDisclaimer && !isOnContactAdmin"
    [sideBarMenuConfig]="menuSettingsService.sidebarMenuConfig"
    [menuRoutingPaths]="menuRoutingPaths"
    [menuItemsPermissions]="menuItemsPermissions"
    [mainFontColor]="globalSettings.colors.complementaryColor"
    [applyPermissions]="false"
    [hasBoxShadow]="true"
    [initializeMenuClosed]="true"
    [closeNavigatingOnMobile]="true"
    (menuItemClickEvent)="handleItemClick($event)"
  >
    <div sidebar>
      <horizon-app-header
        [showLogoImg]="headerSettingsService.headerSettings.showLogoImg"
        [showToolbarActions]="headerSettingsService.headerSettings.showToolbar"
        [showUserAvatar]="headerSettingsService.headerSettings.showUserAvatar"
        [showUserOptionsMenu]="
          headerSettingsService.headerSettings.showUserOptionsMenu
        "
        [logoImg]="headerSettingsService.headerSettings.logoImg"
        [toolbarOptions]="headerSettingsService.toolbarOptions"
        [userOptionsMenuItems]="headerSettingsService.customMenuOptions"
        [user]="user"
        [userPhoto]="userPhoto"
        [currentLanguage]="currentLanguage"
        [mainFontColor]="globalSettings.colors.mainColor"
        [secondaryFontColor]="globalSettings.colors.tertiaryColor"
        (logoImgClicked)="goHome()"
        (languageChanged)="languageChanged($event)"
        (totalData)="totalData()"
        (updateData)="updateData($event)"
        (logoutClicked)="logout()"
        (selectItemClicked)="handleItemClick($event)"
      >
        <!--componente recent-search-->
      </horizon-app-header>
    </div>
  </horizon-sidebar-menu>

  <!--endregion -->

  <!-- #region CONTENT -->
  <!-- ----------------------------------------------------------------------------------------------- -->
  <div class="sidebar-menu__main" id="main-text">
    <div class="sidebar-menu__main--content p-0 p-sm-2">
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </div>
  <!--endregion -->
</div>

<!-- #region TOAST -->
<horizon-toast-notification
  data-qa="horizon-toast-notification"
  [toasts]="toastNotifications"
></horizon-toast-notification>
<!-- #endregion -->

<!-- #region NOTIFICATIONS -->
<horizon-dialog-notification-modal
  data-qa="horizon-dialog-notification-modal"
  [dialogNotificationObj]="notificationsDialog"
  (confirmModal)="confirmModal()"
></horizon-dialog-notification-modal>
<!-- #endregion -->

<!-- #region LOADING -->
<horizon-dialog-loading-modal
  *ngIf="showLoadingSpinner"
  [dialogTitle]="'horizon.loading'"
></horizon-dialog-loading-modal>
<!-- #endregion -->