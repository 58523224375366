export enum RoutePaths {
  ACCOUNT = 'account',
  CUSTOMERS = 'customers',
  CUSTOMERS_OVERVIEW = 'customers-overview',
  CUSTOMERS_PARTICIPANTS = 'customers-participants',
  CUSTOMERS_RELATED_PARTIES = 'customers-related-parties',
  CUSTOMERS_VULNERABILITY = 'customers-vulnerability',
  CUSTOMERS_KYC = 'customers-kyc',
  COVENANTS = 'covenants',
  DOCUMENTS = 'documents',
  HOME = '',
  NOTES = 'notes',
  SEARCH = 'search',
  SEARCH_CHILD = 'search/:id',
  SECURITY = 'security',
  SECURITY_OVERVIEW = 'security-overview',
  SECURITY_VALUATION = 'security-valuation',
  FULL_REDEMPTIONS = 'full-redemptions',
  PARTIAL_REDEMPTIONS = 'partial-redemptions',
  SOURCE_OF_FUND_REDEMPTIONS = 'source-of-funds-redemptions',
  SUPER_USER = 'superUser',
  TRANSACTIONS = 'transactions',
  THIRD_PARTY = 'ThirdParty',
  LEGAL = 'legal',
  ACCEPT_DISCLAIMER = 'accept-disclaimer',
  CONTACT_ADMIN = 'contact-admin'
}

export enum MenuRoutePaths {
  SUPER_USER_ROLES_AND_PERMISSIONS = '/superUser/rolesAndPermissions',
  SUPER_USER_SCOPES_GROUPS = '/superUser/scopesGroups',
  SUPER_USER_USERS = '/superUser/users',
  SUPER_USER_CONFIGS = '/superUser/configs',
  SUPER_USER_HUBSPOT_CONFIGS = '/superUser/hubspotConfigs',
  SUPER_USER_CLIENTS = '/superUser/clients',
  THIRD_PARTY = '/ThirdParty',
  SUPER_USER_TABLEAU = '/superUser/tableau',
}
