export const datesEN = {
  main: {
    'en-GB': {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'en',
        territory: 'GB',
      },
      dates: {
        fields: {
          era: {
            displayName: 'era',
          },
          'era-short': {
            displayName: 'era',
          },
          'era-narrow': {
            displayName: 'era',
          },
          year: {
            displayName: 'year',
            'relative-type--1': 'last year',
            'relative-type-0': 'this year',
            'relative-type-1': 'next year',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} year',
              'relativeTimePattern-count-other': 'in {0} years',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} year ago',
              'relativeTimePattern-count-other': '{0} years ago',
            },
          },
          'year-short': {
            displayName: 'yr',
            'relative-type--1': 'last yr',
            'relative-type-0': 'this yr',
            'relative-type-1': 'next yr',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} yr',
              'relativeTimePattern-count-other': 'in {0} yr',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} yr ago',
              'relativeTimePattern-count-other': '{0} yr ago',
            },
          },
          'year-narrow': {
            displayName: 'yr',
            'relative-type--1': 'last yr',
            'relative-type-0': 'this yr',
            'relative-type-1': 'next yr',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} yr',
              'relativeTimePattern-count-other': 'in {0} yr',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} yr ago',
              'relativeTimePattern-count-other': '{0} yr ago',
            },
          },
          quarter: {
            displayName: 'quarter',
            'relative-type--1': 'last quarter',
            'relative-type-0': 'this quarter',
            'relative-type-1': 'next quarter',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} quarter',
              'relativeTimePattern-count-other': 'in {0} quarters',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} quarter ago',
              'relativeTimePattern-count-other': '{0} quarters ago',
            },
          },
          'quarter-short': {
            displayName: 'qtr',
            'relative-type--1': 'last qtr.',
            'relative-type-0': 'this qtr.',
            'relative-type-1': 'next qtr.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} qtr',
              'relativeTimePattern-count-other': 'in {0} qtr',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} qtr ago',
              'relativeTimePattern-count-other': '{0} qtr ago',
            },
          },
          'quarter-narrow': {
            displayName: 'qtr',
            'relative-type--1': 'last qtr.',
            'relative-type-0': 'this qtr.',
            'relative-type-1': 'next qtr.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} qtr',
              'relativeTimePattern-count-other': 'in {0} qtr',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} qtr ago',
              'relativeTimePattern-count-other': '{0} qtr ago',
            },
          },
          month: {
            displayName: 'month',
            'relative-type--1': 'last month',
            'relative-type-0': 'this month',
            'relative-type-1': 'next month',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} month',
              'relativeTimePattern-count-other': 'in {0} months',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} month ago',
              'relativeTimePattern-count-other': '{0} months ago',
            },
          },
          'month-short': {
            displayName: 'mo',
            'relative-type--1': 'last mo',
            'relative-type-0': 'this mo',
            'relative-type-1': 'next mo',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} mo',
              'relativeTimePattern-count-other': 'in {0} mo',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} mo ago',
              'relativeTimePattern-count-other': '{0} mo ago',
            },
          },
          'month-narrow': {
            displayName: 'mo',
            'relative-type--1': 'last mo',
            'relative-type-0': 'this mo',
            'relative-type-1': 'next mo',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} mo',
              'relativeTimePattern-count-other': 'in {0} mo',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} mo ago',
              'relativeTimePattern-count-other': '{0} mo ago',
            },
          },
          week: {
            displayName: 'week',
            'relative-type--1': 'last week',
            'relative-type-0': 'this week',
            'relative-type-1': 'next week',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} week',
              'relativeTimePattern-count-other': 'in {0} weeks',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} week ago',
              'relativeTimePattern-count-other': '{0} weeks ago',
            },
            relativePeriod: 'the week of {0}',
          },
          'week-short': {
            displayName: 'wk',
            'relative-type--1': 'last wk',
            'relative-type-0': 'this wk',
            'relative-type-1': 'next wk',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} wk',
              'relativeTimePattern-count-other': 'in {0} wk',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} wk ago',
              'relativeTimePattern-count-other': '{0} wk ago',
            },
            relativePeriod: 'the week of {0}',
          },
          'week-narrow': {
            displayName: 'wk',
            'relative-type--1': 'last wk',
            'relative-type-0': 'this wk',
            'relative-type-1': 'next wk',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} wk',
              'relativeTimePattern-count-other': 'in {0} wk',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} wk ago',
              'relativeTimePattern-count-other': '{0} wk ago',
            },
            relativePeriod: 'the week of {0}',
          },
          weekOfMonth: {
            displayName: 'week of month',
          },
          'weekOfMonth-short': {
            displayName: 'wk of mo',
          },
          'weekOfMonth-narrow': {
            displayName: 'wk of mo',
          },
          day: {
            displayName: 'day',
            'relative-type--1': 'yesterday',
            'relative-type-0': 'today',
            'relative-type-1': 'tomorrow',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} day',
              'relativeTimePattern-count-other': 'in {0} days',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} day ago',
              'relativeTimePattern-count-other': '{0} days ago',
            },
          },
          'day-short': {
            displayName: 'day',
            'relative-type--1': 'yesterday',
            'relative-type-0': 'today',
            'relative-type-1': 'tomorrow',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} day',
              'relativeTimePattern-count-other': 'in {0} days',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} day ago',
              'relativeTimePattern-count-other': '{0} days ago',
            },
          },
          'day-narrow': {
            displayName: 'day',
            'relative-type--1': 'yesterday',
            'relative-type-0': 'today',
            'relative-type-1': 'tomorrow',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} day',
              'relativeTimePattern-count-other': 'in {0} days',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} day ago',
              'relativeTimePattern-count-other': '{0} days ago',
            },
          },
          dayOfYear: {
            displayName: 'day of year',
          },
          'dayOfYear-short': {
            displayName: 'day of yr',
          },
          'dayOfYear-narrow': {
            displayName: 'day of yr',
          },
          weekday: {
            displayName: 'day of the week',
          },
          'weekday-short': {
            displayName: 'day of wk',
          },
          'weekday-narrow': {
            displayName: 'day of wk',
          },
          weekdayOfMonth: {
            displayName: 'weekday of the month',
          },
          'weekdayOfMonth-short': {
            displayName: 'wkday of mo',
          },
          'weekdayOfMonth-narrow': {
            displayName: 'wkday of mo',
          },
          sun: {
            'relative-type--1': 'last Sunday',
            'relative-type-0': 'this Sunday',
            'relative-type-1': 'next Sunday',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Sunday',
              'relativeTimePattern-count-other': 'in {0} Sundays',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Sunday ago',
              'relativeTimePattern-count-other': '{0} Sundays ago',
            },
          },
          'sun-short': {
            'relative-type--1': 'last Sun',
            'relative-type-0': 'this Sun',
            'relative-type-1': 'next Sun',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Sun',
              'relativeTimePattern-count-other': 'in {0} Sun',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Sun ago',
              'relativeTimePattern-count-other': '{0} Sun ago',
            },
          },
          'sun-narrow': {
            'relative-type--1': 'last Su',
            'relative-type-0': 'this Su',
            'relative-type-1': 'next Su',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Su',
              'relativeTimePattern-count-other': 'in {0} Su',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Su ago',
              'relativeTimePattern-count-other': '{0} Su ago',
            },
          },
          mon: {
            'relative-type--1': 'last Monday',
            'relative-type-0': 'this Monday',
            'relative-type-1': 'next Monday',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Monday',
              'relativeTimePattern-count-other': 'in {0} Mondays',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Monday ago',
              'relativeTimePattern-count-other': '{0} Mondays ago',
            },
          },
          'mon-short': {
            'relative-type--1': 'last Mon',
            'relative-type-0': 'this Mon',
            'relative-type-1': 'next Mon',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Mon',
              'relativeTimePattern-count-other': 'in {0} Mon',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Mon ago',
              'relativeTimePattern-count-other': '{0} Mon ago',
            },
          },
          'mon-narrow': {
            'relative-type--1': 'last M',
            'relative-type-0': 'this M',
            'relative-type-1': 'next M',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} M',
              'relativeTimePattern-count-other': 'in {0} M',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} M ago',
              'relativeTimePattern-count-other': '{0} M ago',
            },
          },
          tue: {
            'relative-type--1': 'last Tuesday',
            'relative-type-0': 'this Tuesday',
            'relative-type-1': 'next Tuesday',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Tuesday',
              'relativeTimePattern-count-other': 'in {0} Tuesdays',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Tuesday ago',
              'relativeTimePattern-count-other': '{0} Tuesdays ago',
            },
          },
          'tue-short': {
            'relative-type--1': 'last Tue',
            'relative-type-0': 'this Tue',
            'relative-type-1': 'next Tue',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Tue',
              'relativeTimePattern-count-other': 'in {0} Tue',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Tue ago',
              'relativeTimePattern-count-other': '{0} Tue ago',
            },
          },
          'tue-narrow': {
            'relative-type--1': 'last Tu',
            'relative-type-0': 'this Tu',
            'relative-type-1': 'next Tu',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Tu',
              'relativeTimePattern-count-other': 'in {0} Tu',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Tu ago',
              'relativeTimePattern-count-other': '{0} Tu ago',
            },
          },
          wed: {
            'relative-type--1': 'last Wednesday',
            'relative-type-0': 'this Wednesday',
            'relative-type-1': 'next Wednesday',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Wednesday',
              'relativeTimePattern-count-other': 'in {0} Wednesdays',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Wednesday ago',
              'relativeTimePattern-count-other': '{0} Wednesdays ago',
            },
          },
          'wed-short': {
            'relative-type--1': 'last Wed',
            'relative-type-0': 'this Wed',
            'relative-type-1': 'next Wed',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Wed',
              'relativeTimePattern-count-other': 'in {0} Wed',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Wed ago',
              'relativeTimePattern-count-other': '{0} Wed ago',
            },
          },
          'wed-narrow': {
            'relative-type--1': 'last W',
            'relative-type-0': 'this W',
            'relative-type-1': 'next W',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} W',
              'relativeTimePattern-count-other': 'in {0} W',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} W ago',
              'relativeTimePattern-count-other': '{0} W ago',
            },
          },
          thu: {
            'relative-type--1': 'last Thursday',
            'relative-type-0': 'this Thursday',
            'relative-type-1': 'next Thursday',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Thursday',
              'relativeTimePattern-count-other': 'in {0} Thursdays',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Thursday ago',
              'relativeTimePattern-count-other': '{0} Thursdays ago',
            },
          },
          'thu-short': {
            'relative-type--1': 'last Thu',
            'relative-type-0': 'this Thu',
            'relative-type-1': 'next Thu',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Thu',
              'relativeTimePattern-count-other': 'in {0} Thu',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Thu ago',
              'relativeTimePattern-count-other': '{0} Thu ago',
            },
          },
          'thu-narrow': {
            'relative-type--1': 'last Th',
            'relative-type-0': 'this Th',
            'relative-type-1': 'next Th',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Th',
              'relativeTimePattern-count-other': 'in {0} Th',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Th ago',
              'relativeTimePattern-count-other': '{0} Th ago',
            },
          },
          fri: {
            'relative-type--1': 'last Friday',
            'relative-type-0': 'this Friday',
            'relative-type-1': 'next Friday',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Friday',
              'relativeTimePattern-count-other': 'in {0} Fridays',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Friday ago',
              'relativeTimePattern-count-other': '{0} Fridays ago',
            },
          },
          'fri-short': {
            'relative-type--1': 'last Fri',
            'relative-type-0': 'this Fri',
            'relative-type-1': 'next Fri',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Fri',
              'relativeTimePattern-count-other': 'in {0} Fri',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Fri ago',
              'relativeTimePattern-count-other': '{0} Fri ago',
            },
          },
          'fri-narrow': {
            'relative-type--1': 'last F',
            'relative-type-0': 'this F',
            'relative-type-1': 'next F',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} F',
              'relativeTimePattern-count-other': 'in {0} F',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} F ago',
              'relativeTimePattern-count-other': '{0} F ago',
            },
          },
          sat: {
            'relative-type--1': 'last Saturday',
            'relative-type-0': 'this Saturday',
            'relative-type-1': 'next Saturday',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Saturday',
              'relativeTimePattern-count-other': 'in {0} Saturdays',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Saturday ago',
              'relativeTimePattern-count-other': '{0} Saturdays ago',
            },
          },
          'sat-short': {
            'relative-type--1': 'last Sat',
            'relative-type-0': 'this Sat',
            'relative-type-1': 'next Sat',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Sat',
              'relativeTimePattern-count-other': 'in {0} Sat',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Sat ago',
              'relativeTimePattern-count-other': '{0} Sat ago',
            },
          },
          'sat-narrow': {
            'relative-type--1': 'last Sa',
            'relative-type-0': 'this Sa',
            'relative-type-1': 'next Sa',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} Sa',
              'relativeTimePattern-count-other': 'in {0} Sa',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} Sa ago',
              'relativeTimePattern-count-other': '{0} Sa ago',
            },
          },
          'dayperiod-short': {
            displayName: 'am/pm',
            'displayName-alt-variant': 'am/pm',
          },
          dayperiod: {
            displayName: 'am/pm',
            'displayName-alt-variant': 'am/pm',
          },
          'dayperiod-narrow': {
            displayName: 'am/pm',
            'displayName-alt-variant': 'am/pm',
          },
          hour: {
            displayName: 'hour',
            'relative-type-0': 'this hour',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} hour',
              'relativeTimePattern-count-other': 'in {0} hours',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} hour ago',
              'relativeTimePattern-count-other': '{0} hours ago',
            },
          },
          'hour-short': {
            displayName: 'hr',
            'relative-type-0': 'this hour',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} hr',
              'relativeTimePattern-count-other': 'in {0} hr',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} hr ago',
              'relativeTimePattern-count-other': '{0} hr ago',
            },
          },
          'hour-narrow': {
            displayName: 'hr',
            'relative-type-0': 'this hour',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} hr',
              'relativeTimePattern-count-other': 'in {0} hr',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} hr ago',
              'relativeTimePattern-count-other': '{0} hr ago',
            },
          },
          minute: {
            displayName: 'minute',
            'relative-type-0': 'this minute',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} minute',
              'relativeTimePattern-count-other': 'in {0} minutes',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} minute ago',
              'relativeTimePattern-count-other': '{0} minutes ago',
            },
          },
          'minute-short': {
            displayName: 'min',
            'relative-type-0': 'this minute',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} min',
              'relativeTimePattern-count-other': 'in {0} min',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} min ago',
              'relativeTimePattern-count-other': '{0} min ago',
            },
          },
          'minute-narrow': {
            displayName: 'min',
            'relative-type-0': 'this minute',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} min',
              'relativeTimePattern-count-other': 'in {0} min',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} min ago',
              'relativeTimePattern-count-other': '{0} min ago',
            },
          },
          second: {
            displayName: 'second',
            'relative-type-0': 'now',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} second',
              'relativeTimePattern-count-other': 'in {0} seconds',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} second ago',
              'relativeTimePattern-count-other': '{0} seconds ago',
            },
          },
          'second-short': {
            displayName: 'sec',
            'relative-type-0': 'now',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} sec',
              'relativeTimePattern-count-other': 'in {0} sec',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} sec ago',
              'relativeTimePattern-count-other': '{0} sec ago',
            },
          },
          'second-narrow': {
            displayName: 'sec',
            'relative-type-0': 'now',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'in {0} sec',
              'relativeTimePattern-count-other': 'in {0} sec',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': '{0} sec ago',
              'relativeTimePattern-count-other': '{0} sec ago',
            },
          },
          zone: {
            displayName: 'time zone',
          },
          'zone-short': {
            displayName: 'zone',
          },
          'zone-narrow': {
            displayName: 'zone',
          },
        },
      },
    },
  },
};
