import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  L10n,
  setCulture,
  setCurrencyCode,
  loadCldr,
} from '@syncfusion/ej2-base';
import { TranslateService } from '@ngx-translate/core';
import { EventFireService } from '@shared/services/event-fire.service';
import { BehaviorSubject, Subject, catchError, of, takeUntil, throwError } from 'rxjs';
import { gregorianEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/ca-gregorian';
import { currenciesEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/currencies';
import { datesEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/dates';
import { numbersEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/numbers';
import { timeZonesEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/timeZones';
import { unitsEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/units';
import { enLanguage } from 'src/assets/i18n/syncfusion/en-GB';
import { esLanguage } from 'src/assets/i18n/syncfusion/es';
import { unitsES } from 'src/assets/i18n/syncfusion/CLDR/es/units';
import { currenciesES } from 'src/assets/i18n/syncfusion/CLDR/es/currencies';
import { datesES } from 'src/assets/i18n/syncfusion/CLDR/es/dates';
import { numbersES } from 'src/assets/i18n/syncfusion/CLDR/es/numbers';
import { timeZonesES } from 'src/assets/i18n/syncfusion/CLDR/es/timeZones';
import { gregorianES } from 'src/assets/i18n/syncfusion/CLDR/es/ca-gregorian';
import { DynamicModelApiService, GeocodingService } from '@horizon/dm-core';
import {
  DocumentsApiService,
  UsersService,
  WorkloadApiService,
} from '@horizon/core';
import { AppSections } from '@shared/enums/permissions.enums';
import { environment } from 'src/environments/environment';
import { globalSettings } from 'src/global-settings';
import { UrlBuilder } from 'http-url-builder';
import { MsalService } from '@azure/msal-angular';
import { SharedService } from '@core/services/shared.service';
import { SearchApiService } from '@core/services/search.service';
import { MenuRoutePaths } from '@shared/enums/routes.enums';
import { PermissionAccessType } from '@horizon/core';
import { RoutePaths } from '@shared/enums/routes.enums';
import { ClarityService } from '@shared/services/clarity-tags.service';

loadCldr(gregorianEN, currenciesEN, datesEN, numbersEN, timeZonesEN, unitsEN);
setCulture('en-GB');
setCurrencyCode('GBP');
L10n.load(enLanguage);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  private readonly destroy$ = new Subject<void>();
  isLoading$ = new BehaviorSubject<boolean>(false);
  public loadingImg: string = '../assets/img/' + globalSettings.loadingImage;

  constructor(
    public translate: TranslateService,
    private eventFireService: EventFireService,
    private usersService: UsersService,
    private changeDetector: ChangeDetectorRef,
    private workloadsService: WorkloadApiService,
    private documentsService: DocumentsApiService,
    private dynamicModelApiService: DynamicModelApiService,
    private geocodingService: GeocodingService,
    private authService: MsalService,
    private sharedService: SharedService,
    public searchApiService: SearchApiService,
    private clarityService: ClarityService
  ) {
    this.workloadsService.setAppEnvironment({
      workLoadApiBaseUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.workLoadApi)
        .addPathVariable('/')
        .build(),
      blobStorage: environment.blobStorage,
    });
    this.documentsService.setAppEnvironment({
      documentsApiBaseUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.documentsApi)
        .addPathVariable('/')
        .build(),
    });
    this.dynamicModelApiService.setAppEnvironment({
      dynamicModelApiBaseUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.dynamicModelApi)
        .addPathVariable('/')
        .build(),
    });

    this.geocodingService.setAppEnvironment(environment);

    this.usersService.setAppSectionsToCheckForPermissions(AppSections);

    this.isLoading$.next(true);
    translate.addLangs(['en', 'es']);

    //DEFAULT LANGUAGE
    this.translate.use('en');
    setCulture('en-GB');
    setCurrencyCode('GBP');
    L10n.load(enLanguage);

    this.eventFireService.getCurrentLanguage().subscribe({
      next: (lan) => {
        this.translate.use(lan);
        if (lan === 'es') {
          loadCldr(
            gregorianES,
            currenciesES,
            datesES,
            numbersES,
            timeZonesES,
            unitsES
          );
          setCulture('es');
          setCurrencyCode('EUR');
          L10n.load(esLanguage);
        } else if (lan === 'en') {
          loadCldr(
            gregorianEN,
            currenciesEN,
            datesEN,
            numbersEN,
            timeZonesEN,
            unitsEN
          );
          setCulture('en-GB');
          setCurrencyCode('GBP');
          L10n.load(enLanguage);
        }
      },
    });

    this.setUserFullName();

    this.setUserConfig();

  }

  ngOnInit(): void {
    const loadingImageInIndex = document.getElementById('app-loader-in-index');

    if (loadingImageInIndex) {
      loadingImageInIndex.style.display = 'none';
    }
  }

  setUserFullName() {
    const allAccounts = this.authService?.instance?.getAllAccounts();
    const loginDisplay = allAccounts?.length > 0;

    if (loginDisplay && allAccounts[0]?.name) {
      localStorage.setItem('userLoggedName', allAccounts[0]?.name);
    }
  }

  setUserConfig() {
    this.searchApiService.getMyUserConfig()
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          if (error) {
            this.sharedService.setUserHasNoConfig();
            return of(null);
          }
          return throwError(error);
        })
      )
      .subscribe((response: any) => {
        const languages = this.translate.getLangs();

        let menuItems = [];
        if (response?.menus) {
          menuItems = response.menus.map((item) => {
            const newNode = {
              nodeId: Number(item.id),
              nodeText: `menu.item${item.id}`,
              icon: item.body?.icon,
              redirect: item.body?.redirect,
              url: item.body?.url,
              tabs: item?.tabs,
            };
            const languages = this.translate.getLangs();
            languages.forEach((lang) => {
              this.addTranslation(
                lang,
                `item${item.id}`,
                item.body.displayName[lang]
              );
            });
            return newNode;
          });
        }
        this.sharedService.setMenuItems(menuItems);
        this.changeSelectedMenuItem(menuItems[0]);

        let policiesItems = [];
        if (response?.policies) {
          policiesItems = response.policies.map((policy) => {
            const policyItem = {
              id: policy.Name,
              title: `menu.item${policy.Name}`,
              url: RoutePaths.LEGAL + '/policy',
              image_url: policy?.image_url
            };
            languages.forEach((lang) => {
              this.addTranslation(
                lang,
                `item${policy.Name}`,
                policy.displayText[lang]
              );
            });
            return policyItem;
          });
        }

        if (response?.faqType && response?.faqType?.displayText && response?.faqType) {
          policiesItems.push({
            id: "",
            title: `menu.itemfaq`,
            url: RoutePaths.LEGAL + '/faqs',
          })
          languages.forEach((lang) => {
            this.addTranslation(
              lang,
              `itemfaq`,
              response?.faqType?.displayText[lang]
            );
          });
        } else {
          this.sharedService.setShowFAQSPage(false)
        }

        if (response?.policies) {
          this.sharedService.setPoliciesItems(policiesItems);
        }

        if (!response?.welcomeType) {
          this.sharedService.setShowWelcomeSection(false);
        }

        if (!response?.postTagList) {
          this.sharedService.setShowPostsSection(false);
        }

        if (!response?.latestTagList) {
          this.sharedService.setShowLatestEnhancementsSection(false);
        }

        setTimeout(() => {
          this.isLoading$.next(false);
        }, 500);
      });
  }

  addTranslation(lang, key, value) {
    const newObj = {
      menu: {
        [key]: value,
      },
    };
    this.translate.setTranslation(lang, newObj, true);
  }

  changeSelectedMenuItem(data: any) {
    this.sharedService.setSelectedMenuItem(data);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
