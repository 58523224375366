export enum AppSections {
  ACTIVITIES = 'Activities',
  DOCUMENTS = 'Notes_Documents',
  CUSTOMERS = 'Customers',
  COVENANTS = 'Covenants',
  INDIVIDUALCUSTOMERS = 'IndividualCustomers',
  CUSTOMERS_OVERVIEW = 'Customers_Overview',
  CUSTOMERS_PARTICIPANTS = 'Customers_Participants',
  CUSTOMERS_RELATED_PARTIES = 'Customers_Related_Parties',
  CUSTOMERS_VULNERABILITY = 'Customers_Vulnerability',
  CUSTOMERS_KYC = 'Customers_KYC',
  RELATEDPARTIES = 'RelatedParties',
  SECURITY_OVERVIEW = 'Security_Overview',
  VALUATIONS = 'Valuations',
  FULL_REDEMPTIONS = 'Full_Redemptions',
  PARTIAL_REDEMPTIONS = 'Partial_Redemptions',
  SOURCE_OF_FUND_REDEMPTIONS = 'Sof_Redemptions',
  SOF_ADVANCED = 'Sof_Advanced',
  SEARCH = 'Search',
  ROLES_AND_PERMISSIONS = 'Roles_Permissions',
  SCOPES_GROUPS = 'Scopes_Groups',
  USERS = 'Users',
  REMINDERS_ASSIGN = 'Reminders_Assign',
  TRANSACTIONS = 'Transactions',
  THIRD_PARTY = '3rdParties',
  THIRD_PARTY_CHILD = '3rdParties',
  //CHARTS
  DASHBOARD = 'Dashboard',
  INVESTOR_SUMMARY = 'Investor_Summary',
  DELINQUENCY = 'Delinquency',
}

export enum AppSectionsPermissionsKey {
  ACCOUNT = 'Info',
  ACCOUNT_CHILD = 'Info',
  NOTES = 'Notes',
  DOCUMENTS = 'Notes_Documents',
  CUSTOMERS = 'Customers',
  COVENANTS = 'Covenants',
  INDIVIDUALCUSTOMERS = 'IndividualCustomers',
  CUSTOMERS_OVERVIEW = 'Customers_Overview',
  CUSTOMERS_PARTICIPANTS = 'Customers_Participants',
  CUSTOMERS_RELATED_PARTIES = 'Customers_Related_Parties',
  CUSTOMERS_VULNERABILITY = 'Customers_Vulnerability',
  CUSTOMERS_KYC = 'Customers_KYC',
  RELATEDPARTIES = 'RelatedParties',
  SECURITY = 'Security',
  SECURITY_OVERVIEW = 'Security_Overview',
  SECURITY_VALUATION = 'Security_Valuation',
  VALUATIONS = 'Valuations',
  FULL_REDEMPTIONS = 'Full_Redemptions',
  PARTIAL_REDEMPTIONS = 'Partial_Redemptions',
  SOURCE_OF_FUND_REDEMPTIONS = 'Sof_Redemptions',
  SOF_ADVANCED = 'Sof_Advanced',
  SEARCH = 'Search',
  SEARCH_CHILD = 'Search',
  SUPER_USER = 'Roles_Permissions',
  SUPER_USER_ROLES_AND_PERMISSIONS = 'Roles_Permissions',
  SUPER_USER_SCOPES_GROUPS = 'Scopes_Groups',
  SUPER_USER_USERS = 'Users',
  SUPER_USER_CONFIGS = 'Configs',
  SUPER_USER_CLIENTS = 'Clients',
  REMINDERS_ASSIGN = 'Reminders_Assign',
  TRANSACTIONS = 'Transactions',
  THIRD_PARTY = '3rdParties',
  THIRD_PARTY_CHILD = '3rdParties',
}
