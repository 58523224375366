export const unitsES = {
  main: {
    es: {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'es',
      },
      units: {
        long: {
          per: {
            compoundUnitPattern: '{0} por {1}',
          },
          times: {
            compoundUnitPattern: '{0}⋅{1}',
          },
          'acceleration-g-force': {
            displayName: 'fuerza G',
            'unitPattern-count-one': '{0} unidad de fuerza gravitacional',
            'unitPattern-count-other': '{0} unidades de fuerza gravitacional',
          },
          'acceleration-meter-per-second-squared': {
            displayName: 'metros por segundo al cuadrado',
            'unitPattern-count-one': '{0} metro por segundo al cuadrado',
            'unitPattern-count-other': '{0} metros por segundo al cuadrado',
          },
          'angle-revolution': {
            displayName: 'revoluciones',
            'unitPattern-count-one': '{0} revolución',
            'unitPattern-count-other': '{0} revoluciones',
          },
          'angle-radian': {
            displayName: 'radianes',
            'unitPattern-count-one': '{0} radián',
            'unitPattern-count-other': '{0} radianes',
          },
          'angle-degree': {
            displayName: 'grados',
            'unitPattern-count-one': '{0} grado',
            'unitPattern-count-other': '{0} grados',
          },
          'angle-arc-minute': {
            displayName: 'minutos de arco',
            'unitPattern-count-one': '{0} minuto de arco',
            'unitPattern-count-other': '{0} minutos de arco',
          },
          'angle-arc-second': {
            displayName: 'segundos de arco',
            'unitPattern-count-one': '{0} segundo de arco',
            'unitPattern-count-other': '{0} segundos de arco',
          },
          'area-square-kilometer': {
            displayName: 'kilómetros cuadrados',
            'unitPattern-count-one': '{0} kilómetro cuadrado',
            'unitPattern-count-other': '{0} kilómetros cuadrados',
            perUnitPattern: '{0} por kilómetro cuadrado',
          },
          'area-hectare': {
            displayName: 'hectáreas',
            'unitPattern-count-one': '{0} hectárea',
            'unitPattern-count-other': '{0} hectáreas',
          },
          'area-square-meter': {
            displayName: 'metros cuadrados',
            'unitPattern-count-one': '{0} metro cuadrado',
            'unitPattern-count-other': '{0} metros cuadrados',
            perUnitPattern: '{0} por metro cuadrado',
          },
          'area-square-centimeter': {
            displayName: 'centímetros cuadrados',
            'unitPattern-count-one': '{0} centímetro cuadrado',
            'unitPattern-count-other': '{0} centímetros cuadrados',
            perUnitPattern: '{0} por centímetro cuadrado',
          },
          'area-square-mile': {
            displayName: 'millas cuadradas',
            'unitPattern-count-one': '{0} milla cuadrada',
            'unitPattern-count-other': '{0} millas cuadradas',
            perUnitPattern: '{0} por milla cuadrada',
          },
          'area-acre': {
            displayName: 'acres',
            'unitPattern-count-one': '{0} acre',
            'unitPattern-count-other': '{0} acres',
          },
          'area-square-yard': {
            displayName: 'yardas cuadradas',
            'unitPattern-count-one': '{0} yarda cuadrada',
            'unitPattern-count-other': '{0} yardas cuadradas',
          },
          'area-square-foot': {
            displayName: 'pies cuadrados',
            'unitPattern-count-one': '{0} pie cuadrado',
            'unitPattern-count-other': '{0} pies cuadrados',
          },
          'area-square-inch': {
            displayName: 'pulgadas cuadradas',
            'unitPattern-count-one': '{0} pulgada cuadrada',
            'unitPattern-count-other': '{0} pulgadas cuadradas',
            perUnitPattern: '{0} por pulgada cuadrada',
          },
          'area-dunam': {
            displayName: 'dunams',
            'unitPattern-count-one': '{0} dunam',
            'unitPattern-count-other': '{0} dunams',
          },
          'concentr-karat': {
            displayName: 'quilates',
            'unitPattern-count-one': '{0} quilate',
            'unitPattern-count-other': '{0} quilates',
          },
          'concentr-milligram-per-deciliter': {
            displayName: 'miligramos por decilitro',
            'unitPattern-count-one': '{0} miligramo por decilitro',
            'unitPattern-count-other': '{0} miligramos por decilitro',
          },
          'concentr-millimole-per-liter': {
            displayName: 'milimoles por litro',
            'unitPattern-count-one': '{0} milimol por litro',
            'unitPattern-count-other': '{0} milimoles por litro',
          },
          'concentr-part-per-million': {
            displayName: 'partes por millón',
            'unitPattern-count-one': '{0} parte por millón',
            'unitPattern-count-other': '{0} partes por millón',
          },
          'concentr-percent': {
            displayName: 'por ciento',
            'unitPattern-count-one': '{0} por ciento',
            'unitPattern-count-other': '{0} por ciento',
          },
          'concentr-permille': {
            displayName: 'por mil',
            'unitPattern-count-one': '{0} por mil',
            'unitPattern-count-other': '{0} por mil',
          },
          'concentr-permyriad': {
            displayName: 'por diez mil',
            'unitPattern-count-one': '{0} por diez mil',
            'unitPattern-count-other': '{0} por diez mil',
          },
          'concentr-mole': {
            displayName: 'moles',
            'unitPattern-count-one': '{0} moles',
            'unitPattern-count-other': '{0} moles',
          },
          'consumption-liter-per-kilometer': {
            displayName: 'litros por kilómetro',
            'unitPattern-count-one': '{0} litro por kilómetro',
            'unitPattern-count-other': '{0} litros por kilómetro',
          },
          'consumption-liter-per-100kilometers': {
            displayName: 'litros por 100 kilómetros',
            'unitPattern-count-one': '{0} litro por 100 kilómetros',
            'unitPattern-count-other': '{0} litros por 100 kilómetros',
          },
          'consumption-mile-per-gallon': {
            displayName: 'millas por galón',
            'unitPattern-count-one': '{0} milla por galón',
            'unitPattern-count-other': '{0} millas por galón',
          },
          'consumption-mile-per-gallon-imperial': {
            displayName: 'millas por galón imperial',
            'unitPattern-count-one': '{0} milla por galón imperial',
            'unitPattern-count-other': '{0} millas por galón imperial',
          },
          'digital-petabyte': {
            displayName: 'petabytes',
            'unitPattern-count-one': '{0} petabyte',
            'unitPattern-count-other': '{0} petabytes',
          },
          'digital-terabyte': {
            displayName: 'terabytes',
            'unitPattern-count-one': '{0} terabyte',
            'unitPattern-count-other': '{0} terabytes',
          },
          'digital-terabit': {
            displayName: 'terabits',
            'unitPattern-count-one': '{0} terabit',
            'unitPattern-count-other': '{0} terabits',
          },
          'digital-gigabyte': {
            displayName: 'gigabytes',
            'unitPattern-count-one': '{0} gigabyte',
            'unitPattern-count-other': '{0} gigabytes',
          },
          'digital-gigabit': {
            displayName: 'gigabits',
            'unitPattern-count-one': '{0} gigabit',
            'unitPattern-count-other': '{0} gigabits',
          },
          'digital-megabyte': {
            displayName: 'megabytes',
            'unitPattern-count-one': '{0} megabyte',
            'unitPattern-count-other': '{0} megabytes',
          },
          'digital-megabit': {
            displayName: 'megabits',
            'unitPattern-count-one': '{0} megabit',
            'unitPattern-count-other': '{0} megabits',
          },
          'digital-kilobyte': {
            displayName: 'kilobytes',
            'unitPattern-count-one': '{0} kilobyte',
            'unitPattern-count-other': '{0} kilobytes',
          },
          'digital-kilobit': {
            displayName: 'kilobits',
            'unitPattern-count-one': '{0} kilobit',
            'unitPattern-count-other': '{0} kilobits',
          },
          'digital-byte': {
            displayName: 'bytes',
            'unitPattern-count-one': '{0} byte',
            'unitPattern-count-other': '{0} bytes',
          },
          'digital-bit': {
            displayName: 'bits',
            'unitPattern-count-one': '{0} bit',
            'unitPattern-count-other': '{0} bits',
          },
          'duration-century': {
            displayName: 'siglos',
            'unitPattern-count-one': '{0} siglo',
            'unitPattern-count-other': '{0} siglos',
          },
          'duration-decade': {
            displayName: 'décadas',
            'unitPattern-count-one': '{0} década',
            'unitPattern-count-other': '{0} décadas',
          },
          'duration-year': {
            displayName: 'años',
            'unitPattern-count-one': '{0} año',
            'unitPattern-count-other': '{0} años',
            perUnitPattern: '{0} por año',
          },
          'duration-month': {
            displayName: 'meses',
            'unitPattern-count-one': '{0} mes',
            'unitPattern-count-other': '{0} meses',
            perUnitPattern: '{0} por mes',
          },
          'duration-week': {
            displayName: 'semanas',
            'unitPattern-count-one': '{0} semana',
            'unitPattern-count-other': '{0} semanas',
            perUnitPattern: '{0} por semana',
          },
          'duration-day': {
            displayName: 'días',
            'unitPattern-count-one': '{0} día',
            'unitPattern-count-other': '{0} días',
            perUnitPattern: '{0} por día',
          },
          'duration-hour': {
            displayName: 'horas',
            'unitPattern-count-one': '{0} hora',
            'unitPattern-count-other': '{0} horas',
            perUnitPattern: '{0} por hora',
          },
          'duration-minute': {
            displayName: 'minutos',
            'unitPattern-count-one': '{0} minuto',
            'unitPattern-count-other': '{0} minutos',
            perUnitPattern: '{0} por minuto',
          },
          'duration-second': {
            displayName: 'segundos',
            'unitPattern-count-one': '{0} segundo',
            'unitPattern-count-other': '{0} segundos',
            perUnitPattern: '{0} por segundo',
          },
          'duration-millisecond': {
            displayName: 'milisegundos',
            'unitPattern-count-one': '{0} milisegundo',
            'unitPattern-count-other': '{0} milisegundos',
          },
          'duration-microsecond': {
            displayName: 'microsegundos',
            'unitPattern-count-one': '{0} microsegundo',
            'unitPattern-count-other': '{0} microsegundos',
          },
          'duration-nanosecond': {
            displayName: 'nanosegundos',
            'unitPattern-count-one': '{0} nanosegundo',
            'unitPattern-count-other': '{0} nanosegundos',
          },
          'electric-ampere': {
            displayName: 'amperios',
            'unitPattern-count-one': '{0} amperio',
            'unitPattern-count-other': '{0} amperios',
          },
          'electric-milliampere': {
            displayName: 'miliamperios',
            'unitPattern-count-one': '{0} miliamperio',
            'unitPattern-count-other': '{0} miliamperios',
          },
          'electric-ohm': {
            displayName: 'ohmios',
            'unitPattern-count-one': '{0} ohmio',
            'unitPattern-count-other': '{0} ohmios',
          },
          'electric-volt': {
            displayName: 'voltios',
            'unitPattern-count-one': '{0} voltio',
            'unitPattern-count-other': '{0} voltios',
          },
          'energy-kilocalorie': {
            displayName: 'kilocalorías',
            'unitPattern-count-one': '{0} kilocaloría',
            'unitPattern-count-other': '{0} kilocalorías',
          },
          'energy-calorie': {
            displayName: 'calorías',
            'unitPattern-count-one': '{0} caloría',
            'unitPattern-count-other': '{0} calorías',
          },
          'energy-foodcalorie': {
            displayName: 'calorías',
            'unitPattern-count-one': '{0} caloría',
            'unitPattern-count-other': '{0} calorías',
          },
          'energy-kilojoule': {
            displayName: 'kilojulios',
            'unitPattern-count-one': '{0} kilojulio',
            'unitPattern-count-other': '{0} kilojulios',
          },
          'energy-joule': {
            displayName: 'julios',
            'unitPattern-count-one': '{0} julio',
            'unitPattern-count-other': '{0} julios',
          },
          'energy-kilowatt-hour': {
            displayName: 'kilovatios-hora',
            'unitPattern-count-one': '{0} kilovatio-hora',
            'unitPattern-count-other': '{0} kilovatios-hora',
          },
          'energy-electronvolt': {
            displayName: 'electronvoltios',
            'unitPattern-count-one': '{0} electronvoltio',
            'unitPattern-count-other': '{0} electronvoltios',
          },
          'energy-british-thermal-unit': {
            displayName: 'unidades térmicas británicas',
            'unitPattern-count-one': '{0} unidad térmica británica',
            'unitPattern-count-other': '{0} unidades térmicas británicas',
          },
          'energy-therm-us': {
            displayName: 'termia estadounidense',
            'unitPattern-count-one': '{0} termia estadounidense',
            'unitPattern-count-other': '{0} termias estadounidenses',
          },
          'force-pound-force': {
            displayName: 'libras de fuerza',
            'unitPattern-count-one': '{0} libra de fuerza',
            'unitPattern-count-other': '{0} libras de fuerza',
          },
          'force-newton': {
            displayName: 'newtons',
            'unitPattern-count-one': '{0} newton',
            'unitPattern-count-other': '{0} newtons',
          },
          'frequency-gigahertz': {
            displayName: 'gigahercios',
            'unitPattern-count-one': '{0} gigahercio',
            'unitPattern-count-other': '{0} gigahercios',
          },
          'frequency-megahertz': {
            displayName: 'megahercios',
            'unitPattern-count-one': '{0} megahercio',
            'unitPattern-count-other': '{0} megahercios',
          },
          'frequency-kilohertz': {
            displayName: 'kilohercios',
            'unitPattern-count-one': '{0} kilohercio',
            'unitPattern-count-other': '{0} kilohercios',
          },
          'frequency-hertz': {
            displayName: 'hercios',
            'unitPattern-count-one': '{0} hercio',
            'unitPattern-count-other': '{0} hercios',
          },
          'graphics-em': {
            displayName: 'espacios eme',
            'unitPattern-count-one': '{0} espacio eme',
            'unitPattern-count-other': '{0} espacios eme',
          },
          'graphics-pixel': {
            displayName: 'píxeles',
            'unitPattern-count-one': '{0} píxel',
            'unitPattern-count-other': '{0} píxeles',
          },
          'graphics-megapixel': {
            displayName: 'megapíxeles',
            'unitPattern-count-one': '{0} megapíxel',
            'unitPattern-count-other': '{0} megapíxeles',
          },
          'graphics-pixel-per-centimeter': {
            displayName: 'píxeles por centímetro',
            'unitPattern-count-one': '{0} píxel por centímetro',
            'unitPattern-count-other': '{0} píxeles por centímetro',
          },
          'graphics-pixel-per-inch': {
            displayName: 'píxeles por pulgada',
            'unitPattern-count-one': '{0} píxel por pulgada',
            'unitPattern-count-other': '{0} píxeles por pulgada',
          },
          'graphics-dot-per-centimeter': {
            displayName: 'puntos por centímetro',
            'unitPattern-count-one': '{0} punto por centímetro',
            'unitPattern-count-other': '{0} puntos por centímetro',
          },
          'graphics-dot-per-inch': {
            displayName: 'puntos por pulgada',
            'unitPattern-count-one': '{0} punto por pulgada',
            'unitPattern-count-other': '{0} puntos por pulgada',
          },
          'length-kilometer': {
            displayName: 'kilómetros',
            'unitPattern-count-one': '{0} kilómetro',
            'unitPattern-count-other': '{0} kilómetros',
            perUnitPattern: '{0} por kilómetro',
          },
          'length-meter': {
            displayName: 'metros',
            'unitPattern-count-one': '{0} metro',
            'unitPattern-count-other': '{0} metros',
            perUnitPattern: '{0} por metro',
          },
          'length-decimeter': {
            displayName: 'decímetros',
            'unitPattern-count-one': '{0} decímetro',
            'unitPattern-count-other': '{0} decímetros',
          },
          'length-centimeter': {
            displayName: 'centímetros',
            'unitPattern-count-one': '{0} centímetro',
            'unitPattern-count-other': '{0} centímetros',
            perUnitPattern: '{0} por centímetro',
          },
          'length-millimeter': {
            displayName: 'milímetros',
            'unitPattern-count-one': '{0} milímetro',
            'unitPattern-count-other': '{0} milímetros',
          },
          'length-micrometer': {
            displayName: 'micrómetros',
            'unitPattern-count-one': '{0} micrómetro',
            'unitPattern-count-other': '{0} micrómetros',
          },
          'length-nanometer': {
            displayName: 'nanómetros',
            'unitPattern-count-one': '{0} nanómetro',
            'unitPattern-count-other': '{0} nanómetros',
          },
          'length-picometer': {
            displayName: 'picómetros',
            'unitPattern-count-one': '{0} picómetro',
            'unitPattern-count-other': '{0} picómetros',
          },
          'length-mile': {
            displayName: 'millas',
            'unitPattern-count-one': '{0} milla',
            'unitPattern-count-other': '{0} millas',
          },
          'length-yard': {
            displayName: 'yardas',
            'unitPattern-count-one': '{0} yarda',
            'unitPattern-count-other': '{0} yardas',
          },
          'length-foot': {
            displayName: 'pies',
            'unitPattern-count-one': '{0} pie',
            'unitPattern-count-other': '{0} pies',
            perUnitPattern: '{0} por pie',
          },
          'length-inch': {
            displayName: 'pulgadas',
            'unitPattern-count-one': '{0} pulgada',
            'unitPattern-count-other': '{0} pulgadas',
            perUnitPattern: '{0} por pulgada',
          },
          'length-parsec': {
            displayName: 'parsecs',
            'unitPattern-count-one': '{0} parsec',
            'unitPattern-count-other': '{0} parsecs',
          },
          'length-light-year': {
            displayName: 'años luz',
            'unitPattern-count-one': '{0} año luz',
            'unitPattern-count-other': '{0} años luz',
          },
          'length-astronomical-unit': {
            displayName: 'unidades astronómicas',
            'unitPattern-count-one': '{0} unidad astronómica',
            'unitPattern-count-other': '{0} unidades astronómicas',
          },
          'length-furlong': {
            displayName: 'fur',
            'unitPattern-count-other': '{0} fur',
          },
          'length-fathom': {
            displayName: 'fm',
            'unitPattern-count-other': '{0} fth',
          },
          'length-nautical-mile': {
            displayName: 'millas náuticas',
            'unitPattern-count-one': '{0} milla náutica',
            'unitPattern-count-other': '{0} millas náuticas',
          },
          'length-mile-scandinavian': {
            displayName: 'millas escandinavas',
            'unitPattern-count-one': '{0} milla escandinava',
            'unitPattern-count-other': '{0} millas escandinavas',
          },
          'length-point': {
            displayName: 'puntos',
            'unitPattern-count-one': '{0} punto',
            'unitPattern-count-other': '{0} puntos',
          },
          'length-solar-radius': {
            displayName: 'radios solares',
            'unitPattern-count-one': '{0} radio solar',
            'unitPattern-count-other': '{0} radios solares',
          },
          'light-lux': {
            displayName: 'luxes',
            'unitPattern-count-one': '{0} lux',
            'unitPattern-count-other': '{0} luxes',
          },
          'light-solar-luminosity': {
            displayName: 'luminosidades solares',
            'unitPattern-count-one': '{0} luminosidad solar',
            'unitPattern-count-other': '{0} luminosidades solares',
          },
          'mass-metric-ton': {
            displayName: 'toneladas métricas',
            'unitPattern-count-one': '{0} tonelada métrica',
            'unitPattern-count-other': '{0} toneladas métricas',
          },
          'mass-kilogram': {
            displayName: 'kilogramos',
            'unitPattern-count-one': '{0} kilogramo',
            'unitPattern-count-other': '{0} kilogramos',
            perUnitPattern: '{0} por kilogramo',
          },
          'mass-gram': {
            displayName: 'gramos',
            'unitPattern-count-one': '{0} gramo',
            'unitPattern-count-other': '{0} gramos',
            perUnitPattern: '{0} por gramo',
          },
          'mass-milligram': {
            displayName: 'miligramos',
            'unitPattern-count-one': '{0} miligramo',
            'unitPattern-count-other': '{0} miligramos',
          },
          'mass-microgram': {
            displayName: 'microgramos',
            'unitPattern-count-one': '{0} microgramo',
            'unitPattern-count-other': '{0} microgramos',
          },
          'mass-ton': {
            displayName: 'toneladas',
            'unitPattern-count-one': '{0} tonelada',
            'unitPattern-count-other': '{0} toneladas',
          },
          'mass-stone': {
            displayName: 'st',
            'unitPattern-count-other': '{0} st',
          },
          'mass-pound': {
            displayName: 'libras',
            'unitPattern-count-one': '{0} libra',
            'unitPattern-count-other': '{0} libras',
            perUnitPattern: '{0} por libra',
          },
          'mass-ounce': {
            displayName: 'onzas',
            'unitPattern-count-one': '{0} onza',
            'unitPattern-count-other': '{0} onzas',
            perUnitPattern: '{0} por onza',
          },
          'mass-ounce-troy': {
            displayName: 'onzas troy',
            'unitPattern-count-one': '{0} onza troy',
            'unitPattern-count-other': '{0} onzas troy',
          },
          'mass-carat': {
            displayName: 'quilates',
            'unitPattern-count-one': '{0} quilate',
            'unitPattern-count-other': '{0} quilates',
          },
          'mass-dalton': {
            displayName: 'daltones',
            'unitPattern-count-one': '{0} dalton',
            'unitPattern-count-other': '{0} daltones',
          },
          'mass-earth-mass': {
            displayName: 'masas terrestres',
            'unitPattern-count-one': '{0} masa terrestre',
            'unitPattern-count-other': '{0} masas terrestres',
          },
          'mass-solar-mass': {
            displayName: 'masas solares',
            'unitPattern-count-one': '{0} masa solar',
            'unitPattern-count-other': '{0} masas solares',
          },
          'power-gigawatt': {
            displayName: 'gigavatios',
            'unitPattern-count-one': '{0} gigavatio',
            'unitPattern-count-other': '{0} gigavatios',
          },
          'power-megawatt': {
            displayName: 'megavatios',
            'unitPattern-count-one': '{0} megavatio',
            'unitPattern-count-other': '{0} megavatios',
          },
          'power-kilowatt': {
            displayName: 'kilovatios',
            'unitPattern-count-one': '{0} kilovatio',
            'unitPattern-count-other': '{0} kilovatios',
          },
          'power-watt': {
            displayName: 'vatios',
            'unitPattern-count-one': '{0} vatio',
            'unitPattern-count-other': '{0} vatios',
          },
          'power-milliwatt': {
            displayName: 'milivatios',
            'unitPattern-count-one': '{0} milivatio',
            'unitPattern-count-other': '{0} milivatios',
          },
          'power-horsepower': {
            displayName: 'caballos de vapor',
            'unitPattern-count-one': '{0} caballo de vapor',
            'unitPattern-count-other': '{0} caballos de vapor',
          },
          'pressure-millimeter-of-mercury': {
            displayName: 'milímetros de mercurio',
            'unitPattern-count-one': '{0} milímetro de mercurio',
            'unitPattern-count-other': '{0} milímetros de mercurio',
          },
          'pressure-pound-per-square-inch': {
            displayName: 'libras por pulgada cuadrada',
            'unitPattern-count-one': '{0} libra por pulgada cuadrada',
            'unitPattern-count-other': '{0} libras por pulgada cuadrada',
          },
          'pressure-inch-hg': {
            displayName: 'pulgadas de mercurio',
            'unitPattern-count-one': '{0} pulgada de mercurio',
            'unitPattern-count-other': '{0} pulgadas de mercurio',
          },
          'pressure-bar': {
            displayName: 'bares',
            'unitPattern-count-one': '{0} bar',
            'unitPattern-count-other': '{0} bares',
          },
          'pressure-millibar': {
            displayName: 'milibares',
            'unitPattern-count-one': '{0} milibar',
            'unitPattern-count-other': '{0} milibares',
          },
          'pressure-atmosphere': {
            displayName: 'atmósferas',
            'unitPattern-count-one': '{0} atmósfera',
            'unitPattern-count-other': '{0} atmósferas',
          },
          'pressure-pascal': {
            displayName: 'pascales',
            'unitPattern-count-one': '{0} pascal',
            'unitPattern-count-other': '{0} pascales',
          },
          'pressure-hectopascal': {
            displayName: 'hectopascales',
            'unitPattern-count-one': '{0} hectopascal',
            'unitPattern-count-other': '{0} hectopascales',
          },
          'pressure-kilopascal': {
            displayName: 'kilopascales',
            'unitPattern-count-one': '{0} kilopascal',
            'unitPattern-count-other': '{0} kilopascales',
          },
          'pressure-megapascal': {
            displayName: 'megapascales',
            'unitPattern-count-one': '{0} megapascal',
            'unitPattern-count-other': '{0} megapascales',
          },
          'speed-kilometer-per-hour': {
            displayName: 'kilómetros por hora',
            'unitPattern-count-one': '{0} kilómetro por hora',
            'unitPattern-count-other': '{0} kilómetros por hora',
          },
          'speed-meter-per-second': {
            displayName: 'metros por segundo',
            'unitPattern-count-one': '{0} metro por segundo',
            'unitPattern-count-other': '{0} metros por segundo',
          },
          'speed-mile-per-hour': {
            displayName: 'millas por hora',
            'unitPattern-count-one': '{0} milla por hora',
            'unitPattern-count-other': '{0} millas por hora',
          },
          'speed-knot': {
            displayName: 'nudos',
            'unitPattern-count-one': '{0} nudo',
            'unitPattern-count-other': '{0} nudos',
          },
          'temperature-generic': {
            displayName: '°',
            'unitPattern-count-one': '{0}°',
            'unitPattern-count-other': '{0}°',
          },
          'temperature-celsius': {
            displayName: 'grados Celsius',
            'unitPattern-count-one': '{0} grado Celsius',
            'unitPattern-count-other': '{0} grados Celsius',
          },
          'temperature-fahrenheit': {
            displayName: 'grados Fahrenheit',
            'unitPattern-count-one': '{0} grado Fahrenheit',
            'unitPattern-count-other': '{0} grados Fahrenheit',
          },
          'temperature-kelvin': {
            displayName: 'kelvin',
            'unitPattern-count-one': '{0} kelvin',
            'unitPattern-count-other': '{0} kelvin',
          },
          'torque-pound-foot': {
            displayName: 'libra pies',
            'unitPattern-count-one': '{0} libra pie',
            'unitPattern-count-other': '{0} libras pies',
          },
          'torque-newton-meter': {
            displayName: 'newton metro',
            'unitPattern-count-one': '{0} newton metro',
            'unitPattern-count-other': '{0} newton metros',
          },
          'volume-cubic-kilometer': {
            displayName: 'kilómetros cúbicos',
            'unitPattern-count-one': '{0} kilómetro cúbico',
            'unitPattern-count-other': '{0} kilómetros cúbicos',
          },
          'volume-cubic-meter': {
            displayName: 'metros cúbicos',
            'unitPattern-count-one': '{0} metro cúbico',
            'unitPattern-count-other': '{0} metros cúbicos',
            perUnitPattern: '{0} por metro cúbico',
          },
          'volume-cubic-centimeter': {
            displayName: 'centímetros cúbicos',
            'unitPattern-count-one': '{0} centímetro cúbico',
            'unitPattern-count-other': '{0} centímetros cúbicos',
            perUnitPattern: '{0} por centímetro cúbico',
          },
          'volume-cubic-mile': {
            displayName: 'millas cúbicas',
            'unitPattern-count-one': '{0} milla cúbica',
            'unitPattern-count-other': '{0} millas cúbicas',
          },
          'volume-cubic-yard': {
            displayName: 'yardas cúbicas',
            'unitPattern-count-one': '{0} yarda cúbica',
            'unitPattern-count-other': '{0} yardas cúbicas',
          },
          'volume-cubic-foot': {
            displayName: 'pies cúbicos',
            'unitPattern-count-one': '{0} pie cúbico',
            'unitPattern-count-other': '{0} pies cúbicos',
          },
          'volume-cubic-inch': {
            displayName: 'pulgadas cúbicas',
            'unitPattern-count-one': '{0} pulgada cúbica',
            'unitPattern-count-other': '{0} pulgadas cúbicas',
          },
          'volume-megaliter': {
            displayName: 'megalitros',
            'unitPattern-count-one': '{0} megalitro',
            'unitPattern-count-other': '{0} megalitros',
          },
          'volume-hectoliter': {
            displayName: 'hectolitros',
            'unitPattern-count-one': '{0} hectolitro',
            'unitPattern-count-other': '{0} hectolitros',
          },
          'volume-liter': {
            displayName: 'litros',
            'unitPattern-count-one': '{0} litro',
            'unitPattern-count-other': '{0} litros',
            perUnitPattern: '{0} por litro',
          },
          'volume-deciliter': {
            displayName: 'decilitros',
            'unitPattern-count-one': '{0} decilitro',
            'unitPattern-count-other': '{0} decilitros',
          },
          'volume-centiliter': {
            displayName: 'centilitros',
            'unitPattern-count-one': '{0} centilitro',
            'unitPattern-count-other': '{0} centilitros',
          },
          'volume-milliliter': {
            displayName: 'mililitros',
            'unitPattern-count-one': '{0} mililitro',
            'unitPattern-count-other': '{0} mililitros',
          },
          'volume-pint-metric': {
            displayName: 'pintas métricas',
            'unitPattern-count-one': '{0} pinta métrica',
            'unitPattern-count-other': '{0} pintas métricas',
          },
          'volume-cup-metric': {
            displayName: 'tazas métricas',
            'unitPattern-count-one': '{0} taza métrica',
            'unitPattern-count-other': '{0} tazas métricas',
          },
          'volume-acre-foot': {
            displayName: 'acres-pies',
            'unitPattern-count-one': '{0} acre-pie',
            'unitPattern-count-other': '{0} acres-pies',
          },
          'volume-bushel': {
            displayName: 'bu',
            'unitPattern-count-other': '{0} bu',
          },
          'volume-gallon': {
            displayName: 'galones',
            'unitPattern-count-one': '{0} galón',
            'unitPattern-count-other': '{0} galones',
            perUnitPattern: '{0} por galón',
          },
          'volume-gallon-imperial': {
            displayName: 'galones imperiales',
            'unitPattern-count-one': '{0} galón imperial',
            'unitPattern-count-other': '{0} galones imperiales',
            perUnitPattern: '{0} por galón imperial',
          },
          'volume-quart': {
            displayName: 'cuartos',
            'unitPattern-count-one': '{0} cuarto',
            'unitPattern-count-other': '{0} cuartos',
          },
          'volume-pint': {
            displayName: 'pintas',
            'unitPattern-count-one': '{0} pinta',
            'unitPattern-count-other': '{0} pintas',
          },
          'volume-cup': {
            displayName: 'tazas',
            'unitPattern-count-one': '{0} taza',
            'unitPattern-count-other': '{0} tazas',
          },
          'volume-fluid-ounce': {
            displayName: 'onzas líquidas',
            'unitPattern-count-one': '{0} onza líquida',
            'unitPattern-count-other': '{0} onzas líquidas',
          },
          'volume-fluid-ounce-imperial': {
            displayName: 'onzas líquidas imperiales',
            'unitPattern-count-one': '{0} onza líquida imperial',
            'unitPattern-count-other': '{0} onzas líquidas imperiales',
          },
          'volume-tablespoon': {
            displayName: 'cucharadas',
            'unitPattern-count-one': '{0} cucharada',
            'unitPattern-count-other': '{0} cucharadas',
          },
          'volume-teaspoon': {
            displayName: 'cucharaditas',
            'unitPattern-count-one': '{0} cucharadita',
            'unitPattern-count-other': '{0} cucharaditas',
          },
          'volume-barrel': {
            displayName: 'barriles',
            'unitPattern-count-one': '{0} barril',
            'unitPattern-count-other': '{0} barriles',
          },
          coordinateUnit: {
            displayName: 'punto cardinal',
            east: '{0} E',
            north: '{0} N',
            south: '{0} S',
            west: '{0} O',
          },
        },
        short: {
          per: {
            compoundUnitPattern: '{0}/{1}',
          },
          times: {
            compoundUnitPattern: '{0}⋅{1}',
          },
          'acceleration-g-force': {
            displayName: 'Fg',
            'unitPattern-count-one': '{0} Fg',
            'unitPattern-count-other': '{0} Fg',
          },
          'acceleration-meter-per-second-squared': {
            displayName: 'm/s²',
            'unitPattern-count-one': '{0} m/s²',
            'unitPattern-count-other': '{0} m/s²',
          },
          'angle-revolution': {
            displayName: 'rev',
            'unitPattern-count-one': '{0} rev',
            'unitPattern-count-other': '{0} rev',
          },
          'angle-radian': {
            displayName: 'rad',
            'unitPattern-count-one': '{0} rad',
            'unitPattern-count-other': '{0} rad',
          },
          'angle-degree': {
            displayName: 'grad.',
            'unitPattern-count-one': '{0}°',
            'unitPattern-count-other': '{0}°',
          },
          'angle-arc-minute': {
            displayName: 'arcmin',
            'unitPattern-count-one': '{0} arcmin',
            'unitPattern-count-other': '{0} arcmin',
          },
          'angle-arc-second': {
            displayName: 'arcsec',
            'unitPattern-count-one': '{0} arcsec',
            'unitPattern-count-other': '{0} arcsec',
          },
          'area-square-kilometer': {
            displayName: 'km²',
            'unitPattern-count-one': '{0} km²',
            'unitPattern-count-other': '{0} km²',
            perUnitPattern: '{0}/km²',
          },
          'area-hectare': {
            displayName: 'ha',
            'unitPattern-count-one': '{0} ha',
            'unitPattern-count-other': '{0} ha',
          },
          'area-square-meter': {
            displayName: 'm²',
            'unitPattern-count-one': '{0} m²',
            'unitPattern-count-other': '{0} m²',
            perUnitPattern: '{0}/m²',
          },
          'area-square-centimeter': {
            displayName: 'cm²',
            'unitPattern-count-one': '{0} cm²',
            'unitPattern-count-other': '{0} cm²',
            perUnitPattern: '{0}/cm²',
          },
          'area-square-mile': {
            displayName: 'mi²',
            'unitPattern-count-one': '{0} mi²',
            'unitPattern-count-other': '{0} mi²',
            perUnitPattern: '{0}/mi²',
          },
          'area-acre': {
            displayName: 'ac',
            'unitPattern-count-one': '{0} ac',
            'unitPattern-count-other': '{0} ac',
          },
          'area-square-yard': {
            displayName: 'yd²',
            'unitPattern-count-one': '{0} yd²',
            'unitPattern-count-other': '{0} yd²',
          },
          'area-square-foot': {
            displayName: 'ft²',
            'unitPattern-count-one': '{0} ft²',
            'unitPattern-count-other': '{0} ft²',
          },
          'area-square-inch': {
            displayName: 'in²',
            'unitPattern-count-one': '{0} in²',
            'unitPattern-count-other': '{0} in²',
            perUnitPattern: '{0}/in²',
          },
          'area-dunam': {
            displayName: 'dunams',
            'unitPattern-count-one': '{0} dunam',
            'unitPattern-count-other': '{0} dunams',
          },
          'concentr-karat': {
            displayName: 'ct',
            'unitPattern-count-one': '{0} ct',
            'unitPattern-count-other': '{0} ct',
          },
          'concentr-milligram-per-deciliter': {
            displayName: 'mg/dL',
            'unitPattern-count-one': '{0} mg/dL',
            'unitPattern-count-other': '{0} mg/dL',
          },
          'concentr-millimole-per-liter': {
            displayName: 'mmol/L',
            'unitPattern-count-one': '{0} mmol/L',
            'unitPattern-count-other': '{0} mmol/L',
          },
          'concentr-part-per-million': {
            displayName: 'ppm',
            'unitPattern-count-one': '{0} ppm',
            'unitPattern-count-other': '{0} ppm',
          },
          'concentr-percent': {
            displayName: 'por ciento',
            'unitPattern-count-one': '{0} %',
            'unitPattern-count-other': '{0} %',
          },
          'concentr-permille': {
            displayName: 'por mil',
            'unitPattern-count-one': '{0} ‰',
            'unitPattern-count-other': '{0} ‰',
          },
          'concentr-permyriad': {
            displayName: 'por diez mil',
            'unitPattern-count-one': '{0} ‱',
            'unitPattern-count-other': '{0} ‱',
          },
          'concentr-mole': {
            displayName: 'mol',
            'unitPattern-count-one': '{0} moles',
            'unitPattern-count-other': '{0} moles',
          },
          'consumption-liter-per-kilometer': {
            displayName: 'l/km',
            'unitPattern-count-one': '{0} l/km',
            'unitPattern-count-other': '{0} l/km',
          },
          'consumption-liter-per-100kilometers': {
            displayName: 'l/100 km',
            'unitPattern-count-one': '{0} l/100 km',
            'unitPattern-count-other': '{0} l/100 km',
          },
          'consumption-mile-per-gallon': {
            displayName: 'mi/gal',
            'unitPattern-count-one': '{0} mi/gal',
            'unitPattern-count-other': '{0} mi/gal',
          },
          'consumption-mile-per-gallon-imperial': {
            displayName: 'mi/gal imp.',
            'unitPattern-count-one': '{0} mi/gal imp.',
            'unitPattern-count-other': '{0} mi/gal imp.',
          },
          'digital-petabyte': {
            displayName: 'PB',
            'unitPattern-count-one': '{0} PB',
            'unitPattern-count-other': '{0} PB',
          },
          'digital-terabyte': {
            displayName: 'TB',
            'unitPattern-count-one': '{0} TB',
            'unitPattern-count-other': '{0} TB',
          },
          'digital-terabit': {
            displayName: 'Tb',
            'unitPattern-count-one': '{0} Tb',
            'unitPattern-count-other': '{0} Tb',
          },
          'digital-gigabyte': {
            displayName: 'GB',
            'unitPattern-count-one': '{0} GB',
            'unitPattern-count-other': '{0} GB',
          },
          'digital-gigabit': {
            displayName: 'Gb',
            'unitPattern-count-one': '{0} Gb',
            'unitPattern-count-other': '{0} Gb',
          },
          'digital-megabyte': {
            displayName: 'MB',
            'unitPattern-count-one': '{0} MB',
            'unitPattern-count-other': '{0} MB',
          },
          'digital-megabit': {
            displayName: 'Mb',
            'unitPattern-count-one': '{0} Mb',
            'unitPattern-count-other': '{0} Mb',
          },
          'digital-kilobyte': {
            displayName: 'kB',
            'unitPattern-count-one': '{0} kB',
            'unitPattern-count-other': '{0} kB',
          },
          'digital-kilobit': {
            displayName: 'kb',
            'unitPattern-count-one': '{0} kb',
            'unitPattern-count-other': '{0} kb',
          },
          'digital-byte': {
            displayName: 'B',
            'unitPattern-count-one': '{0} B',
            'unitPattern-count-other': '{0} B',
          },
          'digital-bit': {
            displayName: 'b',
            'unitPattern-count-one': '{0} b',
            'unitPattern-count-other': '{0} b',
          },
          'duration-century': {
            displayName: 's.',
            'unitPattern-count-one': '{0} s.',
            'unitPattern-count-other': '{0} s.',
          },
          'duration-decade': {
            displayName: 'déc.',
            'unitPattern-count-one': '{0} déc.',
            'unitPattern-count-other': '{0} déc.',
          },
          'duration-year': {
            displayName: 'a',
            'unitPattern-count-one': '{0} a',
            'unitPattern-count-other': '{0} a',
            perUnitPattern: '{0}/a',
          },
          'duration-month': {
            displayName: 'm.',
            'unitPattern-count-one': '{0} m.',
            'unitPattern-count-other': '{0} m.',
            perUnitPattern: '{0}/m.',
          },
          'duration-week': {
            displayName: 'sem.',
            'unitPattern-count-one': '{0} sem.',
            'unitPattern-count-other': '{0} sem.',
            perUnitPattern: '{0}/sem.',
          },
          'duration-day': {
            displayName: 'd',
            'unitPattern-count-one': '{0} d',
            'unitPattern-count-other': '{0} d',
            perUnitPattern: '{0}/d',
          },
          'duration-hour': {
            displayName: 'horas',
            'unitPattern-count-one': '{0} h',
            'unitPattern-count-other': '{0} h',
            perUnitPattern: '{0}/h',
          },
          'duration-minute': {
            displayName: 'min',
            'unitPattern-count-one': '{0} min',
            'unitPattern-count-other': '{0} min',
            perUnitPattern: '{0}/min',
          },
          'duration-second': {
            displayName: 's',
            'unitPattern-count-one': '{0} s',
            'unitPattern-count-other': '{0} s',
            perUnitPattern: '{0}/s',
          },
          'duration-millisecond': {
            displayName: 'ms',
            'unitPattern-count-one': '{0} ms',
            'unitPattern-count-other': '{0} ms',
          },
          'duration-microsecond': {
            displayName: 'μs',
            'unitPattern-count-one': '{0} μs',
            'unitPattern-count-other': '{0} μs',
          },
          'duration-nanosecond': {
            displayName: 'ns',
            'unitPattern-count-one': '{0} ns',
            'unitPattern-count-other': '{0} ns',
          },
          'electric-ampere': {
            displayName: 'A',
            'unitPattern-count-one': '{0} A',
            'unitPattern-count-other': '{0} A',
          },
          'electric-milliampere': {
            displayName: 'mA',
            'unitPattern-count-one': '{0} mA',
            'unitPattern-count-other': '{0} mA',
          },
          'electric-ohm': {
            displayName: 'Ω',
            'unitPattern-count-one': '{0} Ω',
            'unitPattern-count-other': '{0} Ω',
          },
          'electric-volt': {
            displayName: 'voltios',
            'unitPattern-count-one': '{0} V',
            'unitPattern-count-other': '{0} V',
          },
          'energy-kilocalorie': {
            displayName: 'kcal',
            'unitPattern-count-one': '{0} kcal',
            'unitPattern-count-other': '{0} kcal',
          },
          'energy-calorie': {
            displayName: 'cal',
            'unitPattern-count-one': '{0} cal',
            'unitPattern-count-other': '{0} cal',
          },
          'energy-foodcalorie': {
            displayName: 'cal',
            'unitPattern-count-one': '{0} cal',
            'unitPattern-count-other': '{0} cal',
          },
          'energy-kilojoule': {
            displayName: 'kJ',
            'unitPattern-count-one': '{0} kJ',
            'unitPattern-count-other': '{0} kJ',
          },
          'energy-joule': {
            displayName: 'J',
            'unitPattern-count-one': '{0} J',
            'unitPattern-count-other': '{0} J',
          },
          'energy-kilowatt-hour': {
            displayName: 'kWh',
            'unitPattern-count-one': '{0} kWh',
            'unitPattern-count-other': '{0} kWh',
          },
          'energy-electronvolt': {
            displayName: 'electronvoltio',
            'unitPattern-count-other': '{0} eV',
          },
          'energy-british-thermal-unit': {
            displayName: 'BTU',
            'unitPattern-count-one': '{0} BTU',
            'unitPattern-count-other': '{0} BTU',
          },
          'energy-therm-us': {
            displayName: 'thm EE. UU.',
            'unitPattern-count-one': '{0} thm EE. UU.',
            'unitPattern-count-other': '{0} thm EE. UU.',
          },
          'force-pound-force': {
            displayName: 'libra fuerza',
            'unitPattern-count-other': '{0} lbf',
          },
          'force-newton': {
            displayName: 'newton',
            'unitPattern-count-other': '{0} N',
          },
          'frequency-gigahertz': {
            displayName: 'GHz',
            'unitPattern-count-one': '{0} GHz',
            'unitPattern-count-other': '{0} GHz',
          },
          'frequency-megahertz': {
            displayName: 'MHz',
            'unitPattern-count-one': '{0} MHz',
            'unitPattern-count-other': '{0} MHz',
          },
          'frequency-kilohertz': {
            displayName: 'kHz',
            'unitPattern-count-one': '{0} kHz',
            'unitPattern-count-other': '{0} kHz',
          },
          'frequency-hertz': {
            displayName: 'Hz',
            'unitPattern-count-one': '{0} Hz',
            'unitPattern-count-other': '{0} Hz',
          },
          'graphics-em': {
            displayName: 'em',
            'unitPattern-count-one': '{0} em',
            'unitPattern-count-other': '{0} em',
          },
          'graphics-pixel': {
            displayName: 'px',
            'unitPattern-count-one': '{0} px',
            'unitPattern-count-other': '{0} px',
          },
          'graphics-megapixel': {
            displayName: 'Mpx',
            'unitPattern-count-one': '{0} Mpx',
            'unitPattern-count-other': '{0} Mpx',
          },
          'graphics-pixel-per-centimeter': {
            displayName: 'px/cm',
            'unitPattern-count-one': '{0} px/cm',
            'unitPattern-count-other': '{0} px/cm',
          },
          'graphics-pixel-per-inch': {
            displayName: 'px/in',
            'unitPattern-count-one': '{0} px/in',
            'unitPattern-count-other': '{0} px/in',
          },
          'graphics-dot-per-centimeter': {
            displayName: 'ppcm',
            'unitPattern-count-one': '{0} ppcm',
            'unitPattern-count-other': '{0} ppcm',
          },
          'graphics-dot-per-inch': {
            displayName: 'ppp',
            'unitPattern-count-one': '{0} ppp',
            'unitPattern-count-other': '{0} ppp',
          },
          'length-kilometer': {
            displayName: 'km',
            'unitPattern-count-one': '{0} km',
            'unitPattern-count-other': '{0} km',
            perUnitPattern: '{0}/km',
          },
          'length-meter': {
            displayName: 'm',
            'unitPattern-count-one': '{0} m',
            'unitPattern-count-other': '{0} m',
            perUnitPattern: '{0}/m',
          },
          'length-decimeter': {
            displayName: 'dm',
            'unitPattern-count-one': '{0} dm',
            'unitPattern-count-other': '{0} dm',
          },
          'length-centimeter': {
            displayName: 'cm',
            'unitPattern-count-one': '{0} cm',
            'unitPattern-count-other': '{0} cm',
            perUnitPattern: '{0}/cm',
          },
          'length-millimeter': {
            displayName: 'mm',
            'unitPattern-count-one': '{0} mm',
            'unitPattern-count-other': '{0} mm',
          },
          'length-micrometer': {
            displayName: 'µm',
            'unitPattern-count-one': '{0} µm',
            'unitPattern-count-other': '{0} µm',
          },
          'length-nanometer': {
            displayName: 'nm',
            'unitPattern-count-one': '{0} nm',
            'unitPattern-count-other': '{0} nm',
          },
          'length-picometer': {
            displayName: 'pm',
            'unitPattern-count-one': '{0} pm',
            'unitPattern-count-other': '{0} pm',
          },
          'length-mile': {
            displayName: 'mi',
            'unitPattern-count-one': '{0} mi',
            'unitPattern-count-other': '{0} mi',
          },
          'length-yard': {
            displayName: 'yd',
            'unitPattern-count-one': '{0} yd',
            'unitPattern-count-other': '{0} yd',
          },
          'length-foot': {
            displayName: 'ft',
            'unitPattern-count-one': '{0} ft',
            'unitPattern-count-other': '{0} ft',
            perUnitPattern: '{0}/ft',
          },
          'length-inch': {
            displayName: 'in',
            'unitPattern-count-one': '{0} in',
            'unitPattern-count-other': '{0} in',
            perUnitPattern: '{0}/in',
          },
          'length-parsec': {
            displayName: 'pc',
            'unitPattern-count-one': '{0} pc',
            'unitPattern-count-other': '{0} pc',
          },
          'length-light-year': {
            displayName: 'a. l.',
            'unitPattern-count-one': '{0} a. l.',
            'unitPattern-count-other': '{0} a. l.',
          },
          'length-astronomical-unit': {
            displayName: 'au',
            'unitPattern-count-one': '{0} au',
            'unitPattern-count-other': '{0} au',
          },
          'length-furlong': {
            displayName: 'fur',
            'unitPattern-count-other': '{0} fur',
          },
          'length-fathom': {
            displayName: 'fm',
            'unitPattern-count-other': '{0} fth',
          },
          'length-nautical-mile': {
            displayName: 'M',
            'unitPattern-count-one': '{0} M',
            'unitPattern-count-other': '{0} M',
          },
          'length-mile-scandinavian': {
            displayName: 'mi esc.',
            'unitPattern-count-one': '{0} mi esc.',
            'unitPattern-count-other': '{0} mi esc.',
          },
          'length-point': {
            displayName: 'puntos',
            'unitPattern-count-one': '{0} pto.',
            'unitPattern-count-other': '{0} ptos.',
          },
          'length-solar-radius': {
            displayName: 'radios solares',
            'unitPattern-count-other': '{0} R☉',
          },
          'light-lux': {
            displayName: 'lx',
            'unitPattern-count-one': '{0} lx',
            'unitPattern-count-other': '{0} lx',
          },
          'light-solar-luminosity': {
            displayName: 'luminosidades solares',
            'unitPattern-count-other': '{0} L☉',
          },
          'mass-metric-ton': {
            displayName: 'tm',
            'unitPattern-count-one': '{0} tm',
            'unitPattern-count-other': '{0} tm',
          },
          'mass-kilogram': {
            displayName: 'kg',
            'unitPattern-count-one': '{0} kg',
            'unitPattern-count-other': '{0} kg',
            perUnitPattern: '{0}/kg',
          },
          'mass-gram': {
            displayName: 'g',
            'unitPattern-count-one': '{0} g',
            'unitPattern-count-other': '{0} g',
            perUnitPattern: '{0}/g',
          },
          'mass-milligram': {
            displayName: 'mg',
            'unitPattern-count-one': '{0} mg',
            'unitPattern-count-other': '{0} mg',
          },
          'mass-microgram': {
            displayName: 'µg',
            'unitPattern-count-one': '{0} µg',
            'unitPattern-count-other': '{0} µg',
          },
          'mass-ton': {
            displayName: 't',
            'unitPattern-count-one': '{0} t',
            'unitPattern-count-other': '{0} t',
          },
          'mass-stone': {
            displayName: 'st',
            'unitPattern-count-other': '{0} st',
          },
          'mass-pound': {
            displayName: 'lb',
            'unitPattern-count-one': '{0} lb',
            'unitPattern-count-other': '{0} lb',
            perUnitPattern: '{0}/lb',
          },
          'mass-ounce': {
            displayName: 'oz',
            'unitPattern-count-one': '{0} oz',
            'unitPattern-count-other': '{0} oz',
            perUnitPattern: '{0}/oz',
          },
          'mass-ounce-troy': {
            displayName: 'oz t',
            'unitPattern-count-one': '{0} oz t',
            'unitPattern-count-other': '{0} oz t',
          },
          'mass-carat': {
            displayName: 'c',
            'unitPattern-count-one': '{0} c',
            'unitPattern-count-other': '{0} c',
          },
          'mass-dalton': {
            displayName: 'daltones',
            'unitPattern-count-other': '{0} Da',
          },
          'mass-earth-mass': {
            displayName: 'masas terrestres',
            'unitPattern-count-other': '{0} M⊕',
          },
          'mass-solar-mass': {
            displayName: 'masas solares',
            'unitPattern-count-other': '{0} M☉',
          },
          'power-gigawatt': {
            displayName: 'GW',
            'unitPattern-count-one': '{0} GW',
            'unitPattern-count-other': '{0} GW',
          },
          'power-megawatt': {
            displayName: 'MW',
            'unitPattern-count-one': '{0} MW',
            'unitPattern-count-other': '{0} MW',
          },
          'power-kilowatt': {
            displayName: 'kW',
            'unitPattern-count-one': '{0} kW',
            'unitPattern-count-other': '{0} kW',
          },
          'power-watt': {
            displayName: 'vatios',
            'unitPattern-count-one': '{0} W',
            'unitPattern-count-other': '{0} W',
          },
          'power-milliwatt': {
            displayName: 'mW',
            'unitPattern-count-one': '{0} mW',
            'unitPattern-count-other': '{0} mW',
          },
          'power-horsepower': {
            displayName: 'CV',
            'unitPattern-count-one': '{0} CV',
            'unitPattern-count-other': '{0} CV',
          },
          'pressure-millimeter-of-mercury': {
            displayName: 'mmHg',
            'unitPattern-count-one': '{0} mmHg',
            'unitPattern-count-other': '{0} mmHg',
          },
          'pressure-pound-per-square-inch': {
            displayName: 'psi',
            'unitPattern-count-one': '{0} psi',
            'unitPattern-count-other': '{0} psi',
          },
          'pressure-inch-hg': {
            displayName: 'inHg',
            'unitPattern-count-one': '{0} inHg',
            'unitPattern-count-other': '{0} inHg',
          },
          'pressure-bar': {
            displayName: 'bar',
            'unitPattern-count-one': '{0} bar',
            'unitPattern-count-other': '{0} bar',
          },
          'pressure-millibar': {
            displayName: 'mbar',
            'unitPattern-count-one': '{0} mbar',
            'unitPattern-count-other': '{0} mbar',
          },
          'pressure-atmosphere': {
            displayName: 'atm',
            'unitPattern-count-one': '{0} atm',
            'unitPattern-count-other': '{0} atm',
          },
          'pressure-pascal': {
            displayName: 'Pa',
            'unitPattern-count-one': '{0} Pa',
            'unitPattern-count-other': '{0} Pa',
          },
          'pressure-hectopascal': {
            displayName: 'hPa',
            'unitPattern-count-one': '{0} hPa',
            'unitPattern-count-other': '{0} hPa',
          },
          'pressure-kilopascal': {
            displayName: 'kPa',
            'unitPattern-count-other': '{0} kPa',
          },
          'pressure-megapascal': {
            displayName: 'MPa',
            'unitPattern-count-other': '{0} MPa',
          },
          'speed-kilometer-per-hour': {
            displayName: 'km/h',
            'unitPattern-count-one': '{0} km/h',
            'unitPattern-count-other': '{0} km/h',
          },
          'speed-meter-per-second': {
            displayName: 'm/s',
            'unitPattern-count-one': '{0} m/s',
            'unitPattern-count-other': '{0} m/s',
          },
          'speed-mile-per-hour': {
            displayName: 'mi/h',
            'unitPattern-count-one': '{0} mi/h',
            'unitPattern-count-other': '{0} mi/h',
          },
          'speed-knot': {
            displayName: 'kn',
            'unitPattern-count-one': '{0} kn',
            'unitPattern-count-other': '{0} kn',
          },
          'temperature-generic': {
            displayName: '°',
            'unitPattern-count-one': '{0}°',
            'unitPattern-count-other': '{0}°',
          },
          'temperature-celsius': {
            displayName: '°C',
            'unitPattern-count-one': '{0} °C',
            'unitPattern-count-other': '{0} °C',
          },
          'temperature-fahrenheit': {
            displayName: '°F',
            'unitPattern-count-one': '{0} °F',
            'unitPattern-count-other': '{0} °F',
          },
          'temperature-kelvin': {
            displayName: 'K',
            'unitPattern-count-one': '{0} K',
            'unitPattern-count-other': '{0} K',
          },
          'torque-pound-foot': {
            displayName: 'lbf ft',
            'unitPattern-count-one': '{0} lbf ft',
            'unitPattern-count-other': '{0} lbf ft',
          },
          'torque-newton-meter': {
            displayName: 'Nm',
            'unitPattern-count-one': '{0} Nm',
            'unitPattern-count-other': '{0} Nm',
          },
          'volume-cubic-kilometer': {
            displayName: 'km³',
            'unitPattern-count-one': '{0} km³',
            'unitPattern-count-other': '{0} km³',
          },
          'volume-cubic-meter': {
            displayName: 'm³',
            'unitPattern-count-one': '{0} m³',
            'unitPattern-count-other': '{0} m³',
            perUnitPattern: '{0}/m³',
          },
          'volume-cubic-centimeter': {
            displayName: 'cm³',
            'unitPattern-count-one': '{0} cm³',
            'unitPattern-count-other': '{0} cm³',
            perUnitPattern: '{0}/cm³',
          },
          'volume-cubic-mile': {
            displayName: 'mi³',
            'unitPattern-count-one': '{0} mi³',
            'unitPattern-count-other': '{0} mi³',
          },
          'volume-cubic-yard': {
            displayName: 'yd³',
            'unitPattern-count-one': '{0} yd³',
            'unitPattern-count-other': '{0} yd³',
          },
          'volume-cubic-foot': {
            displayName: 'ft³',
            'unitPattern-count-one': '{0} ft³',
            'unitPattern-count-other': '{0} ft³',
          },
          'volume-cubic-inch': {
            displayName: 'in³',
            'unitPattern-count-one': '{0} in³',
            'unitPattern-count-other': '{0} in³',
          },
          'volume-megaliter': {
            displayName: 'Ml',
            'unitPattern-count-one': '{0} Ml',
            'unitPattern-count-other': '{0} Ml',
          },
          'volume-hectoliter': {
            displayName: 'hl',
            'unitPattern-count-one': '{0} hl',
            'unitPattern-count-other': '{0} hl',
          },
          'volume-liter': {
            displayName: 'l',
            'unitPattern-count-one': '{0} l',
            'unitPattern-count-other': '{0} l',
            perUnitPattern: '{0}/l',
          },
          'volume-deciliter': {
            displayName: 'dl',
            'unitPattern-count-one': '{0} dl',
            'unitPattern-count-other': '{0} dl',
          },
          'volume-centiliter': {
            displayName: 'cl',
            'unitPattern-count-one': '{0} cl',
            'unitPattern-count-other': '{0} cl',
          },
          'volume-milliliter': {
            displayName: 'ml',
            'unitPattern-count-one': '{0} ml',
            'unitPattern-count-other': '{0} ml',
          },
          'volume-pint-metric': {
            displayName: 'mpt',
            'unitPattern-count-one': '{0} mpt',
            'unitPattern-count-other': '{0} mpt',
          },
          'volume-cup-metric': {
            displayName: 'mc',
            'unitPattern-count-one': '{0} mc',
            'unitPattern-count-other': '{0} mc',
          },
          'volume-acre-foot': {
            displayName: 'ac ft',
            'unitPattern-count-one': '{0} ac ft',
            'unitPattern-count-other': '{0} ac ft',
          },
          'volume-bushel': {
            displayName: 'bu',
            'unitPattern-count-other': '{0} bu',
          },
          'volume-gallon': {
            displayName: 'gal',
            'unitPattern-count-one': '{0} gal',
            'unitPattern-count-other': '{0} gal',
            perUnitPattern: '{0}/gal',
          },
          'volume-gallon-imperial': {
            displayName: 'gal imp.',
            'unitPattern-count-one': '{0} gal imp.',
            'unitPattern-count-other': '{0} gal imp.',
            perUnitPattern: '{0}/gal imp.',
          },
          'volume-quart': {
            displayName: 'qt',
            'unitPattern-count-one': '{0} qt',
            'unitPattern-count-other': '{0} qt',
          },
          'volume-pint': {
            displayName: 'pt',
            'unitPattern-count-one': '{0} pt',
            'unitPattern-count-other': '{0} pt',
          },
          'volume-cup': {
            displayName: 'tazas',
            'unitPattern-count-one': '{0} tza.',
            'unitPattern-count-other': '{0} tza.',
          },
          'volume-fluid-ounce': {
            displayName: 'fl oz',
            'unitPattern-count-one': '{0} fl oz',
            'unitPattern-count-other': '{0} fl oz',
          },
          'volume-fluid-ounce-imperial': {
            displayName: 'fl oz imp.',
            'unitPattern-count-one': '{0} fl oz imp.',
            'unitPattern-count-other': '{0} fl oz imp.',
          },
          'volume-tablespoon': {
            displayName: 'cda',
            'unitPattern-count-one': '{0} cda',
            'unitPattern-count-other': '{0} cda',
          },
          'volume-teaspoon': {
            displayName: 'cdta',
            'unitPattern-count-one': '{0} cdta',
            'unitPattern-count-other': '{0} cdta',
          },
          'volume-barrel': {
            displayName: 'barril',
            'unitPattern-count-one': '{0} bbl',
            'unitPattern-count-other': '{0} bbl',
          },
          coordinateUnit: {
            displayName: 'punto',
            east: '{0} E',
            north: '{0} N',
            south: '{0} S',
            west: '{0} O',
          },
        },
        narrow: {
          per: {
            compoundUnitPattern: '{0}/{1}',
          },
          times: {
            compoundUnitPattern: '{0}⋅{1}',
          },
          'acceleration-g-force': {
            displayName: 'Fg',
            'unitPattern-count-one': '{0}G',
            'unitPattern-count-other': '{0}G',
          },
          'acceleration-meter-per-second-squared': {
            displayName: 'm/s²',
            'unitPattern-count-one': '{0} m/s²',
            'unitPattern-count-other': '{0} m/s²',
          },
          'angle-revolution': {
            displayName: 'rev',
            'unitPattern-count-one': '{0} rev',
            'unitPattern-count-other': '{0} rev',
          },
          'angle-radian': {
            displayName: 'rad',
            'unitPattern-count-one': '{0} rad',
            'unitPattern-count-other': '{0} rad',
          },
          'angle-degree': {
            displayName: 'grad.',
            'unitPattern-count-one': '{0}°',
            'unitPattern-count-other': '{0}°',
          },
          'angle-arc-minute': {
            displayName: 'arcmin',
            'unitPattern-count-one': "{0}'",
            'unitPattern-count-other': "{0}'",
          },
          'angle-arc-second': {
            displayName: 'arcsec',
            'unitPattern-count-one': '{0}\\"',
            'unitPattern-count-other': '{0}\\"',
          },
          'area-square-kilometer': {
            displayName: 'km²',
            'unitPattern-count-one': '{0}km²',
            'unitPattern-count-other': '{0}km²',
            perUnitPattern: '{0}/km²',
          },
          'area-hectare': {
            displayName: 'ha',
            'unitPattern-count-one': '{0}ha',
            'unitPattern-count-other': '{0}ha',
          },
          'area-square-meter': {
            displayName: 'm²',
            'unitPattern-count-one': '{0}m²',
            'unitPattern-count-other': '{0}m²',
            perUnitPattern: '{0}/m²',
          },
          'area-square-centimeter': {
            displayName: 'cm²',
            'unitPattern-count-one': '{0} cm²',
            'unitPattern-count-other': '{0} cm²',
            perUnitPattern: '{0}/cm²',
          },
          'area-square-mile': {
            displayName: 'mi²',
            'unitPattern-count-one': '{0}mi²',
            'unitPattern-count-other': '{0}mi²',
            perUnitPattern: '{0}/mi²',
          },
          'area-acre': {
            displayName: 'ac',
            'unitPattern-count-one': '{0}ac',
            'unitPattern-count-other': '{0}ac',
          },
          'area-square-yard': {
            displayName: 'yd²',
            'unitPattern-count-one': '{0} yd²',
            'unitPattern-count-other': '{0} yd²',
          },
          'area-square-foot': {
            displayName: 'ft²',
            'unitPattern-count-one': '{0}ft²',
            'unitPattern-count-other': '{0}ft²',
          },
          'area-square-inch': {
            displayName: 'in²',
            'unitPattern-count-one': '{0} in²',
            'unitPattern-count-other': '{0} in²',
            perUnitPattern: '{0}/in²',
          },
          'area-dunam': {
            displayName: 'dunams',
            'unitPattern-count-one': '{0} dunam',
            'unitPattern-count-other': '{0} dunams',
          },
          'concentr-karat': {
            displayName: 'ct',
            'unitPattern-count-one': '{0} ct',
            'unitPattern-count-other': '{0} ct',
          },
          'concentr-milligram-per-deciliter': {
            displayName: 'mg/dL',
            'unitPattern-count-one': '{0} mg/dL',
            'unitPattern-count-other': '{0} mg/dL',
          },
          'concentr-millimole-per-liter': {
            displayName: 'mmol/L',
            'unitPattern-count-one': '{0} mmol/L',
            'unitPattern-count-other': '{0} mmol/L',
          },
          'concentr-part-per-million': {
            displayName: 'ppm',
            'unitPattern-count-one': '{0} ppm',
            'unitPattern-count-other': '{0} ppm',
          },
          'concentr-percent': {
            displayName: '%',
            'unitPattern-count-one': '{0} %',
            'unitPattern-count-other': '{0} %',
          },
          'concentr-permille': {
            displayName: 'por mil',
            'unitPattern-count-one': '{0} ‰',
            'unitPattern-count-other': '{0} ‰',
          },
          'concentr-permyriad': {
            displayName: 'por diez mil',
            'unitPattern-count-one': '{0} ‱',
            'unitPattern-count-other': '{0} ‱',
          },
          'concentr-mole': {
            displayName: 'mol',
            'unitPattern-count-one': '{0} moles',
            'unitPattern-count-other': '{0} moles',
          },
          'consumption-liter-per-kilometer': {
            displayName: 'l/km',
            'unitPattern-count-one': '{0} l/km',
            'unitPattern-count-other': '{0} l/km',
          },
          'consumption-liter-per-100kilometers': {
            displayName: 'l/100km',
            'unitPattern-count-one': '{0}l/100km',
            'unitPattern-count-other': '{0}l/100km',
          },
          'consumption-mile-per-gallon': {
            displayName: 'mi/gal',
            'unitPattern-count-one': '{0} mi/gal',
            'unitPattern-count-other': '{0} mi/gal',
          },
          'consumption-mile-per-gallon-imperial': {
            displayName: 'mi/gal imp.',
            'unitPattern-count-one': '{0} mi/gal imp.',
            'unitPattern-count-other': '{0} mi/gal imp.',
          },
          'digital-petabyte': {
            displayName: 'PB',
            'unitPattern-count-one': '{0} PB',
            'unitPattern-count-other': '{0} PB',
          },
          'digital-terabyte': {
            displayName: 'TB',
            'unitPattern-count-one': '{0} TB',
            'unitPattern-count-other': '{0} TB',
          },
          'digital-terabit': {
            displayName: 'Tb',
            'unitPattern-count-one': '{0} Tb',
            'unitPattern-count-other': '{0} Tb',
          },
          'digital-gigabyte': {
            displayName: 'GB',
            'unitPattern-count-one': '{0} GB',
            'unitPattern-count-other': '{0} GB',
          },
          'digital-gigabit': {
            displayName: 'Gb',
            'unitPattern-count-one': '{0} Gb',
            'unitPattern-count-other': '{0} Gb',
          },
          'digital-megabyte': {
            displayName: 'MB',
            'unitPattern-count-one': '{0} MB',
            'unitPattern-count-other': '{0} MB',
          },
          'digital-megabit': {
            displayName: 'Mb',
            'unitPattern-count-one': '{0} Mb',
            'unitPattern-count-other': '{0} Mb',
          },
          'digital-kilobyte': {
            displayName: 'kB',
            'unitPattern-count-one': '{0} kB',
            'unitPattern-count-other': '{0} kB',
          },
          'digital-kilobit': {
            displayName: 'kb',
            'unitPattern-count-one': '{0} kb',
            'unitPattern-count-other': '{0} kb',
          },
          'digital-byte': {
            displayName: 'B',
            'unitPattern-count-one': '{0} B',
            'unitPattern-count-other': '{0} B',
          },
          'digital-bit': {
            displayName: 'b',
            'unitPattern-count-one': '{0} b',
            'unitPattern-count-other': '{0} b',
          },
          'duration-century': {
            displayName: 'sig.',
            'unitPattern-count-one': '{0} sig.',
            'unitPattern-count-other': '{0} sig.',
          },
          'duration-decade': {
            displayName: 'déc.',
            'unitPattern-count-one': '{0} déc.',
            'unitPattern-count-other': '{0} déc.',
          },
          'duration-year': {
            displayName: 'a',
            'unitPattern-count-one': '{0} a',
            'unitPattern-count-other': '{0} a',
            perUnitPattern: '{0}/a',
          },
          'duration-month': {
            displayName: 'm.',
            'unitPattern-count-one': '{0} m.',
            'unitPattern-count-other': '{0} m.',
            perUnitPattern: '{0}/m.',
          },
          'duration-week': {
            displayName: 'sem.',
            'unitPattern-count-one': '{0} sem.',
            'unitPattern-count-other': '{0} sem.',
            perUnitPattern: '{0}/sem',
          },
          'duration-day': {
            displayName: 'd',
            'unitPattern-count-one': '{0} d',
            'unitPattern-count-other': '{0} d',
            perUnitPattern: '{0}/d',
          },
          'duration-hour': {
            displayName: 'h',
            'unitPattern-count-one': '{0} h',
            'unitPattern-count-other': '{0} h',
            perUnitPattern: '{0}/h',
          },
          'duration-minute': {
            displayName: 'min',
            'unitPattern-count-one': '{0} min',
            'unitPattern-count-other': '{0} min',
            perUnitPattern: '{0}/min',
          },
          'duration-second': {
            displayName: 's',
            'unitPattern-count-one': '{0} s',
            'unitPattern-count-other': '{0} s',
            perUnitPattern: '{0}/s',
          },
          'duration-millisecond': {
            displayName: 'ms',
            'unitPattern-count-one': '{0} ms',
            'unitPattern-count-other': '{0} ms',
          },
          'duration-microsecond': {
            displayName: 'μs',
            'unitPattern-count-one': '{0}μs',
            'unitPattern-count-other': '{0}μs',
          },
          'duration-nanosecond': {
            displayName: 'ns',
            'unitPattern-count-one': '{0}ns',
            'unitPattern-count-other': '{0}ns',
          },
          'electric-ampere': {
            displayName: 'A',
            'unitPattern-count-one': '{0} A',
            'unitPattern-count-other': '{0} A',
          },
          'electric-milliampere': {
            displayName: 'mA',
            'unitPattern-count-one': '{0} mA',
            'unitPattern-count-other': '{0} mA',
          },
          'electric-ohm': {
            displayName: 'Ω',
            'unitPattern-count-one': '{0} Ω',
            'unitPattern-count-other': '{0} Ω',
          },
          'electric-volt': {
            displayName: 'voltios',
            'unitPattern-count-one': '{0} V',
            'unitPattern-count-other': '{0} V',
          },
          'energy-kilocalorie': {
            displayName: 'kcal',
            'unitPattern-count-one': '{0} kcal',
            'unitPattern-count-other': '{0} kcal',
          },
          'energy-calorie': {
            displayName: 'cal',
            'unitPattern-count-one': '{0} cal',
            'unitPattern-count-other': '{0} cal',
          },
          'energy-foodcalorie': {
            displayName: 'cal',
            'unitPattern-count-one': '{0} cal',
            'unitPattern-count-other': '{0} cal',
          },
          'energy-kilojoule': {
            displayName: 'kJ',
            'unitPattern-count-one': '{0} kJ',
            'unitPattern-count-other': '{0} kJ',
          },
          'energy-joule': {
            displayName: 'J',
            'unitPattern-count-one': '{0} J',
            'unitPattern-count-other': '{0} J',
          },
          'energy-kilowatt-hour': {
            displayName: 'kWh',
            'unitPattern-count-one': '{0} kWh',
            'unitPattern-count-other': '{0} kWh',
          },
          'energy-electronvolt': {
            displayName: 'electronvoltio',
            'unitPattern-count-other': '{0} eV',
          },
          'energy-british-thermal-unit': {
            displayName: 'BTU',
            'unitPattern-count-one': '{0} BTU',
            'unitPattern-count-other': '{0} BTU',
          },
          'energy-therm-us': {
            displayName: 'thm EE. UU.',
            'unitPattern-count-one': '{0} thm EE. UU.',
            'unitPattern-count-other': '{0} thm EE. UU.',
          },
          'force-pound-force': {
            displayName: 'libra fuerza',
            'unitPattern-count-other': '{0} lbf',
          },
          'force-newton': {
            displayName: 'newton',
            'unitPattern-count-other': '{0} N',
          },
          'frequency-gigahertz': {
            displayName: 'GHz',
            'unitPattern-count-one': '{0} GHz',
            'unitPattern-count-other': '{0} GHz',
          },
          'frequency-megahertz': {
            displayName: 'MHz',
            'unitPattern-count-one': '{0} MHz',
            'unitPattern-count-other': '{0} MHz',
          },
          'frequency-kilohertz': {
            displayName: 'kHz',
            'unitPattern-count-one': '{0} kHz',
            'unitPattern-count-other': '{0} kHz',
          },
          'frequency-hertz': {
            displayName: 'Hz',
            'unitPattern-count-one': '{0} Hz',
            'unitPattern-count-other': '{0} Hz',
          },
          'graphics-em': {
            displayName: 'em',
            'unitPattern-count-one': '{0} em',
            'unitPattern-count-other': '{0} em',
          },
          'graphics-pixel': {
            displayName: 'px',
            'unitPattern-count-one': '{0} px',
            'unitPattern-count-other': '{0} px',
          },
          'graphics-megapixel': {
            displayName: 'Mpx',
            'unitPattern-count-one': '{0} Mpx',
            'unitPattern-count-other': '{0} Mpx',
          },
          'graphics-pixel-per-centimeter': {
            displayName: 'px/cm',
            'unitPattern-count-one': '{0} px/cm',
            'unitPattern-count-other': '{0} px/cm',
          },
          'graphics-pixel-per-inch': {
            displayName: 'px/in',
            'unitPattern-count-one': '{0} px/in',
            'unitPattern-count-other': '{0} px/in',
          },
          'graphics-dot-per-centimeter': {
            displayName: 'ppcm',
            'unitPattern-count-one': '{0} ppcm',
            'unitPattern-count-other': '{0} ppcm',
          },
          'graphics-dot-per-inch': {
            displayName: 'ppp',
            'unitPattern-count-one': '{0} ppp',
            'unitPattern-count-other': '{0} ppp',
          },
          'length-kilometer': {
            displayName: 'km',
            'unitPattern-count-one': '{0}km',
            'unitPattern-count-other': '{0}km',
            perUnitPattern: '{0}/km',
          },
          'length-meter': {
            displayName: 'm',
            'unitPattern-count-one': '{0} m',
            'unitPattern-count-other': '{0} m',
            perUnitPattern: '{0}/m',
          },
          'length-decimeter': {
            displayName: 'dm',
            'unitPattern-count-one': '{0}dm',
            'unitPattern-count-other': '{0}dm',
          },
          'length-centimeter': {
            displayName: 'cm',
            'unitPattern-count-one': '{0}cm',
            'unitPattern-count-other': '{0}cm',
            perUnitPattern: '{0}/cm',
          },
          'length-millimeter': {
            displayName: 'mm',
            'unitPattern-count-one': '{0}mm',
            'unitPattern-count-other': '{0}mm',
          },
          'length-micrometer': {
            displayName: 'µm',
            'unitPattern-count-one': '{0}µm',
            'unitPattern-count-other': '{0}µm',
          },
          'length-nanometer': {
            displayName: 'nm',
            'unitPattern-count-one': '{0}nm',
            'unitPattern-count-other': '{0}nm',
          },
          'length-picometer': {
            displayName: 'pm',
            'unitPattern-count-one': '{0}pm',
            'unitPattern-count-other': '{0}pm',
          },
          'length-mile': {
            displayName: 'mi',
            'unitPattern-count-one': '{0}mi',
            'unitPattern-count-other': '{0}mi',
          },
          'length-yard': {
            displayName: 'yd',
            'unitPattern-count-one': '{0}yd',
            'unitPattern-count-other': '{0}yd',
          },
          'length-foot': {
            displayName: 'ft',
            'unitPattern-count-one': '{0}ft',
            'unitPattern-count-other': '{0}ft',
            perUnitPattern: '{0}/ft',
          },
          'length-inch': {
            displayName: 'in',
            'unitPattern-count-one': '{0}in',
            'unitPattern-count-other': '{0}in',
            perUnitPattern: '{0}/in',
          },
          'length-parsec': {
            displayName: 'pc',
            'unitPattern-count-one': '{0}pc',
            'unitPattern-count-other': '{0}pc',
          },
          'length-light-year': {
            displayName: 'a.l.',
            'unitPattern-count-one': '{0}a.l.',
            'unitPattern-count-other': '{0}a.l.',
          },
          'length-astronomical-unit': {
            displayName: 'au',
            'unitPattern-count-one': '{0}au',
            'unitPattern-count-other': '{0}au',
          },
          'length-furlong': {
            displayName: 'fur',
            'unitPattern-count-other': '{0} fur',
          },
          'length-fathom': {
            displayName: 'fm',
            'unitPattern-count-other': '{0} fth',
          },
          'length-nautical-mile': {
            displayName: 'M',
            'unitPattern-count-one': '{0} M',
            'unitPattern-count-other': '{0} M',
          },
          'length-mile-scandinavian': {
            displayName: 'mi esc.',
            'unitPattern-count-one': '{0}mi esc.',
            'unitPattern-count-other': '{0}mi esc.',
          },
          'length-point': {
            displayName: 'puntos',
            'unitPattern-count-one': '{0} pto.',
            'unitPattern-count-other': '{0} ptos.',
          },
          'length-solar-radius': {
            displayName: 'radios solares',
            'unitPattern-count-other': '{0} R☉',
          },
          'light-lux': {
            displayName: 'lx',
            'unitPattern-count-one': '{0} lx',
            'unitPattern-count-other': '{0} lx',
          },
          'light-solar-luminosity': {
            displayName: 'luminosidades solares',
            'unitPattern-count-other': '{0} L☉',
          },
          'mass-metric-ton': {
            displayName: 'tm',
            'unitPattern-count-one': '{0} tm',
            'unitPattern-count-other': '{0} tm',
          },
          'mass-kilogram': {
            displayName: 'kg',
            'unitPattern-count-one': '{0}kg',
            'unitPattern-count-other': '{0}kg',
            perUnitPattern: '{0}/kg',
          },
          'mass-gram': {
            displayName: 'g',
            'unitPattern-count-one': '{0}g',
            'unitPattern-count-other': '{0}g',
            perUnitPattern: '{0}/g',
          },
          'mass-milligram': {
            displayName: 'mg',
            'unitPattern-count-one': '{0} mg',
            'unitPattern-count-other': '{0} mg',
          },
          'mass-microgram': {
            displayName: 'µg',
            'unitPattern-count-one': '{0} µg',
            'unitPattern-count-other': '{0} µg',
          },
          'mass-ton': {
            displayName: 't',
            'unitPattern-count-one': '{0} t',
            'unitPattern-count-other': '{0} t',
          },
          'mass-stone': {
            displayName: 'st',
            'unitPattern-count-one': '{0}st',
            'unitPattern-count-other': '{0}st',
          },
          'mass-pound': {
            displayName: 'lb',
            'unitPattern-count-one': '{0}lb',
            'unitPattern-count-other': '{0}lb',
            perUnitPattern: '{0}/lb',
          },
          'mass-ounce': {
            displayName: 'oz',
            'unitPattern-count-one': '{0}oz',
            'unitPattern-count-other': '{0}oz',
            perUnitPattern: '{0}/oz',
          },
          'mass-ounce-troy': {
            displayName: 'oz t',
            'unitPattern-count-one': '{0} oz t',
            'unitPattern-count-other': '{0} oz t',
          },
          'mass-carat': {
            displayName: 'c',
            'unitPattern-count-one': '{0} c',
            'unitPattern-count-other': '{0} c',
          },
          'mass-dalton': {
            displayName: 'daltones',
            'unitPattern-count-other': '{0} Da',
          },
          'mass-earth-mass': {
            displayName: 'masas terrestres',
            'unitPattern-count-other': '{0} M⊕',
          },
          'mass-solar-mass': {
            displayName: 'masas solares',
            'unitPattern-count-other': '{0} M☉',
          },
          'power-gigawatt': {
            displayName: 'GW',
            'unitPattern-count-one': '{0} GW',
            'unitPattern-count-other': '{0} GW',
          },
          'power-megawatt': {
            displayName: 'MW',
            'unitPattern-count-one': '{0} MW',
            'unitPattern-count-other': '{0} MW',
          },
          'power-kilowatt': {
            displayName: 'kW',
            'unitPattern-count-one': '{0}kW',
            'unitPattern-count-other': '{0}kW',
          },
          'power-watt': {
            displayName: 'vatios',
            'unitPattern-count-one': '{0}W',
            'unitPattern-count-other': '{0}W',
          },
          'power-milliwatt': {
            displayName: 'mW',
            'unitPattern-count-one': '{0} mW',
            'unitPattern-count-other': '{0} mW',
          },
          'power-horsepower': {
            displayName: 'CV',
            'unitPattern-count-one': '{0}hp',
            'unitPattern-count-other': '{0}hp',
          },
          'pressure-millimeter-of-mercury': {
            displayName: 'mmHg',
            'unitPattern-count-one': '{0}mmHg',
            'unitPattern-count-other': '{0}mmHg',
          },
          'pressure-pound-per-square-inch': {
            displayName: 'psi',
            'unitPattern-count-one': '{0}psi',
            'unitPattern-count-other': '{0}psi',
          },
          'pressure-inch-hg': {
            displayName: 'inHg',
            'unitPattern-count-one': '{0}inHg',
            'unitPattern-count-other': '{0}inHg',
          },
          'pressure-bar': {
            displayName: 'bar',
            'unitPattern-count-one': '{0} bar',
            'unitPattern-count-other': '{0} bar',
          },
          'pressure-millibar': {
            displayName: 'mbar',
            'unitPattern-count-one': '{0}mbar',
            'unitPattern-count-other': '{0}mbar',
          },
          'pressure-atmosphere': {
            displayName: 'atm',
            'unitPattern-count-one': '{0} atm',
            'unitPattern-count-other': '{0} atm',
          },
          'pressure-pascal': {
            displayName: 'Pa',
            'unitPattern-count-one': '{0} Pa',
            'unitPattern-count-other': '{0} Pa',
          },
          'pressure-hectopascal': {
            displayName: 'hPa',
            'unitPattern-count-one': '{0}hPa',
            'unitPattern-count-other': '{0}hPa',
          },
          'pressure-kilopascal': {
            displayName: 'kPa',
            'unitPattern-count-other': '{0} kPa',
          },
          'pressure-megapascal': {
            displayName: 'MPa',
            'unitPattern-count-other': '{0} MPa',
          },
          'speed-kilometer-per-hour': {
            displayName: 'km/h',
            'unitPattern-count-one': '{0}km/h',
            'unitPattern-count-other': '{0}km/h',
          },
          'speed-meter-per-second': {
            displayName: 'm/s',
            'unitPattern-count-one': '{0}m/s',
            'unitPattern-count-other': '{0}m/s',
          },
          'speed-mile-per-hour': {
            displayName: 'mi/h',
            'unitPattern-count-one': '{0}mi/h',
            'unitPattern-count-other': '{0}mi/h',
          },
          'speed-knot': {
            displayName: 'kn',
            'unitPattern-count-one': '{0} kn',
            'unitPattern-count-other': '{0} kn',
          },
          'temperature-generic': {
            displayName: '°',
            'unitPattern-count-one': '{0}°',
            'unitPattern-count-other': '{0}°',
          },
          'temperature-celsius': {
            displayName: '°C',
            'unitPattern-count-one': '{0}°C',
            'unitPattern-count-other': '{0}°C',
          },
          'temperature-fahrenheit': {
            displayName: '°F',
            'unitPattern-count-one': '{0} °F',
            'unitPattern-count-other': '{0} °F',
          },
          'temperature-kelvin': {
            displayName: 'K',
            'unitPattern-count-one': '{0}K',
            'unitPattern-count-other': '{0}K',
          },
          'torque-pound-foot': {
            displayName: 'lbf ft',
            'unitPattern-count-one': '{0} lbf ft',
            'unitPattern-count-other': '{0} lbf ft',
          },
          'torque-newton-meter': {
            displayName: 'Nm',
            'unitPattern-count-one': '{0} Nm',
            'unitPattern-count-other': '{0} Nm',
          },
          'volume-cubic-kilometer': {
            displayName: 'km³',
            'unitPattern-count-one': '{0}km³',
            'unitPattern-count-other': '{0}km³',
          },
          'volume-cubic-meter': {
            displayName: 'm³',
            'unitPattern-count-one': '{0} m³',
            'unitPattern-count-other': '{0} m³',
            perUnitPattern: '{0}/m³',
          },
          'volume-cubic-centimeter': {
            displayName: 'cm³',
            'unitPattern-count-one': '{0} cm³',
            'unitPattern-count-other': '{0} cm³',
            perUnitPattern: '{0}/cm³',
          },
          'volume-cubic-mile': {
            displayName: 'mi³',
            'unitPattern-count-one': '{0}mi³',
            'unitPattern-count-other': '{0}mi³',
          },
          'volume-cubic-yard': {
            displayName: 'yd³',
            'unitPattern-count-one': '{0} yd³',
            'unitPattern-count-other': '{0} yd³',
          },
          'volume-cubic-foot': {
            displayName: 'ft³',
            'unitPattern-count-one': '{0} ft³',
            'unitPattern-count-other': '{0} ft³',
          },
          'volume-cubic-inch': {
            displayName: 'in³',
            'unitPattern-count-one': '{0} in³',
            'unitPattern-count-other': '{0} in³',
          },
          'volume-megaliter': {
            displayName: 'Ml',
            'unitPattern-count-one': '{0} Ml',
            'unitPattern-count-other': '{0} Ml',
          },
          'volume-hectoliter': {
            displayName: 'hl',
            'unitPattern-count-one': '{0} hl',
            'unitPattern-count-other': '{0} hl',
          },
          'volume-liter': {
            displayName: 'l',
            'unitPattern-count-one': '{0}l',
            'unitPattern-count-other': '{0}l',
            perUnitPattern: '{0}/l',
          },
          'volume-deciliter': {
            displayName: 'dl',
            'unitPattern-count-one': '{0} dl',
            'unitPattern-count-other': '{0} dl',
          },
          'volume-centiliter': {
            displayName: 'cl',
            'unitPattern-count-one': '{0} cl',
            'unitPattern-count-other': '{0} cl',
          },
          'volume-milliliter': {
            displayName: 'ml',
            'unitPattern-count-one': '{0} ml',
            'unitPattern-count-other': '{0} ml',
          },
          'volume-pint-metric': {
            displayName: 'mpt',
            'unitPattern-count-one': '{0} mpt',
            'unitPattern-count-other': '{0} mpt',
          },
          'volume-cup-metric': {
            displayName: 'mc',
            'unitPattern-count-one': '{0} mc',
            'unitPattern-count-other': '{0} mc',
          },
          'volume-acre-foot': {
            displayName: 'ac ft',
            'unitPattern-count-one': '{0} ac ft',
            'unitPattern-count-other': '{0} ac ft',
          },
          'volume-bushel': {
            displayName: 'bu',
            'unitPattern-count-other': '{0} bu',
          },
          'volume-gallon': {
            displayName: 'gal',
            'unitPattern-count-one': '{0} gal',
            'unitPattern-count-other': '{0} gal',
            perUnitPattern: '{0}/gal',
          },
          'volume-gallon-imperial': {
            displayName: 'gal imp.',
            'unitPattern-count-one': '{0} gal imp.',
            'unitPattern-count-other': '{0} gal imp.',
            perUnitPattern: '{0}/gal imp.',
          },
          'volume-quart': {
            displayName: 'qt',
            'unitPattern-count-one': '{0} qt',
            'unitPattern-count-other': '{0} qt',
          },
          'volume-pint': {
            displayName: 'pt',
            'unitPattern-count-one': '{0} pt',
            'unitPattern-count-other': '{0} pt',
          },
          'volume-cup': {
            displayName: 'tazas',
            'unitPattern-count-one': '{0} tza.',
            'unitPattern-count-other': '{0} tza.',
          },
          'volume-fluid-ounce': {
            displayName: 'fl oz',
            'unitPattern-count-one': '{0} fl oz',
            'unitPattern-count-other': '{0} fl oz',
          },
          'volume-fluid-ounce-imperial': {
            displayName: 'fl oz imp.',
            'unitPattern-count-one': '{0} fl oz imp.',
            'unitPattern-count-other': '{0} fl oz imp.',
          },
          'volume-tablespoon': {
            displayName: 'cda',
            'unitPattern-count-one': '{0} cda',
            'unitPattern-count-other': '{0} cda',
          },
          'volume-teaspoon': {
            displayName: 'cdta',
            'unitPattern-count-one': '{0} cdta',
            'unitPattern-count-other': '{0} cdta',
          },
          'volume-barrel': {
            displayName: 'barril',
            'unitPattern-count-one': '{0} bbl',
            'unitPattern-count-other': '{0} bbl',
          },
          coordinateUnit: {
            displayName: 'punto',
            east: '{0}E',
            north: '{0}N',
            south: '{0}S',
            west: '{0}O',
          },
        },
        'durationUnit-type-hm': {
          durationUnitPattern: 'h:mm',
        },
        'durationUnit-type-hms': {
          durationUnitPattern: 'h:mm:ss',
        },
        'durationUnit-type-ms': {
          durationUnitPattern: 'm:ss',
        },
      },
    },
  },
};
