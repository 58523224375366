export const esLanguage = {
  es: {
    grid: {
      EmptyRecord: 'No hay registros que mostrar',
      True: 'Sí',
      False: 'No',
      InvalidFilterMessage: 'Datos de filtro no válidos',
      GroupDropArea:
        'Arrastra aquí la columna por la que deseas agrupar elementos',
      UnGroup: 'Haga clic aquí para desagrupar',
      GroupDisable: 'La agrupación está deshabilitada para esta columna',
      FilterbarTitle: 'celda de barra de filtro',
      EmptyDataSourceError:
        'DataSource no debe estar vacío en la carga inicial ya que las columnas se generan a partir de dataSource en AutoGenerate Column Grid',
      Add: 'Añadir',
      Edit: 'Editar',
      Cancel: 'Cancelar',
      Update: 'Actualizar',
      Delete: 'Eliminar',
      Print: 'Impresión',
      Pdfexport: 'Exportar PDF',
      Excelexport: 'Exportación Excel',
      Wordexport: 'Exportación de palabras',
      Csvexport: 'Exportación CSV',
      Search: 'Buscar',
      Columnchooser: 'Columnas',
      Save: 'Salvar',
      Item: 'articulo',
      Items: 'artículos',
      EditOperationAlert:
        'No hay registros seleccionados para la operación de edición',
      DeleteOperationAlert:
        'No hay registros seleccionados para la operación de eliminación',
      SaveButton: 'Salvar',
      OKButton: 'Ok',
      CancelButton: 'Cancelar',
      EditFormTitle: 'Detalles de',
      AddFormTitle: 'Añadir nuevo récord',
      BatchSaveConfirm: '¿Seguro que quieres guardar los cambios?',
      BatchSaveLostChanges:
        'Los cambios no guardados se perderán. Estás seguro de que quieres continuar?',
      ConfirmDelete: '¿Estás seguro de que deseas eliminar el registro?',
      CancelEdit: '¿Estás seguro de que deseas cancelar los cambios?',
      ChooseColumns: 'Elegir columna',
      SearchColumns: 'columnas de búsqueda',
      Matchs: 'No se encontraron coincidencias',
      FilterButton: 'Filtrar',
      ClearButton: 'Claro',
      StartsWith: 'Comienza con',
      EndsWith: 'Termina con',
      Contains: 'Contiene',
      Equal: 'Igual',
      NotEqual: 'No es igual',
      LessThan: 'Menos que',
      LessThanOrEqual: 'Menor o igual',
      GreaterThan: 'Mas grande que',
      GreaterThanOrEqual: 'Mayor que o igual',
      ChooseDate: 'Elige una fecha',
      EnterValue: 'Ingrese el valor',
      Copy: 'Copiar',
      Group: 'Agrupar por esta columna',
      Ungroup: 'Desagrupar por esta columna',
      autoFitAll: 'Ajuste automático de todas las columnas',
      autoFit: 'Ajustar automáticamente esta columna',
      Export: 'Exportar',
      FirstPage: 'Primera página',
      LastPage: 'Última página',
      PreviousPage: 'Pagina anterior',
      NextPage: 'Siguiente página',
      SortAscending: 'Orden ascendente',
      SortDescending: 'Orden descendiente',
      EditRecord: 'Editar registro',
      DeleteRecord: 'Eliminar el registro',
      FilterMenu: 'Filtrar',
      SelectAll: 'Seleccionar todo',
      Blanks: 'Espacios en blanco',
      FilterTrue: 'Cierto',
      FilterFalse: 'Falso',
      NoResult: 'No se encontraron coincidencias',
      ClearFilter: 'Filtro claro',
      NumberFilter: 'Filtros de número',
      TextFilter: 'Filtros de texto',
      DateFilter: 'Filtros de fecha',
      DateTimeFilter: 'Filtros de fecha y hora',
      MatchCase: 'Match Case',
      Between: 'Entre',
      CustomFilter: 'Filtro personalizado',
      CustomFilterPlaceHolder: 'Ingrese el valor',
      CustomFilterDatePlaceHolder: 'Elige una fecha',
      AND: 'Y',
      OR: 'O',
      ShowRowsWhere: 'Mostrar filas donde:',
      UnGroupButton: 'Haga clic aquí para desagrupar',
      AutoFitAll: 'Autoajustar todas las columnas',
      AutoFit: 'Autoajustar esta columna',
      Clear: 'Clara',
      FilterMenuDialogARIA: 'Cuadro de diálogo del menú de filtro',
      ExcelFilterDialogARIA: 'Diálogo de filtro de Excel',
      DialogEditARIA: 'Cuadro de diálogo Editar',
      ColumnChooserDialogARIA: 'Cuadro de diálogo del selector de columnas',
      ColumnMenuDialogARIA: 'Diálogo de menú de columna',
      CustomFilterDialogARIA: 'Diálogo de filtro personalizado',
      SortAtoZ: 'Ordenar de la A a la Z',
      SortZtoA: 'Ordenar de Z a A',
      SortByOldest: 'Ordenar por más antigua',
      SortByNewest: 'Ordenar por más nueva',
      SortSmallestToLargest: 'Ordenar más pequeña a más grande',
      SortLargestToSmallest: 'Ordenar más grande a más pequeña',
      Sort: 'Clasificar',
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      totalItemsInfo: '({0} artículos)',
      firstPageTooltip: 'Ir a la primera página',
      lastPageTooltip: 'Ir a la última página',
      nextPageTooltip: 'Ir a la página siguiente',
      previousPageTooltip: 'Regresar a la pagina anterior',
      nextPagerTooltip: 'Ir a los siguientes elementos del buscapersonas',
      previousPagerTooltip: 'Ir a elementos anteriores del buscapersonas',
      pagerDropDown: 'Artículos por página',
      pagerAllDropDown: 'Artículos',
      All: 'Todos',
      totalItemInfo: '({0} artículo)',
    },
    kanban: {
      items: 'artículos',
      min: 'Min',
      max: 'Max',
      cardsSelected: 'Tarjetas Seleccionadas',
      addTitle: 'Agregar nueva tarjeta',
      editTitle: 'Editar detalles de la tarjeta',
      deleteTitle: 'Eliminar tarjeta',
      deleteContent: '¿Estás seguro de que deseas eliminar esta tarjeta?',
      save: 'Salvar',
      delete: 'Eliminar',
      cancel: 'Cancelar',
      yes: 'si',
      no: 'No',
      close: 'Cerca',
      noCard: 'No hay tarjetas para mostrar',
      unassigned: 'Sin asignar',
    },
    dropdowns: {
      noRecordsTemplate: 'No se encontraron registros',
      actionFailureTemplate: 'La solicitud falló',
      overflowCountTemplate: '+${count} más ..',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deselecciona todo',
      totalCountTemplate: '${count} seleccionado',
    },
    'drop-down-list': {
      noRecordsTemplate: 'No se encontraron registros',
      actionFailureTemplate: 'La solicitud falló',
    },
    'combo-box': {
      noRecordsTemplate: 'No se encontraron registros',
      actionFailureTemplate: 'La solicitud falló',
    },
    'auto-complete': {
      noRecordsTemplate: 'No se encontraron registros',
      actionFailureTemplate: 'La solicitud falló',
    },
    'multi-select': {
      noRecordsTemplate: 'No se encontraron registros',
      actionFailureTemplate: 'La solicitud falló',
      overflowCountTemplate: '+${count} más ..',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deselecciona todo',
      totalCountTemplate: '${count} seleccionado',
    },
    listbox: {
      noRecordsTemplate: 'No se encontraron registros',
      actionFailureTemplate: 'La solicitud falló',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deselecciona todo',
      moveUp: 'Ascender',
      moveDown: 'Mover hacia abajo',
      moveTo: 'Mover a',
      moveFrom: 'Mover de',
      moveAllTo: 'Mover todo a',
      moveAllFrom: 'Mover todo de',
    },
    spreadsheet: {
      InsertingEmptyValue: 'El valor de referencia no es válido.',
      FindValue: 'Encuentra valor',
      ReplaceValue: 'Reemplazar valor',
      FindReplaceTooltip: 'Buscar y reemplazar',
      ByRow: ' Por filas',
      ByColumn: 'Por columnas',
      MatchExactCellElements: 'Coincide con el contenido exacto de la celda',
      EntercellAddress: 'Ingrese la dirección de la celda',
      FindAndReplace: 'Encontrar y reemplazar',
      ReplaceAllEnd: ' partidos reemplazados por',
      FindNextBtn: 'Encontrar siguiente',
      FindPreviousBtn: 'Encontrar anterior',
      ReplaceBtn: 'Reemplazar',
      ReplaceAllBtn: 'Reemplaza todo',
      GotoHeader: 'Ir',
      GotoSpecialHeader: 'Ir a especial',
      Sheet: 'Sábana',
      SearchWithin: 'Buscar dentro',
      SearchBy: 'Búsqueda por',
      Reference: 'Referencia',
      Workbook: 'Libro de trabajo',
      NoElements:
        'No pudimos encontrar lo que estabas buscando. Haga clic en las opciones para obtener más formas de búsqueda',
      FindWhat: 'Encontrar que',
      ReplaceWith: 'Reemplazar con',
      EnterValue: 'Ingrese valor',
      FileName: 'Nombre del archivo',
      EnterName: 'Ingrese su nombre',
      ExtendValidation:
        'La selección contiene algunas celdas sin validación de datos. ¿Quiere extender la validación a estas celdas?',
      Yes: 'sí',
      No: 'No',
      InvalidFileName: 'El nombre del archivo contiene un carácter no válido.',
      PROPER:
        'Convierte un texto en mayúsculas y minúsculas; primera letra a mayúsculas y otras letras a minúsculas.',
      Cut: 'Cortar',
      Copy: 'Copiar',
      Paste: 'Pegar',
      PasteSpecial: 'Pegado especial',
      All: 'Todos',
      Values: 'Valores',
      Formats: 'Formatos',
      Font: 'Fuente',
      FontSize: 'Tamaño de fuente',
      Bold: 'Negrita',
      Italic: 'Itálico',
      Underline: 'Subrayar',
      Strikethrough: 'Tachado',
      TextColor: 'Color de texto',
      FillColor: 'Color de relleno',
      HorizontalAlignment: 'Alineación horizontal',
      AlignLeft: 'Alinear a la izquierda',
      AlignCenter: 'Centrar',
      AlignRight: 'Alinear a la derecha',
      VerticalAlignment: 'Alineamiento vertical',
      AlignTop: 'Alinear la parte superior',
      AlignMiddle: 'Alinear Medio',
      AlignBottom: 'Alinear la parte inferior',
      MergeCells: 'Combinar células',
      MergeAll: 'Fusionar todo',
      MergeHorizontally: 'Fusionar horizontalmente',
      MergeVertically: 'Fusionar verticalmente',
      Unmerge: 'Desunir',
      UnmergeCells: 'Unmerge Cells',
      SelectMergeType: 'Seleccionar tipo de fusión',
      MergeCellsAlert:
        'La combinación de celdas solo conservará el valor superior izquierdo (superior). Fusionar de todos modos?',
      PasteMergeAlert: 'No podemos hacer eso en una celda de fusión.',
      Borders: 'Fronteras',
      TopBorders: 'Fronteras superiores',
      LeftBorders: 'Fronteras izquierdas',
      RightBorders: 'Fronteras derechas',
      BottomBorders: 'Fronteras inferiores',
      AllBorders: 'Todas las fronteras',
      HorizontalBorders: 'Fronteras horizontales',
      VerticalBorders: 'Fronteras verticales',
      OutsideBorders: 'Fronteras exteriores',
      InsideBorders: 'Fronteras interiores',
      NoBorders: 'Sin Fronteras',
      BorderColor: 'Color del borde',
      BorderStyle: 'Estilo de borde',
      InsertFunction: 'Función de inserción',
      Insert: 'Insertar',
      Delete: 'Eliminar',
      DuplicateSheet: 'Duplicar',
      MoveRight: 'Moverse a la derecha',
      MoveLeft: 'Mover hacia la izquierda',
      Rename: 'Rebautizar',
      Hide: 'Esconder',
      Unhide: 'Mostrar',
      NameBox: 'Nombre de Caja',
      ShowHeaders: 'Mostrar encabezados',
      HideHeaders: 'Ocultar encabezados',
      ShowGridLines: 'Mostrar cuadrículas',
      HideGridLines: 'Ocultar cuadrículas',
      FreezePanes: 'Congelar paneles',
      FreezeRows: 'Congelar filas',
      FreezeColumns: 'Congelar columnas',
      UnfreezePanes: 'Descongelar paneles',
      UnfreezeRows: 'Descongelar filas',
      UnfreezeColumns: 'Descongelar columnas',
      AddSheet: 'Agregar hoja',
      ListAllSheets: 'Listar todas las hojas',
      FullScreen: 'Pantalla completa',
      CollapseToolbar: 'Contraer barra de herramientas',
      ExpandToolbar: 'Expandir barra de herramientas',
      CollapseFormulaBar: 'Contraer barra de fórmulas',
      ExpandFormulaBar: 'Expandir barra de fórmulas',
      File: 'Archivo',
      Home: 'Hogar',
      Formulas: 'Fórmulas',
      View: 'Ver',
      New: 'Nuevo',
      Open: 'Abierto',
      SaveAs: 'Guardar como',
      ExcelXlsx: 'Microsoft Excel',
      ExcelXls: 'Microsoft Excel 97-2003',
      CSV: 'Valores Separados por Comas',
      FormulaBar: 'Barra de formulas',
      Sort: 'Ordenar',
      SortAscending: 'Ascendente',
      SortDescending: 'Descendente',
      CustomSort: 'Ordenación personalizada',
      AddColumn: 'Añadir columna',
      ContainsHeader: 'Los datos contienen encabezado',
      CaseSensitive: 'Distingue mayúsculas y minúsculas',
      SortBy: 'Ordenar por',
      ThenBy: 'Entonces por',
      SelectAColumn: 'Selecciona una columna',
      SortEmptyFieldError:
        'Todos los criterios de clasificación deben tener una columna especificada. Verifique los criterios de clasificación seleccionados e intente nuevamente.',
      SortDuplicateFieldError:
        ' se ordena por valores más de una vez. Elimine los criterios de ordenación duplicados e intente nuevamente.',
      SortOutOfRangeError:
        'Seleccione una celda o rango dentro del rango utilizado e intente nuevamente.',
      MultiRangeSortError:
        'Esto no se puede hacer en una selección de rango múltiple. Seleccione un solo rango y vuelva a intentarlo.',
      HideRow: 'Ocultar fila',
      HideRows: 'Ocultar filas',
      UnHideRows: 'UnHide Filas',
      HideColumn: 'Ocultar columna',
      HideColumns: 'Ocultar columnas',
      UnHideColumns: 'UnHide Columnas',
      InsertRow: 'Insertar fila',
      InsertRows: 'Insertar filas',
      Above: 'Encima',
      Below: 'Abajo',
      InsertColumn: 'Insertar columna',
      InsertColumns: 'Insertar columnas',
      Before: 'antes de',
      After: 'Después',
      DeleteRow: 'Borrar fila',
      DeleteRows: 'Eliminar filas',
      DeleteColumn: 'Eliminar columna',
      DeleteColumns: 'Eliminar columnas',
      Ok: 'Ok',
      Close: 'Cerca',
      Cancel: 'Cancelar',
      Apply: 'Aplicar',
      MoreColors: 'Mas colores',
      StandardColors: 'Colores estándar',
      General: 'General',
      Number: 'Número',
      Currency: 'Moneda',
      Accounting: 'Contabilidad',
      ShortDate: 'Cita corta',
      LongDate: 'Fecha larga',
      Time: 'Hora',
      Percentage: 'Porcentaje',
      Fraction: 'Fracción',
      Scientific: 'Científico',
      Text: 'Texto',
      NumberFormat: 'Formato numérico',
      MobileFormulaBarPlaceHolder: 'Ingrese el valor o la fórmula',
      PasteAlert:
        'No puede pegar esto aquí, porque el área de copia y el área de pegado no tienen el mismo tamaño. Intenta pegar en un rango diferente.',
      DestroyAlert:
        '¿Está seguro de que desea destruir el libro de trabajo actual sin guardar y crear un nuevo libro de trabajo?',
      SheetRenameInvalidAlert:
        'El nombre de la hoja contiene caracteres no válidos.',
      SheetRenameEmptyAlert: 'El nombre de la hoja no puede estar vacío.',
      SheetRenameAlreadyExistsAlert:
        'El nombre de la hoja ya existe. Por favor ingrese otro nombre.',
      DeleteSheetAlert: '¿Seguro que quieres eliminar esta hoja?',
      DeleteSingleLastSheetAlert:
        'Un libro de trabajo debe contener al menos una hoja de trabajo visible.',
      PickACategory: 'Elige una categoría',
      Description: 'Descripción',
      UnsupportedFile: 'Archivo no soportado',
      InvalidUrl: 'URL invalida',
      SUM: 'Agrega una serie de números y / o celdas.',
      SUMIF: 'Agrega las celdas según la condición especificada.',
      SUMIFS: 'Agrega las celdas según las condiciones especificadas.',
      ABS: 'Devuelve el valor de un número sin su signo.',
      RAND: 'Devuelve un número aleatorio entre 0 y 1.',
      RANDBETWEEN:
        'Devuelve un entero aleatorio basado en valores especificados.',
      FLOOR:
        'Redondea un número hacia abajo al múltiplo más cercano de un factor dado.',
      CEILING: 'Redondea un número al múltiplo más cercano de un factor dado.',
      PRODUCT: 'Multiplica una serie de números y / o celdas.',
      AVERAGE:
        'Calcula el promedio de la serie de números y / o celdas excluyendo el texto.',
      AVERAGEIF:
        'Calcula el promedio de las celdas según el criterio especificado.',
      AVERAGEIFS:
        'Calcula el promedio de las celdas según las condiciones especificadas.',
      AVERAGEA:
        'Calcula el promedio de las celdas que evalúan VERDADERO como 1, texto y FALSO como 0.',
      COUNT: 'Cuenta las celdas que contienen valores numéricos en un rango.',
      COUNTIF: 'Cuenta las celdas según la condición especificada.',
      COUNTIFS: 'Cuenta las celdas según las condiciones especificadas.',
      COUNTA: 'Cuenta las celdas que contienen valores en un rango.',
      MIN: 'Devuelve el número más pequeño de los argumentos dados.',
      MAX: 'Devuelve el mayor número de argumentos dados.',
      DATE: 'Devuelve la fecha según el año, mes y día dados.',
      DAY: 'Devuelve el día desde la fecha dada.',
      DAYS: 'Devuelve el número de días entre dos fechas.',
      IF: 'Devuelve el valor basado en la expresión dada.',
      IFS: 'Devuelve el valor basado en las múltiples expresiones dadas.',
      CalculateAND:
        'Devuelve VERDADERO si todos los argumentos son VERDADEROS; de lo contrario, devuelve FALSO.',
      CalculateOR:
        'Devuelve VERDADERO si alguno de los argumentos es VERDADERO; de lo contrario, devuelve FALSO.',
      IFERROR:
        'Devuelve el valor si no se encuentra ningún error; de lo contrario, devolverá el valor especificado.',
      CHOOSE:
        'Devuelve un valor de la lista de valores, según el número de índice.',
      INDEX:
        'Devuelve un valor de la celda en un rango determinado según el número de fila y columna.',
      FIND: 'Devuelve la posición de una cadena dentro de otra cadena, que distingue entre mayúsculas y minúsculas',
      CONCATENATE: 'Combina dos o más cadenas juntas.',
      CONCAT: 'Concatena una lista o un rango de cadenas de texto.',
      SUBTOTAL:
        'Devuelve el subtotal de un rango usando el número de función dado.',
      RADIANS: 'Convierte grados en radianes.',
      MATCH:
        'Devuelve la posición relativa de un valor especificado en un rango dado.',
      SLOPE:
        'Devuelve la pendiente de la línea desde la regresión lineal de los puntos de datos.',
      INTERCEPT:
        'Calcula el punto de la línea de intersección en Y mediante regresión lineal.',
      UNIQUE: 'Devuelve valores únicos de un rango o matriz.',
      TEXT: 'Convierte un valor en texto en formato numérico especificado.',
      DefineNameExists:
        'Este nombre ya existe, intente con un nombre diferente.',
      CircularReference:
        'Cuando una fórmula se refiere a una o más referencias circulares, esto puede resultar en un cálculo incorrecto.',
      SORT: 'Ordena un rango de una matriz',
      T: 'Comprueba si un valor es texto o no y devuelve el texto.',
      EXACT:
        'Comprueba si dos cadenas de texto son exactamente iguales y devuelve VERDADERO o FALSO.',
      LEN: 'Devuelve un número de caracteres en una cadena determinada.',
      MOD: 'Devuelve un resto después de dividir un número por divisor.',
      ODD: 'Redondea un número positivo hacia arriba y un número negativo hacia abajo al entero impar más cercano.',
      PI: 'Devuelve el valor de pi.',
      COUNTBLANK:
        'Devuelve el número de celdas vacías en un rango de celdas especificado.',
      EVEN: 'Redondea un número positivo hacia arriba y un número negativo hacia abajo al número entero par más cercano.',
      DECIMAL:
        'Convierte una representación de texto de un número en una base determinada en un número decimal.',
      ADDRESS:
        'Devuelve una referencia de celda como texto, dados los números de fila y columna especificados.',
      CHAR: 'Devuelve el carácter del número especificado.',
      CODE: 'Devuelve el código numérico del primer carácter de una cadena determinada.',
      DOLLAR: 'Convierte el número en texto con formato de moneda.',
      SMALL: 'Devuelve el k-ésimo valor más pequeño en una matriz dada.',
      LARGE: 'Devuelve el k-ésimo valor más grande en una matriz dada.',
      TIME: 'Convierte horas, minutos, segundos al texto con formato de hora.',
      DEGREES: 'Convierte radianes a grados.',
      FACT: 'Devuelve el factorial de un número.',
      MEDIAN: 'Devuelve la mediana del conjunto de números dado.',
      EDATE:
        'Devuelve una fecha con un número determinado de meses antes o después de la fecha especificada.',
      DATEVALUE: 'Convierte una cadena de fecha en un valor de fecha.',
      NOW: 'Devuelve la fecha y hora actuales.',
      HOUR: 'Devuelve el número de horas en una cadena de tiempo especificada.',
      MINUTE:
        'Devuelve el número de minutos en una cadena de tiempo especificada.',
      SECOND:
        'Devuelve el número de segundos en una cadena de tiempo especificada.',
      MONTH: 'Devuelve el número de meses en una cadena de fecha especificada.',
      ShowRowsWhere: 'Mostrar filas donde:',
      OR: 'O',
      AND: 'Y',
      CustomFilterDatePlaceHolder: 'Elige una fecha',
      CustomFilterPlaceHolder: 'Ingrese el valor',
      CustomFilter: 'Filtro personalizado',
      Between: 'Entre',
      MatchCase: 'Match Case',
      DateTimeFilter: 'Filtros de fecha y hora',
      Undo: 'Deshacer',
      Redo: 'Rehacer',
      ClearAllFilter: 'Claro',
      ReapplyFilter: 'Aplicar de nuevo',
      DateFilter: 'Filtros de fecha',
      TextFilter: 'Filtros de texto',
      NumberFilter: 'Filtros de número',
      ClearFilter: 'Filtro claro',
      NoResult: 'No se encontraron coincidencias',
      FilterFalse: 'Falso',
      FilterTrue: 'Cierto',
      Blanks: 'Espacios en blanco',
      SelectAll: 'Seleccionar todo',
      GreaterThanOrEqual: 'Mayor que o igual',
      GreaterThan: 'Mas grande que',
      LessThanOrEqual: 'Menor o igual',
      LessThan: 'Menos que',
      NotEqual: 'No es igual',
      Equal: 'Igual',
      Contains: 'Contiene',
      EndsWith: 'Termina con',
      StartsWith: 'Comienza con',
      ClearButton: 'Claro',
      FilterButton: 'Filtrar',
      CancelButton: 'Cancelar',
      OKButton: 'Ok',
      Search: 'Buscar',
      DataValidation: 'Validación de datos',
      CLEARALL: 'LIMPIAR TODO',
      APPLY: 'APLICAR',
      CellRange: 'Rango de celdas',
      Allow: 'Permitir',
      Data: 'Datos',
      Minimum: 'Mínimo',
      Maximum: 'Máximo',
      IgnoreBlank: 'Ignorar en blanco',
      WholeNumber: 'Número entero',
      Decimal: 'Decimal',
      Date: 'Fecha',
      TextLength: 'Longitud del texto',
      List: 'Lista',
      NotBetween: 'No entre',
      EqualTo: 'Igual a',
      NotEqualTo: 'No igual a',
      Greaterthan: 'Mas grande que',
      Lessthan: 'Menos que',
      GreaterThanOrEqualTo: 'Mayor qué o igual a',
      LessThanOrEqualTo: 'Menos que o igual a',
      InCellDropDown: 'Desplegable en la celda',
      Sources: 'Fuentes',
      Value: 'Valor',
      Retry: 'Procesar de nuevo',
      DialogError:
        'El origen de la lista debe ser una referencia a una sola fila o columna.',
      MinMaxError: 'El Máximo debe ser mayor o igual que el Mínimo.',
      Spreadsheet: 'Hoja de cálculo',
      MoreValidation: 'Esta selección contiene más de una validación.',
      FileNameError: ' ¿Borrar la configuración actual y continuar?',
      ValidationError:
        'Este valor no coincide con las restricciones de validación de datos definidas para la celda.',
      ListLengthError:
        'Los valores de la lista solo permiten hasta 256 caracteres',
      ProtectSheet: 'Hoja de protección',
      UnprotectSheet: 'Desproteger hoja',
      SelectCells: 'Seleccionar celdas',
      SelectUnLockedCells: 'Seleccionar celdas',
      Save: 'Seleccionar celdas',
      EmptyFileName: 'Ahorrar',
      LargeName: 'El nombre del archivo no puede estar vacío.',
      FormatCells: 'Formato de celdas',
      FormatRows: 'Formatear filas',
      'Format Columns': 'Formatear columnas',
      InsertLinks: 'Insertar enlaces',
      ProtectContent: 'Protege el contenido de las celdas bloqueadas',
      ProtectAllowUser:
        'Permita que todos los usuarios de esta hoja de trabajo:',
      EditAlert:
        'La celda que intenta cambiar está protegida. Para hacer el cambio, desproteja la hoja.',
      ClearValidation: 'Validación clara',
      ISNUMBER:
        'Devuelve verdadero cuando el valor se analiza como un valor numérico.',
      Round: 'Redondea un número a un número específico de dígitos.',
      GEOMEAN:
        'Devuelve la media geométrica de una matriz o rango de datos positivos.',
      POWER: 'Devuelve el resultado de un número elevado al poder',
      LOG: 'Devuelve el logaritmo de un número a la base que especifique.',
      TRUNC:
        'Devuelve el valor truncado de un número a un número específico de lugares decimales.',
      EXP: 'Devuelve e elevado a la potencia del número dado.',
      HighlightCellsRules: 'Resaltar reglas de celdas',
      CFEqualTo: 'Igual a',
      TextThatContains: 'Texto que contiene',
      ADateOccuring: 'Una fecha que ocurre',
      DuplicateValues: 'Valores duplicados',
      TopBottomRules: 'Reglas superiores / inferiores',
      Top10Items: '10 artículos principales',
      Top10: 'Top 10',
      Bottom10Items: '10 artículos inferiores',
      Bottom10: '10 inferiores',
      AboveAverage: 'Por encima del promedio',
      BelowAverage: 'Por debajo del promedio',
      FormatCellsGreaterThan: 'Formatee las celdas que sean MAYORES QUE:',
      FormatCellsLessThan: 'Dar formato a las celdas MENOS DE:',
      FormatCellsBetween: 'Dar formato a las celdas ENTRE:',
      FormatCellsEqualTo: 'Dar formato a celdas IGUALES A:',
      FormatCellsThatContainTheText:
        'Dar formato a las celdas que contienen el texto:',
      FormatCellsThatContainADateOccurring:
        'Dar formato a las celdas que contienen una fecha que ocurre:',
      FormatCellsDuplicate: 'Dar formato a las celdas que contienen:',
      FormatCellsTop: 'Dar formato a las celdas que se clasifican en el TOP:',
      FormatCellsBottom:
        'Dar formato a las celdas que se clasifican en la PARTE INFERIOR:',
      FormatCellsAbove:
        'Dar formato a las celdas que están POR ENCIMA DEL PROMEDIO:',
      FormatCellsBelow:
        'Dar formato a las celdas que están POR DEBAJO DEL PROMEDIO:',
      With: 'con',
      DataBars: 'Barras de datos',
      ColorScales: 'Escalas de color',
      IconSets: 'Conjuntos de iconos',
      ClearRules: 'ClearRules',
      SelectedCells: 'Borrar reglas de celdas seleccionadas',
      EntireSheet: 'Borrar reglas de toda la hoja',
      LightRedFillWithDarkRedText: 'Relleno rojo claro con texto rojo oscuro',
      YellowFillWithDarkYellowText:
        'Relleno amarillo con texto amarillo oscuro',
      GreenFillWithDarkGreenText: 'Relleno verde con texto verde oscuro',
      RedFill: 'Relleno rojo',
      RedText: 'Texto rojo',
      Duplicate: 'Duplicar',
      Unique: 'Único',
      And: 'y',
      WebPage: 'PÁGINA WEB',
      ThisDocument: 'ESTE DOCUMENTO',
      DisplayText: 'Mostrar texto',
      Url: 'URL',
      CellReference: 'Referencia de celda',
      DefinedNames: 'Nombres definidos',
      EnterTheTextToDisplay: 'Ingrese el texto para mostrar',
      EnterTheUrl: 'Ingrese la URL',
      INT: 'Devuelve un número al entero más cercano.',
      SUMPRODUCT:
        'Devuelve la suma del producto de los rangos de matrices dados.',
      TODAY: 'Devuelve la fecha actual como valor de fecha.',
      ROUNDUP: 'Redondea un número desde cero.',
      Replace: 'Reemplazar...',
      Find: 'Encontrar y reemplazar...',
      Goto: 'Ir...',
      GotoSpecial: 'Ir a Especial ...',
      Link: 'Enlace',
      SpreadsheetHyperlink: 'Hoja de cálculoHyperlink',
      Hyperlink: 'Hipervínculo',
      EditHyperlink: 'Editar hipervínculo',
      OpenHyperlink: 'Abrir hipervínculo',
      RemoveHyperlink: 'Eliminar hipervínculo',
      InvalidHyperlinkAlert:
        'La dirección de este sitio no es válida. Compruebe la dirección e inténtelo de nuevo.',
      InsertLink: 'Insertar el link',
      EditLink: 'Editar enlace',
      WrapText: 'Ajustar texto',
      Update: 'Actualizar',
      SortAndFilter: 'Filtro de clasificación',
      Filter: 'Filtrar',
      FilterCellValue: 'Filtrar por valor de celda seleccionada',
      FilterOutOfRangeError:
        'Seleccione una celda o rango dentro del rango usado e intente nuevamente.',
      ClearFilterFrom: 'Borrar filtro de',
      LN: 'Devuelve el logaritmo natural de un número.',
      DefineNameInValid: 'El nombre que ingresó no es válido.',
      LockCells: 'Bloquear celdas',
      EmptyError: 'Debes ingresar un valor',
      ClearHighlight: 'Resaltado claro',
      HighlightInvalidData: 'Resaltar datos no válidos',
      Clear: 'Claro',
      ClearContents: 'Contenidos claros',
      ClearAll: 'Limpiar todo',
      ClearFormats: 'Formatos claros',
      ClearHyperlinks: 'Borrar hipervínculos',
      Image: 'Imagen',
      ConditionalFormatting: 'Formato condicional',
      BlueDataBar: 'Barra de datos azul',
      GreenDataBar: 'Barra de datos verde',
      RedDataBar: 'Barra de datos roja',
      OrangeDataBar: 'Barra de datos naranja',
      LightblueDataBar: 'Barra de datos azul claro',
      PurpleDataBar: 'Barra de datos púrpura',
      GYRColorScale: 'Escala de color verde - amarillo - rojo',
      RYGColorScale: 'Escala de color rojo - amarillo - verde',
      GWRColorScale: 'Escala de color verde - blanco - rojo',
      RWGColorScale: 'Escala de color rojo - blanco - verde',
      BWRColorScale: 'Escala de color azul - blanco - rojo',
      RWBColorScale: 'Escala de color rojo - blanco - azul',
      WRColorScale: 'Escala de color blanco - rojo',
      RWColorScale: 'Escala de color rojo - blanco',
      GWColorScale: 'Escala de color verde - blanco',
      WGColorScale: 'Escala de color blanco - verde',
      GYColorScale: 'Escala de color verde - amarillo',
      YGColorScale: 'Escala de color amarillo - verde',
      ThreeArrowsColor: '3 flechas (coloreadas)',
      ThreeArrowsGray: '3 flechas (gris)',
      ThreeTriangles: '3 triángulos',
      FourArrowsColor: '4 flechas (gris)',
      FourArrowsGray: '4 flechas (coloreadas)',
      FiveArrowsColor: '5 flechas (gris)',
      FiveArrowsGray: '5 flechas (coloreadas)',
      ThreeTrafficLights1: '3 semáforos (sin marco)',
      ThreeTrafficLights2: '3 semáforos (bordeados)',
      ThreeSigns: '3 señales',
      FourTrafficLights: '4 semáforos',
      RedToBlack: 'Rojo a negro',
      ThreeSymbols1: '3 símbolos (en un círculo)',
      ThreeSymbols2: '3 símbolos (sin círculo)',
      ThreeFlags: '3 banderas',
      ThreeStars: '3 estrellas',
      FourRatings: '4 valoraciones',
      FiveQuarters: '5 cuartos',
      FiveRatings: '5 valoraciones',
      FiveBoxes: '5 cajas',
      Chart: 'Gráfico',
      Column: 'Columna',
      Bar: 'Bar',
      Area: 'Zona',
      Pie: 'Tarta',
      Doughnut: 'Rosquilla',
      PieAndDoughnut: 'Pastel / Donut',
      Line: 'Línea',
      Radar: 'Radar',
      Scatter: 'Dispersión',
      ChartDesign: 'Diseño gráfico',
      ClusteredColumn: 'Columna agrupada',
      StackedColumn: 'Columna apilada',
      StackedColumn100: 'Columna 100% apilada',
      ClusteredBar: 'Barra agrupada',
      StackedBar: 'Barra apilada',
      StackedBar100: 'Barra 100% apilada',
      StackedArea: 'Área apilada',
      StackedArea100: 'Área 100% apilada',
      StackedLine: 'Línea apilada',
      StackedLine100: 'Línea 100% apilada',
      AddChartElement: 'Agregar elemento de gráfico',
      Axes: 'Ejes',
      AxisTitle: 'Título del eje',
      ChartTitle: 'Titulo del gráfico',
      DataLabels: 'Etiquetas de datos',
      Gridlines: 'Líneas de cuadrícula',
      Legends: 'Leyendas',
      PrimaryHorizontal: 'Horizontal primario',
      PrimaryVertical: 'Vertical principal',
      None: 'Ninguno',
      AboveChart: 'Por encima de la tabla',
      Center: 'Centrar',
      InsideEnd: 'Extremo interior',
      InsideBase: 'Base interior',
      OutsideEnd: 'Extremo exterior',
      PrimaryMajorHorizontal: 'Horizontal principal principal',
      PrimaryMajorVertical: 'Vertical principal principal',
      PrimaryMinorHorizontal: 'Horizontal menor primaria',
      PrimaryMinorVertical: 'Vertical menor primaria',
      Right: 'Derecha',
      Left: 'Izquierda',
      Bottom: 'Fondo',
      Top: 'Cima',
      SwitchRowColumn: 'Cambiar fila / columna',
      ChartTheme: 'Tema de gráfico',
      ChartType: 'Tipo de gráfico',
      Material: 'Material',
      Fabric: 'Tela',
      Bootstrap: 'Oreja',
      HighContrastLight: 'AltoContrasteLuz',
      MaterialDark: 'MaterialOscuro',
      FabricDark: 'TejidoOscuro',
      HighContrast: 'Alto contraste',
      BootstrapDark: 'BootstrapOscuro',
      Bootstrap4: 'Bootstrap4',
      Bootstrap5Dark: 'Bootstrap4',
      Bootstrap5: 'Bootstrap5 Oscuro',
      Tailwind: 'Bootstrap5',
      TailwindDark: 'Viento de cola',
      VerticalAxisTitle: 'Título del eje vertical',
      HorizontalAxisTitle: 'Título del eje horizontal',
      EnterTitle: 'Ingrese el título',
      UnProtectWorksheet: 'Ingrese el título',
      ReEnterPassword: 'Desproteger hoja',
      SheetPassword: 'Vuelva a ingresar la contraseña para continuar',
      ProtectWorkbook: 'Proteger el libro de trabajo',
      Password: 'Contraseña (opcional):',
      unProtectPassword: 'Contraseña',
      EnterThePassword: 'Introduce la contraseña',
      ConfirmPassword: 'confirmar Contraseña',
      EnterTheConfirmPassword: 'reingresa tu contraseña',
      PasswordAlert: 'La contraseña de confirmación no es idéntica',
      UnProtectWorkbook: 'Desproteger el libro de trabajo',
      UnProtectPasswordAlert: 'La contraseña que proporcionó no es correcta.',
      InCorrectPassword:
        'No se puede abrir el archivo o la hoja de trabajo con la contraseña proporcionada',
      PasswordAlertMsg: 'Por favor introduzca la contraseña',
      ConfirmPasswordAlertMsg:
        'Por favor ingrese la contraseña de confirmación',
      IsProtected: 'esta protegido',
      PDF: 'Formato de Documento Portable',
      AutoFillMergeAlertMsg: 'Formato de Documento Portable',
      SelectBorderOption: 'Seleccionar opción de borde',
    },
    filemanager: {
      NewFolder: 'Nueva carpeta',
      Upload: 'Subir',
      Delete: 'Eliminar',
      Rename: 'Rebautizar',
      Download: 'Descargar',
      Cut: 'Cortar',
      Copy: 'Copiar',
      Paste: 'Pegar',
      SortBy: 'Ordenar por',
      Refresh: 'Actualizar',
      'Item-Selection': 'elemento seleccionado',
      'Items-Selection': 'artículos seleccionados',
      View: 'Ver',
      Details: 'Detalles',
      SelectAll: 'Seleccionar todo',
      Open: 'Abierto',
      'Tooltip-NewFolder': 'Nueva carpeta',
      'Tooltip-Upload': 'Subir',
      'Tooltip-Delete': 'Eliminar',
      'Tooltip-Rename': 'Rebautizar',
      'Tooltip-Download': 'Descargar',
      'Tooltip-Cut': 'Cortar',
      'Tooltip-Copy': 'Copiar',
      'Tooltip-Paste': 'Pegar',
      'Tooltip-SortBy': 'Ordenar por',
      'Tooltip-Refresh': 'Actualizar',
      'Tooltip-Selection': 'Selección clara',
      'Tooltip-View': 'Ver',
      'Tooltip-Details': 'Detalles',
      'Tooltip-SelectAll': 'Seleccionar todo',
      Name: 'Nombre',
      Size: 'Talla',
      DateModified: 'Modificado',
      DateCreated: 'Fecha de creacion',
      Path: 'Camino',
      Modified: 'Modificado',
      Created: 'Creado',
      Location: 'Ubicación',
      Type: 'Tipo',
      Permission: 'Permiso',
      Ascending: 'Ascendente',
      Descending: 'Descendente',
      None: 'Ninguna',
      'View-LargeIcons': 'Iconos grandes',
      'View-Details': 'Detalles',
      Search: 'Buscar',
      'Button-Ok': 'Ok',
      'Button-Cancel': 'Cancelar',
      'Button-Yes': 'si',
      'Button-No': 'No',
      'Button-Create': 'Crear',
      'Button-Save': 'Salvar',
      'Header-NewFolder': 'Carpeta',
      'Content-NewFolder': 'Ingrese el nombre de su carpeta',
      'Header-Rename': 'Rebautizar',
      'Content-Rename': 'Ingrese su nuevo nombre',
      'Header-Rename-Confirmation': 'Cambiar nombre de confirmación',
      'Content-Rename-Confirmation':
        'Si cambia una extensión de nombre de archivo, el archivo podría volverse inestable. ¿Estás seguro de que quieres cambiarlo?',
      'Header-Delete': 'Borrar archivo',
      'Content-Delete': '¿Seguro que quieres eliminar este archivo?',
      'Header-Folder-Delete': 'Eliminar carpeta',
      'Content-Folder-Delete':
        '¿Está seguro de que desea eliminar esta carpeta?',
      'Header-Multiple-Delete': 'Eliminar múltiples archivos',
      'Content-Multiple-Delete':
        '¿Está seguro de que desea eliminar estos {0} archivos?',
      'Header-Duplicate': 'Archivo / Carpeta existe',
      'Content-Duplicate': '{0} ya existe. ¿Quieres renombrar y pegar?',
      'Header-Upload': 'Subir archivos',
      Error: 'Error',
      'Validation-Empty':
        'El nombre del archivo o carpeta no puede estar vacío.',
      'Validation-Invalid':
        'El nombre del archivo o carpeta {0} contiene caracteres no válidos. Por favor use un nombre diferente. Los nombres válidos de archivos o carpetas no pueden terminar con un punto o espacio, y no pueden contener ninguno de los siguientes caracteres: \\ /: *? "<> |',
      'Validation-NewFolder-Exists':
        'Ya existe un archivo o carpeta con el nombre {0}.',
      'Validation-Rename-Exists':
        'No se puede cambiar el nombre de {0} a {1}: el destino ya existe.',
      'Folder-Empty': 'Esta carpeta está vacía',
      'File-Upload': 'Arrastra los archivos aquí para subir',
      'Search-Empty': 'No se han encontrado resultados',
      'Search-Key': 'Pruebe con diferentes palabras clave',
      'Filter-Empty': 'No se han encontrado resultados',
      'Filter-Key': 'Probar con un filtro diferente',
      'Sub-Folder-Error':
        'La carpeta de destino es la subcarpeta de la carpeta de origen.',
      'Same-Folder-Error':
        'La carpeta de destino es la misma que la carpeta de origen.',
      'Access-Denied': 'Acceso denegado',
      'Access-Details': 'No tienes permiso para acceder a esta carpeta.',
      'Header-Retry': 'El archivo ya existe',
      'Content-Retry':
        'Ya existe un archivo con este nombre en esta carpeta. Que te gustaría hacer?',
      'Button-Keep-Both': 'Mantén ambos',
      'Button-Replace': 'Reemplazar',
      'Button-Skip': 'Omitir',
      'ApplyAll-Label': 'Haz esto para todos los artículos actuales',
      KB: 'KB',
      'Access-Message':
        '{0} no es accesible. Necesita permiso para realizar la acción {1}.',
      'Network-Error':
        'NetworkError: error al enviar en XMLHTTPRequest: error al cargar',
      'Server-Error': 'ServerError: respuesta no válida de',
    },
    calendar: {
      today: 'Hoy',
    },
    datepicker: {
      today: 'Hoy',
      placeholder: 'Elige una fecha',
    },
    daterangepicker: {
      placeholder: 'Elige un rango de fechas',
      startLabel: 'Fecha de inicio',
      endLabel: 'Fecha final',
      applyText: 'Aplicar',
      cancelText: 'Cancelar',
      selectedDays: 'Días seleccionados',
      days: 'Dias',
      customRange: 'Rango personalizado',
    },
    timepicker: {
      placeholder: 'Elige un momento',
    },
    datetimepicker: {
      today: 'Hoy',
      placeholder: 'Elige una fecha y hora',
    },
    dialog: {
      close: 'Cerca',
    },
    inplaceeditor: {
      save: 'Salvar',
      cancel: 'Cancelar',
      loadingText: 'Cargando...',
      editIcon: 'Haz click para editar',
      editAreaClick: 'Haz click para editar',
      editAreaDoubleClick: 'Doble click para editar',
    },
    toast: {
      close: 'Cerca',
    },
    tab: {
      closeButtonTitle: 'Cerca',
    },
    schedule: {
      day: 'Día',
      week: 'Semana',
      workWeek: 'Semana de trabajo',
      month: 'Mes',
      year: 'Año',
      agenda: 'Agenda',
      weekAgenda: 'Agenda de la semana',
      workWeekAgenda: 'Agenda de la semana laboral',
      monthAgenda: 'Agenda del mes',
      today: 'Hoy',
      noEvents: 'No hay eventos',
      emptyContainer: 'No hay eventos programados para este día.',
      allDay: 'Todo el dia',
      start: 'comienzo',
      end: 'Final',
      more: 'más',
      close: 'Cerca',
      cancel: 'Cancelar',
      noTitle: '(Sin título)',
      delete: 'Eliminar',
      deleteEvent: 'Este evento',
      deleteMultipleEvent: 'Eliminar múltiples eventos',
      selectedItems: 'Artículos seleccionados',
      deleteSeries: 'Serie completa',
      edit: 'Editar',
      editSeries: 'Serie completa',
      editEvent: 'Este evento',
      createEvent: 'Crear',
      subject: 'Tema',
      addTitle: 'Añadir título',
      moreDetails: 'Más detalles',
      save: 'Salvar',
      editContent: '¿Cómo le gustaría cambiar la cita en la serie?',
      deleteContent: '¿Seguro que quieres eliminar este evento?',
      deleteMultipleContent:
        '¿Estás seguro de que deseas eliminar los eventos seleccionados?',
      newEvent: 'Nuevo evento',
      title: 'Título',
      location: 'Ubicación',
      description: 'Descripción',
      timezone: 'Zona horaria',
      startTimezone: 'Zona horaria de inicio',
      endTimezone: 'Zona horaria final',
      repeat: 'Repetir',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      deleteButton: 'Eliminar',
      recurrence: 'Reaparición',
      wrongPattern: 'El patrón de recurrencia no es válido.',
      seriesChangeAlert:
        '¿Desea cancelar los cambios realizados en instancias específicas de esta serie y volver a vincularlos con toda la serie?',
      createError:
        'La duración del evento debe ser más corta que la frecuencia con la que ocurre. Acorte la duración o cambie el patrón de recurrencia en el editor de eventos de recurrencia.',
      sameDayAlert:
        'Dos ocurrencias del mismo evento no pueden ocurrir en el mismo día.',
      occurenceAlert:
        'No se puede reprogramar una ocurrencia de la cita recurrente si se salta una ocurrencia posterior de la misma cita.',
      editRecurrence: 'Editar recurrencia',
      repeats: 'Repite',
      alert: 'Alerta',
      startEndError:
        'La fecha de finalización seleccionada ocurre antes de la fecha de inicio.',
      invalidDateError: 'El valor de la fecha ingresada no es válido.',
      blockAlert:
        'Los eventos no se pueden programar dentro del rango de tiempo bloqueado.',
      ok: 'Ok',
      yes: 'si',
      no: 'No',
      occurrence: 'Ocurrencia',
      series: 'Serie',
      previous: 'Anterior',
      next: 'próximo',
      timelineDay: 'Día de la línea de tiempo',
      timelineWeek: 'Semana de la línea de tiempo',
      timelineWorkWeek: 'Semana laboral cronológica',
      timelineMonth: 'Mes de la línea de tiempo',
      timelineYear: 'Cronología Año',
      editFollowingEvent: 'Eventos siguientes',
      deleteTitle: 'Eliminar evento',
      editTitle: 'Editar evento',
      beginFrom: 'Comience desde',
      endAt: 'Termina en',
      expandAllDaySection: 'Expandir-sección-todo-el-día',
      collapseAllDaySection: 'Colapsar la sección de todo el día',
      searchTimezone: 'Zona horaria de búsqueda',
      noRecords: 'No se encontraron registros',
    },
    recurrenceeditor: {
      none: 'Ninguna',
      daily: 'Diario',
      weekly: 'Semanal',
      monthly: 'Mensual',
      month: 'Mes',
      yearly: 'Anual',
      never: 'Nunca',
      until: 'Hasta',
      count: 'Contar',
      first: 'primero',
      second: 'Segundo',
      third: 'Tercero',
      fourth: 'Cuarto',
      last: 'Último',
      repeat: 'Repetir',
      repeatEvery: 'Repite cada',
      on: 'Repetir en',
      end: 'Final',
      onDay: 'Día',
      days: 'Dias)',
      weeks: 'Semanas)',
      months: 'Meses)',
      years: 'Años)',
      every: 'cada',
      summaryTimes: 'veces)',
      summaryOn: 'en',
      summaryUntil: 'hasta',
      summaryRepeat: 'Repite',
      summaryDay: 'dias)',
      summaryWeek: 'semanas)',
      summaryMonth: 'meses)',
      summaryYear: 'años)',
      monthWeek: 'Mes Semana',
      monthPosition: 'Posición del mes',
      monthExpander: 'Expansor de mes',
      yearExpander: 'Expansor de año',
      repeatInterval: 'Intervalo de repetición',
    },
    gantt: {
      emptyRecord: 'No hay registros que mostrar',
      id: 'CARNÉ DE IDENTIDAD',
      name: 'Nombre',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha final',
      duration: 'Duración',
      progress: 'Progreso',
      dependency: 'Dependencia',
      notes: 'Notas',
      baselineStartDate: 'Fecha de inicio de línea de base',
      baselineEndDate: 'Fecha de finalización de línea de base',
      taskMode: 'Modo de Tarea',
      changeScheduleMode: 'Cambiar modo de programación',
      subTasksStartDate: 'Fecha de inicio de subtareas',
      subTasksEndDate: 'Fecha de finalización de subtareas',
      scheduleStartDate: 'Fecha de inicio del horario',
      scheduleEndDate: 'Fecha de finalización del horario',
      auto: 'Auto',
      manual: 'Manual',
      type: 'Tipo',
      offset: 'Compensar',
      resourceName: 'Recursos',
      resourceID: 'ID de recurso',
      day: 'día',
      hour: 'hora',
      minute: 'minuto',
      days: 'dias',
      hours: 'horas',
      minutes: 'minutos',
      generalTab: 'General',
      customTab: 'Columnas personalizadas',
      writeNotes: 'Escribe notas',
      addDialogTitle: 'Nueva tarea',
      editDialogTitle: 'Información de tarea',
      saveButton: 'Salvar',
      add: 'Añadir',
      edit: 'Editar',
      update: 'Actualizar',
      delete: 'Eliminar',
      cancel: 'Cancelar',
      search: 'Buscar',
      task: ' tarea',
      tasks: ' Tareas',
      zoomIn: 'Acercarse',
      zoomOut: 'Disminuir el zoom',
      zoomToFit: 'Zoom para ajustar',
      excelExport: 'Exportación Excel',
      csvExport: 'Exportación CSV',
      expandAll: 'Expandir todo',
      collapseAll: 'Desplegar todo',
      nextTimeSpan: 'Próximo intervalo de tiempo',
      prevTimeSpan: 'Periodo de tiempo anterior',
      okText: 'Ok',
      confirmDelete: '¿Estás seguro de que deseas eliminar el registro?',
      from: 'Desde',
      to: 'A',
      taskLink: 'Enlace de tareas',
      lag: 'Retraso',
      start: 'comienzo',
      finish: 'Terminar',
      enterValue: 'Ingrese el valor',
      taskBeforePredecessor_FS:
        "Movió '{0}' para comenzar antes de que '{1}' finalice y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskAfterPredecessor_FS:
        "Se movió '{0}' lejos de '{1}' y las dos tareas están vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskBeforePredecessor_SS:
        "Movió '{0}' para comenzar antes de que comience '{1}' y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskAfterPredecessor_SS:
        "Movió '{0}' para comenzar después de que '{1}' comience y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskBeforePredecessor_FF:
        "Movió '{0}' para finalizar antes de que '{1}' finalice y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskAfterPredecessor_FF:
        "Movió '{0}' para finalizar después de que '{1}' finalice y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskBeforePredecessor_SF:
        "Se movió '{0}' lejos de '{1}' para comenzar y las dos tareas están vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskAfterPredecessor_SF:
        "Movió '{0}' para finalizar después de que '{1}' comience y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskInformation: 'Información de tarea',
      deleteTask: 'Eliminar tarea',
      deleteDependency: 'Eliminar dependencia',
      convert: 'Convertir',
      save: 'Salvar',
      above: 'Encima',
      below: 'Abajo',
      child: 'Niño',
      milestone: 'Hito',
      toTask: 'A la tarea',
      toMilestone: 'Al hito',
      eventMarkers: 'Marcadores de eventos',
      leftTaskLabel: 'Etiqueta de tarea izquierda',
      rightTaskLabel: 'Etiqueta de tarea correcta',
      timelineCell: 'Celda de línea de tiempo',
      confirmPredecessorDelete:
        '¿Seguro que quieres eliminar el enlace de dependencia?',
      unit: 'Unidad',
      work: 'Trabajo',
      taskType: 'Tipo de tarea',
      unassignedTask: 'Tarea no asignada',
      group: 'Grupo',
      indent: 'Sangrar',
      outdent: 'Outdent',
      segments: 'Segmentos',
      splitTask: 'Tarea dividida',
      mergeTask: 'Combinar tarea',
      left: 'Izquierda',
      right: 'Correcto',
    },
    richtexteditor: {
      alignments: 'Alineaciones',
      justifyLeft: 'Alinear a la izquierda',
      justifyCenter: 'Alinear al centro',
      justifyRight: 'Alinear a la derecha',
      justifyFull: 'Alinear Justificar',
      fontName: 'Nombre de la fuente',
      fontSize: 'Tamaño de fuente',
      fontColor: 'Color de fuente',
      backgroundColor: 'Color de fondo',
      bold: 'Negrita',
      italic: 'Itálico',
      underline: 'Subrayar',
      strikethrough: 'Tachado',
      clearFormat: 'Formato claro',
      clearAll: 'Limpiar todo',
      cut: 'Cortar',
      copy: 'Copiar',
      paste: 'Pegar',
      unorderedList: 'Lista con viñetas',
      orderedList: 'Lista numerada',
      indent: 'Aumentar sangría',
      outdent: 'Disminuir sangría',
      undo: 'Deshacer',
      redo: 'Rehacer',
      superscript: 'Sobrescrito',
      subscript: 'Subíndice',
      createLink: 'Insertar hipervínculo',
      openLink: 'Enlace abierto',
      editLink: 'Editar enlace',
      removeLink: 'Remover enlace',
      image: 'Insertar imagen',
      replace: 'Reemplazar',
      align: 'Alinear',
      caption: 'Captura de imagen',
      remove: 'Eliminar',
      insertLink: 'Insertar el link',
      display: 'Monitor',
      altText: 'Texto alternativo',
      dimension: 'Cambiar tamaño',
      fullscreen: 'Maximizar',
      maximize: 'Maximizar',
      minimize: 'Minimizar',
      lowerCase: 'Minúscula',
      upperCase: 'Mayúscula',
      print: 'Impresión',
      formats: 'Formatos',
      sourcecode: 'Vista de código',
      preview: 'Avance',
      viewside: 'ViewSide',
      insertCode: 'Insertar codigo',
      linkText: 'Mostrar texto',
      linkTooltipLabel: 'Título',
      linkWebUrl: 'Dirección web',
      linkTitle: 'Ingrese un título',
      linkurl: 'http://ejemplo.com',
      linkOpenInNewWindow: 'Abrir enlace en una nueva ventana',
      linkHeader: 'Insertar el link',
      dialogInsert: 'Insertar',
      dialogCancel: 'Cancelar',
      dialogUpdate: 'Actualizar',
      imageHeader: 'Insertar imagen',
      imageLinkHeader: 'También puede proporcionar un enlace desde la web',
      mdimageLink: 'Proporcione una URL para su imagen',
      imageUploadMessage: 'Suelta la imagen aquí o navega para subir',
      imageDeviceUploadMessage: 'Haga clic aquí para subir',
      imageAlternateText: 'Texto alternativo',
      alternateHeader: 'Texto alternativo',
      browse: 'Vistazo',
      imageUrl: 'http://example.com/image.png',
      imageCaption: 'Subtítulo',
      imageSizeHeader: 'Tamaño de la imagen',
      imageHeight: 'Altura',
      imageWidth: 'Anchura',
      textPlaceholder: 'Ingrese texto',
      inserttablebtn: 'Insertar tabla',
      tabledialogHeader: 'Insertar tabla',
      tableWidth: 'Anchura',
      cellpadding: 'Relleno Celular',
      cellspacing: 'Espaciamiento celular',
      columns: 'Número de columnas',
      rows: 'Número de filas',
      tableRows: 'Filas de mesa',
      tableColumns: 'Columnas de mesa',
      tableCellHorizontalAlign: 'Alineación horizontal de celda de tabla',
      tableCellVerticalAlign: 'Alineación vertical de celda de tabla',
      createTable: 'Crear mesa',
      removeTable: 'Eliminar tabla',
      tableHeader: 'Encabezado de tabla',
      tableRemove: 'Eliminar tabla',
      tableCellBackground: 'Fondo de celda de tabla',
      tableEditProperties: 'Propiedades de edición de tabla',
      styles: 'Estilos',
      insertColumnLeft: 'Insertar columna a la izquierda',
      insertColumnRight: 'Insertar columna a la derecha',
      deleteColumn: 'Eliminar columna',
      insertRowBefore: 'Insertar fila antes',
      insertRowAfter: 'Insertar fila después',
      deleteRow: 'Borrar fila',
      tableEditHeader: 'Editar tabla',
      TableHeadingText: 'Bóveda',
      TableColText: 'Columna',
      imageInsertLinkHeader: 'Insertar el link',
      editImageHeader: 'Editar imagen',
      alignmentsDropDownLeft: 'Alinear a la izquierda',
      alignmentsDropDownCenter: 'Alinear al centro',
      alignmentsDropDownRight: 'Alinear a la derecha',
      alignmentsDropDownJustify: 'Alinear Justificar',
      imageDisplayDropDownInline: 'En línea',
      imageDisplayDropDownBreak: 'Descanso',
      tableInsertRowDropDownBefore: 'Insertar fila antes',
      tableInsertRowDropDownAfter: 'Insertar fila después',
      tableInsertRowDropDownDelete: 'Borrar fila',
      tableInsertColumnDropDownLeft: 'Insertar columna a la izquierda',
      tableInsertColumnDropDownRight: 'Insertar columna a la derecha',
      tableInsertColumnDropDownDelete: 'Eliminar columna',
      tableVerticalAlignDropDownTop: 'Alinear la parte superior',
      tableVerticalAlignDropDownMiddle: 'Alinear Medio',
      tableVerticalAlignDropDownBottom: 'Alinear la parte inferior',
      tableStylesDropDownDashedBorder: 'Fronteras discontinuas',
      tableStylesDropDownAlternateRows: 'Filas Alternas',
      pasteFormat: 'Pegar formato',
      pasteFormatContent: 'Elija la acción de formateo',
      plainText: 'Texto sin formato',
      cleanFormat: 'Limpiar',
      keepFormat: 'Mantener',
      pasteDialogOk: 'Ok',
      pasteDialogCancel: 'Cancelar',
      fileManager: 'Administrador de archivos',
      fileDialogHeader: 'Explorador de archivos',
      formatsDropDownParagraph: 'Párrafo',
      formatsDropDownCode: 'Código',
      formatsDropDownQuotation: 'Cotización',
      formatsDropDownHeading1: 'Título 1',
      formatsDropDownHeading2: 'Título 2',
      formatsDropDownHeading3: 'Título 3',
      formatsDropDownHeading4: 'Título 4',
      fontNameSegoeUI: 'SegoeUI',
      fontNameArial: 'Arial',
      fontNameGeorgia: 'Georgia',
      fontNameImpact: 'Impacto',
      fontNameTahoma: 'Tahoma',
      fontNameTimesNewRoman: 'Times New Roman',
      fontNameVerdana: 'Verdana',
      formatsOLListNumber: 'Número',
      formatsOLListLowerAlpha: 'LowerAlpha',
      formatsOLListUpperAlpha: 'UpperAlpha',
      formatsOLListLowerRoman: 'LowerRoman',
      formatsOLListUpperRoman: 'UpperRoman',
      formatsOLListLowerGreek: 'LowerGreek',
      formatsULListDisc: 'Desct',
      formatsULListCircle: 'Circulo',
      formatsULListSquare: 'Cuadrado',
      formatsOLListNone: 'Ninguno',
      formatsULListNone: 'Ninguno',
    },
    colorpicker: {
      Apply: 'Aplicar',
      Cancel: 'Cancelar',
      ModeSwitcher: 'Modo interruptor',
    },
    uploader: {
      Browse: 'Vistazo...',
      Clear: 'Claro',
      Upload: 'Subir',
      dropFilesHint: 'O suelta archivos aquí',
      invalidMaxFileSize: 'El tamaño del archivo es demasiado grande.',
      invalidMinFileSize: 'El tamaño del archivo es demasiado pequeño.',
      invalidFileType: 'El tipo de archivo no está permitido',
      uploadFailedMessage: 'El archivo no se pudo cargar',
      uploadSuccessMessage: 'documento cargado exitosamente',
      removedSuccessMessage: 'Archivo eliminado con éxito',
      removedFailedMessage: 'No se puede eliminar el archivo',
      inProgress: 'Cargando',
      readyToUploadMessage: 'Listo para subir',
      abort: 'Abortar',
      remove: 'Eliminar',
      cancel: 'Cancelar',
      delete: 'Borrar archivo',
      pauseUpload: 'Carga de archivo en pausa',
      pause: 'Pausa',
      resume: 'Currículum',
      retry: 'Procesar de nuevo',
      fileUploadCancel: 'Carga de archivo cancelada',
    },
    numerictextbox: {
      incrementTitle: 'Valor de incremento',
      decrementTitle: 'Valor de disminución',
    },
    slider: {
      incrementTitle: 'Incrementar',
      decrementTitle: 'Disminución',
    },
    formValidator: {
      required: 'Este campo es requerido.',
      email: 'Por favor, introduce una dirección de correo electrónico válida.',
      url: 'Por favor introduzca un URL válido.',
      date: 'Por favor introduzca una fecha valida.',
      dateIso: 'Por favor, introduzca una fecha válida (ISO).',
      creditcard: 'Por favor ingrese un número de tarjeta válido',
      number: 'Por favor ingrese un número valido.',
      digits: 'Por favor ingrese solo dígitos.',
      maxLength: 'Ingrese no más de {0} caracteres.',
      minLength: 'Ingrese al menos {0} caracteres.',
      rangeLength: 'Ingrese un valor entre {0} y {1} caracteres de longitud.',
      range: 'Ingrese un valor entre {0} y {1}.',
      max: 'Ingrese un valor menor o igual a {0}.',
      min: 'Ingrese un valor mayor o igual a {0}.',
      regex: 'Por favor, introduzca un valor correcto.',
      tel: 'Por favor ingrese un número de teléfono válido.',
      pattern: 'Por favor, introduzca un valor de patrón correcto.',
      equalTo: 'Por favor ingrese el texto válido del partido',
    },
    treegrid: {
      Above: 'Encima',
      Below: 'Abajo',
      AddRow: 'Añadir fila',
      ExpandAll: 'Expandir todo',
      CollapseAll: 'Desplegar todo',
    },
    querybuilder: {
      StartsWith: 'Comienza con',
      EndsWith: 'Termina con',
      Contains: 'Contiene',
      Equal: 'Igual',
      NotEqual: 'No es igual',
      LessThan: 'Menos que',
      LessThanOrEqual: 'Menor o igual',
      GreaterThan: 'Mas grande que',
      GreaterThanOrEqual: 'Mayor que o igual',
      Between: 'Entre',
      NotBetween: 'No entre',
      Empty: 'Vacío',
      NotEmpty: 'No vacío',
      In: 'En',
      NotIn: 'No en',
      NotContains: 'No contiene',
      Remove: 'ELIMINAR',
      SelectField: 'Selecciona un campo',
      SelectOperator: 'Seleccionar operador',
      DeleteRule: 'Eliminar esta condición',
      DeleteGroup: 'Eliminar grupo',
      AddGroup: 'Añadir grupo',
      AddCondition: 'Agregar condición',
      Edit: 'EDITAR',
      ValidationMessage: 'este campo es requerido',
      SummaryViewTitle: 'Vista de resumen',
      OtherFields: 'Otros campos',
      AND: 'Y',
      OR: 'O',
      SelectValue: 'Ingrese valor',
      IsEmpty: 'Esta vacio',
      IsNotEmpty: 'No está vacío',
      IsNull: 'Es nulo',
      IsNotNull: 'No es nulo',
      True: 'cierto',
      False: 'falso',
    },
    barcode: {},
    datamatrix: {},
    qrcode: {},
    pivotview: {
      grandTotal: 'Gran total',
      total: 'Total',
      value: 'Valor',
      noValue: 'Sin valor',
      row: 'Hilera',
      column: 'Columna',
      collapse: 'Colapso',
      expand: 'Expandir',
      rowAxisPrompt: 'Suelta la fila aquí',
      columnAxisPrompt: 'Suelta la columna aquí',
      valueAxisPrompt: 'Suelta el valor aquí',
      filterAxisPrompt: 'Suelta el filtro aquí',
      filter: 'Filtrar',
      filtered: 'Filtrado',
      sort: 'Clasificar',
      filters: 'Filtros',
      rows: 'Filas',
      columns: 'Columnas',
      values: 'Valores',
      close: 'Cerrar',
      cancel: 'Cancelar',
      delete: 'Borrar',
      CalculatedField: 'Campo calculado',
      createCalculatedField: 'Crear campo calculado',
      fieldName: 'Ingrese el nombre del campo',
      error: 'Error',
      invalidFormula: 'Fórmula no válida.',
      dropText: "Ejemplo: ('Sum (Order_Count)' + 'Sum (In_Stock)') * 250",
      dropTextMobile: 'Agregue campos y edite la fórmula aquí.',
      dropAction:
        'El campo calculado no se puede colocar en ninguna otra región excepto en el eje de valores.',
      alert: 'Alerta',
      warning: 'Advertencia',
      ok: 'OK',
      search: 'Buscar',
      drag: 'Arrastrar',
      remove: 'Eliminar',
      allFields: 'Todos los campos',
      formula: 'Fórmula',
      addToRow: 'Agregar a la fila',
      addToColumn: 'Agregar a columna',
      addToValue: 'Agregar valor',
      addToFilter: 'Agregar al filtro',
      emptyData: 'No hay registros que mostrar',
      fieldExist:
        'Ya existe un campo con este nombre. Ingrese un nombre diferente.',
      confirmText:
        'Ya existe un campo de cálculo con este nombre. ¿Quieres cambiarlo?',
      noMatches: 'No hay coincidencias',
      format: 'Valores de resúmenes por',
      edit: 'Editar',
      clear: 'Claro',
      formulaField: 'Arrastre y suelte campos a la fórmula',
      dragField: 'Arrastra el campo a la fórmula',
      clearFilter: 'Claro',
      by: 'por',
      all: 'Todos',
      multipleItems: 'Varios elementos',
      member: 'Miembro',
      label: 'Etiqueta',
      date: 'Fecha',
      enterValue: 'Ingrese valor',
      chooseDate: 'Ingrese la fecha',
      Before: 'Antes',
      BeforeOrEqualTo: 'Antes o igual a',
      After: 'Después',
      AfterOrEqualTo: 'Después o igual a',
      labelTextContent: 'Muestre los artículos para los que la etiqueta',
      dateTextContent: 'Muestre los elementos para los que la fecha',
      valueTextContent: 'Muestre los elementos para los que',
      Equals: 'Igual',
      DoesNotEquals: 'No es igual',
      BeginWith: 'Empieza con',
      DoesNotBeginWith: 'No comienza con',
      EndsWith: 'Termina con',
      DoesNotEndsWith: 'No termina con',
      Contains: 'Contiene',
      DoesNotContains: 'No contiene',
      GreaterThan: 'Mas grande que',
      GreaterThanOrEqualTo: 'Mayor qué o igual a',
      LessThan: 'Menos que',
      LessThanOrEqualTo: 'Menos que o igual a',
      Between: 'Entre',
      NotBetween: 'No entre',
      And: 'y',
      Sum: 'Suma',
      Count: 'Contar',
      DistinctCount: 'Recuento distinto',
      Product: 'Producto',
      Avg: 'Promedio',
      Min: 'Min',
      SampleVar: 'Var de muestra',
      PopulationVar: 'Var de población',
      RunningTotals: 'Totales acumulados',
      Max: 'Max',
      Index: 'Índice',
      SampleStDev: 'Muestra StDev',
      PopulationStDev: 'Desv.población',
      PercentageOfRowTotal: '% del total de la fila',
      PercentageOfParentTotal: '% del total de padres',
      PercentageOfParentColumnTotal: '% del total de la columna principal',
      PercentageOfParentRowTotal: '% del total de la fila principal',
      DifferenceFrom: 'Diferencia de',
      PercentageOfDifferenceFrom: '% de diferencia de',
      PercentageOfGrandTotal: '% del total general',
      PercentageOfColumnTotal: '% del total de la columna',
      NotEquals: 'No es igual',
      AllValues: 'Todos los valores',
      conditionalFormating: 'Formato condicional',
      apply: 'SOLICITAR',
      condition: 'Agregar condición',
      formatLabel: 'Formato',
      valueFieldSettings: 'Configuración del campo de valor',
      baseField: 'Campo base:',
      baseItem: 'Elemento base:',
      summarizeValuesBy: 'Resuma los valores por:',
      sourceName: 'Nombre del campo :',
      sourceCaption: 'Título de campo:',
      example: 'p.ej:',
      editorDataLimitMsg: ' mas cosas. Busque para refinar aún más.',
      details: 'Detalles',
      manageRecords: 'Administrar registros',
      Years: 'Años',
      Quarters: 'Cuarteles',
      Months: 'Meses',
      Days: 'Dias',
      Hours: 'Horas',
      Minutes: 'Minutos',
      Seconds: 'Segundos',
      save: 'Guardar un informe',
      new: 'Crear un informe nuevo',
      load: 'Carga',
      saveAs: 'Guardar como informe actual',
      rename: 'Cambiar el nombre de un informe actual',
      deleteReport: 'Eliminar un informe actual',
      export: 'Exportar',
      subTotals: 'Sub totales',
      grandTotals: 'Grandes totales',
      reportName: 'Reportar nombre :',
      pdf: 'PDF',
      excel: 'Sobresalir',
      csv: 'CSV',
      png: 'PNG',
      jpeg: 'JPEG',
      svg: 'SVG',
      mdxQuery: 'Consulta MDX',
      showSubTotals: 'Mostrar subtotales',
      doNotShowSubTotals: 'No mostrar subtotales',
      showSubTotalsRowsOnly: 'Mostrar subtotales solo en filas',
      showSubTotalsColumnsOnly: 'Mostrar solo columnas de subtotales',
      showGrandTotals: 'Mostrar totales generales',
      doNotShowGrandTotals: 'No mostrar totales generales',
      showGrandTotalsRowsOnly: 'Mostrar solo filas de totales generales',
      showGrandTotalsColumnsOnly: 'Mostrar solo columnas de totales generales',
      fieldList: 'Mostrar lista de campos',
      grid: 'Mostrar tabla',
      toolbarFormatting: 'Formato condicional',
      chart: 'Gráfico',
      reportMsg: 'Introduzca un nombre de informe válido.',
      reportList: 'Lista de informes',
      removeConfirm: '¿Está seguro de que desea eliminar este informe?',
      emptyReport: '¡No se encontraron informes!',
      bar: 'Bar',
      line: 'Línea',
      area: 'Zona',
      scatter: 'Dispersión',
      polar: 'Polar',
      of: 'de',
      emptyFormat: '¡No se ha encontrado ningún formato!',
      emptyInput: 'Ingrese un valor',
      newReportConfirm: '¿Quiere guardar los cambios para informar?',
      emptyReportName: 'Ingrese un nombre de informe',
      qtr: 'Qtr',
      null: 'nulo',
      undefined: 'indefinido',
      groupOutOfRange: 'Fuera de rango',
      fieldDropErrorAction:
        'El campo que está moviendo no se puede colocar en esa área del informe',
      MoreOption: 'Más...',
      aggregate: 'Agregar',
      drillThrough: 'Perforar a través',
      ascending: 'Ascendente',
      descending: 'Descendiendo',
      number: 'Número',
      currency: 'Divisa',
      percentage: 'Porcentaje',
      formatType: 'Tipo de formato',
      customText: 'Símbolo de moneda',
      symbolPosition: 'Posición del símbolo',
      left: 'Izquierda',
      right: 'Derecha',
      grouping: 'Agrupamiento',
      true: 'Cierto',
      false: 'Falso',
      decimalPlaces: 'Lugares decimales',
      numberFormat: 'Formato de número',
      memberType: 'Tipo de campo',
      formatString: 'Cadena de formato',
      expressionField: 'Expresión',
      customFormat: 'Ingrese una cadena de formato personalizado',
      selectedHierarchy: 'Jerarquía de padres',
      olapDropText:
        'Ejemplo: [Medidas]. [Cantidad de pedido] + ([Medidas]. [Cantidad de pedido] * 0.10)',
      Percent: 'Por ciento',
      Custom: 'Personalizado',
      Measure: 'La medida',
      Dimension: 'Dimensión',
      Standard: 'Estándar',
      blank: '(Blanco)',
      fieldTooltip:
        "Arrastre y suelte campos para crear una expresión. ¡Y, si desea editar los campos calculados existentes! Entonces puede lograrlo simplemente seleccionando el campo debajo de 'Miembros calculados'.",
      QuarterYear: 'Trimestre del año',
      fieldTitle: 'Nombre del campo',
      drillError:
        'No se pueden mostrar los elementos sin procesar de los campos calculados.',
      caption: 'Título de campo',
      copy: 'Dupdo',
      defaultReport: 'Informe predeterminado',
      customFormatString: 'Formato personalizado',
      invalidFormat: 'Formato inválido.',
      group: 'Grupo',
      unGroup: 'Desagrupar',
      invalidSelection: 'No se puede agrupar esa selección.',
      groupName: 'Ingrese el título para mostrar en el encabezado',
      captionName: 'Ingrese el título para el campo del grupo',
      selectedItems: 'Elementos seleccionados',
      groupFieldCaption: 'Título de campo',
      groupTitle: 'Nombre del grupo',
      startAt: 'A partir de',
      endAt: 'Terminando en',
      groupBy: 'Intervalo por',
      selectGroup: 'Seleccionar grupos',
      numberFormatString: 'Ejemplo: C, P, 0000%, ### 0. ## 0 #, etc.',
      stackingcolumn: 'Columna apilada',
      stackingbar: 'Barra apilada',
      stackingarea: 'Área apilada',
      stepline: 'Línea de paso',
      steparea: 'Área de paso',
      splinearea: 'Área de spline',
      spline: 'Ranura',
      stackingcolumn100: 'Columna 100% apilada',
      stackingbar100: 'Barra 100% apilada',
      stackingarea100: 'Área 100% apilada',
      bubble: 'burbuja',
      pareto: 'Pareto',
      radar: 'Radar',
      chartTypeSettings: 'Configuración de tipo de gráfico',
      multipleAxes: 'Múltiples ejes',
      sortAscending: 'Ordenar en orden ascendente',
      sortDescending: 'Ordenar en orden descendente',
      sortNone: 'Ordenar los datos',
      clearCalculatedField: 'Borrar información de campo editada',
      editCalculatedField: 'Editar campo calculado',
      ChartType: 'Tipo de gráfico',
      yes: 'sí',
      no: 'No',
      numberFormatMenu: 'Formato de números ...',
      conditionalFormatingMenu: 'Formato condicional...',
      removeCalculatedField:
        '¿Está seguro de que desea eliminar este campo calculado?',
      replaceConfirmBefore: 'Un informe llamado',
      replaceConfirmAfter: ' ya existe. ¿Quieres cambiarlo?',
      pie: 'Tarta',
      funnel: 'Embudo',
      doughnut: 'Rosquilla',
      pyramid: 'Pirámide',
      showLegend: 'Mostrar leyenda',
      exit: 'Salida',
      invalidJSON: 'Datos JSON no válidos',
      invalidCSV: 'Datos CSV no válidos',
      stacked: 'Apilado',
      single: 'Soltero',
      multipleAxisMode: 'Modo de múltiples ejes',
      grandTotalPosition: 'Posición de los totales generales',
      top: 'Cima',
      bottom: 'Abajo',
      None: 'Ninguna',
      stackingline: 'línea apilada',
      stackingline100: '100% línea apilada',
    },
    pivotfieldlist: {
      staticFieldList: 'Lista de campos dinámicos',
      fieldList: 'Lista de campo',
      dropFilterPrompt: 'Suelta el filtro aquí',
      dropColPrompt: 'Suelta la columna aquí',
      dropRowPrompt: 'Suelta la fila aquí',
      dropValPrompt: 'Suelta el valor aquí',
      addPrompt: 'Agregar campo aquí',
      adaptiveFieldHeader: 'Elegir campo',
      centerHeader: 'Arrastre los campos entre los ejes a continuación:',
      add: 'agregar',
      drag: 'Arrastrar',
      filter: 'Filtrar',
      filtered: 'Filtrado',
      sort: 'Clasificar',
      remove: 'Eliminar',
      filters: 'Filtros',
      rows: 'Filas',
      columns: 'Columnas',
      values: 'Valores',
      CalculatedField: 'Campo calculado',
      createCalculatedField: 'Crear campo calculado',
      fieldName: 'Ingrese el nombre del campo',
      error: 'Error',
      invalidFormula: 'Fórmula no válida.',
      dropText: "Ejemplo: ('Sum (Order_Count)' + 'Sum (In_Stock)' ') * 250",
      dropTextMobile: 'Agregue campos y edite la fórmula aquí.',
      dropAction:
        'El campo calculado no se puede colocar en ninguna otra región excepto en el eje de valores.',
      search: 'Buscar',
      close: 'Cerrar',
      cancel: 'Cancelar',
      delete: 'Borrar',
      alert: 'Alerta',
      warning: 'Advertencia',
      ok: 'OK',
      allFields: 'Todos los campos',
      formula: 'Fórmula',
      fieldExist:
        'Ya existe un campo con este nombre. Ingrese un nombre diferente.',
      confirmText:
        'Ya existe un campo de cálculo con este nombre. ¿Quieres cambiarlo?',
      noMatches: 'No hay coincidencias',
      format: 'Valores de resúmenes por',
      edit: 'Editar',
      clear: 'Claro',
      formulaField: 'Arrastre y suelte campos a la fórmula',
      dragField: 'Arrastra el campo a la fórmula',
      clearFilter: 'Claro',
      by: 'por',
      enterValue: 'Ingrese valor',
      chooseDate: 'Ingrese la fecha',
      all: 'Todos',
      multipleItems: 'Varios elementos',
      Equals: 'Igual',
      DoesNotEquals: 'No es igual',
      BeginWith: 'Empieza con',
      DoesNotBeginWith: 'No comienza con',
      EndsWith: 'Termina con',
      DoesNotEndsWith: 'No termina con',
      Contains: 'Contiene',
      DoesNotContains: 'No contiene',
      GreaterThan: 'Mas grande que',
      GreaterThanOrEqualTo: 'Mayor qué o igual a',
      LessThan: 'Menos que',
      LessThanOrEqualTo: 'Menos que o igual a',
      Between: 'Entre',
      NotBetween: 'No entre',
      Before: 'Antes',
      BeforeOrEqualTo: 'Antes o igual a',
      After: 'Después',
      AfterOrEqualTo: 'Después o igual a',
      member: 'Miembro',
      label: 'Etiqueta',
      date: 'Fecha',
      value: 'Valor',
      labelTextContent: 'Muestre los artículos para los que la etiqueta',
      dateTextContent: 'Muestre los elementos para los que la fecha',
      valueTextContent: 'Muestre los elementos para los que',
      And: 'y',
      Sum: 'Suma',
      Count: 'Contar',
      DistinctCount: 'Recuento distinto',
      Product: 'Producto',
      Avg: 'Promedio',
      Min: 'Min',
      Max: 'Max',
      Index: 'Índice',
      SampleStDev: 'Muestra StDev',
      PopulationStDev: 'Desv.población',
      SampleVar: 'Var de muestra',
      PopulationVar: 'Var de población',
      RunningTotals: 'Totales acumulados',
      DifferenceFrom: 'Diferencia de',
      PercentageOfDifferenceFrom: '% de diferencia de',
      PercentageOfGrandTotal: '% del total general',
      PercentageOfColumnTotal: '% del total de la columna',
      PercentageOfRowTotal: '% del total de la fila',
      PercentageOfParentTotal: '% del total de padres',
      PercentageOfParentColumnTotal: '% del total de la columna principal',
      PercentageOfParentRowTotal: '% del total de la fila principal',
      Years: 'Años',
      Quarters: 'Cuarteles',
      Months: 'Meses',
      Days: 'Dias',
      Hours: 'Horas',
      Minutes: 'Minutos',
      Seconds: 'Segundos',
      apply: 'SOLICITAR',
      valueFieldSettings: 'Configuración del campo de valor',
      sourceName: 'Nombre del campo :',
      sourceCaption: 'Título de campo:',
      summarizeValuesBy: 'Resuma los valores por:',
      baseField: 'Campo base:',
      baseItem: 'Elemento base:',
      example: 'p.ej:',
      editorDataLimitMsg: ' mas cosas. Busque para refinar aún más.',
      deferLayoutUpdate: 'Aplazar la actualización del diseño',
      null: 'nulo',
      undefined: 'indefinido',
      groupOutOfRange: 'Fuera de rango',
      fieldDropErrorAction:
        'El campo que está moviendo no se puede colocar en esa área del informe',
      MoreOption: 'Más...',
      memberType: 'Tipo de campo',
      selectedHierarchy: 'Jerarquía de padres',
      formatString: 'Cadena de formato',
      expressionField: 'Expresión',
      olapDropText:
        'Ejemplo: [Medidas]. [Cantidad de pedido] + ([Medidas]. [Cantidad de pedido] * 0.10)',
      customFormat: 'Ingrese una cadena de formato personalizado',
      Measure: 'La medida',
      Dimension: 'Dimensión',
      Standard: 'Estándar',
      Currency: 'Divisa',
      Percent: 'Por ciento',
      Custom: 'Personalizado',
      blank: '(Blanco)',
      fieldTooltip:
        "Arrastre y suelte campos para crear una expresión. ¡Y, si desea editar los campos calculados existentes! Puede lograrlo simplemente seleccionando el campo bajo 'Miembros calculados'.",
      fieldTitle: 'Nombre del campo',
      QuarterYear: 'Trimestre del año',
      caption: 'Título de campo',
      copy: 'Dupdo',
      group: 'Grupo',
      numberFormatString: 'Ejemplo: C, P, 0000%, ### 0. ## 0 #, etc.',
      sortAscending: 'Ordenar en orden ascendente',
      sortDescending: 'Ordenar en orden descendente',
      sortNone: 'Ordenar los datos',
      clearCalculatedField: 'Borrar información de campo editada',
      editCalculatedField: 'Editar campo calculado',
      selectGroup: 'Seleccionar grupos',
      of: 'de',
      removeCalculatedField:
        '¿Está seguro de que desea eliminar este campo calculado?',
      yes: '¿Está seguro de que desea eliminar este campo calculado?',
      no: 'Sí',
      None: 'No',
    },
    pdfviewer: {
      PdfViewer: 'Visor de PDF',
      Cancel: 'Cancelar',
      'Download file': 'Descargar archivo',
      Download: 'Descargar',
      'Enter Password':
        'Este documento está protegido por contraseña. Porfavor ingrese una contraseña.',
      'File Corrupted': 'Archivo corrupto',
      'File Corrupted Content': 'El archivo está dañado y no se puede abrir.',
      'Fit Page': 'La página de ajuste',
      'Fit Width': 'Ajuste ancho',
      Automatic: 'Automático',
      'Go To First Page': 'Mostrar primera página',
      'Invalid Password': 'Contraseña incorrecta. Inténtalo de nuevo.',
      'Next Page': 'Mostrar página siguiente',
      OK: 'Ok',
      Open: 'Abrir documento',
      'Page Number': 'Número de página actual',
      'Previous Page': 'Mostrar página anterior',
      'Go To Last Page': 'Mostrar la última página',
      Zoom: 'Enfocar',
      'Zoom In': 'Acercarse',
      'Zoom Out': 'Disminuir el zoom',
      'Page Thumbnails': 'Miniaturas de página',
      Bookmarks: 'Marcadores',
      Print: 'Imprimir archivo',
      'Password Protected': 'Se requiere contraseña',
      Copy: 'Copiar',
      'Text Selection': 'Herramienta de selección de texto',
      Panning: 'Modo panorámico',
      'Text Search': 'Buscar texto',
      'Find in document': 'Encuentra en el documento',
      'Match case': 'Caso de partido',
      Apply: 'Aplicar',
      GoToPage: 'Ir a la página',
      'No matches':
        'El visor ha terminado de buscar el documento. No se encontraron más coincidencias.',
      'No Text Found': 'No se encontró texto',
      Undo: 'Deshacer',
      Redo: 'Rehacer',
      Annotation: 'Agregar o editar anotaciones',
      Highlight: 'Subrayar el texto',
      Underline: 'Subrayar texto',
      Strikethrough: 'Texto tachado',
      Delete: 'Eliminar anotación',
      Opacity: 'Opacidad',
      'Color edit': 'Cambiar el color',
      'Opacity edit': 'Cambiar opacidad',
      highlight: 'Cambiar Opacidad',
      underline: 'Resaltar',
      strikethrough: 'Subrayar',
      'Server error':
        'El servicio web no está escuchando. PDF Viewer depende del servicio web para todas sus funciones. Inicie el servicio web para continuar.',
      'Open text': 'Abierto',
      'First text': 'Primera página',
      'Previous text': 'Pagina anterior',
      'Next text': 'Siguiente página',
      'Last text': 'Última página',
      'Zoom in text': 'Acercarse',
      'Zoom out text': 'Disminuir el zoom',
      'Selection text': 'Selección',
      'Pan text': 'Pan',
      'Print text': 'Impresión',
      'Seach text': 'Imprimir',
      'Annotation Edit text': 'Editar anotación',
      'Highlight context': 'Realce',
      'Underline context': 'Subrayar',
      'Strikethrough context': 'Penetrar',
      'Search text': 'Buscar',
      'Line Thickness': 'Grosor de la línea',
      'Line Properties': 'Propiedades de linea',
      'Start Arrow': 'Flecha de inicio',
      'End Arrow': 'Flecha final',
      'Line Style': 'Estilo de línea',
      'Fill Color': 'Color de relleno',
      'Line Color': 'Color de linea',
      None: 'Ninguna',
      'Open Arrow': 'Abierto',
      'Closed Arrow': 'Cerrado',
      'Round Arrow': 'Redondo',
      'Square Arrow': 'Cuadrado',
      'Diamond Arrow': 'Diamante',
      Cut: 'Cortar',
      Paste: 'Pegar',
      'Delete Context': 'Eliminar',
      Properties: 'Propiedades',
      'Add Stamp': 'Agregar sello',
      'Add Shapes': 'Agregar formas',
      'Stroke edit': 'Cambiar color de trazo',
      'Change thickness': 'Cambiar grosor del borde',
      'Add line': 'Añadir línea',
      'Add arrow': 'Agregar flecha',
      'Add rectangle': 'Añadir rectángulo',
      'Add circle': 'Agregar círculo',
      'Add polygon': 'Agregar polígono',
      'Add Comments': 'Añadir comentarios',
      Comments: 'Comentarios',
      'No Comments Yet': 'Sin comentarios aún',
      Accepted: 'Aceptado',
      Completed: 'Terminado',
      Cancelled: 'Cancelado',
      Rejected: 'Rechazado',
      'Leader Length': 'Longitud del líder',
      'Scale Ratio': 'Ratio de escala',
      Calibrate: 'Calibrar',
      'Calibrate Distance': 'Calibrar distancia',
      'Calibrate Perimeter': 'Calibrar perímetro',
      'Calibrate Area': 'Área de calibración',
      'Calibrate Radius': 'Calibrar radio',
      'Calibrate Volume': 'Calibrar volumen',
    },
    diagram: {
      Copy: 'Copiar',
      Cut: 'Cortar',
      Paste: 'Pegar',
      Undo: 'Deshacer',
      Redo: 'Rehacer',
      SelectAll: 'Seleccionar todo',
      Grouping: 'Agrupamiento',
      Group: 'Grupo',
      UnGroup: 'Desagrupar',
      Order: 'Orden',
      BringToFront: 'Traer al frente',
      MoveForward: 'Avanzar',
      SendToBack: 'Enviar al fondo',
      SendBackward: 'Enviar atrás',
    },
    'inplace-editor': {
      editIcon: 'Haz click para editar',
      save: 'Salvar',
      cancel: 'Cancelar',
    },
    DocumentEditor: {
      Table: 'Mesa',
      Row: 'Fila',
      Cell: 'Célula',
      Ok: 'Ok',
      Cancel: 'Cancelar',
      Size: 'Talla',
      'Preferred Width': 'Ancho preferido',
      Points: 'Puntos',
      Percent: 'Por ciento',
      'Measure in': 'Medir en',
      Alignment: 'Alineación',
      Left: 'Izquierda',
      Center: 'Centrar',
      Right: 'Derecho',
      Justify: 'Justificar',
      'Indent from left': 'Sangría desde la izquierda',
      'Borders and Shading': 'Bordes y sombreado',
      Options: 'Opciones',
      'Specify height': 'Especificar altura',
      'At least': 'Al menos',
      Exactly: 'Exactamente',
      'Row height is': 'La altura de la fila es',
      'Allow row to break across pages':
        'Permitir que la fila se rompa entre páginas',
      'Repeat as header row at the top of each page':
        'Repita como fila de encabezado en la parte superior de cada página',
      'Vertical alignment': 'Alineamiento vertical',
      Top: 'Parte superior',
      Bottom: 'Fondo',
      'Default cell margins': 'Márgenes de celda predeterminados',
      'Default cell spacing': 'Espaciado de celda predeterminado',
      'Allow spacing between cells': 'Permitir espacio entre celdas',
      'Cell margins': 'Márgenes celulares',
      'Same as the whole table': 'Igual que toda la mesa',
      Borders: 'Fronteras',
      None: 'Ninguna',
      Single: 'Soltero',
      Dot: 'Punto',
      DashSmallGap: 'GuiónPequeñoGap',
      DashLargeGap: 'GuiónLargeGap',
      DashDot: 'Guion punto',
      DashDotDot: 'guiónpuntopunto',
      Double: 'Doble',
      Triple: 'Triple',
      ThinThickSmallGap: 'DelgadoGruesoPequeñoGap',
      ThickThinSmallGap: 'GruesoDelgadoPequeñoGap',
      ThinThickThinSmallGap: 'DelgadoGruesoDelgadoPequeñoGap',
      ThinThickMediumGap: 'DelgadoGruesoMedioEspacio',
      ThickThinMediumGap: 'GruesoDelgadoMedioEspacio',
      ThinThickThinMediumGap: 'DelgadoGruesoDelgadoMedioBrecha',
      ThinThickLargeGap: 'DelgadoGesorGrandeEspacio',
      ThickThinLargeGap: 'GruesoDelgadoGrandeBrecha',
      ThinThickThinLargeGap: 'DelgadoGruesoDelgadoGrandeBrecha',
      SingleWavy: 'Solo ondulado',
      DoubleWavy: 'doble ondulado',
      DashDotStroked: 'GuiónPuntoAcariciado',
      Emboss3D: 'Relieve 3D',
      Engrave3D: 'Grabar3D',
      Outset: 'Comienzo',
      Inset: 'Recuadro',
      Thick: 'Grueso',
      Style: 'Estilo',
      Width: 'Anchura',
      Height: 'Altura',
      Letter: 'Letra',
      Tabloid: 'Tabloide',
      Legal: 'Legal',
      Statement: 'Declaración',
      Executive: 'Ejecutivo',
      A3: 'A3',
      A4: 'A4',
      A5: 'A5',
      B4: 'B4',
      B5: 'B5',
      'Custom Size': 'Tamaño personalizado',
      'Different odd and even': 'Diferentes pares e impares',
      'Different first page': 'Primera página diferente',
      'From edge': 'Desde el borde',
      Header: 'Encabezamiento',
      Footer: 'Pie de página',
      Margin: 'Márgenes',
      Paper: 'Papel',
      Layout: 'Diseño',
      Orientation: 'Orientación',
      Landscape: 'Paisaje',
      Portrait: 'Retrato',
      'Table Of Contents': 'Tabla de contenido',
      'Show page numbers': 'Mostrar números de página',
      'Right align page numbers': 'Alinear a la derecha los números de página',
      Nothing: 'Nada',
      'Tab leader': 'Tabulador',
      'Show levels': 'Mostrar niveles',
      'Use hyperlinks instead of page numbers':
        'Use hipervínculos en lugar de números de página',
      'Build table of contents from': 'Crear tabla de contenido a partir de',
      Styles: 'Estilos',
      'Available styles': 'Estilos disponibles',
      'TOC level': 'Nivel de TOC',
      Heading: 'Bóveda',
      'List Paragraph': 'Párrafo de lista',
      Normal: 'Normal',
      'Outline levels': 'Niveles de esquema',
      'Table entry fields': 'Campos de entrada de tabla',
      Modify: 'Modificar',
      Color: 'Color',
      Setting: 'Ajuste',
      Box: 'Caja',
      All: 'Todos',
      Custom: 'Personalizado',
      Preview: 'Avance',
      Shading: 'Sombreado',
      Fill: 'Llenar',
      'Apply To': 'Aplicar para',
      'Table Properties': 'Propiedades de tabla',
      'Cell Options': 'Opciones de celda',
      'Table Options': 'Opciones de tabla',
      'Insert Table': 'Insertar tabla',
      'Number of columns': 'Número de columnas',
      'Number of rows': 'Número de filas',
      'Text to display': 'Texto a mostrar',
      Address: 'Habla a',
      'Insert Hyperlink': 'Insertar hipervínculo',
      'Edit Hyperlink': 'Editar hipervínculo',
      Insert: 'Insertar',
      General: 'General',
      Indentation: 'Sangría',
      'Before text': 'Antes del texto',
      Special: 'Especial',
      'First line': 'Primera linea',
      Hanging: 'Colgando',
      'After text': 'Después del texto',
      By: 'Por',
      Before: 'antes de',
      'Line Spacing': 'Espaciado entre líneas',
      After: 'Después',
      At: 'A',
      Multiple: 'Múltiple',
      Spacing: 'Espaciado',
      'Define new Multilevel list': 'Definir nueva lista multinivel',
      'List level': 'Nivel de lista',
      'Choose level to modify': 'Elija el nivel para modificar',
      Level: 'Nivel',
      'Number format': 'Formato numérico',
      'Number style for this level': 'Estilo numérico para este nivel',
      'Enter formatting for number': 'Ingrese el formato para el número',
      'Start at': 'Empieza en',
      'Restart list after': 'Reiniciar lista después',
      Position: 'Posición',
      'Text indent at': 'Sangría de texto en',
      'Aligned at': 'Alineado a las',
      'Follow number with': 'Seguir número con',
      'Tab character': 'Carácter de tabulación',
      Space: 'Espacio',
      Arabic: 'Arábica',
      UpRoman: 'UpRoman',
      LowRoman: 'LowRoman',
      UpLetter: 'UpLetter',
      LowLetter: 'Letra baja',
      Number: 'Número',
      'Leading zero': 'Cero a la izquierda',
      Bullet: 'Bala',
      Ordinal: 'Ordinal',
      'Ordinal Text': 'Texto ordinal',
      'For East': 'Para el este',
      'No Restart': 'No reiniciar',
      Font: 'Fuente',
      'Font style': 'Estilo de fuente',
      'Underline style': 'Estilo subrayado',
      'Font color': 'Color de fuente',
      Effects: 'Efectos',
      Strikethrough: 'Tachado',
      Superscript: 'Sobrescrito',
      Subscript: 'Subíndice',
      'Double strikethrough': 'Tachado doble',
      Regular: 'Regular',
      Bold: 'Negrita',
      Italic: 'Itálico',
      Cut: 'Cortar',
      Copy: 'Copiar',
      Paste: 'Pegar',
      Hyperlink: 'Hipervínculo',
      'Open Hyperlink': 'Abrir hipervínculo',
      'Copy Hyperlink': 'Copiar hipervínculo',
      'Remove Hyperlink': 'Eliminar hipervínculo',
      Paragraph: 'Párrafo',
      'Merge Cells': 'Combinar células',
      'Insert Above': 'Insertar arriba',
      'Insert Below': 'Insertar a continuación',
      'Insert Left': 'Insertar a la izquierda',
      'Insert Right': 'Insertar a la derecha',
      Delete: 'Eliminar',
      'Delete Table': 'Eliminar tabla',
      'Delete Row': 'Borrar fila',
      'Delete Column': 'Eliminar columna',
      'File Name': 'Nombre del archivo',
      'Format Type': 'Tipo de formato',
      Save: 'Salvar',
      Navigation: 'Navegación',
      Results: 'Resultados',
      Replace: 'Reemplazar',
      'Replace All': 'Reemplaza todo',
      'We replaced all': 'Reemplazamos todo',
      Find: 'Encontrar',
      'No matches': 'No hay coincidencias',
      'All Done': 'Todo listo',
      Result: 'Resultado',
      of: 'de',
      instances: 'instancias',
      with: 'con',
      'Click to follow link': 'Haga clic para seguir el enlace',
      'Continue Numbering': 'Continuar numerando',
      'Bookmark name': 'Nombre del marcador',
      Close: 'Cerca',
      'Restart At': 'Reiniciar en',
      Properties: 'Propiedades',
      Name: 'Nombre',
      'Style type': 'Tipo de estilo',
      'Style based on': 'Estilo basado en',
      'Style for following paragraph': 'Estilo para el siguiente párrafo',
      Formatting: 'Formateo',
      'Numbering and Bullets': 'Numeración y viñetas',
      Numbering: 'Numeración',
      'Update Field': 'Campo de actualización',
      'Edit Field': 'Editar campo',
      Bookmark: 'Marcador',
      'Page Setup': 'Configurar página',
      'No bookmarks found': 'No se encontraron marcadores',
      'Number format tooltip information':
        'Formato de número de nivel único: </br> [PREFIJO]% [NIVEL DE NIVEL] [SUFIJO] </br> Por ejemplo, Capítulo% 1. mostrará numeración como </br> Capítulo 1. Elemento </br> Capítulo 2. Elemento </br> ... </br> Capítulo N. Elemento </br> </br> Formato de número multinivel: </ br > [PREFIJO]% [NIVEL DE NIVEL] [SUFIJO] + [PREFIJO]% [NIVEL DE NIVEL] [SUFIJO] </br> Por ejemplo,% 1.% 2. mostrará numeración como </br> 1.1. Artículo </br> 1.2. Artículo </br> ... </br> 1.N. Articulo',
      Format: 'Formato',
      'Create New Style': 'Crear nuevo estilo',
      'Modify Style': 'Modificar estilo',
      New: 'Nuevo',
      Bullets: 'Balas',
      'Use bookmarks': 'Usar marcadores',
      'Table of Contents': 'Tabla de contenido',
    },
    drawing: {},
    'rich-text-editor': {
      alignments: 'alineaciones',
      justifyleft: 'justifyLeft',
      justifycenter: 'justifyCenter',
      justifyright: 'justifyRight',
      justifyfull: 'justifyFull',
      fontname: 'nombre de la fuente',
      fontsize: 'tamaño de fuente',
      fontcolor: 'color de fuente',
      backgroundcolor: 'color de fondo',
      clearformat: 'clearFormat',
      clearall: 'limpiar todo',
      unorderedlist: 'lista desordenada',
      orderedlist: 'lista ordenada',
      createlink: 'crear vínculo',
      openlink: 'enlace abierto',
      editlink: 'editLink',
      removelink: 'remover enlace',
      openimagelink: 'enlace abierto',
      editimagelink: 'editLink',
      removeimagelink: 'remover enlace',
      image: 'imagen',
      replace: 'reemplazar',
      align: 'alinear',
      caption: 'subtítulo',
      remove: 'eliminar',
      insertlink: 'Insertar el link',
      display: 'monitor',
      alttext: 'texto alternativo',
      dimension: 'dimensión',
      fullscreen: 'pantalla completa',
      maximize: 'maximizar',
      minimize: 'minimizar',
      lowercase: 'lowerCase',
      uppercase: 'upperCase',
      print: 'impresión',
      formats: 'formatos',
      sourcecode: 'código fuente',
      preview: 'avance',
      viewside: 'lado de vista',
      insertcode: 'insertar codigo',
      justifyLeft: 'Alinear a la izquierda',
      justifyCenter: 'Alinear al centro',
      justifyRight: 'Alinear a la derecha',
      justifyFull: 'Alinear Justificar',
      fontName: 'Nombre de la fuente',
      fontSize: 'Tamaño de fuente',
      fontColor: 'Color de fuente',
      backgroundColor: 'Color de fondo',
      bold: 'negrita',
      italic: 'itálico',
      underline: 'subrayar',
      strikethrough: 'tachado',
      cut: 'cortar',
      copy: 'Copiar',
      paste: 'pegar',
      indent: 'sangrar',
      outdent: 'outdent',
      undo: 'deshacer',
      redo: 'rehacer',
      superscript: 'sobrescrito',
      subscript: 'subíndice',
      createLink: 'Insertar hipervínculo',
      openLink: 'Enlace abierto',
      editLink: 'Editar enlace',
      removeLink: 'Remover enlace',
      insertLink: 'Insertar el link',
      altText: 'Texto alternativo',
      lowerCase: 'Minúscula',
      upperCase: 'Mayúscula',
      insertCode: 'Insertar codigo',
      linkText: 'Mostrar texto',
      linkTooltipLabel: 'Información sobre herramientas',
      linkWebUrl: 'Dirección web',
      linkurl: 'http://ejemplo.com',
      linkOpenInNewWindow: 'Abrir enlace en una nueva ventana',
      linkHeader: 'Insertar el link',
      dialogInsert: 'Insertar',
      dialogCancel: 'Cancelar',
      dialogUpdate: 'Actualizar',
      imageHeader: 'Insertar imagen',
      imageLinkHeader: 'También puede proporcionar un enlace desde la web',
      mdimageLink: 'Proporcione una URL para su imagen',
      imageUploadMessage: 'Suelta la imagen aquí o navega para subir',
      imageDeviceUploadMessage: 'Haga clic aquí para subir',
      imageAlternateText: 'Texto alternativo',
      alternateHeader: 'Texto alternativo',
      browse: 'Vistazo',
      imageUrl: 'http://example.com/image.png',
      imageCaption: 'Subtítulo',
      imageSizeHeader: 'Tamaño de la imagen',
      imageHeight: 'Altura',
      imageWidth: 'Anchura',
      textPlaceholder: 'Ingrese texto',
      tblhead: '1X1',
      inserttablebtn: 'Insertar tabla',
      tabledialogHeader: 'Insertar tabla',
      tableWidth: 'Ancho',
      cellpadding: 'Relleno celular',
      cellspacing: 'Espaciado de celdas',
      columns: 'Número de columnas',
      rows: 'Número de filas',
      tableRows: 'Filas de tabla',
      tableColumns: 'Columnas de la tabla',
      tableCellHorizontalAlign: 'Alineación horizontal de celda de tabla',
      tableCellVerticalAlign: 'Alineación vertical de celdas de tabla',
      createTable: 'Crear mesa',
      removeTable: 'Eliminar tabla',
      tableHeader: 'Encabezado de tabla',
      tableRemove: 'Eliminar tabla',
      tableCellBackground: 'Fondo de celda de tabla',
      tableEditProperties: 'Propiedades de edición de tabla',
      styles: 'Estilos',
      insertColumnLeft: 'Insertar columna a la izquierda',
      insertColumnRight: 'Insertar columna a la derecha',
      deleteColumn: 'Eliminar columna',
      insertRowBefore: 'Insertar fila antes',
      insertRowAfter: 'Insertar fila después',
      deleteRow: 'Borrar fila',
      tablerows: 'filas de mesa',
      tablecolumns: 'mesacolumnas',
      tablecellhorizontalalign: 'tableCellHorizontalAlign',
      tablecellverticalalign: 'tableCellVerticalAlign',
      createtable: 'crear mesa',
      removetable: 'removeTable',
      tableheader: 'encabezado de tabla',
      tableremove: 'tablaEliminar',
      tablecellbackground: 'mesaceldafondo',
      tableeditproperties: 'tableEditProperties',
      insertcolumnleft: 'insertarColumnaIzquierda',
      insertcolumnright: 'insertarColumnaDerecha',
      deletecolumn: 'eliminarColumna',
      aligntop: 'Alinear arriba',
      alignmiddle: 'Alinear medio',
      alignbottom: 'Alinear abajo',
      insertrowbefore: 'insertRowBefore',
      insertrowafter: 'insertRowAfter',
      deleterow: 'borrar fila',
    },
    'drop-down-base': {
      noRecordsTemplate: 'No se encontraron registros',
      actionFailureTemplate: 'La solicitud falló',
    },
    chart: {
      Zoom: 'Enfocar',
      ZoomIn: 'Acercarse',
      ZoomOut: 'Disminuir el zoom',
      Reset: 'Reiniciar',
      Pan: 'Pan',
      ResetZoom: 'Restablecer zoom',
    },
    maps: {
      Zoom: 'Enfocar',
      ZoomIn: 'Acercarse',
      ZoomOut: 'Disminuir el zoom',
      Reset: 'Reiniciar',
      Pan: 'Pan',
      ResetZoom: 'Restablecer zoom',
    },
    'drop-down-tree': {
      noRecordsTemplate: 'No se encontraron registros',
      actionFailureTemplate: 'Solicitud fallida',
      overflowCountTemplate: '+ $ {count} más ...',
      totalCountTemplate: '$ {count} seleccionados',
    },
    PdfViewer: {
      PdfViewer: 'Visor de PDF',
      Cancel: 'Cancelar',
      'Download file': 'Descargar archivo',
      Download: 'Descargar',
      'Enter Password':
        'Este documento está protegido por contraseña. Porfavor ingrese una contraseña.',
      'File Corrupted': 'Archivo corrupto',
      'File Corrupted Content': 'El archivo está dañado y no se puede abrir.',
      'Fit Page': 'La página de ajuste',
      'Fit Width': 'Ajuste ancho',
      Automatic: 'Automático',
      'Go To First Page': 'Mostrar primera página',
      'Invalid Password': 'Contraseña incorrecta. Inténtalo de nuevo.',
      'Next Page': 'Mostrar página siguiente',
      OK: 'Ok',
      Open: 'Abrir documento',
      'Page Number': 'Número de página actual',
      'Previous Page': 'Mostrar página anterior',
      'Go To Last Page': 'Mostrar la última página',
      Zoom: 'Enfocar',
      'Zoom In': 'Acercarse',
      'Zoom Out': 'Disminuir el zoom',
      'Page Thumbnails': 'Miniaturas de página',
      Bookmarks: 'Marcadores',
      Print: 'Imprimir archivo',
      'Password Protected': 'Se requiere contraseña',
      Copy: 'Copiar',
      'Text Selection': 'Herramienta de selección de texto',
      Panning: 'Modo panorámico',
      'Text Search': 'Buscar texto',
      'Find in document': 'Encuentra en el documento',
      'Match case': 'Caso de partido',
      Apply: 'Aplicar',
      GoToPage: 'Ir a la página',
      'No matches':
        'El visor ha terminado de buscar el documento. No se encontraron más coincidencias.',
      'No Text Found': 'No se encontró texto',
      Undo: 'Deshacer',
      Redo: 'Rehacer',
      Annotation: 'Agregar o editar anotaciones',
      Highlight: 'Subrayar el texto',
      Underline: 'Subrayar texto',
      Strikethrough: 'Texto tachado',
      Delete: 'Eliminar anotación',
      Opacity: 'Opacidad',
      'Color edit': 'Cambiar el color',
      'Opacity edit': 'Cambiar opacidad',
      'Highlight context': 'Realce',
      'Underline context': 'Subrayar',
      'Strikethrough context': 'Penetrar',
      'Server error':
        'El servicio web no está escuchando. Visor de PDFr depende del servicio web para todas sus funciones. Inicie el servicio web para continuar.',
      'Open text': 'Abierto',
      'First text': 'Primera página',
      'Previous text': 'Pagina anterior',
      'Next text': 'Siguiente página',
      'Last text': 'Última página',
      'Zoom in text': 'Acercarse',
      'Zoom out text': 'Disminuir el zoom',
      'Selection text': 'Selección',
      'Pan text': 'Pan',
      'Print text': 'Impresión',
      'Search text': 'Buscar',
      'Annotation Edit text': 'Editar anotación',
      'Line Thickness': 'Grosor de la línea',
      'Line Properties': 'Propiedades de linea',
      'Start Arrow': 'Flecha de inicio',
      'End Arrow': 'Flecha final',
      'Line Style': 'Estilo de línea',
      'Fill Color': 'Color de relleno',
      'Line Color': 'Color de linea',
      None: 'Ninguna',
      'Open Arrow': 'Abierto',
      'Closed Arrow': 'Cerrado',
      'Round Arrow': 'Redondo',
      'Square Arrow': 'Cuadrado',
      'Diamond Arrow': 'Diamante',
      Butt: 'Extremo',
      Cut: 'Cortar',
      Paste: 'Pegar',
      'Delete Context': 'Eliminar',
      Properties: 'Propiedades',
      'Add Stamp': 'Agregar sello',
      'Add Shapes': 'Agregar formas',
      'Stroke edit': 'Cambiar color de trazo',
      'Change thickness': 'Cambiar grosor del borde',
      'Add line': 'Añadir línea',
      'Add arrow': 'Agregar flecha',
      'Add rectangle': 'Añadir rectángulo',
      'Add circle': 'Agregar círculo',
      'Add polygon': 'Agregar polígono',
      'Add Comments': 'Añadir comentarios',
      Comments: 'Comentarios',
      'No Comments Yet': 'Sin comentarios aún',
      Accepted: 'Aceptado',
      Completed: 'Terminado',
      Cancelled: 'Cancelado',
      Rejected: 'Rechazado',
      'Leader Length': 'Longitud del líder',
      'Scale Ratio': 'Ratio de escala',
      Calibrate: 'Calibrar',
      'Calibrate Distance': 'Calibrar distancia',
      'Calibrate Perimeter': 'Calibrar perímetro',
      'Calibrate Area': 'Área de calibración',
      'Calibrate Radius': 'Calibrar radio',
      'Calibrate Volume': 'Calibrar volumen',
      Depth: 'Profundidad',
      Closed: 'Cerrada',
      Round: 'Ronda',
      Square: 'Cuadrado',
      Diamond: 'Diamante',
      Edit: 'Editar',
      Comment: 'Comentario',
      'Comment Panel': 'Panel de comentarios',
      'Set Status': 'Establecer estado',
      Post: 'Correo',
      Page: 'Página',
      'Add a comment': 'Añadir un comentario',
      'Add a reply': 'Agregar una respuesta',
      'Import Annotations': 'Importar anotaciones desde un archivo JSON',
      'Export Annotations': 'Exportar anotación a archivo JSON',
      'Export XFDF': 'Exportar anotación a archivo XFDF',
      'Import XFDF': 'Importar anotaciones desde un archivo XFDF',
      Add: 'Agregar',
      Clear: 'Claro',
      Bold: 'Negrito',
      Italic: 'Itálico',
      Strikethroughs: 'Tachado',
      Underlines: 'Subrayar',
      Superscript: 'Sobrescrito',
      Subscript: 'Subíndice',
      'Align left': 'Alinear a la izquierda',
      'Align right': 'Alinear a la derecha',
      Center: 'Centrar',
      Justify: 'Justificar',
      'Font color': 'Color de fuente',
      'Text Align': 'Texto alineado',
      'Text Properties': 'Estilo de fuente',
      SignatureFieldDialogHeaderText: 'Agregar firma',
      HandwrittenSignatureDialogHeaderText: 'Agregar firma',
      InitialFieldDialogHeaderText: 'Agregar inicial',
      HandwrittenInitialDialogHeaderText: 'Agregar inicial',
      'Draw Ink': 'Dibujar tinta',
      Create: 'Crear',
      'Font family': 'Familia tipográfica',
      'Font size': 'Tamaño de fuente',
      'Free Text': 'Texto libre',
      'Import Failed':
        'Tipo de archivo JSON o nombre de archivo no válido; seleccione un archivo JSON válido',
      'Import PDF Failed':
        'El tipo de archivo PDF no es válido o el archivo PDF no existe. Seleccione un archivo PDF válido',
      'File not found':
        'El archivo JSON importado no se encuentra en la ubicación deseada',
      'Export Failed':
        'La acción de exportación de anotaciones ha fallado; asegúrese de que las anotaciones se agreguen correctamente',
      of: 'de',
      Dynamic: 'Dinámico',
      'Standard Business': 'Negocio estándar',
      'Sign Here': 'Firma aqui',
      'Custom Stamp': 'Sello personalizado',
      'Enter Signature as Name': 'Introduzca su nombre',
      'Draw-hand Signature': 'DIBUJAR',
      'Type Signature': 'TIPO',
      'Upload Signature': 'SUBIR',
      'Browse Signature Image': 'NAVEGAR',
      'Save Signature': 'Guardar firma',
    },
    documenteditor: {
      Table: 'Mesa',
      Row: 'Fila',
      Cell: 'Célula',
      Ok: 'Ok',
      Cancel: 'Cancelar',
      Size: 'Talla',
      'Preferred Width': 'Ancho preferido',
      Points: 'Puntos',
      Percent: 'Por ciento',
      'Measure in': 'Medir en',
      Alignment: 'Alineación',
      Left: 'Izquierda',
      Center: 'Centrar',
      Right: 'Derecho',
      Justify: 'Justificar',
      'Indent from left': 'Sangría desde la izquierda',
      'Borders and Shading': 'Bordes y sombreado',
      Options: 'Opciones',
      'Specify height': 'Especificar altura',
      'At least': 'Al menos',
      Exactly: 'Exactamente',
      'Row height is': 'La altura de la fila es',
      'Allow row to break across pages':
        'Permitir que la fila se rompa entre páginas',
      'Repeat as header row at the top of each page':
        'Repita como fila de encabezado en la parte superior de cada página',
      'Vertical alignment': 'Alineamiento vertical',
      Top: 'Parte superior',
      Bottom: 'Fondo',
      'Default cell margins': 'Márgenes de celda predeterminados',
      'Default cell spacing': 'Espaciado de celda predeterminado',
      'Allow spacing between cells': 'Permitir espacio entre celdas',
      'Cell margins': 'Márgenes celulares',
      'Same as the whole table': 'Igual que toda la mesa',
      Borders: 'Fronteras',
      None: 'Ninguna',
      Style: 'Estilo',
      Width: 'Anchura',
      Height: 'Altura',
      Letter: 'Letra',
      Tabloid: 'Tabloide',
      Legal: 'Legal',
      Statement: 'Declaración',
      Executive: 'Ejecutivo',
      A3: 'A3',
      A4: 'A4',
      A5: 'A5',
      B4: 'B4',
      B5: 'B5',
      'Custom Size': 'Tamaño personalizado',
      'Different odd and even': 'Diferentes pares e impares',
      'Different first page': 'Primera página diferente',
      'From edge': 'Desde el borde',
      Header: 'Encabezamiento',
      Footer: 'Pie de página',
      'First Page Header': 'Encabezado de la primera página',
      'First Page Footer': 'Pie de página de la primera página',
      'Even Page Header': 'Encabezado de página par',
      'Even Page Footer': 'Pie de página par',
      'Odd Page Header': 'Encabezado de página impar',
      'Odd Page Footer': 'Pie de página impar',
      Margin: 'Márgenes',
      Paper: 'Papel',
      Layout: 'Diseño',
      Orientation: 'Orientación',
      Landscape: 'Paisaje',
      Portrait: 'Retrato',
      'Show page numbers': 'Mostrar números de página',
      'Right align page numbers': 'Alinear a la derecha los números de página',
      Nothing: 'Nada',
      'Tab leader': 'Tabulador',
      'Show levels': 'Mostrar niveles',
      'Use hyperlinks instead of page numbers':
        'Use hipervínculos en lugar de números de página',
      'Build table of contents from': 'Crear tabla de contenido a partir de',
      Styles: 'Estilos',
      'Available styles': 'Estilos disponibles',
      'TOC level': 'Nivel de TOC',
      Heading: 'Bóveda',
      'Heading 1': 'Título 1',
      'Heading 2': 'Título 2',
      'Heading 3': 'Título 3',
      'Heading 4': 'Título 4',
      'Heading 5': 'Título 5',
      'Heading 6': 'Título 6',
      'List Paragraph': 'Párrafo de lista',
      Normal: 'Normal',
      'Outline levels': 'Niveles de esquema',
      'Table entry fields': 'Campos de entrada de tabla',
      Modify: 'Modificar',
      Color: 'Color',
      Setting: 'Ajuste',
      Box: 'Caja',
      All: 'Todos',
      Custom: 'Personalizado',
      Preview: 'Avance',
      Shading: 'Sombreado',
      Fill: 'Llenar',
      'Apply To': 'Aplicar para',
      'Table Properties': 'Propiedades de tabla',
      'Cell Options': 'Opciones de celda',
      'Table Options': 'Opciones de tabla',
      'Insert Table': 'Insertar tabla',
      'Number of columns': 'Número de columnas',
      'Number of rows': 'Número de filas',
      'Text to display': 'Texto a mostrar',
      'ScreenTip text': 'Text to display',
      Address: 'Habla a',
      'Insert Hyperlink': 'Insertar hipervínculo',
      'Edit Hyperlink': 'Editar hipervínculo',
      Insert: 'Insertar',
      General: 'General',
      Indentation: 'Sangría',
      'Before text': 'Antes del texto',
      Special: 'Especial',
      'First line': 'Primera linea',
      Hanging: 'Colgando',
      'After text': 'Después del texto',
      By: 'Por',
      Before: 'antes de',
      'Line Spacing': 'Espaciado entre líneas',
      After: 'Después',
      At: 'A',
      Multiple: 'Múltiple',
      Spacing: 'Espaciado',
      'Define new Multilevel list': 'Definir nueva lista multinivel',
      'List level': 'Nivel de lista',
      'Choose level to modify': 'Elija el nivel para modificar',
      Level: 'Nivel',
      'Number format': 'Formato numérico',
      'Number style for this level': 'Estilo numérico para este nivel',
      'Enter formatting for number': 'Ingrese el formato para el número',
      'Start at': 'Empieza en',
      'Restart list after': 'Reiniciar lista después',
      Position: 'Posición',
      'Text indent at': 'Sangría de texto en',
      'Aligned at': 'Alineado a las',
      'Follow number with': 'Seguir número con',
      'Tab character': 'Carácter de tabulación',
      Space: 'Espacio',
      Arabic: 'Arábica',
      UpRoman: 'UpRoman',
      LowRoman: 'LowRoman',
      UpLetter: 'UpLetter',
      LowLetter: 'Letra baja',
      Number: 'Número',
      'Leading zero': 'Cero a la izquierda',
      Bullet: 'Bala',
      Ordinal: 'Ordinal',
      'Ordinal Text': 'Texto ordinal',
      'For East': 'Para el este',
      'No Restart': 'No reiniciar',
      Font: 'Fuente',
      'Font style': 'Estilo de fuente',
      'Underline style': 'Estilo subrayado',
      'Font color': 'Color de fuente',
      Effects: 'Efectos',
      Strikethrough: 'Tachado',
      Superscript: 'Sobrescrito',
      Subscript: 'Subíndice',
      'Double strikethrough': 'Tachado doble',
      Regular: 'Regular',
      Bold: 'Negrita',
      Italic: 'Itálico',
      Cut: 'Cortar',
      Copy: 'Copiar',
      Paste: 'Pegar',
      Hyperlink: 'Hipervínculo',
      'Open Hyperlink': 'Abrir hipervínculo',
      'Copy Hyperlink': 'Copiar hipervínculo',
      'Remove Hyperlink': 'Eliminar hipervínculo',
      Paragraph: 'Párrafo',
      'Linked Style': 'Vinculado (párrafo y personaje)',
      Character: 'Personaje',
      'Merge Cells': 'Combinar células',
      'Insert Above': 'Insertar arriba',
      'Insert Below': 'Insertar a continuación',
      'Insert Left': 'Insertar a la izquierda',
      'Insert Right': 'Insertar a la derecha',
      Delete: 'Eliminar',
      'Delete Table': 'Eliminar tabla',
      'Delete Row': 'Borrar fila',
      'Delete Column': 'Eliminar columna',
      'File Name': 'Nombre del archivo',
      'Format Type': 'Tipo de formato',
      Save: 'Salvar',
      Navigation: 'Navegación',
      Results: 'Resultados',
      Replace: 'Reemplazar',
      'Replace All': 'Reemplaza todo',
      'We replaced all': 'Reemplazamos todo',
      Find: 'Encontrar',
      'No matches': 'No hay coincidencias',
      'All Done': 'Todo listo',
      Result: 'Resultado',
      of: 'de',
      instances: 'instancias',
      with: 'con',
      'Click to follow link': 'Haga clic para seguir el enlace',
      'Continue Numbering': 'Continuar numerando',
      'Bookmark name': 'Nombre del marcador',
      Close: 'Cerca',
      'Restart At': 'Reiniciar en',
      Properties: 'Propiedades',
      Name: 'Nombre',
      'Style type': 'Tipo de estilo',
      'Style based on': 'Estilo basado en',
      'Style for following paragraph': 'Estilo para el siguiente párrafo',
      Formatting: 'Formateo',
      'Numbering and Bullets': 'Numeración y viñetas',
      Numbering: 'Numeración',
      'Update Field': 'Campo de actualización',
      'Edit Field': 'Editar campo',
      Bookmark: 'Marcador',
      'Page Setup': 'Configurar página',
      'No bookmarks found': 'No se encontraron marcadores',
      'Number format tooltip information':
        "Formato de número de nivel único: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Por ejemplo, 'Capítulo% 1'. mostrará numeración como </br> Capítulo 1. Elemento </br> Capítulo 2. Elemento </br>… </br> Capítulo N. Elemento </br> </br> Formato de número multinivel: </br> [ PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Por ejemplo, '% 1.% 2.' mostrará numeración como </br> 1.1. Artículo </br> 1.2. Artículo </br> ... </br> 1.N. Articulo",
      Format: 'Formato',
      'Create New Style': 'Crear nuevo estilo',
      'Modify Style': 'Modificar estilo',
      New: 'Nuevo',
      Bullets: 'Balas',
      'Use bookmarks': 'Usar marcadores',
      'Table of Contents': 'Tabla de contenido',
      AutoFit: 'Autoajuste',
      'AutoFit to Contents': 'Autoajuste al contenido',
      'AutoFit to Window': 'Autoajustar a la ventana',
      'Fixed Column Width': 'Ancho de columna fijo',
      Reset: 'Reiniciar',
      'Match case': 'Caso de partido',
      'Whole words': 'Palabras completas',
      Add: 'Añadir',
      'Go To': 'Ir',
      'Search for': 'Buscar',
      'Replace with': 'Reemplazar con',
      'TOC 1': 'TOC 1',
      'TOC 2': 'TOC 2',
      'TOC 3': 'TOC 3',
      'TOC 4': 'TOC 4',
      'TOC 5': 'TOC 5',
      'TOC 6': 'TOC 6',
      'TOC 7': 'TOC 7',
      'TOC 8': 'TOC 8',
      'TOC 9': 'TOC 9',
      'Right-to-left': 'De derecha a izquierda',
      'Left-to-right': 'De izquierda a derecha',
      Direction: 'Dirección',
      'Table direction': 'Dirección de la mesa',
      'Indent from right': 'Sangría desde la derecha',
      'Contextual Spacing':
        'No agregue espacio entre los párrafos de los mismos estilos.',
      'Password Mismatch': 'La contraseña no coincide',
      'Restrict Editing': 'Restringir edición',
      'Formatting restrictions': 'Restricciones de formato',
      'Allow formatting': 'Permitir formateo',
      'Editing restrictions': 'Edición de restricciones',
      'Read only': 'Solo lectura',
      'Exceptions Optional': 'Excepciones (opcional)',
      'Select Part Of Document And User':
        'Seleccione partes del documento y elija los usuarios que pueden editarlos libremente.',
      Everyone: 'Todo el mundo',
      'More users': 'Más usuarios',
      'Add Users': 'Agregar usuarios',
      'Enforcing Protection': 'Sí, comience a aplicar la protección',
      'Start Enforcing Protection': 'Comience a hacer cumplir la protección',
      'Enter User': 'Ingresar usuario',
      Users: 'Los usuarios',
      'Enter new password': 'Ingrese nueva clave',
      'Reenter new password to confirm':
        'Vuelva a ingresar la nueva contraseña para confirmar',
      'Your permissions': 'Sus permisos',
      'Protected Document':
        'Este documento está protegido contra la edición no intencional. Puede editar en esta región.',
      FormFieldsOnly: 'Solo puede completar formularios en esta región.',
      CommentsOnly: 'Solo puede insertar comentarios en esta región.',
      ReadOnlyProtection: 'Puede editar en esta región.',
      'Stop Protection': 'Detener la protección',
      Password: 'Contraseña',
      'Spelling Editor': 'Editor de ortografía',
      Spelling: 'Ortografía',
      'Spell Check': 'Corrector ortográfico',
      'Underline errors': 'Subrayar errores',
      Ignore: 'Ignorar',
      'Ignore All': 'Ignora todo',
      'Add to Dictionary': 'Añadir al diccionario',
      Change: 'Cambio',
      'Change All': 'Cambia todo',
      Suggestions: 'Sugerencias',
      'The password is incorrect': 'La contraseña es incorrecta',
      'Error in establishing connection with web server':
        'Error al establecer conexión con el servidor web',
      'Highlight the regions I can edit':
        'Destacar las regiones que puedo editar',
      'Show All Regions I Can Edit':
        'Mostrar todas las regiones que puedo editar',
      'Find Next Region I Can Edit':
        'Buscar la siguiente región que puedo editar',
      'Keep source formatting': 'Mantener el formato de origen',
      'Match destination formatting': 'Hacer coincidir el formato de destino',
      'Text only': 'Solo texto',
      Comments: 'Comentarios',
      'Type your comment': 'Escribe tu comentario',
      Post: 'Enviar',
      Reply: 'Respuesta',
      'New Comment': 'Nuevo comentario',
      Edit: 'Editar',
      Resolve: 'Resolver',
      Reopen: 'Reabrir',
      'No comments in this document': 'No hay comentarios en este documento.',
      more: 'más',
      'Type your comment here': 'escribe tu comentario aquí',
      'Next Comment': 'Siguiente comentario',
      'Previous Comment': 'Comentario anterior',
      'Un-posted comments': 'Comentarios no publicados',
      'Discard Comment':
        'Comentarios agregados no publicados. Si continúa, ese comentario será descartado.',
      'No Headings': '¡No se encontró rumbo!',
      'Add Headings':
        'Este documento no tiene encabezados. Agregue encabezados e intente nuevamente.',
      'More Options': 'Mas opciones',
      'Click to see this comment': 'Haga clic para ver este comentario',
      'Drop Down Form Field': 'Campo de formulario desplegable',
      'Drop-down items': 'Artículos desplegables',
      'Items in drop-down list': 'Artículos en la lista desplegable',
      ADD: 'AÑADIR',
      REMOVE: 'ELIMINAR',
      'Field settings': 'Configuraciones de campo',
      Tooltip: 'Información sobre herramientas',
      'Drop-down enabled': 'Desplegable habilitado',
      'Check Box Form Field': 'Campo de formulario de casilla de verificación',
      'Check box size': 'Casilla de verificación',
      Auto: 'Auto',
      'Default value': 'Valor por defecto',
      'Not checked': 'Sin revisar',
      Checked: 'Comprobado',
      'Check box enabled': 'Casilla de verificación habilitada',
      'Text Form Field': 'Campo de formulario de texto',
      Type: 'Tipo',
      'Default text': 'Texto predeterminado',
      'Maximum length': 'Longitud máxima',
      'Text format': 'Formato de texto',
      'Fill-in enabled': 'Relleno habilitado',
      'Default number': 'Número predeterminado',
      'Default date': 'Fecha predeterminada',
      'Date format': 'Formato de fecha',
      'Merge Track':
        'Esta acción no se marcará como cambio. ¿Quieres continuar?',
      UnTrack: 'No se puede rastrear',
      Accept: 'Aceptar',
      Reject: 'Rechazar',
      'Previous Changes': 'Cambios previos',
      'Next Changes': 'Próximos cambios',
      Inserted: 'Insertado',
      Deleted: 'Eliminado',
      Changes: 'Cambios',
      'Accept all': 'Aceptar todo',
      'Reject all': 'Rechazar todo',
      'No changes': 'Sin cambios',
      'Accept Changes': 'Aceptar cambios',
      'Reject Changes': 'Rechazar cambios',
      User: 'Usuario',
      View: 'Ver',
      Insertion: 'Inserción',
      Deletion: 'Supresión',
      'All caps': 'Todas mayúsculas',
      'This region is locked by': 'Esta región está bloqueada por',
      Lock: 'Bloquear',
      Unlock: 'desbloquear',
      'Already locked':
        'Otro usuario ya ha bloqueado la región seleccionada o parte de ella',
      '1, 2, 3, ...': '1, 2, 3, ...',
      'a, b, c, ...': 'a B C, ...',
      'A, B, C, ...': 'A B C, ...',
      'I, II, III, ...': 'Yo, II, III, ...',
      'i, ii, iii, ...': 'yo, ii, iii, ...',
      'Text Form': 'Formulario de texto',
      'Check Box': 'Casilla de verificación',
      DropDown: 'Desplegable',
      'Form Fields': 'Campos de formulario',
      'Fillin enabled': 'Completar habilitado',
      'Multiple Comment': 'Por favor publique su comentario',
      InsertFootnote: 'Insertar nota al pie',
      InsertEndnote: 'Insertar nota final',
      Footnote: 'Nota',
      Endnote: 'Nota final',
      'Click to View/Edit Footnote': 'Haga clic para ver / editar nota al pie',
      'Click to View/Edit Endnote': 'Haga clic para ver / editar nota final',
      InsertAsRows: 'Insertar como nuevas filas',
      InsertAsColumns: 'Insertar como nuevas columnas',
      OverwriteCells: 'Sobrescribir celdas',
      NestTable: 'Mesa nido',
      'No suggestions': 'No hay sugerencias',
      'More Suggestion': 'Más sugerencia',
      'Ignore Once': 'Ignorar una vez',
      'Keep With Next': 'Seguir con el siguiente',
      'Keep Lines Together': 'Mantener líneas juntas',
      WidowControl: 'Control de viudas/huérfanos',
      'Indents and Spacing': 'Sangrías y espaciado',
      'Line and Page Breaks': 'Saltos de línea y de página',
      Pagination: 'Paginación',
      Single: 'Soltero',
      DashSmallGap: 'GuiónPequeñoGap',
      DashDot: 'Guion punto',
      Double: 'Doble',
      ThinThickSmallGap: 'DelgadoGruesoPequeñoGap',
      ThickThinSmallGap: 'GruesoDelgadoPequeñoGap',
      ThickThinMediumGap: 'GruesoDelgadoMedioEspacio',
      ThickThinLargeGap: 'GruesoDelgadoGrandeBrecha',
      SingleWavy: 'Solo ondulado',
      DoubleWavy: 'doble ondulado',
      Inset: 'Recuadro',
      DashLargeGap: 'GuiónLargeGap',
      Dot: 'Punto',
      DashDotDot: 'guiónpuntopunto',
      Triple: 'Triple',
      ThinThickThinSmallGap: 'DelgadoGruesoDelgadoPequeñoGap',
      ThinThickThinMediumGap: 'DelgadoGruesoDelgadoMedioBrecha',
      ThinThickThinLargeGap: 'DelgadoGruesoDelgadoGrandeBrecha',
      DashDotStroked: 'GuiónPuntoAcariciado',
      Engrave3D: 'Grabar3D',
      Thick: 'Grueso',
      Outset: 'Comienzo',
      Emboss3D: 'Relieve 3D',
      ThinThickLargeGap: 'DelgadoGesorGrandeEspacio',
      ThinThickMediumGap: 'DelgadoGruesoMedioEspacio',
      'Number of rows must be between 1 and 32767.':
        'El número de filas debe estar entre 1 y 32767.',
      'Number of columns must be between 1 and 63.':
        'El número de columnas debe estar entre 1 y 63.',
      Unlimited: 'Ilimitado',
      'Regular text': 'Texto normal',
      Date: 'Fecha',
      Uppercase: 'Mayúsculas',
      Lowercase: 'Minúsculas',
      FirstCapital: 'PrimeraCapital',
      TitleCase: 'Titulo del caso',
      'Filling in forms': 'Rellenar formularios',
      px: 'píxeles',
      'Tracked changes': 'Tracked changes',
      TrackChangesOnly:
        'You may edit in this region, but all change will be tracked.',
      RemovedIgnore: 'Do you want to remove the ignored exceptions?',
      RemovedIgnoreExceptions:
        'If you make this change in document protection, Word will ignore all the exceptions in this document.',
      Information: 'Información',
      Yes: 'Sí',
      No: 'No',
      'You may format text only with certain styles':
        'Puede formatear texto solo con ciertos estilos.',
      'Ignore all': 'Ignora todo',
    },
    documenteditorcontainer: {
      New: 'Nuevo',
      Open: 'Abierto',
      Undo: 'Deshacer',
      Redo: 'Rehacer',
      Image: 'Imagen',
      Table: 'Mesa',
      Link: 'Enlace',
      Bookmark: 'Marcador',
      'Table of Contents': 'Tabla de contenido',
      'HEADING - - - - 1': 'TÍTULO - - - - 1',
      'HEADING - - - - 2': 'TÍTULO - - - - 2',
      'HEADING - - - - 3': 'TÍTULO - - - - 3',
      Header: 'Encabezamiento',
      Footer: 'Pie de página',
      'Page Setup': 'Configurar página',
      'Page Number': 'Número de página',
      Break: 'Descanso',
      Find: 'Encontrar',
      'Local Clipboard': 'Portapapeles local',
      'Restrict Editing': 'Restringir edición',
      'Upload from computer': 'Subir desde la computadora',
      'By URL': 'Por URL',
      'Page Break': 'Salto de página',
      'Section Break': 'Salto de sección',
      'Header And Footer': 'Encabezado y pie de página',
      Options: 'Opciones',
      Levels: 'Niveles',
      'Different First Page': 'Primera página diferente',
      'Different header and footer for odd and even pages':
        'Encabezado y pie de página diferentes para páginas pares e impares.',
      'Different Odd And Even Pages': 'Diferentes páginas pares e impares',
      'Different header and footer for first page':
        'Encabezado y pie de página diferentes para la primera página.',
      Position: 'Posición',
      'Header from Top': 'Encabezado desde arriba',
      'Footer from Bottom': 'Pie de página desde abajo',
      'Distance from top of the page to top of the header':
        'Distancia desde la parte superior de la página hasta la parte superior del encabezado.',
      'Distance from bottom of the page to bottom of the footer':
        'Distancia desde la parte inferior de la página hasta la parte inferior del pie de página.',
      'Aspect ratio': 'Relación de aspecto',
      W: 'W',
      H: 'H',
      Width: 'Anchura',
      Height: 'Altura',
      Text: 'Texto',
      Paragraph: 'Párrafo',
      Fill: 'Llenar',
      'Fill color': 'Color de relleno',
      'Border Style': 'Estilo de borde',
      'Outside borders': 'Fronteras exteriores',
      'All borders': 'Todas las fronteras',
      'Inside borders': 'Bordes interiores',
      'Left border': 'Borde izquierdo',
      'Inside vertical border': 'Borde vertical interior',
      'Right border': 'Borde derecho',
      'Top border': 'Borde superior',
      'Inside horizontal border': 'Borde horizontal interior',
      'Bottom border': 'Borde inferior',
      'Border color': 'Color del borde',
      'Border width': 'Ancho del borde',
      Cell: 'Célula',
      'Merge cells': 'Combinar células',
      'Insert Or Delete': 'Insertar / Eliminar',
      'Insert columns to the left': 'Insertar columnas a la izquierda',
      'Insert columns to the right': 'Insertar columnas a la derecha',
      'Insert rows above': 'Insertar filas arriba',
      'Insert rows below': 'Insertar filas debajo',
      'Delete rows': 'Eliminar filas',
      'Delete columns': 'Eliminar columnas',
      'Cell Margin': 'Margen de celda',
      Top: 'Parte superior',
      Bottom: 'Fondo',
      Left: 'Izquierda',
      Right: 'Derecho',
      'Align Text': 'Texto alineado',
      'Align top': 'Alinear la parte superior',
      'Align bottom': 'Alinear el fondo',
      'Align center': 'Alinear al centro',
      'Number of heading or outline levels to be shown in table of contents':
        'Número de niveles de encabezado o esquema que se mostrarán en la tabla de contenido.',
      'Show page numbers': 'Mostrar números de página',
      'Show page numbers in table of contents':
        'Mostrar números de página en la tabla de contenido.',
      'Right align page numbers': 'Alinear a la derecha los números de página',
      'Right align page numbers in table of contents':
        'Alinee a la derecha los números de página en la tabla de contenido.',
      'Use hyperlinks': 'Usar hipervínculos',
      'Use hyperlinks instead of page numbers':
        'Use hipervínculos en lugar de números de página.',
      Font: 'Fuente',
      'Font Size': 'Tamaño de fuente',
      'Font color': 'Color de fuente',
      'Text highlight color': 'Color de resaltado de texto',
      'Clear all formatting': 'Borrar todo el formato',
      'Bold Tooltip': 'Negrita (Ctrl + B)',
      'Italic Tooltip': 'Cursiva (Ctrl + I)',
      'Underline Tooltip': 'Subrayado (Ctrl + U)',
      Strikethrough: 'Tachado',
      'Superscript Tooltip': 'Superíndice (Ctrl + Shift ++)',
      'Subscript Tooltip': 'Subíndice (Ctrl + =)',
      'Align left Tooltip': 'Alinear a la izquierda (Ctrl + L)',
      'Center Tooltip': 'Centro (Ctrl + E)',
      'Align right Tooltip': 'Alinear a la derecha (Ctrl + R)',
      'Justify Tooltip': 'Justificar (Ctrl + J)',
      'Decrease indent': 'Disminuir sangría',
      'Increase indent': 'Aumentar sangría',
      'Line spacing': 'Espaciado entre líneas',
      Bullets: 'Balas',
      Numbering: 'Numeración',
      Styles: 'Estilos',
      'Manage Styles': 'Administrar estilos',
      Page: 'Página',
      of: 'de',
      'Fit one page': 'Ajustar una página',
      'Spell Check': 'Corrector ortográfico',
      'Underline errors': 'Subrayar errores',
      'Fit page width': 'Ajustar ancho de página',
      Update: 'Actualizar',
      Cancel: 'Cancelar',
      Insert: 'Insertar',
      'No Border': 'Sin bordes',
      'Create a new document': 'Crea un nuevo documento.',
      'Open a document': 'Abrir un documento',
      'Undo Tooltip': 'Deshacer la última operación (Ctrl + Z).',
      'Redo Tooltip': 'Rehaga la última operación (Ctrl + Y).',
      'Insert inline picture from a file':
        'Insertar imagen en línea de un archivo.',
      'Insert a table into the document': 'Insertar una tabla en el documento',
      'Create Hyperlink':
        'Cree un enlace en su documento para acceder rápidamente a páginas web y archivos (Ctrl + K).',
      'Insert a bookmark in a specific place in this document':
        'Inserte un marcador en un lugar específico en este documento.',
      'Provide an overview of your document by adding a table of contents':
        'Proporcione una descripción general de su documento agregando una tabla de contenido.',
      'Add or edit the header': 'Agregar o editar el encabezado.',
      'Add or edit the footer': 'Agregar o editar el pie de página.',
      'Open the page setup dialog':
        'Abra el cuadro de diálogo de configuración de página.',
      'Add page numbers': 'Agregar números de página.',
      'Find Text': 'Buscar texto en el documento (Ctrl + F).',
      'Toggle between the internal clipboard and system clipboard':
        'Alterne entre el portapapeles interno y el portapapeles del sistema. </br> El acceso al portapapeles del sistema a través del script está denegado debido a la política de seguridad de los navegadores. En su lugar, </br> 1. Puede habilitar el portapapeles interno para cortar, copiar y pegar dentro del componente. </br> 2. Puede usar los atajos de teclado (Ctrl + X, Ctrl + C y Ctrl + V) para cortar , copie y pegue con el portapapeles del sistema.',
      'Current Page Number':
        'El número de página actual en el documento. Haga clic o toque para navegar por una página específica.',
      'Read only': 'Solo lectura',
      Protections: 'Protecciones',
      'Error in establishing connection with web server':
        'Error al establecer conexión con el servidor web',
      Single: 'Soltero',
      Double: 'Doble',
      'New comment': 'Nuevo comentario',
      Comments: 'Comentarios',
      'Print layout': 'Diseño de impresión',
      'Web layout': 'diseño web',
      'Text Form': 'Formulario de texto',
      'Check Box': 'Casilla de verificación',
      DropDown: 'Desplegable',
      'Update Fields': 'Actualizar campos',
      'Update cross reference fields':
        'Actualizar campos de referencia cruzada',
      'Hide properties pane': 'Ocultar panel de propiedades',
      'Show properties pane': 'Mostrar panel de propiedades',
      'Form Fields': 'Campos de formulario',
      'Track Changes':
        'Mantenga un registro de los cambios realizados en el documento',
      TrackChanges: 'Cambio de camino',
      AllCaps: 'AllCaps',
      'Change case Tooltip': 'Cambiar caso',
      'Insert Footnote': 'Insertar nota al pie',
      'Insert Endnote': 'Insertar nota al final',
      'Footnote Tooltip': 'Insertar nota al pie (Alt + Ctrl + F).',
      'Endnote Tooltip': 'Insertar nota al final (Alt + Ctrl + D).',
      UPPERCASE: 'MAYÚSCULAS',
      'No color': 'Sin color',
      'Top margin': 'Margen superior',
      'Bottom margin': 'Margen inferior',
      'Left margin': 'Margen izquierdo',
      'Right margin': 'Margen derecho',
      Normal: 'Normal',
      Heading: 'Título',
      'Heading 1': 'Título 1',
      'Heading 2': 'Título 2',
      'Heading 3': 'Título 3',
      'Heading 4': 'Título 4',
      'Heading 5': 'Título 5',
      'Heading 6': 'Título 6',
      ZoomLevelTooltip:
        'Nivel de zoom. Haga clic o toque para abrir las opciones de Zoom.',
      None: 'Ninguna',
      Borders: 'Fronteras',
    },
    carousel: {
      nextSlide: 'Siguiente diapositiva',
      of: 'de',
      pauseSlideTransition: 'Pausar transición de diapositiva',
      playSlideTransition: 'Reproducir transición de diapositiva',
      previousSlide: 'Diapositiva anterior',
      slide: 'Deslizar',
      slideShow: 'Diapositivas',
    },
  },
};
