import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';
import * as Sentry from "@sentry/angular-ivy";


// if (environment.environment !== 'local') {
//   var faro = initializeFaro({
//     url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/c08f24bc4fb96ce2157d292398deaab2',
//     app: {
//       name: 'clientportal',
//       version: '1.0.0',
//       environment: 'production',
//     },
//     instrumentations: [
//       // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
//       ...getWebInstrumentations(),

//       // Initialization of the tracing package.
//       // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
//       new TracingInstrumentation(),
//     ],
//   });
// }

// Registering Syncfusion license key
registerLicense(
  'ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhW39ZcHRQR2NcUEM='
);


if (environment.production) {
  Sentry.init({
    dsn: "https://06c3f49d6b8c7457d589028e105337e1@o4506733927989249.ingest.sentry.io/4506738804654080",
    environment: environment.environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
