import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RoutePaths } from '@shared/enums/routes.enums';

@Injectable({ providedIn: 'root' })
export class SharedService {

  private selectedMenuItem = new BehaviorSubject<number>(1);
  public selectedMenuItem$ = this.selectedMenuItem.asObservable();

  public menuItems = new BehaviorSubject<any>([]);
  public menuItems$ = this.menuItems.asObservable();

  public policiesItems = new BehaviorSubject<any>([]);
  public policiesItems$ = this.policiesItems.asObservable();

  public showWelcomeSection = new BehaviorSubject<any>(true);
  public showWelcomeSection$ = this.showWelcomeSection.asObservable();

  public showLatestEnhancementsSection = new BehaviorSubject<any>(true);
  public showLatestEnhancementsSection$ = this.showLatestEnhancementsSection.asObservable();

  public showPostsSection = new BehaviorSubject<any>(true);
  public showPostsSection$ = this.showPostsSection.asObservable();

  public showFAQSPage = new BehaviorSubject<any>(true);
  public showFAQSPage$ = this.showFAQSPage.asObservable();

  public userHasConfig = new BehaviorSubject<any>(true);
  public userHasConfig$ = this.userHasConfig.asObservable();

  constructor(private readonly router: Router) {}

  setMenuItems(value: any) {
    this.menuItems.next(value);
  }

  setPoliciesItems(value: any) {
    this.policiesItems.next(value);
  }

  setSelectedMenuItem(value: any) {
    this.selectedMenuItem.next(value);
  }

  setFirstMenuItemAsSelected() {
    this.selectedMenuItem.next(this.menuItems.value[0]);
  }

  setShowWelcomeSection(value:any){
    this.showWelcomeSection.next(value);
  }

  setShowLatestEnhancementsSection(value:any){
    this.showLatestEnhancementsSection.next(value);
  }

  setShowPostsSection(value:any){
    this.showPostsSection.next(value);
  }

  setShowFAQSPage(value:any){
    this.showFAQSPage.next(value);
  }

  setUserHasNoConfig(){
    this.userHasConfig.next(false);
    this.router.navigate([RoutePaths.CONTACT_ADMIN]);
  }
}
