export const timeZonesES = {
  main: {
    es: {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'es',
      },
      dates: {
        timeZoneNames: {
          hourFormat: '+HH:mm;-HH:mm',
          gmtFormat: 'GMT{0}',
          gmtZeroFormat: 'GMT',
          regionFormat: 'hora de {0}',
          'regionFormat-type-daylight': 'horario de verano de {0}',
          'regionFormat-type-standard': 'horario estándar de {0}',
          fallbackFormat: '{1} ({0})',
          zone: {
            America: {
              Adak: {
                exemplarCity: 'Adak',
              },
              Anchorage: {
                exemplarCity: 'Anchorage',
              },
              Anguilla: {
                exemplarCity: 'Anguila',
              },
              Antigua: {
                exemplarCity: 'Antigua',
              },
              Araguaina: {
                exemplarCity: 'Araguaína',
              },
              Argentina: {
                Rio_Gallegos: {
                  exemplarCity: 'Río Gallegos',
                },
                San_Juan: {
                  exemplarCity: 'San Juan',
                },
                Ushuaia: {
                  exemplarCity: 'Ushuaia',
                },
                La_Rioja: {
                  exemplarCity: 'La Rioja',
                },
                San_Luis: {
                  exemplarCity: 'San Luis',
                },
                Salta: {
                  exemplarCity: 'Salta',
                },
                Tucuman: {
                  exemplarCity: 'Tucumán',
                },
              },
              Aruba: {
                exemplarCity: 'Aruba',
              },
              Asuncion: {
                exemplarCity: 'Asunción',
              },
              Bahia: {
                exemplarCity: 'Bahía',
              },
              Bahia_Banderas: {
                exemplarCity: 'Bahía de Banderas',
              },
              Barbados: {
                exemplarCity: 'Barbados',
              },
              Belem: {
                exemplarCity: 'Belén',
              },
              Belize: {
                exemplarCity: 'Belice',
              },
              'Blanc-Sablon': {
                exemplarCity: 'Blanc-Sablon',
              },
              Boa_Vista: {
                exemplarCity: 'Boa Vista',
              },
              Bogota: {
                exemplarCity: 'Bogotá',
              },
              Boise: {
                exemplarCity: 'Boise',
              },
              Buenos_Aires: {
                exemplarCity: 'Buenos Aires',
              },
              Cambridge_Bay: {
                exemplarCity: 'Cambridge Bay',
              },
              Campo_Grande: {
                exemplarCity: 'Campo Grande',
              },
              Cancun: {
                exemplarCity: 'Cancún',
              },
              Caracas: {
                exemplarCity: 'Caracas',
              },
              Catamarca: {
                exemplarCity: 'Catamarca',
              },
              Cayenne: {
                exemplarCity: 'Cayena',
              },
              Cayman: {
                exemplarCity: 'Caimán',
              },
              Chicago: {
                exemplarCity: 'Chicago',
              },
              Chihuahua: {
                exemplarCity: 'Chihuahua',
              },
              Coral_Harbour: {
                exemplarCity: 'Atikokan',
              },
              Cordoba: {
                exemplarCity: 'Córdoba',
              },
              Costa_Rica: {
                exemplarCity: 'Costa Rica',
              },
              Creston: {
                exemplarCity: 'Creston',
              },
              Cuiaba: {
                exemplarCity: 'Cuiabá',
              },
              Curacao: {
                exemplarCity: 'Curazao',
              },
              Danmarkshavn: {
                exemplarCity: 'Danmarkshavn',
              },
              Dawson: {
                exemplarCity: 'Dawson',
              },
              Dawson_Creek: {
                exemplarCity: 'Dawson Creek',
              },
              Denver: {
                exemplarCity: 'Denver',
              },
              Detroit: {
                exemplarCity: 'Detroit',
              },
              Dominica: {
                exemplarCity: 'Dominica',
              },
              Edmonton: {
                exemplarCity: 'Edmonton',
              },
              Eirunepe: {
                exemplarCity: 'Eirunepé',
              },
              El_Salvador: {
                exemplarCity: 'El Salvador',
              },
              Fort_Nelson: {
                exemplarCity: 'Fort Nelson',
              },
              Fortaleza: {
                exemplarCity: 'Fortaleza',
              },
              Glace_Bay: {
                exemplarCity: 'Glace Bay',
              },
              Godthab: {
                exemplarCity: 'Nuuk',
              },
              Goose_Bay: {
                exemplarCity: 'Goose Bay',
              },
              Grand_Turk: {
                exemplarCity: 'Gran Turca',
              },
              Grenada: {
                exemplarCity: 'Granada',
              },
              Guadeloupe: {
                exemplarCity: 'Guadalupe',
              },
              Guatemala: {
                exemplarCity: 'Guatemala',
              },
              Guayaquil: {
                exemplarCity: 'Guayaquil',
              },
              Guyana: {
                exemplarCity: 'Guyana',
              },
              Halifax: {
                exemplarCity: 'Halifax',
              },
              Havana: {
                exemplarCity: 'La Habana',
              },
              Hermosillo: {
                exemplarCity: 'Hermosillo',
              },
              Indiana: {
                Vincennes: {
                  exemplarCity: 'Vincennes, Indiana',
                },
                Petersburg: {
                  exemplarCity: 'Petersburg, Indiana',
                },
                Tell_City: {
                  exemplarCity: 'Tell City, Indiana',
                },
                Knox: {
                  exemplarCity: 'Knox, Indiana',
                },
                Winamac: {
                  exemplarCity: 'Winamac, Indiana',
                },
                Marengo: {
                  exemplarCity: 'Marengo, Indiana',
                },
                Vevay: {
                  exemplarCity: 'Vevay, Indiana',
                },
              },
              Indianapolis: {
                exemplarCity: 'Indianápolis',
              },
              Inuvik: {
                exemplarCity: 'Inuvik',
              },
              Iqaluit: {
                exemplarCity: 'Iqaluit',
              },
              Jamaica: {
                exemplarCity: 'Jamaica',
              },
              Jujuy: {
                exemplarCity: 'Jujuy',
              },
              Juneau: {
                exemplarCity: 'Juneau',
              },
              Kentucky: {
                Monticello: {
                  exemplarCity: 'Monticello, Kentucky',
                },
              },
              Kralendijk: {
                exemplarCity: 'Kralendijk',
              },
              La_Paz: {
                exemplarCity: 'La Paz',
              },
              Lima: {
                exemplarCity: 'Lima',
              },
              Los_Angeles: {
                exemplarCity: 'Los Ángeles',
              },
              Louisville: {
                exemplarCity: 'Louisville',
              },
              Lower_Princes: {
                exemplarCity: 'Lower Prince’s Quarter',
              },
              Maceio: {
                exemplarCity: 'Maceió',
              },
              Managua: {
                exemplarCity: 'Managua',
              },
              Manaus: {
                exemplarCity: 'Manaos',
              },
              Marigot: {
                exemplarCity: 'Marigot',
              },
              Martinique: {
                exemplarCity: 'Martinica',
              },
              Matamoros: {
                exemplarCity: 'Matamoros',
              },
              Mazatlan: {
                exemplarCity: 'Mazatlán',
              },
              Mendoza: {
                exemplarCity: 'Mendoza',
              },
              Menominee: {
                exemplarCity: 'Menominee',
              },
              Merida: {
                exemplarCity: 'Mérida',
              },
              Metlakatla: {
                exemplarCity: 'Metlakatla',
              },
              Mexico_City: {
                exemplarCity: 'Ciudad de México',
              },
              Miquelon: {
                exemplarCity: 'Miquelón',
              },
              Moncton: {
                exemplarCity: 'Moncton',
              },
              Monterrey: {
                exemplarCity: 'Monterrey',
              },
              Montevideo: {
                exemplarCity: 'Montevideo',
              },
              Montserrat: {
                exemplarCity: 'Montserrat',
              },
              Nassau: {
                exemplarCity: 'Nassau',
              },
              New_York: {
                exemplarCity: 'Nueva York',
              },
              Nipigon: {
                exemplarCity: 'Nipigon',
              },
              Nome: {
                exemplarCity: 'Nome',
              },
              Noronha: {
                exemplarCity: 'Noronha',
              },
              North_Dakota: {
                Beulah: {
                  exemplarCity: 'Beulah, Dakota del Norte',
                },
                New_Salem: {
                  exemplarCity: 'New Salem, Dakota del Norte',
                },
                Center: {
                  exemplarCity: 'Center, Dakota del Norte',
                },
              },
              Ojinaga: {
                exemplarCity: 'Ojinaga',
              },
              Panama: {
                exemplarCity: 'Panamá',
              },
              Pangnirtung: {
                exemplarCity: 'Pangnirtung',
              },
              Paramaribo: {
                exemplarCity: 'Paramaribo',
              },
              Phoenix: {
                exemplarCity: 'Phoenix',
              },
              'Port-au-Prince': {
                exemplarCity: 'Puerto Príncipe',
              },
              Port_of_Spain: {
                exemplarCity: 'Puerto España',
              },
              Porto_Velho: {
                exemplarCity: 'Porto Velho',
              },
              Puerto_Rico: {
                exemplarCity: 'Puerto Rico',
              },
              Punta_Arenas: {
                exemplarCity: 'Punta Arenas',
              },
              Rainy_River: {
                exemplarCity: 'Rainy River',
              },
              Rankin_Inlet: {
                exemplarCity: 'Rankin Inlet',
              },
              Recife: {
                exemplarCity: 'Recife',
              },
              Regina: {
                exemplarCity: 'Regina',
              },
              Resolute: {
                exemplarCity: 'Resolute',
              },
              Rio_Branco: {
                exemplarCity: 'Río Branco',
              },
              Santa_Isabel: {
                exemplarCity: 'Santa Isabel',
              },
              Santarem: {
                exemplarCity: 'Santarém',
              },
              Santiago: {
                exemplarCity: 'Santiago de Chile',
              },
              Santo_Domingo: {
                exemplarCity: 'Santo Domingo',
              },
              Sao_Paulo: {
                exemplarCity: 'São Paulo',
              },
              Scoresbysund: {
                exemplarCity: 'Ittoqqortoormiit',
              },
              Sitka: {
                exemplarCity: 'Sitka',
              },
              St_Barthelemy: {
                exemplarCity: 'San Bartolomé',
              },
              St_Johns: {
                exemplarCity: 'San Juan de Terranova',
              },
              St_Kitts: {
                exemplarCity: 'San Cristóbal',
              },
              St_Lucia: {
                exemplarCity: 'Santa Lucía',
              },
              St_Thomas: {
                exemplarCity: 'St. Thomas',
              },
              St_Vincent: {
                exemplarCity: 'San Vicente',
              },
              Swift_Current: {
                exemplarCity: 'Swift Current',
              },
              Tegucigalpa: {
                exemplarCity: 'Tegucigalpa',
              },
              Thule: {
                exemplarCity: 'Thule',
              },
              Thunder_Bay: {
                exemplarCity: 'Thunder Bay',
              },
              Tijuana: {
                exemplarCity: 'Tijuana',
              },
              Toronto: {
                exemplarCity: 'Toronto',
              },
              Tortola: {
                exemplarCity: 'Tórtola',
              },
              Vancouver: {
                exemplarCity: 'Vancouver',
              },
              Whitehorse: {
                exemplarCity: 'Whitehorse',
              },
              Winnipeg: {
                exemplarCity: 'Winnipeg',
              },
              Yakutat: {
                exemplarCity: 'Yakutat',
              },
              Yellowknife: {
                exemplarCity: 'Yellowknife',
              },
            },
            Atlantic: {
              Azores: {
                exemplarCity: 'Azores',
              },
              Bermuda: {
                exemplarCity: 'Bermudas',
              },
              Canary: {
                exemplarCity: 'Canarias',
              },
              Cape_Verde: {
                exemplarCity: 'Cabo Verde',
              },
              Faeroe: {
                exemplarCity: 'Islas Feroe',
              },
              Madeira: {
                exemplarCity: 'Madeira',
              },
              Reykjavik: {
                exemplarCity: 'Reikiavik',
              },
              South_Georgia: {
                exemplarCity: 'Georgia del Sur',
              },
              St_Helena: {
                exemplarCity: 'Santa Elena',
              },
              Stanley: {
                exemplarCity: 'Stanley',
              },
            },
            Europe: {
              Amsterdam: {
                exemplarCity: 'Ámsterdam',
              },
              Andorra: {
                exemplarCity: 'Andorra',
              },
              Astrakhan: {
                exemplarCity: 'Astracán',
              },
              Athens: {
                exemplarCity: 'Atenas',
              },
              Belgrade: {
                exemplarCity: 'Belgrado',
              },
              Berlin: {
                exemplarCity: 'Berlín',
              },
              Bratislava: {
                exemplarCity: 'Bratislava',
              },
              Brussels: {
                exemplarCity: 'Bruselas',
              },
              Bucharest: {
                exemplarCity: 'Bucarest',
              },
              Budapest: {
                exemplarCity: 'Budapest',
              },
              Busingen: {
                exemplarCity: 'Busingen',
              },
              Chisinau: {
                exemplarCity: 'Chisináu',
              },
              Copenhagen: {
                exemplarCity: 'Copenhague',
              },
              Dublin: {
                long: {
                  daylight: 'hora de verano de Irlanda',
                },
                exemplarCity: 'Dublín',
              },
              Gibraltar: {
                exemplarCity: 'Gibraltar',
              },
              Guernsey: {
                exemplarCity: 'Guernsey',
              },
              Helsinki: {
                exemplarCity: 'Helsinki',
              },
              Isle_of_Man: {
                exemplarCity: 'Isla de Man',
              },
              Istanbul: {
                exemplarCity: 'Estambul',
              },
              Jersey: {
                exemplarCity: 'Jersey',
              },
              Kaliningrad: {
                exemplarCity: 'Kaliningrado',
              },
              Kiev: {
                exemplarCity: 'Kiev',
              },
              Kirov: {
                exemplarCity: 'Kírov',
              },
              Lisbon: {
                exemplarCity: 'Lisboa',
              },
              Ljubljana: {
                exemplarCity: 'Liubliana',
              },
              London: {
                long: {
                  daylight: 'hora de verano británica',
                },
                exemplarCity: 'Londres',
              },
              Luxembourg: {
                exemplarCity: 'Luxemburgo',
              },
              Madrid: {
                exemplarCity: 'Madrid',
              },
              Malta: {
                exemplarCity: 'Malta',
              },
              Mariehamn: {
                exemplarCity: 'Mariehamn',
              },
              Minsk: {
                exemplarCity: 'Minsk',
              },
              Monaco: {
                exemplarCity: 'Mónaco',
              },
              Moscow: {
                exemplarCity: 'Moscú',
              },
              Oslo: {
                exemplarCity: 'Oslo',
              },
              Paris: {
                exemplarCity: 'París',
              },
              Podgorica: {
                exemplarCity: 'Podgorica',
              },
              Prague: {
                exemplarCity: 'Praga',
              },
              Riga: {
                exemplarCity: 'Riga',
              },
              Rome: {
                exemplarCity: 'Roma',
              },
              Samara: {
                exemplarCity: 'Samara',
              },
              San_Marino: {
                exemplarCity: 'San Marino',
              },
              Sarajevo: {
                exemplarCity: 'Sarajevo',
              },
              Saratov: {
                exemplarCity: 'Sarátov',
              },
              Simferopol: {
                exemplarCity: 'Simferópol',
              },
              Skopje: {
                exemplarCity: 'Skopie',
              },
              Sofia: {
                exemplarCity: 'Sofía',
              },
              Stockholm: {
                exemplarCity: 'Estocolmo',
              },
              Tallinn: {
                exemplarCity: 'Tallin',
              },
              Tirane: {
                exemplarCity: 'Tirana',
              },
              Ulyanovsk: {
                exemplarCity: 'Uliánovsk',
              },
              Uzhgorod: {
                exemplarCity: 'Úzhgorod',
              },
              Vaduz: {
                exemplarCity: 'Vaduz',
              },
              Vatican: {
                exemplarCity: 'El Vaticano',
              },
              Vienna: {
                exemplarCity: 'Viena',
              },
              Vilnius: {
                exemplarCity: 'Vilna',
              },
              Volgograd: {
                exemplarCity: 'Volgogrado',
              },
              Warsaw: {
                exemplarCity: 'Varsovia',
              },
              Zagreb: {
                exemplarCity: 'Zagreb',
              },
              Zaporozhye: {
                exemplarCity: 'Zaporiyia',
              },
              Zurich: {
                exemplarCity: 'Zúrich',
              },
            },
            Africa: {
              Abidjan: {
                exemplarCity: 'Abiyán',
              },
              Accra: {
                exemplarCity: 'Acra',
              },
              Addis_Ababa: {
                exemplarCity: 'Addis Abeba',
              },
              Algiers: {
                exemplarCity: 'Argel',
              },
              Asmera: {
                exemplarCity: 'Asmara',
              },
              Bamako: {
                exemplarCity: 'Bamako',
              },
              Bangui: {
                exemplarCity: 'Bangui',
              },
              Banjul: {
                exemplarCity: 'Banjul',
              },
              Bissau: {
                exemplarCity: 'Bisáu',
              },
              Blantyre: {
                exemplarCity: 'Blantyre',
              },
              Brazzaville: {
                exemplarCity: 'Brazzaville',
              },
              Bujumbura: {
                exemplarCity: 'Bujumbura',
              },
              Cairo: {
                exemplarCity: 'El Cairo',
              },
              Casablanca: {
                exemplarCity: 'Casablanca',
              },
              Ceuta: {
                exemplarCity: 'Ceuta',
              },
              Conakry: {
                exemplarCity: 'Conakry',
              },
              Dakar: {
                exemplarCity: 'Dakar',
              },
              Dar_es_Salaam: {
                exemplarCity: 'Dar es Salaam',
              },
              Djibouti: {
                exemplarCity: 'Yibuti',
              },
              Douala: {
                exemplarCity: 'Duala',
              },
              El_Aaiun: {
                exemplarCity: 'El Aaiún',
              },
              Freetown: {
                exemplarCity: 'Freetown',
              },
              Gaborone: {
                exemplarCity: 'Gaborone',
              },
              Harare: {
                exemplarCity: 'Harare',
              },
              Johannesburg: {
                exemplarCity: 'Johannesburgo',
              },
              Juba: {
                exemplarCity: 'Juba',
              },
              Kampala: {
                exemplarCity: 'Kampala',
              },
              Khartoum: {
                exemplarCity: 'Jartún',
              },
              Kigali: {
                exemplarCity: 'Kigali',
              },
              Kinshasa: {
                exemplarCity: 'Kinshasa',
              },
              Lagos: {
                exemplarCity: 'Lagos',
              },
              Libreville: {
                exemplarCity: 'Libreville',
              },
              Lome: {
                exemplarCity: 'Lomé',
              },
              Luanda: {
                exemplarCity: 'Luanda',
              },
              Lubumbashi: {
                exemplarCity: 'Lubumbashi',
              },
              Lusaka: {
                exemplarCity: 'Lusaka',
              },
              Malabo: {
                exemplarCity: 'Malabo',
              },
              Maputo: {
                exemplarCity: 'Maputo',
              },
              Maseru: {
                exemplarCity: 'Maseru',
              },
              Mbabane: {
                exemplarCity: 'Mbabane',
              },
              Mogadishu: {
                exemplarCity: 'Mogadiscio',
              },
              Monrovia: {
                exemplarCity: 'Monrovia',
              },
              Nairobi: {
                exemplarCity: 'Nairobi',
              },
              Ndjamena: {
                exemplarCity: 'Yamena',
              },
              Niamey: {
                exemplarCity: 'Niamey',
              },
              Nouakchott: {
                exemplarCity: 'Nuakchot',
              },
              Ouagadougou: {
                exemplarCity: 'Uagadugú',
              },
              'Porto-Novo': {
                exemplarCity: 'Portonovo',
              },
              Sao_Tome: {
                exemplarCity: 'Santo Tomé',
              },
              Tripoli: {
                exemplarCity: 'Trípoli',
              },
              Tunis: {
                exemplarCity: 'Túnez',
              },
              Windhoek: {
                exemplarCity: 'Windhoek',
              },
            },
            Asia: {
              Aden: {
                exemplarCity: 'Adén',
              },
              Almaty: {
                exemplarCity: 'Almaty',
              },
              Amman: {
                exemplarCity: 'Ammán',
              },
              Anadyr: {
                exemplarCity: 'Anádyr',
              },
              Aqtau: {
                exemplarCity: 'Aktau',
              },
              Aqtobe: {
                exemplarCity: 'Aktobe',
              },
              Ashgabat: {
                exemplarCity: 'Asjabad',
              },
              Atyrau: {
                exemplarCity: 'Atyrau',
              },
              Baghdad: {
                exemplarCity: 'Bagdad',
              },
              Bahrain: {
                exemplarCity: 'Baréin',
              },
              Baku: {
                exemplarCity: 'Bakú',
              },
              Bangkok: {
                exemplarCity: 'Bangkok',
              },
              Barnaul: {
                exemplarCity: 'Barnaúl',
              },
              Beirut: {
                exemplarCity: 'Beirut',
              },
              Bishkek: {
                exemplarCity: 'Bishkek',
              },
              Brunei: {
                exemplarCity: 'Brunéi',
              },
              Calcutta: {
                exemplarCity: 'Calcuta',
              },
              Chita: {
                exemplarCity: 'Chitá',
              },
              Choibalsan: {
                exemplarCity: 'Choibalsan',
              },
              Colombo: {
                exemplarCity: 'Colombo',
              },
              Damascus: {
                exemplarCity: 'Damasco',
              },
              Dhaka: {
                exemplarCity: 'Daca',
              },
              Dili: {
                exemplarCity: 'Dili',
              },
              Dubai: {
                exemplarCity: 'Dubái',
              },
              Dushanbe: {
                exemplarCity: 'Dusambé',
              },
              Famagusta: {
                exemplarCity: 'Famagusta',
              },
              Gaza: {
                exemplarCity: 'Gaza',
              },
              Hebron: {
                exemplarCity: 'Hebrón',
              },
              Hong_Kong: {
                exemplarCity: 'Hong Kong',
              },
              Hovd: {
                exemplarCity: 'Hovd',
              },
              Irkutsk: {
                exemplarCity: 'Irkutsk',
              },
              Jakarta: {
                exemplarCity: 'Yakarta',
              },
              Jayapura: {
                exemplarCity: 'Jayapura',
              },
              Jerusalem: {
                exemplarCity: 'Jerusalén',
              },
              Kabul: {
                exemplarCity: 'Kabul',
              },
              Kamchatka: {
                exemplarCity: 'Kamchatka',
              },
              Karachi: {
                exemplarCity: 'Karachi',
              },
              Katmandu: {
                exemplarCity: 'Katmandú',
              },
              Khandyga: {
                exemplarCity: 'Khandyga',
              },
              Krasnoyarsk: {
                exemplarCity: 'Krasnoyarsk',
              },
              Kuala_Lumpur: {
                exemplarCity: 'Kuala Lumpur',
              },
              Kuching: {
                exemplarCity: 'Kuching',
              },
              Kuwait: {
                exemplarCity: 'Kuwait',
              },
              Macau: {
                exemplarCity: 'Macao',
              },
              Magadan: {
                exemplarCity: 'Magadán',
              },
              Makassar: {
                exemplarCity: 'Makasar',
              },
              Manila: {
                exemplarCity: 'Manila',
              },
              Muscat: {
                exemplarCity: 'Mascate',
              },
              Nicosia: {
                exemplarCity: 'Nicosia',
              },
              Novokuznetsk: {
                exemplarCity: 'Novokuznetsk',
              },
              Novosibirsk: {
                exemplarCity: 'Novosibirsk',
              },
              Omsk: {
                exemplarCity: 'Omsk',
              },
              Oral: {
                exemplarCity: 'Oral',
              },
              Phnom_Penh: {
                exemplarCity: 'Phnom Penh',
              },
              Pontianak: {
                exemplarCity: 'Pontianak',
              },
              Pyongyang: {
                exemplarCity: 'Pyongyang',
              },
              Qatar: {
                exemplarCity: 'Catar',
              },
              Qostanay: {
                exemplarCity: 'Kostanái',
              },
              Qyzylorda: {
                exemplarCity: 'Kyzylorda',
              },
              Rangoon: {
                exemplarCity: 'Yangón (Rangún)',
              },
              Riyadh: {
                exemplarCity: 'Riad',
              },
              Saigon: {
                exemplarCity: 'Ciudad Ho Chi Minh',
              },
              Sakhalin: {
                exemplarCity: 'Sajalín',
              },
              Samarkand: {
                exemplarCity: 'Samarcanda',
              },
              Seoul: {
                exemplarCity: 'Seúl',
              },
              Shanghai: {
                exemplarCity: 'Shanghái',
              },
              Singapore: {
                exemplarCity: 'Singapur',
              },
              Srednekolymsk: {
                exemplarCity: 'Srednekolimsk',
              },
              Taipei: {
                exemplarCity: 'Taipéi',
              },
              Tashkent: {
                exemplarCity: 'Taskent',
              },
              Tbilisi: {
                exemplarCity: 'Tiflis',
              },
              Tehran: {
                exemplarCity: 'Teherán',
              },
              Thimphu: {
                exemplarCity: 'Timbu',
              },
              Tokyo: {
                exemplarCity: 'Tokio',
              },
              Tomsk: {
                exemplarCity: 'Tomsk',
              },
              Ulaanbaatar: {
                exemplarCity: 'Ulán Bator',
              },
              Urumqi: {
                exemplarCity: 'Ürümqi',
              },
              'Ust-Nera': {
                exemplarCity: 'Ust-Nera',
              },
              Vientiane: {
                exemplarCity: 'Vientián',
              },
              Vladivostok: {
                exemplarCity: 'Vladivostok',
              },
              Yakutsk: {
                exemplarCity: 'Yakutsk',
              },
              Yekaterinburg: {
                exemplarCity: 'Ekaterimburgo',
              },
              Yerevan: {
                exemplarCity: 'Ereván',
              },
            },
            Indian: {
              Antananarivo: {
                exemplarCity: 'Antananarivo',
              },
              Chagos: {
                exemplarCity: 'Chagos',
              },
              Christmas: {
                exemplarCity: 'Navidad',
              },
              Cocos: {
                exemplarCity: 'Cocos',
              },
              Comoro: {
                exemplarCity: 'Comoras',
              },
              Kerguelen: {
                exemplarCity: 'Kerguelen',
              },
              Mahe: {
                exemplarCity: 'Mahé',
              },
              Maldives: {
                exemplarCity: 'Maldivas',
              },
              Mauritius: {
                exemplarCity: 'Mauricio',
              },
              Mayotte: {
                exemplarCity: 'Mayotte',
              },
              Reunion: {
                exemplarCity: 'Reunión',
              },
            },
            Australia: {
              Adelaide: {
                exemplarCity: 'Adelaida',
              },
              Brisbane: {
                exemplarCity: 'Brisbane',
              },
              Broken_Hill: {
                exemplarCity: 'Broken Hill',
              },
              Currie: {
                exemplarCity: 'Currie',
              },
              Darwin: {
                exemplarCity: 'Darwin',
              },
              Eucla: {
                exemplarCity: 'Eucla',
              },
              Hobart: {
                exemplarCity: 'Hobart',
              },
              Lindeman: {
                exemplarCity: 'Lindeman',
              },
              Lord_Howe: {
                exemplarCity: 'Lord Howe',
              },
              Melbourne: {
                exemplarCity: 'Melbourne',
              },
              Perth: {
                exemplarCity: 'Perth',
              },
              Sydney: {
                exemplarCity: 'Sídney',
              },
            },
            Pacific: {
              Apia: {
                exemplarCity: 'Apia',
              },
              Auckland: {
                exemplarCity: 'Auckland',
              },
              Bougainville: {
                exemplarCity: 'Bougainville',
              },
              Chatham: {
                exemplarCity: 'Chatham',
              },
              Easter: {
                exemplarCity: 'Isla de Pascua',
              },
              Efate: {
                exemplarCity: 'Efate',
              },
              Enderbury: {
                exemplarCity: 'Enderbury',
              },
              Fakaofo: {
                exemplarCity: 'Fakaofo',
              },
              Fiji: {
                exemplarCity: 'Fiyi',
              },
              Funafuti: {
                exemplarCity: 'Funafuti',
              },
              Galapagos: {
                exemplarCity: 'Galápagos',
              },
              Gambier: {
                exemplarCity: 'Gambier',
              },
              Guadalcanal: {
                exemplarCity: 'Guadalcanal',
              },
              Guam: {
                exemplarCity: 'Guam',
              },
              Honolulu: {
                exemplarCity: 'Honolulú',
              },
              Johnston: {
                exemplarCity: 'Johnston',
              },
              Kiritimati: {
                exemplarCity: 'Kiritimati',
              },
              Kosrae: {
                exemplarCity: 'Kosrae',
              },
              Kwajalein: {
                exemplarCity: 'Kwajalein',
              },
              Majuro: {
                exemplarCity: 'Majuro',
              },
              Marquesas: {
                exemplarCity: 'Marquesas',
              },
              Midway: {
                exemplarCity: 'Midway',
              },
              Nauru: {
                exemplarCity: 'Nauru',
              },
              Niue: {
                exemplarCity: 'Niue',
              },
              Norfolk: {
                exemplarCity: 'Norfolk',
              },
              Noumea: {
                exemplarCity: 'Numea',
              },
              Pago_Pago: {
                exemplarCity: 'Pago Pago',
              },
              Palau: {
                exemplarCity: 'Palaos',
              },
              Pitcairn: {
                exemplarCity: 'Pitcairn',
              },
              Ponape: {
                exemplarCity: 'Pohnpei',
              },
              Port_Moresby: {
                exemplarCity: 'Port Moresby',
              },
              Rarotonga: {
                exemplarCity: 'Rarotonga',
              },
              Saipan: {
                exemplarCity: 'Saipán',
              },
              Tahiti: {
                exemplarCity: 'Tahití',
              },
              Tarawa: {
                exemplarCity: 'Tarawa',
              },
              Tongatapu: {
                exemplarCity: 'Tongatapu',
              },
              Truk: {
                exemplarCity: 'Chuuk',
              },
              Wake: {
                exemplarCity: 'Wake',
              },
              Wallis: {
                exemplarCity: 'Wallis',
              },
            },
            Arctic: {
              Longyearbyen: {
                exemplarCity: 'Longyearbyen',
              },
            },
            Antarctica: {
              Casey: {
                exemplarCity: 'Casey',
              },
              Davis: {
                exemplarCity: 'Davis',
              },
              DumontDUrville: {
                exemplarCity: 'Dumont d’Urville',
              },
              Macquarie: {
                exemplarCity: 'Macquarie',
              },
              Mawson: {
                exemplarCity: 'Mawson',
              },
              McMurdo: {
                exemplarCity: 'McMurdo',
              },
              Palmer: {
                exemplarCity: 'Palmer',
              },
              Rothera: {
                exemplarCity: 'Rothera',
              },
              Syowa: {
                exemplarCity: 'Syowa',
              },
              Troll: {
                exemplarCity: 'Troll',
              },
              Vostok: {
                exemplarCity: 'Vostok',
              },
            },
            Etc: {
              UTC: {
                long: {
                  standard: 'tiempo universal coordinado',
                },
                short: {
                  standard: 'UTC',
                },
              },
              Unknown: {
                exemplarCity: 'ciudad desconocida',
              },
            },
          },
          metazone: {
            Acre: {
              long: {
                generic: 'Hora de Acre',
                standard: 'Hora estándar de Acre',
                daylight: 'Hora de verano de Acre',
              },
            },
            Afghanistan: {
              long: {
                standard: 'hora de Afganistán',
              },
            },
            Africa_Central: {
              long: {
                standard: 'hora de África central',
              },
            },
            Africa_Eastern: {
              long: {
                standard: 'hora de África oriental',
              },
            },
            Africa_Southern: {
              long: {
                standard: 'hora de Sudáfrica',
              },
            },
            Africa_Western: {
              long: {
                generic: 'hora de África occidental',
                standard: 'hora estándar de África occidental',
                daylight: 'hora de verano de África occidental',
              },
            },
            Alaska: {
              long: {
                generic: 'hora de Alaska',
                standard: 'hora estándar de Alaska',
                daylight: 'hora de verano de Alaska',
              },
            },
            Amazon: {
              long: {
                generic: 'hora del Amazonas',
                standard: 'hora estándar del Amazonas',
                daylight: 'hora de verano del Amazonas',
              },
            },
            America_Central: {
              long: {
                generic: 'hora central',
                standard: 'hora estándar central',
                daylight: 'hora de verano central',
              },
            },
            America_Eastern: {
              long: {
                generic: 'hora oriental',
                standard: 'hora estándar oriental',
                daylight: 'hora de verano oriental',
              },
            },
            America_Mountain: {
              long: {
                generic: 'hora de las Montañas Rocosas',
                standard: 'hora estándar de las Montañas Rocosas',
                daylight: 'hora de verano de las Montañas Rocosas',
              },
            },
            America_Pacific: {
              long: {
                generic: 'hora del Pacífico',
                standard: 'hora estándar del Pacífico',
                daylight: 'hora de verano del Pacífico',
              },
            },
            Anadyr: {
              long: {
                generic: 'hora de Anadyr',
                standard: 'hora estándar de Anadyr',
                daylight: 'hora de verano de Anadyr',
              },
            },
            Apia: {
              long: {
                generic: 'hora de Apia',
                standard: 'hora estándar de Apia',
                daylight: 'horario de verano de Apia',
              },
            },
            Aqtau: {
              long: {
                generic: 'Hora de Aktau',
                standard: 'Hora estándar de Aktau',
                daylight: 'Hora de verano de Aktau',
              },
            },
            Aqtobe: {
              long: {
                generic: 'Hora de Aktobe',
                standard: 'Hora estándar de Aktobe',
                daylight: 'Hora de verano de Aktobe',
              },
            },
            Arabian: {
              long: {
                generic: 'hora de Arabia',
                standard: 'hora estándar de Arabia',
                daylight: 'hora de verano de Arabia',
              },
            },
            Argentina: {
              long: {
                generic: 'hora de Argentina',
                standard: 'hora estándar de Argentina',
                daylight: 'hora de verano de Argentina',
              },
            },
            Argentina_Western: {
              long: {
                generic: 'hora de Argentina occidental',
                standard: 'hora estándar de Argentina occidental',
                daylight: 'hora de verano de Argentina occidental',
              },
            },
            Armenia: {
              long: {
                generic: 'hora de Armenia',
                standard: 'hora estándar de Armenia',
                daylight: 'hora de verano de Armenia',
              },
            },
            Atlantic: {
              long: {
                generic: 'hora del Atlántico',
                standard: 'hora estándar del Atlántico',
                daylight: 'hora de verano del Atlántico',
              },
            },
            Australia_Central: {
              long: {
                generic: 'hora de Australia central',
                standard: 'hora estándar de Australia central',
                daylight: 'hora de verano de Australia central',
              },
            },
            Australia_CentralWestern: {
              long: {
                generic: 'hora de Australia centroccidental',
                standard: 'hora estándar de Australia centroccidental',
                daylight: 'hora de verano de Australia centroccidental',
              },
            },
            Australia_Eastern: {
              long: {
                generic: 'hora de Australia oriental',
                standard: 'hora estándar de Australia oriental',
                daylight: 'hora de verano de Australia oriental',
              },
            },
            Australia_Western: {
              long: {
                generic: 'hora de Australia occidental',
                standard: 'hora estándar de Australia occidental',
                daylight: 'hora de verano de Australia occidental',
              },
            },
            Azerbaijan: {
              long: {
                generic: 'hora de Azerbaiyán',
                standard: 'hora estándar de Azerbaiyán',
                daylight: 'hora de verano de Azerbaiyán',
              },
            },
            Azores: {
              long: {
                generic: 'hora de las Azores',
                standard: 'hora estándar de las Azores',
                daylight: 'hora de verano de las Azores',
              },
            },
            Bangladesh: {
              long: {
                generic: 'hora de Bangladés',
                standard: 'hora estándar de Bangladés',
                daylight: 'hora de verano de Bangladés',
              },
            },
            Bhutan: {
              long: {
                standard: 'hora de Bután',
              },
            },
            Bolivia: {
              long: {
                standard: 'hora de Bolivia',
              },
            },
            Brasilia: {
              long: {
                generic: 'hora de Brasilia',
                standard: 'hora estándar de Brasilia',
                daylight: 'hora de verano de Brasilia',
              },
            },
            Brunei: {
              long: {
                standard: 'hora de Brunéi',
              },
            },
            Cape_Verde: {
              long: {
                generic: 'hora de Cabo Verde',
                standard: 'hora estándar de Cabo Verde',
                daylight: 'hora de verano de Cabo Verde',
              },
            },
            Chamorro: {
              long: {
                standard: 'hora estándar de Chamorro',
              },
            },
            Chatham: {
              long: {
                generic: 'hora de Chatham',
                standard: 'hora estándar de Chatham',
                daylight: 'hora de verano de Chatham',
              },
            },
            Chile: {
              long: {
                generic: 'hora de Chile',
                standard: 'hora estándar de Chile',
                daylight: 'hora de verano de Chile',
              },
            },
            China: {
              long: {
                generic: 'hora de China',
                standard: 'hora estándar de China',
                daylight: 'hora de verano de China',
              },
            },
            Choibalsan: {
              long: {
                generic: 'hora de Choibalsan',
                standard: 'hora estándar de Choibalsan',
                daylight: 'hora de verano de Choibalsan',
              },
            },
            Christmas: {
              long: {
                standard: 'hora de la Isla de Navidad',
              },
            },
            Cocos: {
              long: {
                standard: 'hora de las Islas Cocos',
              },
            },
            Colombia: {
              long: {
                generic: 'hora de Colombia',
                standard: 'hora estándar de Colombia',
                daylight: 'hora de verano de Colombia',
              },
            },
            Cook: {
              long: {
                generic: 'hora de las Islas Cook',
                standard: 'hora estándar de las Islas Cook',
                daylight: 'hora de verano media de las Islas Cook',
              },
            },
            Cuba: {
              long: {
                generic: 'hora de Cuba',
                standard: 'hora estándar de Cuba',
                daylight: 'hora de verano de Cuba',
              },
            },
            Davis: {
              long: {
                standard: 'hora de Davis',
              },
            },
            DumontDUrville: {
              long: {
                standard: 'hora de Dumont-d’Urville',
              },
            },
            East_Timor: {
              long: {
                standard: 'hora de Timor Oriental',
              },
            },
            Easter: {
              long: {
                generic: 'hora de la isla de Pascua',
                standard: 'hora estándar de la isla de Pascua',
                daylight: 'hora de verano de la isla de Pascua',
              },
            },
            Ecuador: {
              long: {
                standard: 'hora de Ecuador',
              },
            },
            Europe_Central: {
              long: {
                generic: 'hora de Europa central',
                standard: 'hora estándar de Europa central',
                daylight: 'hora de verano de Europa central',
              },
              short: {
                generic: 'CET',
                standard: 'CET',
                daylight: 'CEST',
              },
            },
            Europe_Eastern: {
              long: {
                generic: 'hora de Europa oriental',
                standard: 'hora estándar de Europa oriental',
                daylight: 'hora de verano de Europa oriental',
              },
              short: {
                generic: 'EET',
                standard: 'EET',
                daylight: 'EEST',
              },
            },
            Europe_Further_Eastern: {
              long: {
                standard: 'hora del extremo oriental de Europa',
              },
            },
            Europe_Western: {
              long: {
                generic: 'hora de Europa occidental',
                standard: 'hora estándar de Europa occidental',
                daylight: 'hora de verano de Europa occidental',
              },
              short: {
                generic: 'WET',
                standard: 'WET',
                daylight: 'WEST',
              },
            },
            Falkland: {
              long: {
                generic: 'hora de las islas Malvinas',
                standard: 'hora estándar de las islas Malvinas',
                daylight: 'hora de verano de las islas Malvinas',
              },
            },
            Fiji: {
              long: {
                generic: 'hora de Fiyi',
                standard: 'hora estándar de Fiyi',
                daylight: 'hora de verano de Fiyi',
              },
            },
            French_Guiana: {
              long: {
                standard: 'hora de la Guayana Francesa',
              },
            },
            French_Southern: {
              long: {
                standard: 'hora de Antártida y Territorios Australes Franceses',
              },
            },
            Galapagos: {
              long: {
                standard: 'hora de Galápagos',
              },
            },
            Gambier: {
              long: {
                standard: 'hora de Gambier',
              },
            },
            Georgia: {
              long: {
                generic: 'hora de Georgia',
                standard: 'hora estándar de Georgia',
                daylight: 'hora de verano de Georgia',
              },
            },
            Gilbert_Islands: {
              long: {
                standard: 'hora de las islas Gilbert',
              },
            },
            GMT: {
              long: {
                standard: 'hora del meridiano de Greenwich',
              },
              short: {
                standard: 'GMT',
              },
            },
            Greenland_Eastern: {
              long: {
                generic: 'hora de Groenlandia oriental',
                standard: 'hora estándar de Groenlandia oriental',
                daylight: 'hora de verano de Groenlandia oriental',
              },
            },
            Greenland_Western: {
              long: {
                generic: 'hora de Groenlandia occidental',
                standard: 'hora estándar de Groenlandia occidental',
                daylight: 'hora de verano de Groenlandia occidental',
              },
            },
            Guam: {
              long: {
                standard: 'Hora estándar de Guam',
              },
            },
            Gulf: {
              long: {
                standard: 'hora estándar del Golfo',
              },
            },
            Guyana: {
              long: {
                standard: 'hora de Guyana',
              },
            },
            Hawaii_Aleutian: {
              long: {
                generic: 'hora de Hawái-Aleutianas',
                standard: 'hora estándar de Hawái-Aleutianas',
                daylight: 'hora de verano de Hawái-Aleutianas',
              },
            },
            Hong_Kong: {
              long: {
                generic: 'hora de Hong Kong',
                standard: 'hora estándar de Hong Kong',
                daylight: 'hora de verano de Hong Kong',
              },
            },
            Hovd: {
              long: {
                generic: 'hora de Hovd',
                standard: 'hora estándar de Hovd',
                daylight: 'hora de verano de Hovd',
              },
            },
            India: {
              long: {
                standard: 'hora estándar de la India',
              },
            },
            Indian_Ocean: {
              long: {
                standard: 'hora del océano Índico',
              },
            },
            Indochina: {
              long: {
                standard: 'hora de Indochina',
              },
            },
            Indonesia_Central: {
              long: {
                standard: 'hora de Indonesia central',
              },
            },
            Indonesia_Eastern: {
              long: {
                standard: 'hora de Indonesia oriental',
              },
            },
            Indonesia_Western: {
              long: {
                standard: 'hora de Indonesia occidental',
              },
            },
            Iran: {
              long: {
                generic: 'hora de Irán',
                standard: 'hora estándar de Irán',
                daylight: 'hora de verano de Irán',
              },
            },
            Irkutsk: {
              long: {
                generic: 'hora de Irkutsk',
                standard: 'hora estándar de Irkutsk',
                daylight: 'hora de verano de Irkutsk',
              },
            },
            Israel: {
              long: {
                generic: 'hora de Israel',
                standard: 'hora estándar de Israel',
                daylight: 'hora de verano de Israel',
              },
            },
            Japan: {
              long: {
                generic: 'hora de Japón',
                standard: 'hora estándar de Japón',
                daylight: 'hora de verano de Japón',
              },
            },
            Kamchatka: {
              long: {
                generic: 'hora de Kamchatka',
                standard: 'hora estándar de Kamchatka',
                daylight: 'hora de verano de Kamchatka',
              },
            },
            Kazakhstan_Eastern: {
              long: {
                standard: 'hora de Kazajistán oriental',
              },
            },
            Kazakhstan_Western: {
              long: {
                standard: 'hora de Kazajistán occidental',
              },
            },
            Korea: {
              long: {
                generic: 'hora de Corea',
                standard: 'hora estándar de Corea',
                daylight: 'hora de verano de Corea',
              },
            },
            Kosrae: {
              long: {
                standard: 'hora de Kosrae',
              },
            },
            Krasnoyarsk: {
              long: {
                generic: 'hora de Krasnoyarsk',
                standard: 'hora estándar de Krasnoyarsk',
                daylight: 'hora de verano de Krasnoyarsk',
              },
            },
            Kyrgystan: {
              long: {
                standard: 'hora de Kirguistán',
              },
            },
            Lanka: {
              long: {
                standard: 'Hora de Sri Lanka',
              },
            },
            Line_Islands: {
              long: {
                standard: 'hora de las Espóradas Ecuatoriales',
              },
            },
            Lord_Howe: {
              long: {
                generic: 'hora de Lord Howe',
                standard: 'hora estándar de Lord Howe',
                daylight: 'hora de verano de Lord Howe',
              },
            },
            Macau: {
              long: {
                generic: 'Hora de Macao',
                standard: 'Hora estándar de Macao',
                daylight: 'Hora de verano de Macao',
              },
            },
            Macquarie: {
              long: {
                standard: 'hora de la isla Macquarie',
              },
            },
            Magadan: {
              long: {
                generic: 'hora de Magadán',
                standard: 'hora estándar de Magadán',
                daylight: 'hora de verano de Magadán',
              },
            },
            Malaysia: {
              long: {
                standard: 'hora de Malasia',
              },
            },
            Maldives: {
              long: {
                standard: 'hora de Maldivas',
              },
            },
            Marquesas: {
              long: {
                standard: 'hora de Marquesas',
              },
            },
            Marshall_Islands: {
              long: {
                standard: 'hora de las Islas Marshall',
              },
            },
            Mauritius: {
              long: {
                generic: 'hora de Mauricio',
                standard: 'hora estándar de Mauricio',
                daylight: 'hora de verano de Mauricio',
              },
            },
            Mawson: {
              long: {
                standard: 'hora de Mawson',
              },
            },
            Mexico_Northwest: {
              long: {
                generic: 'hora del noroeste de México',
                standard: 'hora estándar del noroeste de México',
                daylight: 'hora de verano del noroeste de México',
              },
            },
            Mexico_Pacific: {
              long: {
                generic: 'hora del Pacífico de México',
                standard: 'hora estándar del Pacífico de México',
                daylight: 'hora de verano del Pacífico de México',
              },
            },
            Mongolia: {
              long: {
                generic: 'hora de Ulán Bator',
                standard: 'hora estándar de Ulán Bator',
                daylight: 'hora de verano de Ulán Bator',
              },
            },
            Moscow: {
              long: {
                generic: 'hora de Moscú',
                standard: 'hora estándar de Moscú',
                daylight: 'hora de verano de Moscú',
              },
            },
            Myanmar: {
              long: {
                standard: 'hora de Myanmar',
              },
            },
            Nauru: {
              long: {
                standard: 'hora de Nauru',
              },
            },
            Nepal: {
              long: {
                standard: 'hora de Nepal',
              },
            },
            New_Caledonia: {
              long: {
                generic: 'hora de Nueva Caledonia',
                standard: 'hora estándar de Nueva Caledonia',
                daylight: 'hora de verano de Nueva Caledonia',
              },
            },
            New_Zealand: {
              long: {
                generic: 'hora de Nueva Zelanda',
                standard: 'hora estándar de Nueva Zelanda',
                daylight: 'hora de verano de Nueva Zelanda',
              },
            },
            Newfoundland: {
              long: {
                generic: 'hora de Terranova',
                standard: 'hora estándar de Terranova',
                daylight: 'hora de verano de Terranova',
              },
            },
            Niue: {
              long: {
                standard: 'hora de Niue',
              },
            },
            Norfolk: {
              long: {
                standard: 'hora de la isla Norfolk',
              },
            },
            Noronha: {
              long: {
                generic: 'hora de Fernando de Noronha',
                standard: 'hora estándar de Fernando de Noronha',
                daylight: 'hora de verano de Fernando de Noronha',
              },
            },
            North_Mariana: {
              long: {
                standard: 'Hora de las Islas Marianas del Norte',
              },
            },
            Novosibirsk: {
              long: {
                generic: 'hora de Novosibirsk',
                standard: 'hora estándar de Novosibirsk',
                daylight: 'hora de verano de Novosibirsk',
              },
            },
            Omsk: {
              long: {
                generic: 'hora de Omsk',
                standard: 'hora estándar de Omsk',
                daylight: 'hora de verano de Omsk',
              },
            },
            Pakistan: {
              long: {
                generic: 'hora de Pakistán',
                standard: 'hora estándar de Pakistán',
                daylight: 'hora de verano de Pakistán',
              },
            },
            Palau: {
              long: {
                standard: 'hora de Palaos',
              },
            },
            Papua_New_Guinea: {
              long: {
                standard: 'hora de Papúa Nueva Guinea',
              },
            },
            Paraguay: {
              long: {
                generic: 'hora de Paraguay',
                standard: 'hora estándar de Paraguay',
                daylight: 'hora de verano de Paraguay',
              },
            },
            Peru: {
              long: {
                generic: 'hora de Perú',
                standard: 'hora estándar de Perú',
                daylight: 'hora de verano de Perú',
              },
            },
            Philippines: {
              long: {
                generic: 'hora de Filipinas',
                standard: 'hora estándar de Filipinas',
                daylight: 'hora de verano de Filipinas',
              },
            },
            Phoenix_Islands: {
              long: {
                standard: 'hora de las Islas Fénix',
              },
            },
            Pierre_Miquelon: {
              long: {
                generic: 'hora de San Pedro y Miquelón',
                standard: 'hora estándar de San Pedro y Miquelón',
                daylight: 'hora de verano de San Pedro y Miquelón',
              },
            },
            Pitcairn: {
              long: {
                standard: 'hora de Pitcairn',
              },
            },
            Ponape: {
              long: {
                standard: 'hora de Pohnpei',
              },
            },
            Pyongyang: {
              long: {
                standard: 'hora de Pyongyang',
              },
            },
            Qyzylorda: {
              long: {
                generic: 'Hora de Qyzylorda',
                standard: 'Hora estándar de Qyzylorda',
                daylight: 'Hora de verano de Qyzylorda',
              },
            },
            Reunion: {
              long: {
                standard: 'hora de Reunión',
              },
            },
            Rothera: {
              long: {
                standard: 'hora de Rothera',
              },
            },
            Sakhalin: {
              long: {
                generic: 'hora de Sajalín',
                standard: 'hora estándar de Sajalín',
                daylight: 'hora de verano de Sajalín',
              },
            },
            Samara: {
              long: {
                generic: 'hora de Samara',
                standard: 'hora estándar de Samara',
                daylight: 'hora de verano de Samara',
              },
            },
            Samoa: {
              long: {
                generic: 'hora de Samoa',
                standard: 'hora estándar de Samoa',
                daylight: 'hora de verano de Samoa',
              },
            },
            Seychelles: {
              long: {
                standard: 'hora de Seychelles',
              },
            },
            Singapore: {
              long: {
                standard: 'hora de Singapur',
              },
            },
            Solomon: {
              long: {
                standard: 'hora de las Islas Salomón',
              },
            },
            South_Georgia: {
              long: {
                standard: 'hora de Georgia del Sur',
              },
            },
            Suriname: {
              long: {
                standard: 'hora de Surinam',
              },
            },
            Syowa: {
              long: {
                standard: 'hora de Syowa',
              },
            },
            Tahiti: {
              long: {
                standard: 'hora de Tahití',
              },
            },
            Taipei: {
              long: {
                generic: 'hora de Taipéi',
                standard: 'hora estándar de Taipéi',
                daylight: 'hora de verano de Taipéi',
              },
            },
            Tajikistan: {
              long: {
                standard: 'hora de Tayikistán',
              },
            },
            Tokelau: {
              long: {
                standard: 'hora de Tokelau',
              },
            },
            Tonga: {
              long: {
                generic: 'hora de Tonga',
                standard: 'hora estándar de Tonga',
                daylight: 'hora de verano de Tonga',
              },
            },
            Truk: {
              long: {
                standard: 'hora de Chuuk',
              },
            },
            Turkmenistan: {
              long: {
                generic: 'hora de Turkmenistán',
                standard: 'hora estándar de Turkmenistán',
                daylight: 'hora de verano de Turkmenistán',
              },
            },
            Tuvalu: {
              long: {
                standard: 'hora de Tuvalu',
              },
            },
            Uruguay: {
              long: {
                generic: 'hora de Uruguay',
                standard: 'hora estándar de Uruguay',
                daylight: 'hora de verano de Uruguay',
              },
            },
            Uzbekistan: {
              long: {
                generic: 'hora de Uzbekistán',
                standard: 'hora estándar de Uzbekistán',
                daylight: 'hora de verano de Uzbekistán',
              },
            },
            Vanuatu: {
              long: {
                generic: 'hora de Vanuatu',
                standard: 'hora estándar de Vanuatu',
                daylight: 'hora de verano de Vanuatu',
              },
            },
            Venezuela: {
              long: {
                standard: 'hora de Venezuela',
              },
            },
            Vladivostok: {
              long: {
                generic: 'hora de Vladivostok',
                standard: 'hora estándar de Vladivostok',
                daylight: 'hora de verano de Vladivostok',
              },
            },
            Volgograd: {
              long: {
                generic: 'hora de Volgogrado',
                standard: 'hora estándar de Volgogrado',
                daylight: 'hora de verano de Volgogrado',
              },
            },
            Vostok: {
              long: {
                standard: 'hora de Vostok',
              },
            },
            Wake: {
              long: {
                standard: 'hora de la isla Wake',
              },
            },
            Wallis: {
              long: {
                standard: 'hora de Wallis y Futuna',
              },
            },
            Yakutsk: {
              long: {
                generic: 'hora de Yakutsk',
                standard: 'hora estándar de Yakutsk',
                daylight: 'hora de verano de Yakutsk',
              },
            },
            Yekaterinburg: {
              long: {
                generic: 'hora de Ekaterimburgo',
                standard: 'hora estándar de Ekaterimburgo',
                daylight: 'hora de verano de Ekaterimburgo',
              },
            },
          },
        },
      },
    },
  },
};
