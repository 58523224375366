import { Injectable } from '@angular/core';
import {
  HeaderSettings,
  HeaderToolbarOption,
  UserMenu,
} from '@horizon/core-layouts';
import { globalSettings } from 'src/global-settings';

@Injectable({
  providedIn: 'root',
})
export class HeaderConfigService {
  public headerSettings: HeaderSettings;
  public toolbarOptions: HeaderToolbarOption[] = [];
  public customMenuOptions: UserMenu;

  constructor() {
    this.headerSettings = {
      showLogoImg: true,
      logoImg: globalSettings.logo,
      showSearch: true,
      showBreadcrumbs: false,
      showToolbar: false,
      showUserAvatar: true,
      showUserOptionsMenu: true,
    };

    this.toolbarOptions = [
      {
        url: '',
        iconImg: 'agenda',
      },
      {
        url: '',
        iconImg: 'questions',
      },
      {
        url: '',
        iconImg: 'notifications',
      },
    ];

    this.customMenuOptions = {
      showCustomMenu: true,
      showLanguage: false,
      showLogout: true,
      showSettings: false,
      showProfile: false,
      language: {
        en: {
          text: 'language.english',
        },
        es: {
          text: 'language.spanish',
        },
        actions: ['changeLanguage'],
      },
      logout: {
        icon: 'logout',
        text: 'actions.log_out',
        actions: ['logout'],
      },
    };
  }

  public configHeaderSettings(headerSettings: HeaderSettings): void {
    this.headerSettings = headerSettings;
  }

  public configToolbarOptions(toolbarOptions: HeaderToolbarOption[]): void {
    this.toolbarOptions = toolbarOptions.map((opt: any) => {
      if (opt.iconImg) {
        return opt;
      } else {
        return {
          ...opt,
          iconImg: opt.icon,
        };
      }
    });
  }
}
