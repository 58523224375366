export const gregorianEN = {
  main: {
    'en-GB': {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'en',
        territory: 'GB',
      },
      dates: {
        calendars: {
          gregorian: {
            months: {
              format: {
                abbreviated: {
                  '1': 'Jan',
                  '2': 'Feb',
                  '3': 'Mar',
                  '4': 'Apr',
                  '5': 'May',
                  '6': 'Jun',
                  '7': 'Jul',
                  '8': 'Aug',
                  '9': 'Sep',
                  '10': 'Oct',
                  '11': 'Nov',
                  '12': 'Dec',
                },
                narrow: {
                  '1': 'J',
                  '2': 'F',
                  '3': 'M',
                  '4': 'A',
                  '5': 'M',
                  '6': 'J',
                  '7': 'J',
                  '8': 'A',
                  '9': 'S',
                  '10': 'O',
                  '11': 'N',
                  '12': 'D',
                },
                wide: {
                  '1': 'January',
                  '2': 'February',
                  '3': 'March',
                  '4': 'April',
                  '5': 'May',
                  '6': 'June',
                  '7': 'July',
                  '8': 'August',
                  '9': 'September',
                  '10': 'October',
                  '11': 'November',
                  '12': 'December',
                },
              },
              'stand-alone': {
                abbreviated: {
                  '1': 'Jan',
                  '2': 'Feb',
                  '3': 'Mar',
                  '4': 'Apr',
                  '5': 'May',
                  '6': 'Jun',
                  '7': 'Jul',
                  '8': 'Aug',
                  '9': 'Sep',
                  '10': 'Oct',
                  '11': 'Nov',
                  '12': 'Dec',
                },
                narrow: {
                  '1': 'J',
                  '2': 'F',
                  '3': 'M',
                  '4': 'A',
                  '5': 'M',
                  '6': 'J',
                  '7': 'J',
                  '8': 'A',
                  '9': 'S',
                  '10': 'O',
                  '11': 'N',
                  '12': 'D',
                },
                wide: {
                  '1': 'January',
                  '2': 'February',
                  '3': 'March',
                  '4': 'April',
                  '5': 'May',
                  '6': 'June',
                  '7': 'July',
                  '8': 'August',
                  '9': 'September',
                  '10': 'October',
                  '11': 'November',
                  '12': 'December',
                },
              },
            },
            days: {
              format: {
                abbreviated: {
                  sun: 'Sun',
                  mon: 'Mon',
                  tue: 'Tue',
                  wed: 'Wed',
                  thu: 'Thu',
                  fri: 'Fri',
                  sat: 'Sat',
                },
                narrow: {
                  sun: 'S',
                  mon: 'M',
                  tue: 'T',
                  wed: 'W',
                  thu: 'T',
                  fri: 'F',
                  sat: 'S',
                },
                short: {
                  sun: 'Su',
                  mon: 'Mo',
                  tue: 'Tu',
                  wed: 'We',
                  thu: 'Th',
                  fri: 'Fr',
                  sat: 'Sa',
                },
                wide: {
                  sun: 'Sunday',
                  mon: 'Monday',
                  tue: 'Tuesday',
                  wed: 'Wednesday',
                  thu: 'Thursday',
                  fri: 'Friday',
                  sat: 'Saturday',
                },
              },
              'stand-alone': {
                abbreviated: {
                  sun: 'Sun',
                  mon: 'Mon',
                  tue: 'Tue',
                  wed: 'Wed',
                  thu: 'Thu',
                  fri: 'Fri',
                  sat: 'Sat',
                },
                narrow: {
                  sun: 'S',
                  mon: 'M',
                  tue: 'T',
                  wed: 'W',
                  thu: 'T',
                  fri: 'F',
                  sat: 'S',
                },
                short: {
                  sun: 'Su',
                  mon: 'Mo',
                  tue: 'Tu',
                  wed: 'We',
                  thu: 'Th',
                  fri: 'Fr',
                  sat: 'Sa',
                },
                wide: {
                  sun: 'Sunday',
                  mon: 'Monday',
                  tue: 'Tuesday',
                  wed: 'Wednesday',
                  thu: 'Thursday',
                  fri: 'Friday',
                  sat: 'Saturday',
                },
              },
            },
            quarters: {
              format: {
                abbreviated: {
                  '1': 'Q1',
                  '2': 'Q2',
                  '3': 'Q3',
                  '4': 'Q4',
                },
                narrow: {
                  '1': '1',
                  '2': '2',
                  '3': '3',
                  '4': '4',
                },
                wide: {
                  '1': '1st quarter',
                  '2': '2nd quarter',
                  '3': '3rd quarter',
                  '4': '4th quarter',
                },
              },
              'stand-alone': {
                abbreviated: {
                  '1': 'Q1',
                  '2': 'Q2',
                  '3': 'Q3',
                  '4': 'Q4',
                },
                narrow: {
                  '1': '1',
                  '2': '2',
                  '3': '3',
                  '4': '4',
                },
                wide: {
                  '1': '1st quarter',
                  '2': '2nd quarter',
                  '3': '3rd quarter',
                  '4': '4th quarter',
                },
              },
            },
            dayPeriods: {
              format: {
                abbreviated: {
                  midnight: 'midnight',
                  am: 'am',
                  'am-alt-variant': 'am',
                  noon: 'noon',
                  pm: 'pm',
                  'pm-alt-variant': 'pm',
                  morning1: 'in the morning',
                  afternoon1: 'in the afternoon',
                  evening1: 'in the evening',
                  night1: 'at night',
                },
                narrow: {
                  midnight: 'mi',
                  am: 'a',
                  'am-alt-variant': 'am',
                  noon: 'n',
                  pm: 'p',
                  'pm-alt-variant': 'pm',
                  morning1: 'in the morning',
                  afternoon1: 'in the afternoon',
                  evening1: 'in the evening',
                  night1: 'at night',
                },
                wide: {
                  midnight: 'midnight',
                  am: 'am',
                  'am-alt-variant': 'am',
                  noon: 'noon',
                  pm: 'pm',
                  'pm-alt-variant': 'pm',
                  morning1: 'in the morning',
                  afternoon1: 'in the afternoon',
                  evening1: 'in the evening',
                  night1: 'at night',
                },
              },
              'stand-alone': {
                abbreviated: {
                  midnight: 'midnight',
                  am: 'am',
                  'am-alt-variant': 'am',
                  noon: 'noon',
                  pm: 'pm',
                  'pm-alt-variant': 'pm',
                  morning1: 'morning',
                  afternoon1: 'afternoon',
                  evening1: 'evening',
                  night1: 'night',
                },
                narrow: {
                  midnight: 'midnight',
                  am: 'am',
                  'am-alt-variant': 'am',
                  noon: 'noon',
                  pm: 'pm',
                  'pm-alt-variant': 'pm',
                  morning1: 'morning',
                  afternoon1: 'afternoon',
                  evening1: 'evening',
                  night1: 'night',
                },
                wide: {
                  midnight: 'midnight',
                  am: 'am',
                  'am-alt-variant': 'am',
                  noon: 'noon',
                  pm: 'pm',
                  'pm-alt-variant': 'pm',
                  morning1: 'morning',
                  afternoon1: 'afternoon',
                  evening1: 'evening',
                  night1: 'night',
                },
              },
            },
            eras: {
              eraNames: {
                '0': 'Before Christ',
                '0-alt-variant': 'Before Common Era',
                '1': 'Anno Domini',
                '1-alt-variant': 'Common Era',
              },
              eraAbbr: {
                '0': 'BC',
                '0-alt-variant': 'BCE',
                '1': 'AD',
                '1-alt-variant': 'CE',
              },
              eraNarrow: {
                '0': 'B',
                '0-alt-variant': 'BCE',
                '1': 'A',
                '1-alt-variant': 'CE',
              },
            },
            dateFormats: {
              full: 'EEEE, d MMMM y',
              long: 'd MMMM y',
              medium: 'd MMM y',
              short: 'dd/MM/y',
            },
            timeFormats: {
              full: 'HH:mm:ss zzzz',
              long: 'HH:mm:ss z',
              medium: 'HH:mm:ss',
              short: 'HH:mm',
            },
            dateTimeFormats: {
              full: "{1} 'at' {0}",
              long: "{1} 'at' {0}",
              medium: '{1}, {0}',
              short: '{1}, {0}',
              availableFormats: {
                Bh: 'h B',
                Bhm: 'h:mm B',
                Bhms: 'h:mm:ss B',
                d: 'd',
                E: 'ccc',
                EBhm: 'E h:mm B',
                EBhms: 'E h:mm:ss B',
                Ed: 'E d',
                Ehm: 'E h:mm a',
                EHm: 'E HH:mm',
                Ehms: 'E h:mm:ss a',
                EHms: 'E HH:mm:ss',
                Gy: 'y G',
                GyMMM: 'MMM y G',
                GyMMMd: 'd MMM y G',
                GyMMMEd: 'E, d MMM y G',
                h: 'h a',
                H: 'HH',
                hm: 'h:mm a',
                Hm: 'HH:mm',
                hms: 'h:mm:ss a',
                Hms: 'HH:mm:ss',
                hmsv: 'h:mm:ss a v',
                Hmsv: 'HH:mm:ss v',
                hmv: 'h:mm a v',
                Hmv: 'HH:mm v',
                M: 'L',
                Md: 'dd/MM',
                MEd: 'E, dd/MM',
                MMdd: 'dd/MM',
                MMM: 'LLL',
                MMMd: 'd MMM',
                MMMEd: 'E, d MMM',
                MMMMd: 'd MMMM',
                'MMMMW-count-one': "'week' W 'of' MMMM",
                'MMMMW-count-other': "'week' W 'of' MMMM",
                ms: 'mm:ss',
                y: 'y',
                yM: 'MM/y',
                yMd: 'dd/MM/y',
                yMEd: 'E, dd/MM/y',
                yMMM: 'MMM y',
                yMMMd: 'd MMM y',
                yMMMEd: 'E, d MMM y',
                yMMMM: 'MMMM y',
                yQQQ: 'QQQ y',
                yQQQQ: 'QQQQ y',
                'yw-count-one': "'week' w 'of' Y",
                'yw-count-other': "'week' w 'of' Y",
              },
              appendItems: {
                Day: '{0} ({2}: {1})',
                'Day-Of-Week': '{0} {1}',
                Era: '{0} {1}',
                Hour: '{0} ({2}: {1})',
                Minute: '{0} ({2}: {1})',
                Month: '{0} ({2}: {1})',
                Quarter: '{0} ({2}: {1})',
                Second: '{0} ({2}: {1})',
                Timezone: '{0} {1}',
                Week: '{0} ({2}: {1})',
                Year: '{0} {1}',
              },
              intervalFormats: {
                intervalFormatFallback: '{0} – {1}',
                Bh: {
                  B: 'h B – h B',
                  h: 'h – h B',
                },
                Bhm: {
                  B: 'h:mm B – h:mm B',
                  h: 'h:mm – h:mm B',
                  m: 'h:mm – h:mm B',
                },
                d: {
                  d: 'd–d',
                },
                Gy: {
                  G: 'y G – y G',
                  y: 'y – y G',
                },
                GyM: {
                  G: 'M/y GGGGG – M/y GGGGG',
                  M: 'M/y – M/y GGGGG',
                  y: 'M/y – M/y GGGGG',
                },
                GyMd: {
                  d: 'dd/MM/y – dd/MM/y GGGGG',
                  G: 'dd/MM/y GGGGG – dd/MM/y GGGGG',
                  M: 'dd/MM/y – dd/MM/y GGGGG',
                  y: 'dd/MM/y – dd/MM/y GGGGG',
                },
                GyMEd: {
                  d: 'E, dd/MM/y – E, dd/MM/y GGGGG',
                  G: 'E, dd/MM/y GGGGG – E, dd/MM/y GGGGG',
                  M: 'E, dd/MM/y – E, dd/MM/y GGGGG',
                  y: 'E, dd/MM/y – E, dd/MM/y GGGGG',
                },
                GyMMM: {
                  G: 'MMM y G – MMM y G',
                  M: 'MMM – MMM y G',
                  y: 'MMM y – MMM y G',
                },
                GyMMMd: {
                  d: 'd – d MMM y G',
                  G: 'd MMM y G – d MMM y G',
                  M: 'd MMM – d MMM y G',
                  y: 'd MMM y – d MMM y G',
                },
                GyMMMEd: {
                  d: 'E, d MMM – E, d MMM y G',
                  G: 'E, d MMM y G – E, d MMM y G',
                  M: 'E, d MMM – E, d MMM y G',
                  y: 'E, d MMM y – E, d MMM y G',
                },
                h: {
                  a: 'h a – h a',
                  h: 'h–h a',
                },
                H: {
                  H: 'HH–HH',
                },
                hm: {
                  a: 'h:mm a – h:mm a',
                  h: 'h:mm – h:mm a',
                  m: 'h:mm – h:mm a',
                },
                Hm: {
                  H: 'HH:mm–HH:mm',
                  m: 'HH:mm–HH:mm',
                },
                hmv: {
                  a: 'h:mm a – h:mm a v',
                  h: 'h:mm – h:mm a v',
                  m: 'h:mm – h:mm a v',
                },
                Hmv: {
                  H: 'HH:mm–HH:mm v',
                  m: 'HH:mm–HH:mm v',
                },
                hv: {
                  a: 'h a – h a v',
                  h: 'h–h a v',
                },
                Hv: {
                  H: 'HH–HH v',
                },
                M: {
                  M: 'M–M',
                },
                Md: {
                  d: 'dd/MM – dd/MM',
                  M: 'dd/MM – dd/MM',
                },
                MEd: {
                  d: 'E dd/MM – E dd/MM',
                  M: 'E dd/MM – E dd/MM',
                },
                MMM: {
                  M: 'MMM – MMM',
                },
                MMMd: {
                  d: 'd–d MMM',
                  M: 'd MMM – d MMM',
                },
                MMMEd: {
                  d: 'E d MMM – E d MMM',
                  M: 'E d MMM – E d MMM',
                },
                y: {
                  y: 'y–y',
                },
                yM: {
                  M: 'MM/y – MM/y',
                  y: 'MM/y – MM/y',
                },
                yMd: {
                  d: 'dd/MM/y – dd/MM/y',
                  M: 'dd/MM/y – dd/MM/y',
                  y: 'dd/MM/y – dd/MM/y',
                },
                yMEd: {
                  d: 'E, dd/MM/y – E, dd/MM/y',
                  M: 'E, dd/MM/y – E, dd/MM/y',
                  y: 'E, dd/MM/y – E, dd/MM/y',
                },
                yMMM: {
                  M: 'MMM – MMM y',
                  y: 'MMM y – MMM y',
                },
                yMMMd: {
                  d: 'd–d MMM y',
                  M: 'd MMM – d MMM y',
                  y: 'd MMM y – d MMM y',
                },
                yMMMEd: {
                  d: 'E, d MMM – E, d MMM y',
                  M: 'E, d MMM – E, d MMM y',
                  y: 'E, d MMM y – E, d MMM y',
                },
                yMMMM: {
                  M: 'MMMM – MMMM y',
                  y: 'MMMM y – MMMM y',
                },
              },
            },
          },
        },
      },
    },
  },
};
