import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventFireService {
  menuIsExpanded$ = new Subject<boolean>();
  currentLanguage$ = new Subject<string>();
  currentLanguage: string;

  constructor() {}

  public setMenuIsExpanded(value: boolean) {
    this.menuIsExpanded$.next(value);
  }

  public currentLanguageChanged(language: string) {
    this.currentLanguage = language;
    this.currentLanguage$.next(language);
  }

  public getCurrentLanguage(): Observable<any> {
    return this.currentLanguage$.asObservable();
  }

  public getMenuIsExpanded(): Observable<any> {
    return this.menuIsExpanded$.asObservable();
  }

  public getCurrentLanguageValue(): string {
    return this.currentLanguage;
  }
}
