export const enLanguage = {
  'en-GB': {
    grid: {
      EmptyRecord: 'No records to display',
      True: 'true',
      False: 'false',
      InvalidFilterMessage: 'Invalid Filter Data',
      GroupDropArea: 'Drag a column header here to group its column',
      UnGroup: 'Click here to ungroup',
      GroupDisable: 'Grouping is disabled for this column',
      FilterbarTitle: '"s filter bar cell',
      EmptyDataSourceError:
        'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
      Add: 'Add',
      Edit: 'Edit',
      Cancel: 'Cancel',
      Update: 'Update',
      Delete: 'Delete',
      Print: 'Print',
      Pdfexport: 'PDF Export',
      Excelexport: 'Excel Export',
      Wordexport: 'Word Export',
      Csvexport: 'CSV Export',
      Search: 'Search',
      Columnchooser: 'Columns',
      Save: 'Save',
      Item: 'item',
      Items: 'items',
      EditOperationAlert: 'No records selected for edit operation',
      DeleteOperationAlert: 'No records selected for delete operation',
      SaveButton: 'Save',
      OKButton: 'OK',
      CancelButton: 'Cancel',
      EditFormTitle: 'Details of ',
      AddFormTitle: 'Add New Record',
      BatchSaveConfirm: 'Are you sure you want to save changes?',
      BatchSaveLostChanges:
        'Unsaved changes will be lost. Are you sure you want to continue?',
      ConfirmDelete: 'Are you sure you want to Delete Record?',
      CancelEdit: 'Are you sure you want to Cancel the changes?',
      ChooseColumns: 'Choose Column',
      SearchColumns: 'search columns',
      Matchs: 'No Matches Found',
      FilterButton: 'Filter',
      ClearButton: 'Clear',
      StartsWith: 'Starts With',
      EndsWith: 'Ends With',
      Contains: 'Contains',
      Equal: 'Equal',
      NotEqual: 'Not Equal',
      LessThan: 'Less Than',
      LessThanOrEqual: 'Less Than Or Equal',
      GreaterThan: 'Greater Than',
      GreaterThanOrEqual: 'Greater Than Or Equal',
      ChooseDate: 'Choose a Date',
      EnterValue: 'Enter the value',
      Copy: 'Copy',
      Group: 'Group by this column',
      Ungroup: 'Ungroup by this column',
      autoFitAll: 'Auto Fit all columns',
      autoFit: 'Auto Fit this column',
      Export: 'Export',
      FirstPage: 'First Page',
      LastPage: 'Last Page',
      PreviousPage: 'Previous Page',
      NextPage: 'Next Page',
      SortAscending: 'Sort Ascending',
      SortDescending: 'Sort Descending',
      EditRecord: 'Edit Record',
      DeleteRecord: 'Delete Record',
      FilterMenu: 'Filter',
      SelectAll: 'Select All',
      Blanks: 'Blanks',
      FilterTrue: 'True',
      FilterFalse: 'False',
      NoResult: 'No Matches Found',
      ClearFilter: 'Clear Filter',
      NumberFilter: 'Number Filters',
      TextFilter: 'Text Filters',
      DateFilter: 'Date Filters',
      DateTimeFilter: 'DateTime Filters',
      MatchCase: 'Match Case',
      Between: 'Between',
      CustomFilter: 'Custom Filter',
      CustomFilterPlaceHolder: 'Enter the value',
      CustomFilterDatePlaceHolder: 'Choose a date',
      AND: 'AND',
      OR: 'OR',
      ShowRowsWhere: 'Show rows where:',
      UnGroupButton: 'Click here to ungroup',
      AutoFitAll: 'Autofit all columns',
      AutoFit: 'Autofit this column',
      Clear: 'Clear',
      FilterMenuDialogARIA: 'Filter menu dialog',
      ExcelFilterDialogARIA: 'Excel filter dialog',
      DialogEditARIA: 'Edit dialog',
      ColumnChooserDialogARIA: 'Column chooser dialog',
      ColumnMenuDialogARIA: 'Column menu dialog',
      CustomFilterDialogARIA: 'Custom filter dialog',
      SortAtoZ: 'Sort A to Z',
      SortZtoA: 'Sort Z to A',
      SortByOldest: 'Sort by Oldest',
      SortByNewest: 'Sort by Newest',
      SortSmallestToLargest: 'Sort Smallest to Largest',
      SortLargestToSmallest: 'Sort Largest to Smallest',
      Sort: 'Sort',
    },
    pager: {
      currentPageInfo: '{0} of {1} pages',
      totalItemsInfo: '({0} items)',
      firstPageTooltip: 'Go to first page',
      lastPageTooltip: 'Go to last page',
      nextPageTooltip: 'Go to next page',
      previousPageTooltip: 'Go to previous page',
      nextPagerTooltip: 'Go to next pager items',
      previousPagerTooltip: 'Go to previous pager items',
      pagerDropDown: 'Items per page',
      pagerAllDropDown: 'Items',
      All: 'All',
      totalItemInfo: '({0} item)',
    },
    kanban: {
      items: 'items',
      min: 'Min',
      max: 'Max',
      cardsSelected: 'Cards Selected',
      addTitle: 'Add New Card',
      editTitle: 'Edit Card Details',
      deleteTitle: 'Delete Card',
      deleteContent: 'Are you sure you want to delete this card?',
      save: 'Save',
      delete: 'Delete',
      cancel: 'Cancel',
      yes: 'Yes',
      no: 'No',
      close: 'Close',
      noCard: 'No cards to display',
      unassigned: 'Unassigned',
    },
    dropdowns: {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
      overflowCountTemplate: '+${count} more..',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      totalCountTemplate: '${count} selected',
    },
    'drop-down-list': {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
    },
    'combo-box': {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
    },
    'auto-complete': {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
    },
    'multi-select': {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
      overflowCountTemplate: '+${count} more..',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      totalCountTemplate: '${count} selected',
    },
    listbox: {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      moveUp: 'Move Up',
      moveDown: 'Move Down',
      moveTo: 'Move To',
      moveFrom: 'Move From',
      moveAllTo: 'Move All To',
      moveAllFrom: 'Move All From',
    },
    spreadsheet: {
      InsertingEmptyValue: 'Reference value is not valid.',
      FindValue: 'Find value',
      ReplaceValue: 'Replace value',
      FindReplaceTooltip: 'Find & Replace',
      ByRow: ' By Rows',
      ByColumn: 'By Columns',
      MatchExactCellElements: 'Match exact cell contents',
      EntercellAddress: 'Enter cell address',
      FindAndReplace: 'Find and Replace',
      ReplaceAllEnd: ' matches replaced with',
      FindNextBtn: 'Find Next',
      FindPreviousBtn: 'Find Previous',
      ReplaceBtn: 'Replace',
      ReplaceAllBtn: 'Replace All',
      GotoHeader: 'Go To',
      GotoSpecialHeader: 'Go To Special',
      Sheet: 'Sheet',
      SearchWithin: 'Search within',
      SearchBy: 'Search by',
      Reference: 'Reference',
      Workbook: 'Workbook',
      NoElements:
        'We couldn’t find what you were looking for. Click options for more way to search',
      FindWhat: 'Find what',
      ReplaceWith: 'Replace with',
      EnterValue: 'Enter value',
      FileName: 'File Name',
      EnterName: 'Enter Name',
      ExtendValidation:
        'The selection contains some cells without data validation. Do you want to extend validation to these cells?',
      Yes: 'Yes',
      No: 'No',
      InvalidFileName: 'File name contains invalid character.',
      PROPER:
        'Converts a text to proper case; first letter to uppercase and other letters to lowercase.',
      Cut: 'Cut',
      Copy: 'Copy',
      Paste: 'Paste',
      PasteSpecial: 'Paste Special',
      All: 'All',
      Values: 'Values',
      Formats: 'Formats',
      Font: 'Font',
      FontSize: 'Font Size',
      Bold: 'Bold',
      Italic: 'Italic',
      Underline: 'Underline',
      Strikethrough: 'Strikethrough',
      TextColor: 'Text Color',
      FillColor: 'Fill Color',
      HorizontalAlignment: 'Horizontal Alignment',
      AlignLeft: 'Align Left',
      AlignCenter: 'Center',
      AlignRight: 'Align Right',
      VerticalAlignment: 'Vertical Alignment',
      AlignTop: 'Align Top',
      AlignMiddle: 'Align Middle',
      AlignBottom: 'Align Bottom',
      MergeCells: 'Merge Cells',
      MergeAll: 'Merge All',
      MergeHorizontally: 'Merge Horizontally',
      MergeVertically: 'Merge Vertically',
      Unmerge: 'Unmerge',
      UnmergeCells: 'Unmerge Cells',
      SelectMergeType: 'Select Merge Type',
      MergeCellsAlert:
        'Merging cells will only preserve the top-leftmost(Uppermost) value. Merge anyway?',
      PasteMergeAlert: "We can't do that to a merge cell.",
      Borders: 'Borders',
      TopBorders: 'Top Borders',
      LeftBorders: 'Left Borders',
      RightBorders: 'Right Borders',
      BottomBorders: 'Bottom Borders',
      AllBorders: 'All Borders',
      HorizontalBorders: 'Horizontal Borders',
      VerticalBorders: 'Vertical Borders',
      OutsideBorders: 'Outside Borders',
      InsideBorders: 'Inside Borders',
      NoBorders: 'No Borders',
      BorderColor: 'Border Color',
      BorderStyle: 'Border Style',
      InsertFunction: 'Insert Function',
      Insert: 'Insert',
      Delete: 'Delete',
      DuplicateSheet: 'Duplicate',
      MoveRight: 'Move Right',
      MoveLeft: 'Move Left',
      Rename: 'Rename',
      Hide: 'Hide',
      Unhide: 'Unhide',
      NameBox: 'Name Box',
      ShowHeaders: 'Show Headers',
      HideHeaders: 'Hide Headers',
      ShowGridLines: 'Show Gridlines',
      HideGridLines: 'Hide Gridlines',
      FreezePanes: 'Freeze Panes',
      FreezeRows: 'Freeze Rows',
      FreezeColumns: 'Freeze Columns',
      UnfreezePanes: 'Unfreeze Panes',
      UnfreezeRows: 'Unfreeze Rows',
      UnfreezeColumns: 'Unfreeze Columns',
      AddSheet: 'Add Sheet',
      ListAllSheets: 'List All Sheets',
      FullScreen: 'Full Screen',
      CollapseToolbar: 'Collapse Toolbar',
      ExpandToolbar: 'Expand Toolbar',
      CollapseFormulaBar: 'Collapse Formula Bar',
      ExpandFormulaBar: 'Expand Formula Bar',
      File: 'File',
      Home: 'Home',
      Formulas: 'Formulas',
      View: 'View',
      New: 'New',
      Open: 'Open',
      SaveAs: 'Save As',
      ExcelXlsx: 'Microsoft Excel',
      ExcelXls: 'Microsoft Excel 97-2003',
      CSV: 'Comma-separated values',
      FormulaBar: 'Formula Bar',
      Sort: 'Sort',
      SortAscending: 'Ascending',
      SortDescending: 'Descending',
      CustomSort: 'Custom Sort',
      AddColumn: 'Add Column',
      ContainsHeader: 'Data contains header',
      CaseSensitive: 'Case sensitive',
      SortBy: 'Sort by',
      ThenBy: 'Then by',
      SelectAColumn: 'Select a column',
      SortEmptyFieldError:
        'All sort criteria must have a column specified. Check the selected sort criteria and try again.',
      SortDuplicateFieldError:
        ' is being sorted by values more than once. Delete the duplicate sort criteria and try again.',
      SortOutOfRangeError:
        'Select a cell or range inside the used range and try again.',
      MultiRangeSortError:
        "This can't be done on a multiple range selection. Select a single range and try again.",
      HideRow: 'Hide Row',
      HideRows: 'Hide Rows',
      UnHideRows: 'UnHide Rows',
      HideColumn: 'Hide Column',
      HideColumns: 'Hide Columns',
      UnHideColumns: 'UnHide Columns',
      InsertRow: 'Insert Row',
      InsertRows: 'Insert Rows',
      Above: 'Above',
      Below: 'Below',
      InsertColumn: 'Insert Column',
      InsertColumns: 'Insert Columns',
      Before: 'Before',
      After: 'After',
      DeleteRow: 'Delete Row',
      DeleteRows: 'Delete Rows',
      DeleteColumn: 'Delete Column',
      DeleteColumns: 'Delete Columns',
      Ok: 'Ok',
      Close: 'Close',
      Cancel: 'Cancel',
      Apply: 'Apply',
      MoreColors: 'More Colors',
      StandardColors: 'Standard Colors',
      General: 'General',
      Number: 'Number',
      Currency: 'Currency',
      Accounting: 'Accounting',
      ShortDate: 'Short Date',
      LongDate: 'Long Date',
      Time: 'Time',
      Percentage: 'Percentage',
      Fraction: 'Fraction',
      Scientific: 'Scientific',
      Text: 'Text',
      NumberFormat: 'Number Format',
      MobileFormulaBarPlaceHolder: 'Enter value or Formula',
      PasteAlert:
        "You can't paste this here, because the copy area and paste area aren't in the same size. Please try pasting in a different range.",
      DestroyAlert:
        'Are you sure you want to destroy the current workbook without saving and create a new workbook?',
      SheetRenameInvalidAlert: 'Sheet name contains invalid character.',
      SheetRenameEmptyAlert: 'Sheet name cannot be empty.',
      SheetRenameAlreadyExistsAlert:
        'Sheet name already exists. Please enter another name.',
      DeleteSheetAlert: 'Are you sure you want to delete this sheet?',
      DeleteSingleLastSheetAlert:
        'A Workbook must contain at least one visible worksheet.',
      PickACategory: 'Pick a category',
      Description: 'Description',
      UnsupportedFile: 'Unsupported File',
      InvalidUrl: 'Invalid URL',
      SUM: 'Adds a series of numbers and/or cells.',
      SUMIF: 'Adds the cells based on specified condition.',
      SUMIFS: 'Adds the cells based on specified conditions.',
      ABS: 'Returns the value of a number without its sign.',
      RAND: 'Returns a random number between 0 and 1.',
      RANDBETWEEN: 'Returns a random integer based on specified values.',
      FLOOR: 'Rounds a number down to the nearest multiple of a given factor.',
      CEILING: 'Rounds a number up to the nearest multiple of a given factor.',
      PRODUCT: 'Multiplies a series of numbers and/or cells.',
      AVERAGE:
        'Calculates average for the series of numbers and/or cells excluding text.',
      AVERAGEIF:
        'Calculates average for the cells based on specified criterion.',
      AVERAGEIFS:
        'Calculates average for the cells based on specified conditions.',
      AVERAGEA:
        'Calculates the average for the cells evaluating TRUE as 1, text and FALSE as 0.',
      COUNT: 'Counts the cells that contain numeric values in a range.',
      COUNTIF: 'Counts the cells based on specified condition.',
      COUNTIFS: 'Counts the cells based on specified conditions.',
      COUNTA: 'Counts the cells that contains values in a range.',
      MIN: 'Returns the smallest number of the given arguments.',
      MAX: 'Returns the largest number of the given arguments.',
      DATE: 'Returns the date based on given year, month, and day.',
      DAY: 'Returns the day from the given date.',
      DAYS: 'Returns the number of days between two dates.',
      IF: 'Returns value based on the given expression.',
      IFS: 'Returns value based on the given multiple expressions.',
      CalculateAND:
        'Returns TRUE if all the arguments are TRUE, otherwise returns FALSE.',
      CalculateOR:
        'Returns TRUE if any of the arguments are TRUE, otherwise returns FALSE.',
      IFERROR:
        'Returns value if no error found else it will return specified value.',
      CHOOSE: 'Returns a value from list of values, based on index number.',
      INDEX:
        'Returns a value of the cell in a given range based on row and column number.',
      FIND: 'Returns the position of a string within another string, which is case sensitive',
      CONCATENATE: 'Combines two or more strings together.',
      CONCAT: 'Concatenates a list or a range of text strings.',
      SUBTOTAL: 'Returns subtotal for a range using the given function number.',
      RADIANS: 'Converts degrees into radians.',
      MATCH:
        'Returns the relative position of a specified value in given range.',
      SLOPE:
        'Returns the slope of the line from linear regression of the data points.',
      INTERCEPT:
        'Calculates the point of the Y-intercept line via linear regression.',
      UNIQUE: 'Returns a unique values from a range or array',
      TEXT: 'Converts a value to text in specified number format.',
      DefineNameExists: 'This name already exists, try different name.',
      CircularReference:
        'When a formula refers to one or more circular references, this may result in an incorrect calculation.',
      SORT: 'Sorts a range of an array',
      T: 'Checks whether a value is text or not and returns the text.',
      EXACT:
        'Checks whether a two text strings are exactly same and returns TRUE or FALSE.',
      LEN: 'Returns a number of characters in a given string.',
      MOD: 'Returns a remainder after a number is divided by divisor.',
      ODD: 'Rounds a positive number up and negative number down to the nearest odd integer.',
      PI: 'Returns the value of pi.',
      COUNTBLANK:
        'Returns the number of empty cells in a specified range of cells.',
      EVEN: 'Rounds a positive number up and negative number down to the nearest even integer.',
      DECIMAL:
        'Converts a text representation of a number in a given base into a decimal number.',
      ADDRESS:
        'Returns a cell reference as text, given specified row and column numbers.',
      CHAR: 'Returns the character from the specified number.',
      CODE: 'Returns the numeric code for the first character in a given string.',
      DOLLAR: 'Converts the number to currency formatted text.',
      SMALL: 'Returns the k-th smallest value in a given array.',
      LARGE: 'Returns the k-th largest value in a given array.',
      TIME: 'Converts hours, minutes, seconds to the time formatted text.',
      DEGREES: 'Converts radians to degrees.',
      FACT: 'Returns the factorial of a number.',
      MEDIAN: 'Returns the median of the given set of numbers.',
      EDATE:
        'Returns a date with given number of months before or after the specified date.',
      DATEVALUE: 'Converts a date string into date value.',
      NOW: 'Returns the current date and time.',
      HOUR: 'Returns the number of hours in a specified time string.',
      MINUTE: 'Returns the number of minutes in a specified time string.',
      SECOND: 'Returns the number of seconds in a specified time string.',
      MONTH: 'Returns the number of months in a specified date string.',
      ShowRowsWhere: 'Show rows where:',
      OR: 'OR',
      AND: 'AND',
      CustomFilterDatePlaceHolder: 'Choose a date',
      CustomFilterPlaceHolder: 'Enter the value',
      CustomFilter: 'Custom Filter',
      Between: 'Between',
      MatchCase: 'Match Case',
      DateTimeFilter: 'DateTime Filters',
      Undo: 'Undo',
      Redo: 'Redo',
      ClearAllFilter: 'Clear',
      ReapplyFilter: 'Reapply',
      DateFilter: 'Date Filters',
      TextFilter: 'Text Filters',
      NumberFilter: 'Number Filters',
      ClearFilter: 'Clear Filter',
      NoResult: 'No Matches Found',
      FilterFalse: 'False',
      FilterTrue: 'True',
      Blanks: 'Blanks',
      SelectAll: 'Select All',
      GreaterThanOrEqual: 'Greater Than Or Equal',
      GreaterThan: 'Greater Than',
      LessThanOrEqual: 'Less Than Or Equal',
      LessThan: 'Less Than',
      NotEqual: 'Not Equal',
      Equal: 'Equal',
      Contains: 'Contains',
      EndsWith: 'Ends With',
      StartsWith: 'Starts With',
      ClearButton: 'Clear',
      FilterButton: 'Filter',
      CancelButton: 'Cancel',
      OKButton: 'OK',
      Search: 'Search',
      DataValidation: 'Data Validation',
      CLEARALL: 'CLEAR ALL',
      APPLY: 'APPLY',
      CellRange: 'Cell Range',
      Allow: 'Allow',
      Data: 'Data',
      Minimum: 'Minimum',
      Maximum: 'Maximum',
      IgnoreBlank: 'Ignore blank',
      WholeNumber: 'Whole Number',
      Decimal: 'Decimal',
      Date: 'Date',
      TextLength: 'Text Length',
      List: 'List',
      NotBetween: 'Not between',
      EqualTo: 'Equal to',
      NotEqualTo: 'Not equal to',
      Greaterthan: 'Greater than',
      Lessthan: 'Less than',
      GreaterThanOrEqualTo: 'Greater than or equal to',
      LessThanOrEqualTo: 'Less than or equal to',
      InCellDropDown: 'In-cell-dropdown',
      Sources: 'Sources',
      Value: 'Value',
      Retry: 'Retry',
      DialogError:
        'The list source must be a reference to single row or column.',
      MinMaxError: 'The Maximum must be greater than or equal to the Minimum.',
      Spreadsheet: 'Spreadsheet',
      MoreValidation: 'This selection contain more than one validation. ',
      FileNameError: ' Erase current settings and continue?',
      ValidationError:
        "This value doesn't match the data validation restrictions defined for the cell.",
      ListLengthError: 'The list values allows only upto 256 characters',
      ProtectSheet: 'Protect Sheet',
      UnprotectSheet: 'Unprotect Sheet',
      SelectCells: 'Select cells',
      SelectUnLockedCells: 'Select cells',
      Save: 'Select cells',
      EmptyFileName: 'Save',
      LargeName: 'File name cannot be empty.',
      FormatCells: 'Format cells',
      FormatRows: 'Format rows',
      'Format Columns': 'Format columns',
      InsertLinks: 'Insert links',
      ProtectContent: 'Protect the contents of locked cells',
      ProtectAllowUser: 'Allow all users of this worksheet to:',
      EditAlert:
        "The cell you're trying to change is protected. To make change, unprotect the sheet.",
      ClearValidation: 'Clear Validation',
      ISNUMBER: 'Returns true when the value parses as a numeric value.',
      Round: 'Rounds a number to a specified number of digits.',
      GEOMEAN:
        'Returns the geometric mean of an array or range of positive data.',
      POWER: 'Returns the result of a number raised to power',
      LOG: 'Returns the logarithm of a number to the base that you specify.',
      TRUNC:
        'Returns the truncated value of a number to a specified number of decimal places.',
      EXP: 'Returns e raised to the power of the given number.',
      HighlightCellsRules: 'Highlight Cells Rules',
      CFEqualTo: 'Equal To',
      TextThatContains: 'Text that Contains',
      ADateOccuring: 'A Date Occuring',
      DuplicateValues: 'Duplicate Values',
      TopBottomRules: 'Top/Bottom Rules',
      Top10Items: 'Top 10 Items',
      Top10: 'Top 10',
      Bottom10Items: 'Bottom 10 Items',
      Bottom10: 'Bottom 10',
      AboveAverage: 'Above Average',
      BelowAverage: 'Below Average',
      FormatCellsGreaterThan: 'Format cells that are GREATER THAN:',
      FormatCellsLessThan: 'Format cells that are LESS THAN:',
      FormatCellsBetween: 'Format cells that are BETWEEN:',
      FormatCellsEqualTo: 'Format cells that are EQUAL TO:',
      FormatCellsThatContainTheText: 'Format cells that contain the text:',
      FormatCellsThatContainADateOccurring:
        'Format cells that contain a date occurring:',
      FormatCellsDuplicate: 'Format cells that contain:',
      FormatCellsTop: 'Format cells that rank in the TOP:',
      FormatCellsBottom: 'Format cells that rank in the BOTTOM:',
      FormatCellsAbove: 'Format cells that are ABOVE AVERAGE:',
      FormatCellsBelow: 'Format cells that are BELOW AVERAGE:',
      With: 'with',
      DataBars: 'Data Bars',
      ColorScales: 'Color Scales',
      IconSets: 'Icon Sets',
      ClearRules: 'ClearRules',
      SelectedCells: 'Clear Rules from Selected Cells',
      EntireSheet: 'Clear Rules from Entire Sheet',
      LightRedFillWithDarkRedText: 'Light Red Fill with Dark Red Text',
      YellowFillWithDarkYellowText: 'Yellow Fill with Dark Yellow Text',
      GreenFillWithDarkGreenText: 'Green Fill with Dark Green Text',
      RedFill: 'Red Fill',
      RedText: 'Red Text',
      Duplicate: 'Duplicate',
      Unique: 'Unique',
      And: 'and',
      WebPage: 'WEB PAGE',
      ThisDocument: 'THIS DOCUMENT',
      DisplayText: 'Display Text',
      Url: 'URL',
      CellReference: 'Cell Reference',
      DefinedNames: 'Defined Names',
      EnterTheTextToDisplay: 'Enter the text to display',
      EnterTheUrl: 'Enter the URL',
      INT: 'Returns a number to the nearest integer.',
      SUMPRODUCT: 'Returns sum of the product of given ranges of arrays.',
      TODAY: 'Returns the current date as date value.',
      ROUNDUP: 'Rounds a number away from zero.',
      Replace: 'Replace...',
      Find: 'Find and Replace...',
      Goto: 'Goto...',
      GotoSpecial: 'GotoSpecial...',
      Link: 'Link',
      SpreadsheetHyperlink: 'SpreadsheetHyperlink',
      Hyperlink: 'Hyperlink',
      EditHyperlink: 'Edit Hyperlink',
      OpenHyperlink: 'Open Hyperlink',
      RemoveHyperlink: 'Remove Hyperlink',
      InvalidHyperlinkAlert:
        'The address of this site is not valid. Check the address and try again.',
      InsertLink: 'Insert Link',
      EditLink: 'Edit Link',
      WrapText: 'Wrap Text',
      Update: 'Update',
      SortAndFilter: 'Sort & Filter',
      Filter: 'Filter',
      FilterCellValue: 'Filter by Value of Selected Cell',
      FilterOutOfRangeError:
        'Select a cell or range inside the used range and try again.',
      ClearFilterFrom: 'Clear Filter From ',
      LN: 'Returns the natural logarithm of a number.',
      DefineNameInValid: 'The name that you entered is not valid.',
      LockCells: 'Lock Cells',
      EmptyError: 'You must enter a value',
      ClearHighlight: 'Clear Highlight',
      HighlightInvalidData: 'Highlight Invalid Data',
      Clear: 'Clear',
      ClearContents: 'Clear Contents',
      ClearAll: 'Clear All',
      ClearFormats: 'Clear Formats',
      ClearHyperlinks: 'Clear Hyperlinks',
      Image: 'Image',
      ConditionalFormatting: 'Conditional Formatting',
      BlueDataBar: 'Blue Data Bar',
      GreenDataBar: 'Green Data Bar',
      RedDataBar: 'Red Data Bar',
      OrangeDataBar: 'Orange Data Bar',
      LightblueDataBar: 'Light blue Data Bar',
      PurpleDataBar: 'Purple Data Bar',
      GYRColorScale: 'Green - Yellow - Red Color Scale',
      RYGColorScale: 'Red - Yellow - Green Color Scale',
      GWRColorScale: 'Green - White - Red Color Scale',
      RWGColorScale: 'Red - White - Green Color Scale',
      BWRColorScale: 'Blue - White - Red Color Scale',
      RWBColorScale: 'Red - White - Blue Color Scale',
      WRColorScale: 'White - Red Color Scale',
      RWColorScale: 'Red - White Color Scale',
      GWColorScale: 'Green - White Color Scale',
      WGColorScale: 'White - Green Color Scale',
      GYColorScale: 'Green - Yellow Color Scale',
      YGColorScale: 'Yellow - Green Color Scale',
      ThreeArrowsColor: '3 Arrows (Colored)',
      ThreeArrowsGray: '3 Arrows (Gray)',
      ThreeTriangles: '3 Triangles',
      FourArrowsColor: '4 Arrows (Gray)',
      FourArrowsGray: '4 Arrows (Colored)',
      FiveArrowsColor: '5 Arrows (Gray)',
      FiveArrowsGray: '5 Arrows (Colored)',
      ThreeTrafficLights1: '3 Traffic Lights (Unrimmed)',
      ThreeTrafficLights2: '3 Traffic Lights (Rimmed)',
      ThreeSigns: '3 Signs',
      FourTrafficLights: '4 Traffic Lights',
      RedToBlack: 'Red To Black',
      ThreeSymbols1: '3 Symbols (Circled)',
      ThreeSymbols2: '3 Symbols (Uncircled)',
      ThreeFlags: '3 Flags',
      ThreeStars: '3 Stars',
      FourRatings: '4 Ratings',
      FiveQuarters: '5 Quarters',
      FiveRatings: '5 Ratings',
      FiveBoxes: '5 Boxes',
      Chart: 'Chart',
      Column: 'Column',
      Bar: 'Bar',
      Area: 'Area',
      Pie: 'Pie',
      Doughnut: 'Doughnut',
      PieAndDoughnut: 'Pie/Doughnut',
      Line: 'Line',
      Radar: 'Radar',
      Scatter: 'Scatter',
      ChartDesign: 'Chart Design',
      ClusteredColumn: 'Clustered Column',
      StackedColumn: 'Stacked Column',
      StackedColumn100: '100% Stacked Column',
      ClusteredBar: 'Clustered Bar',
      StackedBar: 'Stacked Bar',
      StackedBar100: '100% Stacked Bar',
      StackedArea: 'Stacked Area',
      StackedArea100: '100% Stacked Area',
      StackedLine: 'Stacked Line',
      StackedLine100: '100% Stacked Line',
      AddChartElement: 'Add Chart Element',
      Axes: 'Axes',
      AxisTitle: 'Axis Title',
      ChartTitle: 'Chart Title',
      DataLabels: 'Data Labels',
      Gridlines: 'Gridlines',
      Legends: 'Legends',
      PrimaryHorizontal: 'Primary Horizontal',
      PrimaryVertical: 'Primary Vertical',
      None: 'None',
      AboveChart: 'Above Chart',
      Center: 'Center',
      InsideEnd: 'Inside End',
      InsideBase: 'Inside Base',
      OutsideEnd: 'OutSide End',
      PrimaryMajorHorizontal: 'Primary Major Horizontal',
      PrimaryMajorVertical: 'Primary Major Vertical',
      PrimaryMinorHorizontal: 'Primary Minor Horizontal',
      PrimaryMinorVertical: 'Primary Minor Vertical',
      Right: 'Right',
      Left: 'Left',
      Bottom: 'Bottom',
      Top: 'Top',
      SwitchRowColumn: 'Switch Row/Column',
      ChartTheme: 'Chart Theme',
      ChartType: 'Chart Type',
      Material: 'Material',
      Fabric: 'Fabric',
      Bootstrap: 'Bootstrap',
      HighContrastLight: 'HighContrastLight',
      MaterialDark: 'MaterialDark',
      FabricDark: 'FabricDark',
      HighContrast: 'HighContrast',
      BootstrapDark: 'BootstrapDark',
      Bootstrap4: 'Bootstrap4',
      Bootstrap5Dark: 'Bootstrap4',
      Bootstrap5: 'Bootstrap5 Dark',
      Tailwind: 'Bootstrap5',
      TailwindDark: 'Tailwind',
      VerticalAxisTitle: 'Vertical Axis Title',
      HorizontalAxisTitle: 'Horizontal Axis Title',
      EnterTitle: 'Enter Title',
      UnProtectWorksheet: 'Enter Title',
      ReEnterPassword: 'Unprotect Sheet',
      SheetPassword: 'Re-enter password to proceed',
      ProtectWorkbook: 'Protect Workbook',
      Password: 'Password (optional):',
      unProtectPassword: 'Password',
      EnterThePassword: 'Enter the password',
      ConfirmPassword: 'Confirm Password',
      EnterTheConfirmPassword: 'Re-enter your password',
      PasswordAlert: 'Confirmation password is not identical',
      UnProtectWorkbook: 'Unprotect Workbook',
      UnProtectPasswordAlert: 'The password you supplied is not correct.',
      InCorrectPassword:
        'Unable to open the file or worksheet with the given password',
      PasswordAlertMsg: 'Please enter the password',
      ConfirmPasswordAlertMsg: 'Please enter the confirm password',
      IsProtected: 'is protected',
      PDF: 'Portable Document Format',
      AutoFillMergeAlertMsg: 'Portable Document Format',
      SelectBorderOption: 'Select Border Option',
    },
    filemanager: {
      NewFolder: 'New folder',
      Upload: 'Upload',
      Delete: 'Delete',
      Rename: 'Rename',
      Download: 'Download',
      Cut: 'Cut',
      Copy: 'Copy',
      Paste: 'Paste',
      SortBy: 'Sort by',
      Refresh: 'Refresh',
      'Item-Selection': 'item selected',
      'Items-Selection': 'items selected',
      View: 'View',
      Details: 'Details',
      SelectAll: 'Select all',
      Open: 'Open',
      'Tooltip-NewFolder': 'New folder',
      'Tooltip-Upload': 'Upload',
      'Tooltip-Delete': 'Delete',
      'Tooltip-Rename': 'Rename',
      'Tooltip-Download': 'Download',
      'Tooltip-Cut': 'Cut',
      'Tooltip-Copy': 'Copy',
      'Tooltip-Paste': 'Paste',
      'Tooltip-SortBy': 'Sort by',
      'Tooltip-Refresh': 'Refresh',
      'Tooltip-Selection': 'Clear selection',
      'Tooltip-View': 'View',
      'Tooltip-Details': 'Details',
      'Tooltip-SelectAll': 'Select all',
      Name: 'Name',
      Size: 'Size',
      DateModified: 'Modified',
      DateCreated: 'Date created',
      Path: 'Path',
      Modified: 'Modified',
      Created: 'Created',
      Location: 'Location',
      Type: 'Type',
      Permission: 'Permission',
      Ascending: 'Ascending',
      Descending: 'Descending',
      None: 'None',
      'View-LargeIcons': 'Large icons',
      'View-Details': 'Details',
      Search: 'Search',
      'Button-Ok': 'OK',
      'Button-Cancel': 'Cancel',
      'Button-Yes': 'Yes',
      'Button-No': 'No',
      'Button-Create': 'Create',
      'Button-Save': 'Save',
      'Header-NewFolder': 'Folder',
      'Content-NewFolder': 'Enter your folder name',
      'Header-Rename': 'Rename',
      'Content-Rename': 'Enter your new name',
      'Header-Rename-Confirmation': 'Rename Confirmation',
      'Content-Rename-Confirmation':
        'If you change a file name extension, the file might become unstable. Are you sure you want to change it?',
      'Header-Delete': 'Delete File',
      'Content-Delete': 'Are you sure you want to delete this file?',
      'Header-Folder-Delete': 'Delete Folder',
      'Content-Folder-Delete': 'Are you sure you want to delete this folder?',
      'Header-Multiple-Delete': 'Delete Multiple Files',
      'Content-Multiple-Delete':
        'Are you sure you want to delete these {0} files?',
      'Header-Duplicate': 'File/Folder exists',
      'Content-Duplicate':
        '{0} already exists. Do you want to rename and paste?',
      'Header-Upload': 'Upload Files',
      Error: 'Error',
      'Validation-Empty': 'The file or folder name cannot be empty.',
      'Validation-Invalid':
        'The file or folder name {0} contains invalid characters. Please use a different name. Valid file or folder names cannot end with a dot or space, and cannot contain any of the following characters: \\/:*?"<>|',
      'Validation-NewFolder-Exists':
        'A file or folder with the name {0} already exists.',
      'Validation-Rename-Exists':
        'Cannot rename {0} to {1}: destination already exists.',
      'Folder-Empty': 'This folder is empty',
      'File-Upload': 'Drag files here to upload',
      'Search-Empty': 'No results found',
      'Search-Key': 'Try with different keywords',
      'Filter-Empty': 'No results found',
      'Filter-Key': 'Try with different filter',
      'Sub-Folder-Error':
        'The destination folder is the subfolder of the source folder.',
      'Same-Folder-Error':
        'The destination folder is the same as the source folder.',
      'Access-Denied': 'Access Denied',
      'Access-Details': "You don't have permission to access this folder.",
      'Header-Retry': 'File Already Exists',
      'Content-Retry':
        'A file with this name already exists in this folder. What would you like to do?',
      'Button-Keep-Both': 'Keep both',
      'Button-Replace': 'Replace',
      'Button-Skip': 'Skip',
      'ApplyAll-Label': 'Do this for all current items',
      KB: 'KB',
      'Access-Message':
        '{0} is not accessible. You need permission to perform the {1} action.',
      'Network-Error':
        'NetworkError: Failed to send on XMLHTTPRequest: Failed to load',
      'Server-Error': 'ServerError: Invalid response from',
    },
    calendar: {
      today: 'Today',
    },
    datepicker: {
      today: 'Today',
      placeholder: 'Choose a date',
    },
    daterangepicker: {
      placeholder: 'Choose a date range',
      startLabel: 'Start Date',
      endLabel: 'End Date',
      applyText: 'Apply',
      cancelText: 'Cancel',
      selectedDays: 'Selected Days',
      days: 'Days',
      customRange: 'Custom Range',
    },
    timepicker: {
      placeholder: 'Choose a time',
    },
    datetimepicker: {
      today: 'Today',
      placeholder: 'Choose a date and time',
    },
    dialog: {
      close: 'Close',
    },
    inplaceeditor: {
      save: 'Save',
      cancel: 'Cancel',
      loadingText: 'Loading...',
      editIcon: 'Click to edit',
      editAreaClick: 'Click to edit',
      editAreaDoubleClick: 'Double click to edit',
    },
    toast: {
      close: 'Close',
    },
    tab: {
      closeButtonTitle: 'Close',
    },
    schedule: {
      day: 'Day',
      week: 'Week',
      workWeek: 'Work Week',
      month: 'Month',
      year: 'Year',
      agenda: 'Agenda',
      weekAgenda: 'Week Agenda',
      workWeekAgenda: 'Work Week Agenda',
      monthAgenda: 'Month Agenda',
      today: 'Today',
      noEvents: 'No events',
      emptyContainer: 'There are no events scheduled on this day.',
      allDay: 'All day',
      start: 'Start',
      end: 'End',
      more: 'more',
      close: 'Close',
      cancel: 'Cancel',
      noTitle: '(No Title)',
      delete: 'Delete',
      deleteEvent: 'This Event',
      deleteMultipleEvent: 'Delete Multiple Events',
      selectedItems: 'Items selected',
      deleteSeries: 'Entire Series',
      edit: 'Edit',
      editSeries: 'Entire Series',
      editEvent: 'This Event',
      createEvent: 'Create',
      subject: 'Subject',
      addTitle: 'Add title',
      moreDetails: 'More Details',
      save: 'Save',
      editContent:
        'How would you like to change the appointment in the series?',
      deleteContent: 'Are you sure you want to delete this event?',
      deleteMultipleContent:
        'Are you sure you want to delete the selected events?',
      newEvent: 'New Event',
      title: 'Title',
      location: 'Location',
      description: 'Description',
      timezone: 'Timezone',
      startTimezone: 'Start Timezone',
      endTimezone: 'End Timezone',
      repeat: 'Repeat',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      deleteButton: 'Delete',
      recurrence: 'Recurrence',
      wrongPattern: 'The recurrence pattern is not valid.',
      seriesChangeAlert:
        'Do you want to cancel the changes made to specific instances of this series and match it to the whole series again?',
      createError:
        'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
      sameDayAlert:
        'Two occurrences of the same event cannot occur on the same day.',
      occurenceAlert:
        'Cannot reschedule an occurrence of the recurring appointment if it skips over a later occurrence of the same appointment.',
      editRecurrence: 'Edit Recurrence',
      repeats: 'Repeats',
      alert: 'Alert',
      startEndError: 'The selected end date occurs before the start date.',
      invalidDateError: 'The entered date value is invalid.',
      blockAlert: 'Events cannot be scheduled within the blocked time range.',
      ok: 'Ok',
      yes: 'Yes',
      no: 'No',
      occurrence: 'Occurrence',
      series: 'Series',
      previous: 'Previous',
      next: 'Next',
      timelineDay: 'Timeline Day',
      timelineWeek: 'Timeline Week',
      timelineWorkWeek: 'Timeline Work Week',
      timelineMonth: 'Timeline Month',
      timelineYear: 'Timeline Year',
      editFollowingEvent: 'Following Events',
      deleteTitle: 'Delete Event',
      editTitle: 'Edit Event',
      beginFrom: 'Begin From',
      endAt: 'End At',
      expandAllDaySection: 'Expand-all-day-section',
      collapseAllDaySection: 'Collapse-all-day-section',
      searchTimezone: 'Search Timezone',
      noRecords: 'No records found',
    },
    recurrenceeditor: {
      none: 'None',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      month: 'Month',
      yearly: 'Yearly',
      never: 'Never',
      until: 'Until',
      count: 'Count',
      first: 'First',
      second: 'Second',
      third: 'Third',
      fourth: 'Fourth',
      last: 'Last',
      repeat: 'Repeat',
      repeatEvery: 'Repeat every',
      on: 'Repeat On',
      end: 'End',
      onDay: 'Day',
      days: 'Day(s)',
      weeks: 'Week(s)',
      months: 'Month(s)',
      years: 'Year(s)',
      every: 'every',
      summaryTimes: 'time(s)',
      summaryOn: 'on',
      summaryUntil: 'until',
      summaryRepeat: 'Repeats',
      summaryDay: 'day(s)',
      summaryWeek: 'week(s)',
      summaryMonth: 'month(s)',
      summaryYear: 'year(s)',
      monthWeek: 'Month Week',
      monthPosition: 'Month Position',
      monthExpander: 'Month Expander',
      yearExpander: 'Year Expander',
      repeatInterval: 'Repeat Interval',
    },
    gantt: {
      emptyRecord: 'No records to display',
      id: 'ID',
      name: 'Name',
      startDate: 'Start Date',
      endDate: 'End Date',
      duration: 'Duration',
      progress: 'Progress',
      dependency: 'Dependency',
      notes: 'Notes',
      baselineStartDate: 'Baseline Start Date',
      baselineEndDate: 'Baseline End Date',
      taskMode: 'Task Mode',
      changeScheduleMode: 'Change Schedule Mode',
      subTasksStartDate: 'SubTasks Start Date',
      subTasksEndDate: 'SubTasks End Date',
      scheduleStartDate: 'Schedule Start Date',
      scheduleEndDate: 'Schedule End Date',
      auto: 'Auto',
      manual: 'Manual',
      type: 'Type',
      offset: 'Offset',
      resourceName: 'Resources',
      resourceID: 'Resource ID',
      day: 'day',
      hour: 'hour',
      minute: 'minute',
      days: 'days',
      hours: 'hours',
      minutes: 'minutes',
      generalTab: 'General',
      customTab: 'Custom Columns',
      writeNotes: 'Write Notes',
      addDialogTitle: 'New Task',
      editDialogTitle: 'Task Information',
      saveButton: 'Save',
      add: 'Add',
      edit: 'Edit',
      update: 'Update',
      delete: 'Delete',
      cancel: 'Cancel',
      search: 'Search',
      task: ' task',
      tasks: ' tasks',
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
      zoomToFit: 'Zoom to fit',
      excelExport: 'Excel export',
      csvExport: 'CSV export',
      expandAll: 'Expand all',
      collapseAll: 'Collapse all',
      nextTimeSpan: 'Next timespan',
      prevTimeSpan: 'Previous timespan',
      okText: 'Ok',
      confirmDelete: 'Are you sure you want to Delete Record?',
      from: 'From',
      to: 'To',
      taskLink: 'Task Link',
      lag: 'Lag',
      start: 'Start',
      finish: 'Finish',
      enterValue: 'Enter the value',
      taskBeforePredecessor_FS:
        "You moved '{0}' to start before '{1}' finishes and the two tasks are linked. As the result, the links cannot be honored. Select one action below to perform",
      taskAfterPredecessor_FS:
        "You moved '{0}' away from '{1}' and the two tasks are linked. As the result, the links cannot be honored. Select one action below to perform",
      taskBeforePredecessor_SS:
        "You moved '{0}' to start before '{1}' starts and the two tasks are linked. As the result, the links cannot be honored. Select one action below to perform",
      taskAfterPredecessor_SS:
        "You moved '{0}' to start after '{1}' starts and the two tasks are linked. As the result, the links cannot be honored. Select one action below to perform",
      taskBeforePredecessor_FF:
        "You moved '{0}' to finish before '{1}' finishes and the two tasks are linked. As the result, the links cannot be honored. Select one action below to perform",
      taskAfterPredecessor_FF:
        "You moved '{0}' to finish after '{1}' finishes and the two tasks are linked. As the result, the links cannot be honored. Select one action below to perform",
      taskBeforePredecessor_SF:
        "You moved '{0}' away from '{1}' to starts and the two tasks are linked. As the result, the links cannot be honored. Select one action below to perform",
      taskAfterPredecessor_SF:
        "You moved '{0}' to finish after '{1}'starts and the two tasks are linked. As the result, the links cannot be honored. Select one action below to perform",
      taskInformation: 'Task Information',
      deleteTask: 'Delete Task',
      deleteDependency: 'Delete Dependency',
      convert: 'Convert',
      save: 'Save',
      above: 'Above',
      below: 'Below',
      child: 'Child',
      milestone: 'Milestone',
      toTask: 'To Task',
      toMilestone: 'To Milestone',
      eventMarkers: 'Event markers',
      leftTaskLabel: 'Left task label',
      rightTaskLabel: 'Right task label',
      timelineCell: 'Timeline cell',
      confirmPredecessorDelete:
        'Are you sure you want to remove dependency link?',
      unit: 'Unit',
      work: 'Work',
      taskType: 'Task Type',
      unassignedTask: 'Unassigned Task',
      group: 'Group',
      indent: 'Indent',
      outdent: 'Outdent',
      segments: 'Segments',
      splitTask: 'Split Task',
      mergeTask: 'Merge Task',
      left: 'Left',
      right: 'Right',
    },
    richtexteditor: {
      alignments: 'Alignments',
      justifyLeft: 'Align Left',
      justifyCenter: 'Align Center',
      justifyRight: 'Align Right',
      justifyFull: 'Align Justify',
      fontName: 'Font Name',
      fontSize: 'Font Size',
      fontColor: 'Font Color',
      backgroundColor: 'Background Color',
      bold: 'Bold',
      italic: 'Italic',
      underline: 'Underline',
      strikethrough: 'Strikethrough',
      clearFormat: 'Clear Format',
      clearAll: 'Clear All',
      cut: 'Cut',
      copy: 'Copy',
      paste: 'Paste',
      unorderedList: 'Bulleted List',
      orderedList: 'Numbered List',
      indent: 'Increase Indent',
      outdent: 'Decrease Indent',
      undo: 'Undo',
      redo: 'Redo',
      superscript: 'Superscript',
      subscript: 'Subscript',
      createLink: 'Insert Hyperlink',
      openLink: 'Open Link',
      editLink: 'Edit Link',
      removeLink: 'Remove Link',
      image: 'Insert Image',
      replace: 'Replace',
      align: 'Align',
      caption: 'Image Caption',
      remove: 'Remove',
      insertLink: 'Insert Link',
      display: 'Display',
      altText: 'Alternative Text',
      dimension: 'Change Size',
      fullscreen: 'Maximize',
      maximize: 'Maximize',
      minimize: 'Minimize',
      lowerCase: 'Lower Case',
      upperCase: 'Upper Case',
      print: 'Print',
      formats: 'Formats',
      sourcecode: 'Code View',
      preview: 'Preview',
      viewside: 'ViewSide',
      insertCode: 'Insert Code',
      linkText: 'Display Text',
      linkTooltipLabel: 'Title',
      linkWebUrl: 'Web Address',
      linkTitle: 'Enter a title',
      linkurl: 'http://example.com',
      linkOpenInNewWindow: 'Open Link in New Window',
      linkHeader: 'Insert Link',
      dialogInsert: 'Insert',
      dialogCancel: 'Cancel',
      dialogUpdate: 'Update',
      imageHeader: 'Insert Image',
      imageLinkHeader: 'You can also provide a link from the web',
      mdimageLink: 'Please provide a URL for your image',
      imageUploadMessage: 'Drop image here or browse to upload',
      imageDeviceUploadMessage: 'Click here to upload',
      imageAlternateText: 'Alternate Text',
      alternateHeader: 'Alternative Text',
      browse: 'Browse',
      imageUrl: 'http://example.com/image.png',
      imageCaption: 'Caption',
      imageSizeHeader: 'Image Size',
      imageHeight: 'Height',
      imageWidth: 'Width',
      textPlaceholder: 'Enter Text',
      inserttablebtn: 'Insert Table',
      tabledialogHeader: 'Insert Table',
      tableWidth: 'Width',
      cellpadding: 'Cell Padding',
      cellspacing: 'Cell Spacing',
      columns: 'Number of columns',
      rows: 'Number of rows',
      tableRows: 'Table Rows',
      tableColumns: 'Table Columns',
      tableCellHorizontalAlign: 'Table Cell Horizontal Align',
      tableCellVerticalAlign: 'Table Cell Vertical Align',
      createTable: 'Create Table',
      removeTable: 'Remove Table',
      tableHeader: 'Table Header',
      tableRemove: 'Table Remove',
      tableCellBackground: 'Table Cell Background',
      tableEditProperties: 'Table Edit Properties',
      styles: 'Styles',
      insertColumnLeft: 'Insert Column Left',
      insertColumnRight: 'Insert Column Right',
      deleteColumn: 'Delete Column',
      insertRowBefore: 'Insert Row Before',
      insertRowAfter: 'Insert Row After',
      deleteRow: 'Delete Row',
      tableEditHeader: 'Edit Table',
      TableHeadingText: 'Heading',
      TableColText: 'Col',
      imageInsertLinkHeader: 'Insert Link',
      editImageHeader: 'Edit Image',
      alignmentsDropDownLeft: 'Align Left',
      alignmentsDropDownCenter: 'Align Center',
      alignmentsDropDownRight: 'Align Right',
      alignmentsDropDownJustify: 'Align Justify',
      imageDisplayDropDownInline: 'Inline',
      imageDisplayDropDownBreak: 'Break',
      tableInsertRowDropDownBefore: 'Insert row before',
      tableInsertRowDropDownAfter: 'Insert row after',
      tableInsertRowDropDownDelete: 'Delete row',
      tableInsertColumnDropDownLeft: 'Insert column left',
      tableInsertColumnDropDownRight: 'Insert column right',
      tableInsertColumnDropDownDelete: 'Delete column',
      tableVerticalAlignDropDownTop: 'Align Top',
      tableVerticalAlignDropDownMiddle: 'Align Middle',
      tableVerticalAlignDropDownBottom: 'Align Bottom',
      tableStylesDropDownDashedBorder: 'Dashed Borders',
      tableStylesDropDownAlternateRows: 'Alternate Rows',
      pasteFormat: 'Paste Format',
      pasteFormatContent: 'Choose the formatting action',
      plainText: 'Plain Text',
      cleanFormat: 'Clean',
      keepFormat: 'Keep',
      pasteDialogOk: 'OK',
      pasteDialogCancel: 'Cancel',
      fileManager: 'File Manager',
      fileDialogHeader: 'File Browser',
      formatsDropDownParagraph: 'Paragraph',
      formatsDropDownCode: 'Code',
      formatsDropDownQuotation: 'Quotation',
      formatsDropDownHeading1: 'Heading 1',
      formatsDropDownHeading2: 'Heading 2',
      formatsDropDownHeading3: 'Heading 3',
      formatsDropDownHeading4: 'Heading 4',
      fontNameSegoeUI: 'SegoeUI',
      fontNameArial: 'Arial',
      fontNameGeorgia: 'Georgia',
      fontNameImpact: 'Impact',
      fontNameTahoma: 'Tahoma',
      fontNameTimesNewRoman: 'Times New Roman',
      fontNameVerdana: 'Verdana',
      formatsOLListNumber: 'Number',
      formatsOLListLowerAlpha: 'LowerAlpha',
      formatsOLListUpperAlpha: 'UpperAlpha',
      formatsOLListLowerRoman: 'LowerRoman',
      formatsOLListUpperRoman: 'UpperRoman',
      formatsOLListLowerGreek: 'LowerGreek',
      formatsULListDisc: 'Disc',
      formatsULListCircle: 'Circle',
      formatsULListSquare: 'Square',
      formatsOLListNone: 'None',
      formatsULListNone: 'None',
    },
    colorpicker: {
      Apply: 'Apply',
      Cancel: 'Cancel',
      ModeSwitcher: 'Switch Mode',
    },
    uploader: {
      Browse: 'Browse...',
      Clear: 'Clear',
      Upload: 'Upload',
      dropFilesHint: 'Or drop files here',
      invalidMaxFileSize: 'File size is too large',
      invalidMinFileSize: 'File size is too small',
      invalidFileType: 'File type is not allowed',
      uploadFailedMessage: 'File failed to upload',
      uploadSuccessMessage: 'File uploaded successfully',
      removedSuccessMessage: 'File removed successfully',
      removedFailedMessage: 'Disable to remove file',
      inProgress: 'Uploading',
      readyToUploadMessage: 'Ready to upload',
      abort: 'Abort',
      remove: 'Remove',
      cancel: 'Cancel',
      delete: 'Delete file',
      pauseUpload: 'File upload paused',
      pause: 'Pause',
      resume: 'Resume',
      retry: 'Retry',
      fileUploadCancel: 'File upload canceled',
    },
    numerictextbox: {
      incrementTitle: 'Increment value',
      decrementTitle: 'Decrement value',
    },
    slider: {
      incrementTitle: 'Increase',
      decrementTitle: 'Decrease',
    },
    formValidator: {
      required: 'This field is required.',
      email: 'Please enter a valid email address.',
      url: 'Please enter a valid URL.',
      date: 'Please enter a valid date.',
      dateIso: 'Please enter a valid date ( ISO ).',
      creditcard: 'Please enter valid card number',
      number: 'Please enter a valid number.',
      digits: 'Please enter only digits.',
      maxLength: 'Please enter no more than {0} characters.',
      minLength: 'Please enter at least {0} characters.',
      rangeLength: 'Please enter a value between {0} and {1} characters long.',
      range: 'Please enter a value between {0} and {1}.',
      max: 'Please enter a value less than or equal to {0}.',
      min: 'Please enter a value greater than or equal to {0}.',
      regex: 'Please enter a correct value.',
      tel: 'Please enter a valid phone number.',
      pattern: 'Please enter a correct pattern value.',
      equalTo: 'Please enter the valid match text',
    },
    treegrid: {
      Above: 'Above',
      Below: 'Below',
      AddRow: 'Add Row',
      ExpandAll: 'Expand All',
      CollapseAll: 'Collapse All',
    },
    querybuilder: {
      StartsWith: 'Starts With',
      EndsWith: 'Ends With',
      Contains: 'Contains',
      Equal: 'Equal',
      NotEqual: 'Not Equal',
      LessThan: 'Less Than',
      LessThanOrEqual: 'Less Than Or Equal',
      GreaterThan: 'Greater Than',
      GreaterThanOrEqual: 'Greater Than Or Equal',
      Between: 'Between',
      NotBetween: 'Not Between',
      Empty: 'Empty',
      NotEmpty: 'Not Empty',
      In: 'In',
      NotIn: 'Not In',
      NotContains: 'Not Contains',
      Remove: 'REMOVE',
      SelectField: 'Select a field',
      SelectOperator: 'Select operator',
      DeleteRule: 'Remove this condition',
      DeleteGroup: 'Delete group',
      AddGroup: 'Add Group',
      AddCondition: 'Add Condition',
      Edit: 'EDIT',
      ValidationMessage: 'This field is required',
      SummaryViewTitle: 'Summary View',
      OtherFields: 'Other Fields',
      AND: 'AND',
      OR: 'OR',
      SelectValue: 'Enter Value',
      IsEmpty: 'Is Empty',
      IsNotEmpty: 'Is Not Empty',
      IsNull: 'Is Null',
      IsNotNull: 'Is Not Null',
      True: 'true',
      False: 'false',
    },
    barcode: {},
    datamatrix: {},
    qrcode: {},
    pivotview: {
      grandTotal: 'Grand Total',
      total: 'Total',
      value: 'Value',
      noValue: 'No value',
      row: 'Row',
      column: 'Column',
      collapse: 'Collapse',
      expand: 'Expand',
      rowAxisPrompt: 'Drop row here',
      columnAxisPrompt: 'Drop column here',
      valueAxisPrompt: 'Drop value here',
      filterAxisPrompt: 'Drop filter here',
      filter: 'Filter',
      filtered: 'Filtered',
      sort: 'Sort',
      filters: 'Filters',
      rows: 'Rows',
      columns: 'Columns',
      values: 'Values',
      close: 'Close',
      cancel: 'Cancel',
      delete: 'Delete',
      CalculatedField: 'Calculated Field',
      createCalculatedField: 'Create Calculated Field',
      fieldName: 'Enter the field name',
      error: 'Error',
      invalidFormula: 'Invalid formula.',
      dropText: "Example: ('Sum(Order_Count)' + 'Sum(In_Stock)') * 250",
      dropTextMobile: 'Add fields and edit formula here.',
      dropAction:
        'Calculated field cannot be place in any other region except value axis.',
      alert: 'Alert',
      warning: 'Warning',
      ok: 'OK',
      search: 'Search',
      drag: 'Drag',
      remove: 'Remove',
      allFields: 'All Fields',
      formula: 'Formula',
      addToRow: 'Add to Row',
      addToColumn: 'Add to Column',
      addToValue: 'Add to Value',
      addToFilter: 'Add to Filter',
      emptyData: 'No records to display',
      fieldExist:
        'A field already exists in this name. Please enter a different name.',
      confirmText:
        'A calculation field already exists in this name. Do you want to replace it?',
      noMatches: 'No matches',
      format: 'Summaries values by',
      edit: 'Edit',
      clear: 'Clear',
      formulaField: 'Drag and drop fields to formula',
      dragField: 'Drag field to formula',
      clearFilter: 'Clear',
      by: 'by',
      all: 'All',
      multipleItems: 'Multiple items',
      member: 'Member',
      label: 'Label',
      date: 'Date',
      enterValue: 'Enter value',
      chooseDate: 'Enter date',
      Before: 'Before',
      BeforeOrEqualTo: 'Before Or Equal To',
      After: 'After',
      AfterOrEqualTo: 'After Or Equal To',
      labelTextContent: 'Show the items for which the label',
      dateTextContent: 'Show the items for which the date',
      valueTextContent: 'Show the items for which',
      Equals: 'Equals',
      DoesNotEquals: 'Does Not Equal',
      BeginWith: 'Begins With',
      DoesNotBeginWith: 'Does Not Begin With',
      EndsWith: 'Ends With',
      DoesNotEndsWith: 'Does Not End With',
      Contains: 'Contains',
      DoesNotContains: 'Does Not Contain',
      GreaterThan: 'Greater Than',
      GreaterThanOrEqualTo: 'Greater Than Or Equal To',
      LessThan: 'Less Than',
      LessThanOrEqualTo: 'Less Than Or Equal To',
      Between: 'Between',
      NotBetween: 'Not Between',
      And: 'and',
      Sum: 'Sum',
      Count: 'Count',
      DistinctCount: 'Distinct Count',
      Product: 'Product',
      Avg: 'Avg',
      Min: 'Min',
      SampleVar: 'Sample Var',
      PopulationVar: 'Population Var',
      RunningTotals: 'Running Totals',
      Max: 'Max',
      Index: 'Index',
      SampleStDev: 'Sample StDev',
      PopulationStDev: 'Population StDev',
      PercentageOfRowTotal: '% of Row Total',
      PercentageOfParentTotal: '% of Parent Total',
      PercentageOfParentColumnTotal: '% of Parent Column Total',
      PercentageOfParentRowTotal: '% of Parent Row Total',
      DifferenceFrom: 'Difference From',
      PercentageOfDifferenceFrom: '% of Difference From',
      PercentageOfGrandTotal: '% of Grand Total',
      PercentageOfColumnTotal: '% of Column Total',
      NotEquals: 'Not Equals',
      AllValues: 'All Values',
      conditionalFormating: 'Conditional Formatting',
      apply: 'APPLY',
      condition: 'Add Condition',
      formatLabel: 'Format',
      valueFieldSettings: 'Value field settings',
      baseField: 'Base field :',
      baseItem: 'Base item :',
      summarizeValuesBy: 'Summarize values by :',
      sourceName: 'Field name :',
      sourceCaption: 'Field caption :',
      example: 'e.g:',
      editorDataLimitMsg: ' more items. Search to refine further.',
      details: 'Details',
      manageRecords: 'Manage Records',
      Years: 'Years',
      Quarters: 'Quarters',
      Months: 'Months',
      Days: 'Days',
      Hours: 'Hours',
      Minutes: 'Minutes',
      Seconds: 'Seconds',
      save: 'Save a report',
      new: 'Create a new report',
      load: 'Load',
      saveAs: 'Save as current report',
      rename: 'Rename a current report',
      deleteReport: 'Delete a current report',
      export: 'Export',
      subTotals: 'Sub totals',
      grandTotals: 'Grand totals',
      reportName: 'Report Name :',
      pdf: 'PDF',
      excel: 'Excel',
      csv: 'CSV',
      png: 'PNG',
      jpeg: 'JPEG',
      svg: 'SVG',
      mdxQuery: 'MDX Query',
      showSubTotals: 'Show sub totals',
      doNotShowSubTotals: 'Do not show sub totals',
      showSubTotalsRowsOnly: 'Show sub totals rows only',
      showSubTotalsColumnsOnly: 'Show sub totals columns only',
      showGrandTotals: 'Show grand totals',
      doNotShowGrandTotals: 'Do not show grand totals',
      showGrandTotalsRowsOnly: 'Show grand totals rows only',
      showGrandTotalsColumnsOnly: 'Show grand totals columns only',
      fieldList: 'Show fieldlist',
      grid: 'Show table',
      toolbarFormatting: 'Conditional formatting',
      chart: 'Chart',
      reportMsg: 'Please enter vaild report name!!!',
      reportList: 'Report list',
      removeConfirm: 'Are you sure want to delete this report?',
      emptyReport: 'No reports found!!',
      bar: 'Bar',
      line: 'Line',
      area: 'Area',
      scatter: 'Scatter',
      polar: 'Polar',
      of: 'of',
      emptyFormat: 'No format found!!!',
      emptyInput: 'Enter a value',
      newReportConfirm: 'Want to save changes to report?',
      emptyReportName: 'Enter a report name',
      qtr: 'Qtr',
      null: 'null',
      undefined: 'undefined',
      groupOutOfRange: 'Out of Range',
      fieldDropErrorAction:
        'The field you are moving cannot be placed in that area of the report',
      MoreOption: 'More...',
      aggregate: 'Aggregate',
      drillThrough: 'Drill Through',
      ascending: 'Ascending',
      descending: 'Descending',
      number: 'Number',
      currency: 'Currency',
      percentage: 'Percentage',
      formatType: 'Format Type',
      customText: 'Currency Symbol',
      symbolPosition: 'Symbol Position',
      left: 'Left',
      right: 'Right',
      grouping: 'Grouping',
      true: 'True',
      false: 'False',
      decimalPlaces: 'Decimal Places',
      numberFormat: 'Number Formatting',
      memberType: 'Field Type',
      formatString: 'Format String',
      expressionField: 'Expression',
      customFormat: 'Enter custom format string',
      selectedHierarchy: 'Parent Hierarchy',
      olapDropText:
        'Example: [Measures].[Order Quantity] + ([Measures].[Order Quantity] * 0.10)',
      Percent: 'Percent',
      Custom: 'Custom',
      Measure: 'Measure',
      Dimension: 'Dimension',
      Standard: 'Standard',
      blank: '(Blank)',
      fieldTooltip:
        "Drag and drop fields to create an expression. And, if you want to edit the existing the calculated fields! Then you can achieve it by simply selecting the field under 'Calculated Members'.",
      QuarterYear: 'Quarter Year',
      fieldTitle: 'Field Name',
      drillError: 'Cannot show the raw items of calculated fields.',
      caption: 'Field Caption',
      copy: 'Copy',
      defaultReport: 'Default report',
      customFormatString: 'Custom Format',
      invalidFormat: 'Invalid Format.',
      group: 'Group',
      unGroup: 'Ungroup',
      invalidSelection: 'Cannot group that selection.',
      groupName: 'Enter the caption to display in header',
      captionName: 'Enter the caption for group field',
      selectedItems: 'Selected items',
      groupFieldCaption: 'Field caption',
      groupTitle: 'Group name',
      startAt: 'Starting at',
      endAt: 'Ending at',
      groupBy: 'Interval by',
      selectGroup: 'Select groups',
      numberFormatString: 'Example: C, P, 0000 %, ###0.##0#, etc.',
      stackingcolumn: 'Stacked Column',
      stackingbar: 'Stacked Bar',
      stackingarea: 'Stacked Area',
      stepline: 'Step Line',
      steparea: 'Step Area',
      splinearea: 'Spline Area',
      spline: 'Spline',
      stackingcolumn100: '100% Stacked Column',
      stackingbar100: '100% Stacked Bar',
      stackingarea100: '100% Stacked Area',
      bubble: 'bubble',
      pareto: 'Pareto',
      radar: 'Radar',
      chartTypeSettings: 'Chart type settings',
      multipleAxes: 'Multiple Axes',
      sortAscending: 'Sort ascending order',
      sortDescending: 'Sort descending order',
      sortNone: 'Sort data order',
      clearCalculatedField: 'Clear edited field info',
      editCalculatedField: 'Edit calculated field',
      ChartType: 'Chart Type',
      yes: 'Yes',
      no: 'No',
      numberFormatMenu: 'Number Formatting...',
      conditionalFormatingMenu: 'Conditional Formatting...',
      removeCalculatedField:
        'Are you sure you want to delete this calculated field?',
      replaceConfirmBefore: 'A report named ',
      replaceConfirmAfter: ' already exists. Do you want to replace it?',
      pie: 'Pie',
      funnel: 'Funnel',
      doughnut: 'Doughnut',
      pyramid: 'Pyramid',
      showLegend: 'Show Legend',
      exit: 'Exit',
      invalidJSON: 'Invalid JSON data',
      invalidCSV: 'Invalid CSV data',
      stacked: 'Stacked',
      single: 'Single',
      multipleAxisMode: 'Multiple Axis Mode',
      grandTotalPosition: 'Grand totals position',
      top: 'Top',
      bottom: 'Bottom',
      None: 'None',
      stackingline: 'Stacked Line',
      stackingline100: '100% Stacked Line',
    },
    pivotfieldlist: {
      staticFieldList: 'Pivot Field List',
      fieldList: 'Field List',
      dropFilterPrompt: 'Drop filter here',
      dropColPrompt: 'Drop column here',
      dropRowPrompt: 'Drop row here',
      dropValPrompt: 'Drop value here',
      addPrompt: 'Add field here',
      adaptiveFieldHeader: 'Choose field',
      centerHeader: 'Drag fields between axes below:',
      add: 'add',
      drag: 'Drag',
      filter: 'Filter',
      filtered: 'Filtered',
      sort: 'Sort',
      remove: 'Remove',
      filters: 'Filters',
      rows: 'Rows',
      columns: 'Columns',
      values: 'Values',
      CalculatedField: 'Calculated Field',
      createCalculatedField: 'Create Calculated Field',
      fieldName: 'Enter the field name',
      error: 'Error',
      invalidFormula: 'Invalid formula.',
      dropText: "Example: ('Sum(Order_Count)' + 'Sum(In_Stock)'') * 250",
      dropTextMobile: 'Add fields and edit formula here.',
      dropAction:
        'Calculated field cannot be place in any other region except value axis.',
      search: 'Search',
      close: 'Close',
      cancel: 'Cancel',
      delete: 'Delete',
      alert: 'Alert',
      warning: 'Warning',
      ok: 'OK',
      allFields: 'All Fields',
      formula: 'Formula',
      fieldExist:
        'A field already exists in this name. Please enter a different name.',
      confirmText:
        'A calculation field already exists in this name. Do you want to replace it?',
      noMatches: 'No matches',
      format: 'Summaries values by',
      edit: 'Edit',
      clear: 'Clear',
      formulaField: 'Drag and drop fields to formula',
      dragField: 'Drag field to formula',
      clearFilter: 'Clear',
      by: 'by',
      enterValue: 'Enter value',
      chooseDate: 'Enter date',
      all: 'All',
      multipleItems: 'Multiple items',
      Equals: 'Equals',
      DoesNotEquals: 'Does Not Equal',
      BeginWith: 'Begins With',
      DoesNotBeginWith: 'Does Not Begin With',
      EndsWith: 'Ends With',
      DoesNotEndsWith: 'Does Not End With',
      Contains: 'Contains',
      DoesNotContains: 'Does Not Contain',
      GreaterThan: 'Greater Than',
      GreaterThanOrEqualTo: 'Greater Than Or Equal To',
      LessThan: 'Less Than',
      LessThanOrEqualTo: 'Less Than Or Equal To',
      Between: 'Between',
      NotBetween: 'Not Between',
      Before: 'Before',
      BeforeOrEqualTo: 'Before Or Equal To',
      After: 'After',
      AfterOrEqualTo: 'After Or Equal To',
      member: 'Member',
      label: 'Label',
      date: 'Date',
      value: 'Value',
      labelTextContent: 'Show the items for which the label',
      dateTextContent: 'Show the items for which the date',
      valueTextContent: 'Show the items for which',
      And: 'and',
      Sum: 'Sum',
      Count: 'Count',
      DistinctCount: 'Distinct Count',
      Product: 'Product',
      Avg: 'Avg',
      Min: 'Min',
      Max: 'Max',
      Index: 'Index',
      SampleStDev: 'Sample StDev',
      PopulationStDev: 'Population StDev',
      SampleVar: 'Sample Var',
      PopulationVar: 'Population Var',
      RunningTotals: 'Running Totals',
      DifferenceFrom: 'Difference From',
      PercentageOfDifferenceFrom: '% of Difference From',
      PercentageOfGrandTotal: '% of Grand Total',
      PercentageOfColumnTotal: '% of Column Total',
      PercentageOfRowTotal: '% of Row Total',
      PercentageOfParentTotal: '% of Parent Total',
      PercentageOfParentColumnTotal: '% of Parent Column Total',
      PercentageOfParentRowTotal: '% of Parent Row Total',
      Years: 'Years',
      Quarters: 'Quarters',
      Months: 'Months',
      Days: 'Days',
      Hours: 'Hours',
      Minutes: 'Minutes',
      Seconds: 'Seconds',
      apply: 'APPLY',
      valueFieldSettings: 'Value field settings',
      sourceName: 'Field name :',
      sourceCaption: 'Field caption :',
      summarizeValuesBy: 'Summarize values by :',
      baseField: 'Base field :',
      baseItem: 'Base item :',
      example: 'e.g:',
      editorDataLimitMsg: ' more items. Search to refine further.',
      deferLayoutUpdate: 'Defer Layout Update',
      null: 'null',
      undefined: 'undefined',
      groupOutOfRange: 'Out of Range',
      fieldDropErrorAction:
        'The field you are moving cannot be placed in that area of the report',
      MoreOption: 'More...',
      memberType: 'Field Type',
      selectedHierarchy: 'Parent Hierarchy',
      formatString: 'Format String',
      expressionField: 'Expression',
      olapDropText:
        'Example: [Measures].[Order Quantity] + ([Measures].[Order Quantity] * 0.10)',
      customFormat: 'Enter custom format string',
      Measure: 'Measure',
      Dimension: 'Dimension',
      Standard: 'Standard',
      Currency: 'Currency',
      Percent: 'Percent',
      Custom: 'Custom',
      blank: '(Blank)',
      fieldTooltip:
        "Drag and drop fields to create an expression. And, if you want to edit the existing the calculated fields! You can achieve it by simply selecting the field under 'Calculated Members'.",
      fieldTitle: 'Field Name',
      QuarterYear: 'Quarter Year',
      caption: 'Field Caption',
      copy: 'Copy',
      group: 'Group',
      numberFormatString: 'Example: C, P, 0000 %, ###0.##0#, etc.',
      sortAscending: 'Sort ascending order',
      sortDescending: 'Sort descending order',
      sortNone: 'Sort data order',
      clearCalculatedField: 'Clear edited field info',
      editCalculatedField: 'Edit calculated field',
      selectGroup: 'Select groups',
      of: 'of',
      removeCalculatedField:
        'Are you sure you want to delete this calculated field?',
      yes: 'Are you sure you want to delete this calculated field?',
      no: 'Yes',
      None: 'No',
    },
    pdfviewer: {
      PdfViewer: 'PDF Viewer',
      Cancel: 'Cancel',
      'Download file': 'Download file',
      Download: 'Download',
      'Enter Password':
        'This document is password protected. Please enter a password.',
      'File Corrupted': 'File Corrupted',
      'File Corrupted Content': 'The file is corrupted and cannot be opened.',
      'Fit Page': 'Fit Page',
      'Fit Width': 'Fit Width',
      Automatic: 'Automatic',
      'Go To First Page': 'Show first page',
      'Invalid Password': 'Incorrect Password. Please try again.',
      'Next Page': 'Show next page',
      OK: 'OK',
      Open: 'Open file',
      'Page Number': 'Current page number',
      'Previous Page': 'Show previous page',
      'Go To Last Page': 'Show last page',
      Zoom: 'Zoom',
      'Zoom In': 'Zoom in',
      'Zoom Out': 'Zoom out',
      'Page Thumbnails': 'Page thumbnails',
      Bookmarks: 'Bookmarks',
      Print: 'Print file',
      'Password Protected': 'Password Required',
      Copy: 'Copy',
      'Text Selection': 'Text selection tool',
      Panning: 'Pan mode',
      'Text Search': 'Find text',
      'Find in document': 'Find in document',
      'Match case': 'Match case',
      Apply: 'Apply',
      GoToPage: 'Go to Page',
      'No matches':
        'Viewer has finished searching the document. No more matches were found',
      'No Text Found': 'No Text Found',
      Undo: 'Undo',
      Redo: 'Redo',
      Annotation: 'Add or Edit annotations',
      Highlight: 'Highlight Text',
      Underline: 'Underline Text',
      Strikethrough: 'Strikethrough Text',
      Delete: 'Delete annotation',
      Opacity: 'Opacity',
      'Color edit': 'Change Color',
      'Opacity edit': 'Change Opacity',
      highlight: 'Change Opacity',
      underline: 'Highlight',
      strikethrough: 'Underline',
      'Server error':
        "Web-service is not listening. PDF Viewer depends on web-service for all it's features. Please start the web service to continue.",
      'Open text': 'Open',
      'First text': 'First Page',
      'Previous text': 'Previous Page',
      'Next text': 'Next Page',
      'Last text': 'Last Page',
      'Zoom in text': 'Zoom In',
      'Zoom out text': 'Zoom Out',
      'Selection text': 'Selection',
      'Pan text': 'Pan',
      'Print text': 'Print',
      'Seach text': 'Print',
      'Annotation Edit text': 'Edit Annotation',
      'Highlight context': 'Highlight',
      'Underline context': 'Underline',
      'Strikethrough context': 'Strike through',
      'Search text': 'Search',
      'Line Thickness': 'Line Thickness',
      'Line Properties': 'Line Properties',
      'Start Arrow': 'Start Arrow',
      'End Arrow': 'End Arrow',
      'Line Style': 'Line Style',
      'Fill Color': 'Fill Color',
      'Line Color': 'Line Color',
      None: 'None',
      'Open Arrow': 'Open',
      'Closed Arrow': 'Closed',
      'Round Arrow': 'Round',
      'Square Arrow': 'Square',
      'Diamond Arrow': 'Diamond',
      Cut: 'Cut',
      Paste: 'Paste',
      'Delete Context': 'Delete',
      Properties: 'Properties',
      'Add Stamp': 'Add Stamp',
      'Add Shapes': 'Add Shapes',
      'Stroke edit': 'Change Stroke Color',
      'Change thickness': 'Change Border Thickness',
      'Add line': 'Add Line',
      'Add arrow': 'Add Arrow',
      'Add rectangle': 'Add Rectangle',
      'Add circle': 'Add Circle',
      'Add polygon': 'Add Polygon',
      'Add Comments': 'Add Comments',
      Comments: 'Comments',
      'No Comments Yet': 'No Comments Yet',
      Accepted: 'Accepted',
      Completed: 'Completed',
      Cancelled: 'Cancelled',
      Rejected: 'Rejected',
      'Leader Length': 'Leader Length',
      'Scale Ratio': 'Scale Ratio',
      Calibrate: 'Calibrate',
      'Calibrate Distance': 'Calibrate Distance',
      'Calibrate Perimeter': 'Calibrate Perimeter',
      'Calibrate Area': 'Calibrate Area',
      'Calibrate Radius': 'Calibrate Radius',
      'Calibrate Volume': 'Calibrate Volume',
    },
    diagram: {
      Copy: 'Copy',
      Cut: 'Cut',
      Paste: 'Paste',
      Undo: 'Undo',
      Redo: 'Redo',
      SelectAll: 'Select All',
      Grouping: 'Grouping',
      Group: 'Group',
      UnGroup: 'Ungroup',
      Order: 'Order',
      BringToFront: 'Bring To Front',
      MoveForward: 'Move Forward',
      SendToBack: 'Send To Back',
      SendBackward: 'Send Backward',
    },
    'inplace-editor': {
      editIcon: 'Click to edit',
      save: 'Save',
      cancel: 'Cancel',
    },
    DocumentEditor: {
      Table: 'Table',
      Row: 'Row',
      Cell: 'Cell',
      Ok: 'Ok',
      Cancel: 'Cancel',
      Size: 'Size',
      'Preferred Width': 'Preferred width',
      Points: 'Points',
      Percent: 'Percent',
      'Measure in': 'Measure in',
      Alignment: 'Alignment',
      Left: 'Left',
      Center: 'Center',
      Right: 'Right',
      Justify: 'Justify',
      'Indent from left': 'Indent from left',
      'Borders and Shading': 'Borders and Shading',
      Options: 'Options',
      'Specify height': 'Specify height',
      'At least': 'At least',
      Exactly: 'Exactly',
      'Row height is': 'Row height is',
      'Allow row to break across pages': 'Allow row to break across pages',
      'Repeat as header row at the top of each page':
        'Repeat as header row at the top of each page',
      'Vertical alignment': 'Vertical alignment',
      Top: 'Top',
      Bottom: 'Bottom',
      'Default cell margins': 'Default cell margins',
      'Default cell spacing': 'Default cell spacing',
      'Allow spacing between cells': 'Allow spacing between cells',
      'Cell margins': 'Cell margins',
      'Same as the whole table': 'Same as the whole table',
      Borders: 'Borders',
      None: 'None',
      Single: 'None',
      Dot: 'Dot',
      DashSmallGap: 'DashSmallGap',
      DashLargeGap: 'DashLargeGap',
      DashDot: 'DashDot',
      DashDotDot: 'DashDotDot',
      Double: 'Double',
      Triple: 'Triple',
      ThinThickSmallGap: 'ThinThickSmallGap',
      ThickThinSmallGap: 'ThickThinSmallGap',
      ThinThickThinSmallGap: 'ThinThickThinSmallGap',
      ThinThickMediumGap: 'ThinThickMediumGap',
      ThickThinMediumGap: 'ThickThinMediumGap',
      ThinThickThinMediumGap: 'ThinThickThinMediumGap',
      ThinThickLargeGap: 'ThinThickLargeGap',
      ThickThinLargeGap: 'ThickThinLargeGap',
      ThinThickThinLargeGap: 'ThinThickThinLargeGap',
      SingleWavy: 'SingleWavy',
      DoubleWavy: 'DoubleWavy',
      DashDotStroked: 'DashDotStroked',
      Emboss3D: 'Emboss3D',
      Engrave3D: 'Engrave3D',
      Outset: 'Outset',
      Inset: 'Inset',
      Thick: 'Thick',
      Style: 'Style',
      Width: 'Width',
      Height: 'Height',
      Letter: 'Letter',
      Tabloid: 'Tabloid',
      Legal: 'Legal',
      Statement: 'Statement',
      Executive: 'Executive',
      A3: 'A3',
      A4: 'A4',
      A5: 'A5',
      B4: 'B4',
      B5: 'B5',
      'Custom Size': 'Custom size',
      'Different odd and even': 'Different odd and even',
      'Different first page': 'Different first page',
      'From edge': 'From edge',
      Header: 'Header',
      Footer: 'Footer',
      Margin: 'Margins',
      Paper: 'Paper',
      Layout: 'Layout',
      Orientation: 'Orientation',
      Landscape: 'Landscape',
      Portrait: 'Portrait',
      'Table Of Contents': 'Table Of Contents',
      'Show page numbers': 'Show page numbers',
      'Right align page numbers': 'Right align page numbers',
      Nothing: 'Nothing',
      'Tab leader': 'Tab leader',
      'Show levels': 'Show levels',
      'Use hyperlinks instead of page numbers':
        'Use hyperlinks instead of page numbers',
      'Build table of contents from': 'Build table of contents from',
      Styles: 'Styles',
      'Available styles': 'Available styles',
      'TOC level': 'TOC level',
      Heading: 'Heading',
      'List Paragraph': 'List Paragraph',
      Normal: 'Normal',
      'Outline levels': 'Outline levels',
      'Table entry fields': 'Table entry fields',
      Modify: 'Modify',
      Color: 'Color',
      Setting: 'Setting',
      Box: 'Box',
      All: 'All',
      Custom: 'Custom',
      Preview: 'Preview',
      Shading: 'Shading',
      Fill: 'Fill',
      'Apply To': 'Apply to',
      'Table Properties': 'Table Properties',
      'Cell Options': 'Cell Options',
      'Table Options': 'Table Options',
      'Insert Table': 'Insert Table',
      'Number of columns': 'Number of columns',
      'Number of rows': 'Number of rows',
      'Text to display': 'Text to display',
      Address: 'Address',
      'Insert Hyperlink': 'Insert Hyperlink',
      'Edit Hyperlink': 'Edit Hyperlink',
      Insert: 'Insert',
      General: 'General',
      Indentation: 'Indentation',
      'Before text': 'Before text',
      Special: 'Special',
      'First line': 'First line',
      Hanging: 'Hanging',
      'After text': 'After text',
      By: 'By',
      Before: 'Before',
      'Line Spacing': 'Line spacing',
      After: 'After',
      At: 'At',
      Multiple: 'Multiple',
      Spacing: 'Spacing',
      'Define new Multilevel list': 'Define new Multilevel list',
      'List level': 'List level',
      'Choose level to modify': 'Choose level to modify',
      Level: 'Level',
      'Number format': 'Number format',
      'Number style for this level': 'Number style for this level',
      'Enter formatting for number': 'Enter formatting for number',
      'Start at': 'Start at',
      'Restart list after': 'Restart list after',
      Position: 'Position',
      'Text indent at': 'Text indent at',
      'Aligned at': 'Aligned at',
      'Follow number with': 'Follow number with',
      'Tab character': 'Tab character',
      Space: 'Space',
      Arabic: 'Arabic',
      UpRoman: 'UpRoman',
      LowRoman: 'LowRoman',
      UpLetter: 'UpLetter',
      LowLetter: 'LowLetter',
      Number: 'Number',
      'Leading zero': 'Leading zero',
      Bullet: 'Bullet',
      Ordinal: 'Ordinal',
      'Ordinal Text': 'Ordinal Text',
      'For East': 'For East',
      'No Restart': 'No Restart',
      Font: 'Font',
      'Font style': 'Font style',
      'Underline style': 'Underline style',
      'Font color': 'Font color',
      Effects: 'Effects',
      Strikethrough: 'Strikethrough',
      Superscript: 'Superscript',
      Subscript: 'Subscript',
      'Double strikethrough': 'Double strikethrough',
      Regular: 'Regular',
      Bold: 'Bold',
      Italic: 'Italic',
      Cut: 'Cut',
      Copy: 'Copy',
      Paste: 'Paste',
      Hyperlink: 'Hyperlink',
      'Open Hyperlink': 'Open Hyperlink',
      'Copy Hyperlink': 'Copy Hyperlink',
      'Remove Hyperlink': 'Remove Hyperlink',
      Paragraph: 'Paragraph',
      'Merge Cells': 'Merge Cells',
      'Insert Above': 'Insert Above',
      'Insert Below': 'Insert Below',
      'Insert Left': 'Insert Left',
      'Insert Right': 'Insert Right',
      Delete: 'Delete',
      'Delete Table': 'Delete Table',
      'Delete Row': 'Delete Row',
      'Delete Column': 'Delete Column',
      'File Name': 'File Name',
      'Format Type': 'Format Type',
      Save: 'Save',
      Navigation: 'Navigation',
      Results: 'Results',
      Replace: 'Replace',
      'Replace All': 'Replace All',
      'We replaced all': 'We replaced all',
      Find: 'Find',
      'No matches': 'No matches',
      'All Done': 'All Done',
      Result: 'Result',
      of: 'of',
      instances: 'instances',
      with: 'with',
      'Click to follow link': 'Click to follow link',
      'Continue Numbering': 'Continue Numbering',
      'Bookmark name': 'Bookmark name',
      Close: 'Close',
      'Restart At': 'Restart At',
      Properties: 'Properties',
      Name: 'Name',
      'Style type': 'Style type',
      'Style based on': 'Style based on',
      'Style for following paragraph': 'Style for following paragraph',
      Formatting: 'Formatting',
      'Numbering and Bullets': 'Numbering and Bullets',
      Numbering: 'Numbering',
      'Update Field': 'Update Field',
      'Edit Field': 'Edit Field',
      Bookmark: 'Bookmark',
      'Page Setup': 'Page Setup',
      'No bookmarks found': 'No bookmarks found',
      'Number format tooltip information':
        'Single-level number format: </br>[PREFIX]%[LEVELNUMBER][SUFFIX]</br> For example, Chapter %1. will display numbering like</br> Chapter 1. Item</br>Chapter 2. Item</br>... </br>Chapter N. Item</br></br>Multilevel number format:</br>[PREFIX]%[LEVELNUMBER][SUFFIX]+[PREFIX]%[LEVELNUMBER][SUFFIX]</br>For example, %1.%2. will display numbering like</br>1.1. Item</br>1.2. Item</br>…</br>1.N. Item',
      Format: 'Format',
      'Create New Style': 'Create New Style',
      'Modify Style': 'Modify Style',
      New: 'New',
      Bullets: 'Bullets',
      'Use bookmarks': 'Use bookmarks',
      'Table of Contents': 'Table of Contents',
    },
    drawing: {},
    'rich-text-editor': {
      alignments: 'alignments',
      justifyleft: 'justifyLeft',
      justifycenter: 'justifyCenter',
      justifyright: 'justifyRight',
      justifyfull: 'justifyFull',
      fontname: 'fontName',
      fontsize: 'fontSize',
      fontcolor: 'fontColor',
      backgroundcolor: 'backgroundColor',
      clearformat: 'clearFormat',
      clearall: 'clearAll',
      unorderedlist: 'unorderedList',
      orderedlist: 'orderedList',
      createlink: 'createLink',
      openlink: 'openLink',
      editlink: 'editLink',
      removelink: 'removeLink',
      openimagelink: 'openLink',
      editimagelink: 'editLink',
      removeimagelink: 'removeLink',
      image: 'image',
      replace: 'replace',
      align: 'align',
      caption: 'caption',
      remove: 'remove',
      insertlink: 'insertLink',
      display: 'display',
      alttext: 'altText',
      dimension: 'dimension',
      fullscreen: 'fullscreen',
      maximize: 'maximize',
      minimize: 'minimize',
      lowercase: 'lowerCase',
      uppercase: 'upperCase',
      print: 'print',
      formats: 'formats',
      sourcecode: 'sourcecode',
      preview: 'preview',
      viewside: 'viewside',
      insertcode: 'insertCode',
      justifyLeft: 'Align Left',
      justifyCenter: 'Align Center',
      justifyRight: 'Align Right',
      justifyFull: 'Align Justify',
      fontName: 'Font Name',
      fontSize: 'Font Size',
      fontColor: 'Font Color',
      backgroundColor: 'Background Color',
      bold: 'bold',
      italic: 'italic',
      underline: 'underline',
      strikethrough: 'strikethrough',
      cut: 'cut',
      copy: 'copy',
      paste: 'paste',
      indent: 'indent',
      outdent: 'outdent',
      undo: 'undo',
      redo: 'redo',
      superscript: 'superscript',
      subscript: 'subscript',
      createLink: 'Insert Hyperlink',
      openLink: 'Open Link',
      editLink: 'Edit Link',
      removeLink: 'Remove Link',
      insertLink: 'Insert Link',
      altText: 'Alternative Text',
      lowerCase: 'Lower Case',
      upperCase: 'Upper Case',
      insertCode: 'Insert Code',
      linkText: 'Display Text',
      linkTooltipLabel: 'Tooltip',
      linkWebUrl: 'Web Address',
      linkurl: 'http://example.com',
      linkOpenInNewWindow: 'Open Link in New Window',
      linkHeader: 'Insert Link',
      dialogInsert: 'Insert',
      dialogCancel: 'Cancel',
      dialogUpdate: 'Update',
      imageHeader: 'Insert Image',
      imageLinkHeader: 'You can also provide a link from the web',
      mdimageLink: 'Please provide a URL for your image',
      imageUploadMessage: 'Drop image here or browse to upload',
      imageDeviceUploadMessage: 'Click here to upload',
      imageAlternateText: 'Alternate Text',
      alternateHeader: 'Alternative Text',
      browse: 'Browse',
      imageUrl: 'http://example.com/image.png',
      imageCaption: 'Caption',
      imageSizeHeader: 'Image Size',
      imageHeight: 'Height',
      imageWidth: 'Width',
      textPlaceholder: 'Enter Text',
      tblhead: '1X1',
      inserttablebtn: 'Insert Table',
      tabledialogHeader: 'Insert Table',
      tableWidth: 'Width',
      cellpadding: 'Cell Padding',
      cellspacing: 'Cell Spacing',
      columns: 'Number of columns',
      rows: 'Number of rows',
      tableRows: 'Table Rows',
      tableColumns: 'Table Columns',
      tableCellHorizontalAlign: 'Table Cell Horizontal Align',
      tableCellVerticalAlign: 'Table Cell Vertical Align',
      createTable: 'Create Table',
      removeTable: 'Remove Table',
      tableHeader: 'Table Header',
      tableRemove: 'Table Remove',
      tableCellBackground: 'Table Cell Background',
      tableEditProperties: 'Table Edit Properties',
      styles: 'Styles',
      insertColumnLeft: 'Insert Column Left',
      insertColumnRight: 'Insert Column Right',
      deleteColumn: 'Delete Column',
      insertRowBefore: 'Insert Row Before',
      insertRowAfter: 'Insert Row After',
      deleteRow: 'Delete Row',
      tablerows: 'tableRows',
      tablecolumns: 'tableColumns',
      tablecellhorizontalalign: 'tableCellHorizontalAlign',
      tablecellverticalalign: 'tableCellVerticalAlign',
      createtable: 'createTable',
      removetable: 'removeTable',
      tableheader: 'tableHeader',
      tableremove: 'tableRemove',
      tablecellbackground: 'tableCellBackground',
      tableeditproperties: 'tableEditProperties',
      insertcolumnleft: 'insertColumnLeft',
      insertcolumnright: 'insertColumnRight',
      deletecolumn: 'deleteColumn',
      aligntop: 'AlignTop',
      alignmiddle: 'AlignMiddle',
      alignbottom: 'AlignBottom',
      insertrowbefore: 'insertRowBefore',
      insertrowafter: 'insertRowAfter',
      deleterow: 'deleteRow',
    },
    'drop-down-base': {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
    },
    chart: {
      Zoom: 'Zoom',
      ZoomIn: 'ZoomIn',
      ZoomOut: 'ZoomOut',
      Reset: 'Reset',
      Pan: 'Pan',
      ResetZoom: 'Reset Zoom',
    },
    maps: {
      Zoom: 'Zoom',
      ZoomIn: 'ZoomIn',
      ZoomOut: 'ZoomOut',
      Reset: 'Reset',
      Pan: 'Pan',
      ResetZoom: 'Reset Zoom',
    },
    'drop-down-tree': {
      noRecordsTemplate: 'No records found',
      actionFailureTemplate: 'Request failed',
      overflowCountTemplate: '+${count} more..',
      totalCountTemplate: '${count} selected',
    },
    PdfViewer: {
      PdfViewer: 'PDF Viewer',
      Cancel: 'Cancel',
      'Download file': 'Download file',
      Download: 'Download',
      'Enter Password':
        'This document is password protected. Please enter a password.',
      'File Corrupted': 'File Corrupted',
      'File Corrupted Content': 'The file is corrupted and cannot be opened.',
      'Fit Page': 'Fit Page',
      'Fit Width': 'Fit Width',
      Automatic: 'Automatic',
      'Go To First Page': 'Show first page',
      'Invalid Password': 'Incorrect Password. Please try again.',
      'Next Page': 'Show next page',
      OK: 'OK',
      Open: 'Open file',
      'Page Number': 'Current page number',
      'Previous Page': 'Show previous page',
      'Go To Last Page': 'Show last page',
      Zoom: 'Zoom',
      'Zoom In': 'Zoom in',
      'Zoom Out': 'Zoom out',
      'Page Thumbnails': 'Page thumbnails',
      Bookmarks: 'Bookmarks',
      Print: 'Print file',
      'Password Protected': 'Password Required',
      Copy: 'Copy',
      'Text Selection': 'Text selection tool',
      Panning: 'Pan mode',
      'Text Search': 'Find text',
      'Find in document': 'Find in document',
      'Match case': 'Match case',
      Apply: 'Apply',
      GoToPage: 'Go to Page',
      'No matches':
        'Viewer has finished searching the document. No more matches were found',
      'No Text Found': 'No Text Found',
      Undo: 'Undo',
      Redo: 'Redo',
      Annotation: 'Add or Edit annotations',
      Highlight: 'Highlight Text',
      Underline: 'Underline Text',
      Strikethrough: 'Strikethrough Text',
      Delete: 'Delete annotation',
      Opacity: 'Opacity',
      'Color edit': 'Change Color',
      'Opacity edit': 'Change Opacity',
      'Highlight context': 'Highlight',
      'Underline context': 'Underline',
      'Strikethrough context': 'Strike through',
      'Server error':
        "Web-service is not listening. PDF Viewer depends on web-service for all it's features. Please start the web service to continue.",
      'Open text': 'Open',
      'First text': 'First Page',
      'Previous text': 'Previous Page',
      'Next text': 'Next Page',
      'Last text': 'Last Page',
      'Zoom in text': 'Zoom In',
      'Zoom out text': 'Zoom Out',
      'Selection text': 'Selection',
      'Pan text': 'Pan',
      'Print text': 'Print',
      'Search text': 'Search',
      'Annotation Edit text': 'Edit Annotation',
      'Line Thickness': 'Line Thickness',
      'Line Properties': 'Line Properties',
      'Start Arrow': 'Start Arrow',
      'End Arrow': 'End Arrow',
      'Line Style': 'Line Style',
      'Fill Color': 'Fill Color',
      'Line Color': 'Line Color',
      None: 'None',
      'Open Arrow': 'Open',
      'Closed Arrow': 'Closed',
      'Round Arrow': 'Round',
      'Square Arrow': 'Square',
      'Diamond Arrow': 'Diamond',
      Butt: 'Butt',
      Cut: 'Cut',
      Paste: 'Paste',
      'Delete Context': 'Delete',
      Properties: 'Properties',
      'Add Stamp': 'Add Stamp',
      'Add Shapes': 'Add Shapes',
      'Stroke edit': 'Change Stroke Color',
      'Change thickness': 'Change Border Thickness',
      'Add line': 'Add Line',
      'Add arrow': 'Add Arrow',
      'Add rectangle': 'Add Rectangle',
      'Add circle': 'Add Circle',
      'Add polygon': 'Add Polygon',
      'Add Comments': 'Add Comments',
      Comments: 'Comments',
      'No Comments Yet': 'No Comments Yet',
      Accepted: 'Accepted',
      Completed: 'Completed',
      Cancelled: 'Cancelled',
      Rejected: 'Rejected',
      'Leader Length': 'Leader Length',
      'Scale Ratio': 'Scale Ratio',
      Calibrate: 'Calibrate',
      'Calibrate Distance': 'Calibrate Distance',
      'Calibrate Perimeter': 'Calibrate Perimeter',
      'Calibrate Area': 'Calibrate Area',
      'Calibrate Radius': 'Calibrate Radius',
      'Calibrate Volume': 'Calibrate Volume',
      Depth: 'Depth',
      Closed: 'Closed',
      Round: 'Round',
      Square: 'Square',
      Diamond: 'Diamond',
      Edit: 'Edit',
      Comment: 'Comment',
      'Comment Panel': 'Comment Panel',
      'Set Status': 'Set Status',
      Post: 'Post',
      Page: 'Page',
      'Add a comment': 'Add a comment',
      'Add a reply': 'Add a reply',
      'Import Annotations': 'Import annotations from JSON file',
      'Export Annotations': 'Export annotation to JSON file',
      'Export XFDF': 'Export annotation to XFDF file',
      'Import XFDF': 'Import annotations from XFDF file',
      Add: 'Add',
      Clear: 'Clear',
      Bold: 'Bold',
      Italic: 'Italic',
      Strikethroughs: 'Strikethrough',
      Underlines: 'Underline',
      Superscript: 'Superscript',
      Subscript: 'Subscript',
      'Align left': 'Align Left',
      'Align right': 'Align Right',
      Center: 'Center',
      Justify: 'Justify',
      'Font color': 'Font Color',
      'Text Align': 'Text Align',
      'Text Properties': 'Font Style',
      SignatureFieldDialogHeaderText: 'Add Signature',
      HandwrittenSignatureDialogHeaderText: 'Add Signature',
      InitialFieldDialogHeaderText: 'Add Initial',
      HandwrittenInitialDialogHeaderText: 'Add Initial',
      'Draw Ink': 'Draw Ink',
      Create: 'Create',
      'Font family': 'Font Family',
      'Font size': 'Font Size',
      'Free Text': 'Free Text',
      'Import Failed':
        'Invalid JSON file type or file name; please select a valid JSON file',
      'Import PDF Failed':
        'Invalid PDF file type or PDF file does not exist. Please select a valid PDF file',
      'File not found':
        'Imported JSON file is not found in the desired location',
      'Export Failed':
        'Export annotations action has failed; please ensure annotations are added properly',
      of: 'of',
      Dynamic: 'Dynamic',
      'Standard Business': 'Standard Business',
      'Sign Here': 'Sign Here',
      'Custom Stamp': 'Custom Stamp',
      'Enter Signature as Name': 'Enter your name',
      'Draw-hand Signature': 'DRAW',
      'Type Signature': 'TYPE',
      'Upload Signature': 'UPLOAD',
      'Browse Signature Image': 'BROWSE',
      'Save Signature': 'Save Signature',
    },
    documenteditor: {
      Table: 'Table',
      Row: 'Row',
      Cell: 'Cell',
      Ok: 'Ok',
      Cancel: 'Cancel',
      Size: 'Size',
      'Preferred Width': 'Preferred width',
      Points: 'Points',
      Percent: 'Percent',
      'Measure in': 'Measure in',
      Alignment: 'Alignment',
      Left: 'Left',
      Center: 'Center',
      Right: 'Right',
      Justify: 'Justify',
      'Indent from left': 'Indent from left',
      'Borders and Shading': 'Borders and Shading',
      Options: 'Options',
      'Specify height': 'Specify height',
      'At least': 'At least',
      Exactly: 'Exactly',
      'Row height is': 'Row height is',
      'Allow row to break across pages': 'Allow row to break across pages',
      'Repeat as header row at the top of each page':
        'Repeat as header row at the top of each page',
      'Vertical alignment': 'Vertical alignment',
      Top: 'Top',
      Bottom: 'Bottom',
      'Default cell margins': 'Default cell margins',
      'Default cell spacing': 'Default cell spacing',
      'Allow spacing between cells': 'Allow spacing between cells',
      'Cell margins': 'Cell margins',
      'Same as the whole table': 'Same as the whole table',
      Borders: 'Borders',
      None: 'None',
      Style: 'Style',
      Width: 'Width',
      Height: 'Height',
      Letter: 'Letter',
      Tabloid: 'Tabloid',
      Legal: 'Legal',
      Statement: 'Statement',
      Executive: 'Executive',
      A3: 'A3',
      A4: 'A4',
      A5: 'A5',
      B4: 'B4',
      B5: 'B5',
      'Custom Size': 'Custom size',
      'Different odd and even': 'Different odd and even',
      'Different first page': 'Different first page',
      'From edge': 'From edge',
      Header: 'Header',
      Footer: 'Footer',
      'First Page Header': 'First Page Header',
      'First Page Footer': 'First Page Footer',
      'Even Page Header': 'Even Page Header',
      'Even Page Footer': 'Even Page Footer',
      'Odd Page Header': 'Odd Page Header',
      'Odd Page Footer': 'Odd Page Footer',
      Margin: 'Margins',
      Paper: 'Paper',
      Layout: 'Layout',
      Orientation: 'Orientation',
      Landscape: 'Landscape',
      Portrait: 'Portrait',
      'Show page numbers': 'Show page numbers',
      'Right align page numbers': 'Right align page numbers',
      Nothing: 'Nothing',
      'Tab leader': 'Tab leader',
      'Show levels': 'Show levels',
      'Use hyperlinks instead of page numbers':
        'Use hyperlinks instead of page numbers',
      'Build table of contents from': 'Build table of contents from',
      Styles: 'Styles',
      'Available styles': 'Available styles',
      'TOC level': 'TOC level',
      Heading: 'Heading',
      'Heading 1': 'Heading 1',
      'Heading 2': 'Heading 2',
      'Heading 3': 'Heading 3',
      'Heading 4': 'Heading 4',
      'Heading 5': 'Heading 5',
      'Heading 6': 'Heading 6',
      'List Paragraph': 'List Paragraph',
      Normal: 'Normal',
      'Outline levels': 'Outline levels',
      'Table entry fields': 'Table entry fields',
      Modify: 'Modify',
      Color: 'Color',
      Setting: 'Setting',
      Box: 'Box',
      All: 'All',
      Custom: 'Custom',
      Preview: 'Preview',
      Shading: 'Shading',
      Fill: 'Fill',
      'Apply To': 'Apply to',
      'Table Properties': 'Table Properties',
      'Cell Options': 'Cell Options',
      'Table Options': 'Table Options',
      'Insert Table': 'Insert Table',
      'Number of columns': 'Number of columns',
      'Number of rows': 'Number of rows',
      'Text to display': 'Text to display',
      'ScreenTip text': 'Text to display',
      Address: 'Address',
      'Insert Hyperlink': 'Insert Hyperlink',
      'Edit Hyperlink': 'Edit Hyperlink',
      Insert: 'Insert',
      General: 'General',
      Indentation: 'Indentation',
      'Before text': 'Before text',
      Special: 'Special',
      'First line': 'First line',
      Hanging: 'Hanging',
      'After text': 'After text',
      By: 'By',
      Before: 'Before',
      'Line Spacing': 'Line spacing',
      After: 'After',
      At: 'At',
      Multiple: 'Multiple',
      Spacing: 'Spacing',
      'Define new Multilevel list': 'Define new Multilevel list',
      'List level': 'List level',
      'Choose level to modify': 'Choose level to modify',
      Level: 'Level',
      'Number format': 'Number format',
      'Number style for this level': 'Number style for this level',
      'Enter formatting for number': 'Enter formatting for number',
      'Start at': 'Start at',
      'Restart list after': 'Restart list after',
      Position: 'Position',
      'Text indent at': 'Text indent at',
      'Aligned at': 'Aligned at',
      'Follow number with': 'Follow number with',
      'Tab character': 'Tab character',
      Space: 'Space',
      Arabic: 'Arabic',
      UpRoman: 'UpRoman',
      LowRoman: 'LowRoman',
      UpLetter: 'UpLetter',
      LowLetter: 'LowLetter',
      Number: 'Number',
      'Leading zero': 'Leading zero',
      Bullet: 'Bullet',
      Ordinal: 'Ordinal',
      'Ordinal Text': 'Ordinal Text',
      'For East': 'For East',
      'No Restart': 'No Restart',
      Font: 'Font',
      'Font style': 'Font style',
      'Underline style': 'Underline style',
      'Font color': 'Font color',
      Effects: 'Effects',
      Strikethrough: 'Strikethrough',
      Superscript: 'Superscript',
      Subscript: 'Subscript',
      'Double strikethrough': 'Double strikethrough',
      Regular: 'Regular',
      Bold: 'Bold',
      Italic: 'Italic',
      Cut: 'Cut',
      Copy: 'Copy',
      Paste: 'Paste',
      Hyperlink: 'Hyperlink',
      'Open Hyperlink': 'Open Hyperlink',
      'Copy Hyperlink': 'Copy Hyperlink',
      'Remove Hyperlink': 'Remove Hyperlink',
      Paragraph: 'Paragraph',
      'Linked Style': 'Linked(Paragraph and Character)',
      Character: 'Character',
      'Merge Cells': 'Merge Cells',
      'Insert Above': 'Insert Above',
      'Insert Below': 'Insert Below',
      'Insert Left': 'Insert Left',
      'Insert Right': 'Insert Right',
      Delete: 'Delete',
      'Delete Table': 'Delete Table',
      'Delete Row': 'Delete Row',
      'Delete Column': 'Delete Column',
      'File Name': 'File Name',
      'Format Type': 'Format Type',
      Save: 'Save',
      Navigation: 'Navigation',
      Results: 'Results',
      Replace: 'Replace',
      'Replace All': 'Replace All',
      'We replaced all': 'We replaced all',
      Find: 'Find',
      'No matches': 'No matches',
      'All Done': 'All Done',
      Result: 'Result',
      of: 'of',
      instances: 'instances',
      with: 'with',
      'Click to follow link': 'Click to follow link',
      'Continue Numbering': 'Continue Numbering',
      'Bookmark name': 'Bookmark name',
      Close: 'Close',
      'Restart At': 'Restart At',
      Properties: 'Properties',
      Name: 'Name',
      'Style type': 'Style type',
      'Style based on': 'Style based on',
      'Style for following paragraph': 'Style for following paragraph',
      Formatting: 'Formatting',
      'Numbering and Bullets': 'Numbering and Bullets',
      Numbering: 'Numbering',
      'Update Field': 'Update Field',
      'Edit Field': 'Edit Field',
      Bookmark: 'Bookmark',
      'Page Setup': 'Page Setup',
      'No bookmarks found': 'No bookmarks found',
      'Number format tooltip information':
        "Single-level number format: </br>[PREFIX]%[LEVELNUMBER][SUFFIX]</br> For example, 'Chapter %1.' will display numbering like</br>Chapter 1. Item</br>Chapter 2. Item</br>… </br>Chapter N. Item</br> </br>Multilevel number format:</br>[PREFIX]%[LEVELNUMBER][SUFFIX]+[PREFIX]%[LEVELNUMBER][SUFFIX]</br>For example, '%1.%2.' will display numbering like</br>1.1. Item</br>1.2. Item</br>…</br>1.N. Item",
      Format: 'Format',
      'Create New Style': 'Create New Style',
      'Modify Style': 'Modify Style',
      New: 'New',
      Bullets: 'Bullets',
      'Use bookmarks': 'Use bookmarks',
      'Table of Contents': 'Table of Contents',
      AutoFit: 'AutoFit',
      'AutoFit to Contents': 'AutoFit to Contents',
      'AutoFit to Window': 'AutoFit to Window',
      'Fixed Column Width': 'Fixed Column Width',
      Reset: 'Reset',
      'Match case': 'Match case',
      'Whole words': 'Whole words',
      Add: 'Add',
      'Go To': 'Go To',
      'Search for': 'Search for',
      'Replace with': 'Replace with',
      'TOC 1': 'TOC 1',
      'TOC 2': 'TOC 2',
      'TOC 3': 'TOC 3',
      'TOC 4': 'TOC 4',
      'TOC 5': 'TOC 5',
      'TOC 6': 'TOC 6',
      'TOC 7': 'TOC 7',
      'TOC 8': 'TOC 8',
      'TOC 9': 'TOC 9',
      'Right-to-left': 'Right-to-left',
      'Left-to-right': 'Left-to-right',
      Direction: 'Direction',
      'Table direction': 'Table direction',
      'Indent from right': 'Indent from right',
      'Contextual Spacing':
        "Don't add space between the paragraphs of the same styles",
      'Password Mismatch': "The password don't match",
      'Restrict Editing': 'Restrict Editing',
      'Formatting restrictions': 'Formatting restrictions',
      'Allow formatting': 'Allow formatting',
      'Editing restrictions': 'Editing restrictions',
      'Read only': 'Read only',
      'Exceptions Optional': 'Exceptions (optional)',
      'Select Part Of Document And User':
        'Select parts of the document and choose users who are allowed to freely edit them.',
      Everyone: 'Everyone',
      'More users': 'More users',
      'Add Users': 'Add Users',
      'Enforcing Protection': 'Yes, Start Enforcing Protection',
      'Start Enforcing Protection': 'Start Enforcing Protection',
      'Enter User': 'Enter User',
      Users: 'Users',
      'Enter new password': 'Enter new password',
      'Reenter new password to confirm': 'Reenter new password to confirm',
      'Your permissions': 'Your permissions',
      'Protected Document':
        'This document is protected from unintentional editing.You may edit in this region.',
      FormFieldsOnly: 'You may only fill in forms in this region.',
      CommentsOnly: 'You may only insert comments into this region.',
      ReadOnlyProtection: 'You may edit in this region.',
      'Stop Protection': 'Stop Protection',
      Password: 'Password',
      'Spelling Editor': 'Spelling Editor',
      Spelling: 'Spelling',
      'Spell Check': 'Spell Check',
      'Underline errors': 'Underline errors',
      Ignore: 'Ignore',
      'Ignore All': 'Ignore All',
      'Add to Dictionary': 'Add to Dictionary',
      Change: 'Change',
      'Change All': 'Change All',
      Suggestions: 'Suggestions',
      'The password is incorrect': 'The password is incorrect',
      'Error in establishing connection with web server':
        'Error in establishing connection with web server',
      'Highlight the regions I can edit': 'Highlight the regions I can edit',
      'Show All Regions I Can Edit': 'Show All Regions I Can Edit',
      'Find Next Region I Can Edit': 'Find Next Region I Can Edit',
      'Keep source formatting': 'Keep source formatting',
      'Match destination formatting': 'Match destination formatting',
      'Text only': 'Text only',
      Comments: 'Comments',
      'Type your comment': 'Type your comment',
      Post: 'Post',
      Reply: 'Reply',
      'New Comment': 'New Comment',
      Edit: 'Edit',
      Resolve: 'Resolve',
      Reopen: 'Reopen',
      'No comments in this document': 'No comments in this document',
      more: 'more',
      'Type your comment here': 'Type your comment here',
      'Next Comment': 'Next Comment',
      'Previous Comment': 'Previous Comment',
      'Un-posted comments': 'Un-posted comments',
      'Discard Comment':
        'Added comments not posted. If you continue, that comment will be discarded.',
      'No Headings': 'No Heading Found!',
      'Add Headings':
        'This document has no headings. Please add headings and try again.',
      'More Options': 'More Options',
      'Click to see this comment': 'Click to see this comment',
      'Drop Down Form Field': 'Drop Down Form Field',
      'Drop-down items': 'Drop-down items',
      'Items in drop-down list': 'Items in drop-down list',
      ADD: 'ADD',
      REMOVE: 'REMOVE',
      'Field settings': 'Field settings',
      Tooltip: 'Tooltip',
      'Drop-down enabled': 'Drop-down enabled',
      'Check Box Form Field': 'Check Box Form Field',
      'Check box size': 'Check box size',
      Auto: 'Auto',
      'Default value': 'Default value',
      'Not checked': 'Not checked',
      Checked: 'Checked',
      'Check box enabled': 'Check box enabled',
      'Text Form Field': 'Text Form Field',
      Type: 'Type',
      'Default text': 'Default text',
      'Maximum length': 'Maximum length',
      'Text format': 'Text format',
      'Fill-in enabled': 'Fill-in enabled',
      'Default number': 'Default number',
      'Default date': 'Default date',
      'Date format': 'Date format',
      'Merge Track':
        'This action wont be marked as change. Do you want to continue?',
      UnTrack: 'Cannot be tracked',
      Accept: 'Accept',
      Reject: 'Reject',
      'Previous Changes': 'Previous Changes',
      'Next Changes': 'Next Changes',
      Inserted: 'Inserted',
      Deleted: 'Deleted',
      Changes: 'Changes',
      'Accept all': 'Accept all',
      'Reject all': 'Reject all',
      'No changes': 'No changes',
      'Accept Changes': 'Accept Changes',
      'Reject Changes': 'Reject Changes',
      User: 'User',
      View: 'View',
      Insertion: 'Insertion',
      Deletion: 'Deletion',
      'All caps': 'All caps',
      'This region is locked by': 'This region is locked by',
      Lock: 'Lock',
      Unlock: 'Unlock',
      'Already locked':
        'Selected or part of region is already locked by another user',
      '1, 2, 3, ...': '1, 2, 3, ...',
      'a, b, c, ...': 'a, b, c, ...',
      'A, B, C, ...': 'A, B, C, ...',
      'I, II, III, ...': 'I, II, III, ...',
      'i, ii, iii, ...': 'i, ii, iii, ...',
      'Text Form': 'Text Form',
      'Check Box': 'Check Box',
      DropDown: 'Drop-Down',
      'Form Fields': 'Form Fields',
      'Fillin enabled': 'Fill-in enabled',
      'Multiple Comment': 'Please post your comment',
      InsertFootnote: 'InsertFootnote',
      InsertEndnote: 'InsertEndnote',
      Footnote: 'Footnote',
      Endnote: 'Endnote',
      'Click to View/Edit Footnote': 'Click to View/Edit Footnote',
      'Click to View/Edit Endnote': 'Click to View/Edit Endnote',
      InsertAsRows: 'Insert as New Rows',
      InsertAsColumns: 'Insert as New Columns',
      OverwriteCells: 'Overwrite Cells',
      NestTable: 'Nest Table',
      'No suggestions': 'No suggestions',
      'More Suggestion': 'More Suggestion',
      'Ignore Once': 'Ignore Once',
      'Keep With Next': 'Keep with next',
      'Keep Lines Together': 'Keep lines together',
      WidowControl: 'Widow/Orphan control',
      'Indents and Spacing': 'Indents and Spacing',
      'Line and Page Breaks': 'Line and Page Breaks',
      Pagination: 'Pagination',
      Single: 'Single',
      DashSmallGap: 'DashSmallGap',
      DashDot: 'DashDot',
      Double: 'Double',
      ThinThickSmallGap: 'ThinThickSmallGap',
      ThickThinSmallGap: 'ThickThinSmallGap',
      ThickThinMediumGap: 'ThickThinMediumGap',
      ThickThinLargeGap: 'ThickThinLargeGap',
      SingleWavy: 'SingleWavy',
      DoubleWavy: 'DoubleWavy',
      Inset: 'Inset',
      DashLargeGap: 'DashLargeGap',
      Dot: 'Dot',
      DashDotDot: 'DashDotDot',
      Triple: 'Triple',
      ThinThickThinSmallGap: 'ThinThickThinSmallGap',
      ThinThickThinMediumGap: 'ThinThickThinMediumGap',
      ThinThickThinLargeGap: 'ThinThickThinLargeGap',
      DashDotStroked: 'DashDotStroked',
      Engrave3D: 'Engrave3D',
      Thick: 'Thick',
      Outset: 'Outset',
      Emboss3D: 'Emboss3D',
      ThinThickLargeGap: 'ThinThickLargeGap',
      ThinThickMediumGap: 'ThinThickMediumGap',
      'Number of rows must be between 1 and 32767.':
        'Number of rows must be between 1 and 32767.',
      'Number of columns must be between 1 and 63.':
        'Number of columns must be between 1 and 63.',
      Unlimited: 'Unlimited',
      'Regular text': 'Regular text',
      Date: 'Date',
      Uppercase: 'Uppercase',
      Lowercase: 'Lowercase',
      FirstCapital: 'FirstCapital',
      TitleCase: 'Titlecase',
      'Filling in forms': 'Filling in forms',
      px: 'px',
      'Tracked changes': 'Tracked changes',
      TrackChangesOnly:
        'You may edit in this region, but all change will be tracked.',
      RemovedIgnore: 'Do you want to remove the ignored exceptions?',
      RemovedIgnoreExceptions:
        'If you make this change in document protection, Word will ignore all the exceptions in this document.',
      Information: 'Information',
      Yes: 'Yes',
      No: 'No',
      'You may format text only with certain styles':
        'You may format text only with certain styles.',
      'Ignore all': 'Ignore All',
    },
    documenteditorcontainer: {
      New: 'New',
      Open: 'Open',
      Undo: 'Undo',
      Redo: 'Redo',
      Image: 'Image',
      Table: 'Table',
      Link: 'Link',
      Bookmark: 'Bookmark',
      'Table of Contents': 'Table of Contents',
      'HEADING - - - - 1': 'HEADING - - - - 1',
      'HEADING - - - - 2': 'HEADING - - - - 2',
      'HEADING - - - - 3': 'HEADING - - - - 3',
      Header: 'Header',
      Footer: 'Footer',
      'Page Setup': 'Page Setup',
      'Page Number': 'Page Number',
      Break: 'Break',
      Find: 'Find',
      'Local Clipboard': 'Local Clipboard',
      'Restrict Editing': 'Restrict Editing',
      'Upload from computer': 'Upload from computer',
      'By URL': 'By URL',
      'Page Break': 'Page Break',
      'Section Break': 'Section Break',
      'Header And Footer': 'Header & Footer',
      Options: 'Options',
      Levels: 'Levels',
      'Different First Page': 'Different First Page',
      'Different header and footer for odd and even pages':
        'Different header and footer for odd and even pages.',
      'Different Odd And Even Pages': 'Different Odd & Even Pages',
      'Different header and footer for first page':
        'Different header and footer for first page.',
      Position: 'Position',
      'Header from Top': 'Header from Top',
      'Footer from Bottom': 'Footer from Bottom',
      'Distance from top of the page to top of the header':
        'Distance from top of the page to top of the header.',
      'Distance from bottom of the page to bottom of the footer':
        'Distance from bottom of the page to bottom of the footer.',
      'Aspect ratio': 'Aspect ratio',
      W: 'W',
      H: 'H',
      Width: 'Width',
      Height: 'Height',
      Text: 'Text',
      Paragraph: 'Paragraph',
      Fill: 'Fill',
      'Fill color': 'Fill color',
      'Border Style': 'Border Style',
      'Outside borders': 'Outside borders',
      'All borders': 'All borders',
      'Inside borders': 'Inside borders',
      'Left border': 'Left border',
      'Inside vertical border': 'Inside vertical border',
      'Right border': 'Right border',
      'Top border': 'Top border',
      'Inside horizontal border': 'Inside horizontal border',
      'Bottom border': 'Bottom border',
      'Border color': 'Border color',
      'Border width': 'Border width',
      Cell: 'Cell',
      'Merge cells': 'Merge cells',
      'Insert Or Delete': 'Insert / Delete',
      'Insert columns to the left': 'Insert columns to the left',
      'Insert columns to the right': 'Insert columns to the right',
      'Insert rows above': 'Insert rows above',
      'Insert rows below': 'Insert rows below',
      'Delete rows': 'Delete rows',
      'Delete columns': 'Delete columns',
      'Cell Margin': 'Cell Margin',
      Top: 'Top',
      Bottom: 'Bottom',
      Left: 'Left',
      Right: 'Right',
      'Align Text': 'Align Text',
      'Align top': 'Align top',
      'Align bottom': 'Align bottom',
      'Align center': 'Align center',
      'Number of heading or outline levels to be shown in table of contents':
        'Number of heading or outline levels to be shown in table of contents.',
      'Show page numbers': 'Show page numbers',
      'Show page numbers in table of contents':
        'Show page numbers in table of contents.',
      'Right align page numbers': 'Right align page numbers',
      'Right align page numbers in table of contents':
        'Right align page numbers in table of contents.',
      'Use hyperlinks': 'Use hyperlinks',
      'Use hyperlinks instead of page numbers':
        'Use hyperlinks instead of page numbers.',
      Font: 'Font',
      'Font Size': 'Font Size',
      'Font color': 'Font color',
      'Text highlight color': 'Text highlight color',
      'Clear all formatting': 'Clear all formatting',
      'Bold Tooltip': 'Bold (Ctrl+B)',
      'Italic Tooltip': 'Italic (Ctrl+I)',
      'Underline Tooltip': 'Underline (Ctrl+U)',
      Strikethrough: 'Strikethrough',
      'Superscript Tooltip': 'Superscript (Ctrl+Shift++)',
      'Subscript Tooltip': 'Subscript (Ctrl+=)',
      'Align left Tooltip': 'Align left (Ctrl+L)',
      'Center Tooltip': 'Center (Ctrl+E)',
      'Align right Tooltip': 'Align right (Ctrl+R)',
      'Justify Tooltip': 'Justify (Ctrl+J)',
      'Decrease indent': 'Decrease indent',
      'Increase indent': 'Increase indent',
      'Line spacing': 'Line spacing',
      Bullets: 'Bullets',
      Numbering: 'Numbering',
      Styles: 'Styles',
      'Manage Styles': 'Manage Styles',
      Page: 'Page',
      of: 'of',
      'Fit one page': 'Fit one page',
      'Spell Check': 'Spell Check',
      'Underline errors': 'Underline errors',
      'Fit page width': 'Fit page width',
      Update: 'Update',
      Cancel: 'Cancel',
      Insert: 'Insert',
      'No Border': 'No Border',
      'Create a new document': 'Create a new document.',
      'Open a document': 'Open a document.',
      'Undo Tooltip': 'Undo the last operation (Ctrl+Z).',
      'Redo Tooltip': 'Redo the last operation (Ctrl+Y).',
      'Insert inline picture from a file': 'Insert inline picture from a file.',
      'Insert a table into the document': 'Insert a table into the document',
      'Create Hyperlink':
        'Create a link in your document for quick access to web pages and files (Ctrl+K).',
      'Insert a bookmark in a specific place in this document':
        'Insert a bookmark in a specific place in this document.',
      'Provide an overview of your document by adding a table of contents':
        'Provide an overview of your document by adding a table of contents.',
      'Add or edit the header': 'Add or edit the header.',
      'Add or edit the footer': 'Add or edit the footer.',
      'Open the page setup dialog': 'Open the page setup dialog.',
      'Add page numbers': 'Add page numbers.',
      'Find Text': 'Find text in the document (Ctrl+F).',
      'Toggle between the internal clipboard and system clipboard':
        'Toggle between the internal clipboard and system clipboard.</br>Access to system clipboard through script is denied due to browsers security policy. Instead, </br> 1. You can enable internal clipboard to cut, copy and paste within the component.</br> 2. You can use the keyboard shortcuts (Ctrl+X, Ctrl+C and Ctrl+V) to cut, copy and paste with system clipboard.',
      'Current Page Number':
        'The current page number in the document. Click or tap to navigate specific page.',
      'Read only': 'Read only',
      Protections: 'Protections',
      'Error in establishing connection with web server':
        'Error in establishing connection with web server',
      Single: 'Single',
      Double: 'Double',
      'New comment': 'New comment',
      Comments: 'Comments',
      'Print layout': 'Print layout',
      'Web layout': 'Web layout',
      'Text Form': 'Text Form',
      'Check Box': 'Check Box',
      DropDown: 'Drop-Down',
      'Update Fields': 'Update Fields',
      'Update cross reference fields': 'Update cross reference fields',
      'Hide properties pane': 'Hide properties pane',
      'Show properties pane': 'Show properties pane',
      'Form Fields': 'Form Fields',
      'Track Changes': 'Keep track of the changes made in the document',
      TrackChanges: 'Track Changes',
      AllCaps: 'AllCaps',
      'Change case Tooltip': 'Change case',
      'Insert Footnote': 'Insert Footnote',
      'Insert Endnote': 'Insert Endnote',
      'Footnote Tooltip': 'Insert Footnote (Alt+Ctrl+F).',
      'Endnote Tooltip': 'Insert Endnote (Alt+Ctrl+D).',
      UPPERCASE: 'UPPERCASE',
      'No color': 'No color',
      'Top margin': 'Top margin',
      'Bottom margin': 'Bottom margin',
      'Left margin': 'Left margin',
      'Right margin': 'Right margin',
      Normal: 'Normal',
      Heading: 'Heading',
      'Heading 1': 'Heading 1',
      'Heading 2': 'Heading 2',
      'Heading 3': 'Heading 3',
      'Heading 4': 'Heading 4',
      'Heading 5': 'Heading 5',
      'Heading 6': 'Heading 6',
      ZoomLevelTooltip: 'Zoom level. Click or tap to open the Zoom options.',
      None: 'None',
      Borders: 'Borders',
    },
    carousel: {
      nextSlide: 'Next slide',
      of: 'of',
      pauseSlideTransition: 'Pause slide transition',
      playSlideTransition: 'Play slide transition',
      previousSlide: 'Previous slide',
      slide: 'Slide',
      slideShow: 'Slide Show',
    },
  },
};
