import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { BrowserAuthError, SilentRequest } from '@azure/msal-browser';
import {
  ConfigService,
  PolicyService,
  ConfigHubspotService,
} from '@horizon/core';
import { UrlBuilder } from 'http-url-builder';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService implements OnDestroy {
  private readonly destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private policyService: PolicyService,
    private configService: ConfigService,
    private configHubspotService: ConfigHubspotService
  ) {
    this.policyService.setAppEnvironment({
      policyServiceUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.policyService)
        .build(),
    });
    this.configService.setAppClientPortalEnvironment({
      clientPortalServiceUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.tableauApi)
        .build(),
    });
    this.configService.setAppEnvironment({
      configServiceUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.tableauApi)
        .addPath(environment.apim.apiSlugs.configurationApi)
        .build(),
    });
    this.configService.setAppSynchronizeEnvironment({
      synchronizeServiceUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.tableauApi)
        .addPath(environment.apim.apiSlugs.synchronizeApi)
        .build(),
    });
    this.configHubspotService.setAppEnvironment({
      configHubspotServiceUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.tableauApi)
        .addPath(environment.apim.apiSlugs.configurationHubspotApi)
        .build(),
    });
  }

  public checkAuth(): void {
    const authRequest: SilentRequest = {
      ...this.msalGuardConfig.authRequest,
      scopes: environment.authScopes,
    };
    this.authService
      .acquireTokenSilent(authRequest)
      .pipe(takeUntil(this.destroying$))
      .subscribe(
        () => this.setCurrentUserId.bind(this),
        (err) => {
          const noAccountError =
            err instanceof BrowserAuthError &&
            err.errorCode === 'no_account_error';
          if (!noAccountError) {
            console.error(err);
          }
          this.setCurrentUserId();
        }
      );
  }

  // login(): void {
  //   if (this.msalGuardConfig.authRequest) {
  //     this.authService
  //       .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
  //       .subscribe(
  //         () => {
  //           this.setCurrentUserId();
  //         },
  //         (error) => console.log(error)
  //       );
  //   } else {
  //     this.authService.loginPopup().subscribe(
  //       () => this.setCurrentUserId(),
  //       (error) => console.log(error)
  //     );
  //   }
  // }

  logout(): void {
    // Add log out function here
    this.authService
      .logoutPopup({
        mainWindowRedirectUri: '/',
      })
      .subscribe(() => {
        localStorage.clear();
        sessionStorage.clear();
      });
  }

  setCurrentUserId(): void {
    const allAccounts = this.authService?.instance?.getAllAccounts();
    const localAccountId = allAccounts[0]?.localAccountId;
    const loginDisplay = allAccounts?.length > 0;

    if (loginDisplay && localAccountId) {
      localStorage.setItem('currentUserId', JSON.stringify(localAccountId));
      this.policyService.getCurrentUserPermissions();
    }
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
