import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppSectionsPermissionsKey } from '@shared/enums/permissions.enums';
import { PermissionAccessType } from '@horizon/core';
import { RoutePaths } from '@shared/enums/routes.enums';

@Injectable({
  providedIn: 'root',
})
export class ReadPermissionsGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = route?.routeConfig?.path
      ? route?.routeConfig?.path
      : state?.url?.replace(/^\//g, '');

    return isItemAllowed(routeToUrl(url));
  }
}

function isItemAllowed(route: string): boolean {
  let permissions = localStorage.getItem('currentUserPermissions')
    ? JSON.parse(localStorage.getItem('currentUserPermissions'))
    : undefined;
  return (
    route &&
    permissions &&
    !!permissions.filter(
      (perm) =>
        perm.permissionName?.toLowerCase() === route?.toLowerCase() &&
        perm.permissionAccessType === PermissionAccessType.READ
    )[0]
  );
}

function routeToUrl(route: string) {
  if (route !== undefined) {
    const PATH_VALUES: Array<string> = Object.values(RoutePaths);
    const PATH_KEYS: Array<string> = Object.keys(RoutePaths);
    const key = PATH_KEYS[PATH_VALUES.indexOf(route)];
    route = AppSectionsPermissionsKey[key];
    return route;
  }
  return route;
}
