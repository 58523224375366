import { ElementRef, Injectable } from '@angular/core';
import { SideBarMenuConfig } from '@horizon/core-navigations';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@core/services/shared.service';
import { SearchApiService } from '@core/services/search.service';
import { MenuRoutePaths } from '@shared/enums/routes.enums';
import { PermissionAccessType } from '@horizon/core';

@Injectable({
  providedIn: 'root',
})
export class SideBarMenuConfigService {
  public sidebarMenuConfig: SideBarMenuConfig = new SideBarMenuConfig();

  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    public searchApiService: SearchApiService
  ) {
    //Default values
    this.sidebarMenuConfig.menuSettings = {
      ...this.sidebarMenuConfig.menuSettings,
      burguerIcon: `<svg width="62" height="60" viewBox="0 0 62 60" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
      <rect width="62" height="60" fill="white"/>
      <rect width="62" height="60" fill="white" fill-opacity="0.1"/>
      <g clip-path="url(#clip0_3458_7020)">
      <path d="M25.4797 23.4112H42.5761C42.8595 23.4112 43.1313 23.2986 43.3316 23.0983C43.532 22.8979 43.6446 22.6261 43.6446 22.3427C43.6446 22.0593 43.532 21.7875 43.3316 21.5871C43.1313 21.3867 42.8595 21.2742 42.5761 21.2742H25.4797C25.1963 21.2742 24.9245 21.3867 24.7241 21.5871C24.5237 21.7875 24.4111 22.0593 24.4111 22.3427C24.4111 22.6261 24.5237 22.8979 24.7241 23.0983C24.9245 23.2986 25.1963 23.4112 25.4797 23.4112Z" fill="#054646"/>
      <path d="M42.5761 28.7537H25.4797C25.1963 28.7537 24.9245 28.8662 24.7241 29.0666C24.5237 29.267 24.4111 29.5388 24.4111 29.8222C24.4111 30.1056 24.5237 30.3774 24.7241 30.5778C24.9245 30.7781 25.1963 30.8907 25.4797 30.8907H42.5761C42.8595 30.8907 43.1313 30.7781 43.3316 30.5778C43.532 30.3774 43.6446 30.1056 43.6446 29.8222C43.6446 29.5388 43.532 29.267 43.3316 29.0666C43.1313 28.8662 42.8595 28.7537 42.5761 28.7537Z" fill="#054646"/>
      <path d="M42.5761 36.2334H25.4797C25.1963 36.2334 24.9245 36.346 24.7241 36.5464C24.5237 36.7467 24.4111 37.0185 24.4111 37.3019C24.4111 37.5853 24.5237 37.8571 24.7241 38.0575C24.9245 38.2579 25.1963 38.3705 25.4797 38.3705H42.5761C42.8595 38.3705 43.1313 38.2579 43.3316 38.0575C43.532 37.8571 43.6446 37.5853 43.6446 37.3019C43.6446 37.0185 43.532 36.7467 43.3316 36.5464C43.1313 36.346 42.8595 36.2334 42.5761 36.2334Z" fill="#054646"/>
      <path d="M20.1371 24.4797C21.3173 24.4797 22.2741 23.5229 22.2741 22.3426C22.2741 21.1624 21.3173 20.2056 20.1371 20.2056C18.9568 20.2056 18 21.1624 18 22.3426C18 23.5229 18.9568 24.4797 20.1371 24.4797Z" fill="#DF0024"/>
      <path d="M20.1371 31.9594C21.3173 31.9594 22.2741 31.0026 22.2741 29.8224C22.2741 28.6421 21.3173 27.6853 20.1371 27.6853C18.9568 27.6853 18 28.6421 18 29.8224C18 31.0026 18.9568 31.9594 20.1371 31.9594Z" fill="#DF0024"/>
      <path d="M20.1371 39.4391C21.3173 39.4391 22.2741 38.4824 22.2741 37.3021C22.2741 36.1218 21.3173 35.165 20.1371 35.165C18.9568 35.165 18 36.1218 18 37.3021C18 38.4824 18.9568 39.4391 20.1371 39.4391Z" fill="#DF0024"/>
      </g>
      <defs>
      <clipPath id="clip0_3458_7020">
      <rect width="25.6446" height="25.6446" fill="white" transform="translate(18 17)"/>
      </clipPath>
      </defs>
      </svg>
      `,
      closeIcon: `<svg width="62" height="60" viewBox="0 0 62 60" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
      <rect width="62" height="60" fill="white"/>
      <rect width="62" height="60" fill="white" fill-opacity="0.1"/>
      <path d="M38 23L23.9999 37.0001" stroke="#DF0024" stroke-width="2.1175" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24 23L38.0001 37.0001" stroke="#411941" stroke-width="2.1175" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      menuBackgroundColor: '#ffffff',
      menuItemSelectedBackgroundColor: '#ffffff',
    };

    this.sidebarMenuConfig.menuItems = [...this.sharedService.menuItems.getValue()];

    this.sidebarMenuConfig.horizonModules = [
      {
        icon: `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H50V40C50 45.5228 45.5228 50 40 50H0V0Z" fill="#DF0024"/>
        <path d="M34.0026 20.4042V20.4273C34.0026 20.4042 34.0026 20.3811 34.0026 20.3811C34.0026 16.4296 31.7842 10.2597 25.0597 10.0055C25.0597 10.0055 20.8771 9.77439 18.3352 12.3163C16.9255 13.6797 16.0936 15.7594 16.0012 17.0997V17.4232C16.0012 19.0408 18.2889 19.7109 19.7679 18.7404C21.7783 17.4001 21.5703 15.6208 24.5282 15.4128C27.5553 15.2279 28.9187 17.8623 28.9187 20.3811C28.9187 22.8999 27.5553 25.5342 24.5282 25.3494C21.5934 25.1645 21.8014 23.362 19.7679 22.0218C18.312 21.0512 16.0936 21.7445 16.0012 23.2234V35.4939C16.0012 35.4939 15.6546 40 20.6691 40C21.0157 40 21.1312 39.6996 21.1312 39.3761V29.6013C21.1312 29.6013 22.5871 30.3176 25.1983 30.2252C32.177 30.0172 34.0026 24.5175 34.0026 20.4042Z" fill="white"/>
        </svg>
        `,
        name: 'pepper',
        url: '#',
      },
    ];
  }

  public setMenuColors(host: ElementRef<HTMLElement>): void {
    //REQUIRED FOR STYLINGS OF THE SYNCFUSION MENU --> Call it from the parent component
    host.nativeElement.style.setProperty(
      `--menu-background-color`,
      this.sidebarMenuConfig.menuSettings.menuBackgroundColor
    );
    host.nativeElement.style.setProperty(
      `--menu-item-selected-background-color`,
      this.sidebarMenuConfig.menuSettings.menuItemSelectedBackgroundColor
    );
  }


}
