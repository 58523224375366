import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { RoutePaths } from '@shared/enums/routes.enums';
import {
  PolicyService,
} from '@horizon/core';
import { Observable, Subject} from 'rxjs';
import { SharedService } from '@core/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class UserHasConfigGuard implements CanActivate {
  private readonly destroy$ = new Subject<void>();
  public disclaimerRequired: any;
  constructor(private router: Router, private policyService: PolicyService,public sharedService: SharedService,) {}
 
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.sharedService.userHasConfig.getValue()) {
      if (state.url.includes(RoutePaths.CONTACT_ADMIN)) {
        return true;
      }
      this.router.navigate([RoutePaths.CONTACT_ADMIN]);
      return false
    } else {
      if (!state.url.includes(RoutePaths.CONTACT_ADMIN)) {
        return true;
      }
      this.router.navigate([RoutePaths.HOME]);
      return false
    }
  }
}