import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { HorizonAppHeaderModule } from '@horizon/core-layouts';
import { HorizonSidebarMenuModule } from '@horizon/core-navigations';
import {
  HorizonToastNotificationModule,
  HorizonDialogNotificationModalModule,
  HorizonDialogLoadingModalModule,
} from '@horizon/core-notifications';
import { TranslateModule } from '@ngx-translate/core';
import { CoreComponent } from './core.component';
import { SharedModule } from '@shared/shared.module';
import { HorizonComponentsCoreModule } from '@horizon/core';
import { HorizonComponentsDmCoreModule } from '@horizon/dm-core';

@NgModule({
  declarations: [CoreComponent],
  imports: [
    CoreRoutingModule,
    CommonModule,
    TranslateModule,
    SharedModule,
    HorizonAppHeaderModule,
    HorizonSidebarMenuModule,
    HorizonComponentsCoreModule,
    HorizonComponentsDmCoreModule,
    HorizonToastNotificationModule,
    HorizonDialogNotificationModalModule,
    HorizonDialogLoadingModalModule,
  ],
  exports: [CoreComponent],
})
export class CoreModule {}
