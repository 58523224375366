import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SingletonSecureStorageService {
  constructor() {}

  init() {
    const CryptoJS = require('crypto-js');
    Storage.prototype._setItem = Storage.prototype.setItem;
    Storage.prototype._getItem = Storage.prototype.getItem;

    Storage.prototype.setItem = function (key, value) {
      this._setItem(
        key,
        CryptoJS.AES.encrypt(value, environment.clientid)?.toString()
      );
    };

    Storage.prototype.getItem = function (key) {
      try {
        let value = this._getItem(key);
        if (value) {
          return CryptoJS.AES.decrypt(value, environment.clientid)?.toString(
            CryptoJS.enc.Utf8
          );
        } else {
          return null;
        }
      } catch (error) {
        localStorage.clear();
        sessionStorage.clear();
      }
    };
  }
}
