import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ReadPermissionsGuard } from './guards/read-permissions.guard';
import { RoutePaths } from '@shared/enums/routes.enums';
import { UserHasConfigGuard } from './guards/user-has-config.guard';

const routes: Routes = [
  {
    path: RoutePaths.HOME,
    loadChildren: () =>
      import('@home-module/home.module').then((m) => m.HomeModule),
    canActivate: [MsalGuard,UserHasConfigGuard,UserHasConfigGuard],
  },
  {
    path: RoutePaths.SUPER_USER,
    loadChildren: () =>
      import('@super-user-module/super-user.module').then(
        (m) => m.SuperUserModule
      ),
    canActivate: [MsalGuard,UserHasConfigGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.ACCOUNT,
    loadChildren: () =>
      import('@account-module/account.module').then((m) => m.AccountModule),
      canActivate: [MsalGuard, UserHasConfigGuard, ReadPermissionsGuard],
  },
  { path: '**', redirectTo: RoutePaths.HOME, pathMatch: 'full' },
  { path: '', redirectTo: RoutePaths.HOME, pathMatch: 'full' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabled' : 'disabled', // Don't perform initial navigation in iframes
    }),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
