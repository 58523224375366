export const gregorianES = {
  main: {
    es: {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'es',
      },
      dates: {
        calendars: {
          gregorian: {
            months: {
              format: {
                abbreviated: {
                  '1': 'ene.',
                  '2': 'feb.',
                  '3': 'mar.',
                  '4': 'abr.',
                  '5': 'may.',
                  '6': 'jun.',
                  '7': 'jul.',
                  '8': 'ago.',
                  '9': 'sept.',
                  '10': 'oct.',
                  '11': 'nov.',
                  '12': 'dic.',
                },
                narrow: {
                  '1': 'E',
                  '2': 'F',
                  '3': 'M',
                  '4': 'A',
                  '5': 'M',
                  '6': 'J',
                  '7': 'J',
                  '8': 'A',
                  '9': 'S',
                  '10': 'O',
                  '11': 'N',
                  '12': 'D',
                },
                wide: {
                  '1': 'enero',
                  '2': 'febrero',
                  '3': 'marzo',
                  '4': 'abril',
                  '5': 'mayo',
                  '6': 'junio',
                  '7': 'julio',
                  '8': 'agosto',
                  '9': 'septiembre',
                  '10': 'octubre',
                  '11': 'noviembre',
                  '12': 'diciembre',
                },
              },
              'stand-alone': {
                abbreviated: {
                  '1': 'ene.',
                  '2': 'feb.',
                  '3': 'mar.',
                  '4': 'abr.',
                  '5': 'may.',
                  '6': 'jun.',
                  '7': 'jul.',
                  '8': 'ago.',
                  '9': 'sept.',
                  '10': 'oct.',
                  '11': 'nov.',
                  '12': 'dic.',
                },
                narrow: {
                  '1': 'E',
                  '2': 'F',
                  '3': 'M',
                  '4': 'A',
                  '5': 'M',
                  '6': 'J',
                  '7': 'J',
                  '8': 'A',
                  '9': 'S',
                  '10': 'O',
                  '11': 'N',
                  '12': 'D',
                },
                wide: {
                  '1': 'enero',
                  '2': 'febrero',
                  '3': 'marzo',
                  '4': 'abril',
                  '5': 'mayo',
                  '6': 'junio',
                  '7': 'julio',
                  '8': 'agosto',
                  '9': 'septiembre',
                  '10': 'octubre',
                  '11': 'noviembre',
                  '12': 'diciembre',
                },
              },
            },
            days: {
              format: {
                abbreviated: {
                  sun: 'dom.',
                  mon: 'lun.',
                  tue: 'mar.',
                  wed: 'mié.',
                  thu: 'jue.',
                  fri: 'vie.',
                  sat: 'sáb.',
                },
                narrow: {
                  sun: 'D',
                  mon: 'L',
                  tue: 'M',
                  wed: 'X',
                  thu: 'J',
                  fri: 'V',
                  sat: 'S',
                },
                short: {
                  sun: 'DO',
                  mon: 'LU',
                  tue: 'MA',
                  wed: 'MI',
                  thu: 'JU',
                  fri: 'VI',
                  sat: 'SA',
                },
                wide: {
                  sun: 'domingo',
                  mon: 'lunes',
                  tue: 'martes',
                  wed: 'miércoles',
                  thu: 'jueves',
                  fri: 'viernes',
                  sat: 'sábado',
                },
              },
              'stand-alone': {
                abbreviated: {
                  sun: 'dom.',
                  mon: 'lun.',
                  tue: 'mar.',
                  wed: 'mié.',
                  thu: 'jue.',
                  fri: 'vie.',
                  sat: 'sáb.',
                },
                narrow: {
                  sun: 'D',
                  mon: 'L',
                  tue: 'M',
                  wed: 'X',
                  thu: 'J',
                  fri: 'V',
                  sat: 'S',
                },
                short: {
                  sun: 'DO',
                  mon: 'LU',
                  tue: 'MA',
                  wed: 'MI',
                  thu: 'JU',
                  fri: 'VI',
                  sat: 'SA',
                },
                wide: {
                  sun: 'domingo',
                  mon: 'lunes',
                  tue: 'martes',
                  wed: 'miércoles',
                  thu: 'jueves',
                  fri: 'viernes',
                  sat: 'sábado',
                },
              },
            },
            quarters: {
              format: {
                abbreviated: {
                  '1': 'T1',
                  '2': 'T2',
                  '3': 'T3',
                  '4': 'T4',
                },
                narrow: {
                  '1': '1',
                  '2': '2',
                  '3': '3',
                  '4': '4',
                },
                wide: {
                  '1': '1.er trimestre',
                  '2': '2.º trimestre',
                  '3': '3.er trimestre',
                  '4': '4.º trimestre',
                },
              },
              'stand-alone': {
                abbreviated: {
                  '1': 'T1',
                  '2': 'T2',
                  '3': 'T3',
                  '4': 'T4',
                },
                narrow: {
                  '1': '1',
                  '2': '2',
                  '3': '3',
                  '4': '4',
                },
                wide: {
                  '1': '1.er trimestre',
                  '2': '2.º trimestre',
                  '3': '3.er trimestre',
                  '4': '4.º trimestre',
                },
              },
            },
            dayPeriods: {
              format: {
                abbreviated: {
                  am: 'a. m.',
                  noon: 'del mediodía',
                  pm: 'p. m.',
                  morning1: 'de la madrugada',
                  morning2: 'de la mañana',
                  evening1: 'de la tarde',
                  night1: 'de la noche',
                },
                narrow: {
                  am: 'a. m.',
                  noon: 'del mediodía',
                  pm: 'p. m.',
                  morning1: 'de la madrugada',
                  morning2: 'de la mañana',
                  evening1: 'de la tarde',
                  night1: 'de la noche',
                },
                wide: {
                  am: 'a. m.',
                  noon: 'del mediodía',
                  pm: 'p. m.',
                  morning1: 'de la madrugada',
                  morning2: 'de la mañana',
                  evening1: 'de la tarde',
                  night1: 'de la noche',
                },
              },
              'stand-alone': {
                abbreviated: {
                  am: 'a. m.',
                  noon: 'mediodía',
                  pm: 'p. m.',
                  morning1: 'madrugada',
                  morning2: 'mañana',
                  evening1: 'tarde',
                  night1: 'noche',
                },
                narrow: {
                  am: 'a. m.',
                  noon: 'mediodía',
                  pm: 'p. m.',
                  morning1: 'madrugada',
                  morning2: 'mañana',
                  evening1: 'tarde',
                  night1: 'noche',
                },
                wide: {
                  am: 'a. m.',
                  noon: 'mediodía',
                  pm: 'p. m.',
                  morning1: 'madrugada',
                  morning2: 'mañana',
                  evening1: 'tarde',
                  night1: 'noche',
                },
              },
            },
            eras: {
              eraNames: {
                '0': 'antes de Cristo',
                '0-alt-variant': 'antes de la era común',
                '1': 'después de Cristo',
                '1-alt-variant': 'era común',
              },
              eraAbbr: {
                '0': 'a. C.',
                '0-alt-variant': 'a. e. c.',
                '1': 'd. C.',
                '1-alt-variant': 'e. c.',
              },
              eraNarrow: {
                '0': 'a. C.',
                '0-alt-variant': 'a. e. c.',
                '1': 'd. C.',
                '1-alt-variant': 'e. c.',
              },
            },
            dateFormats: {
              full: "EEEE, d 'de' MMMM 'de' y",
              long: "d 'de' MMMM 'de' y",
              medium: 'd MMM y',
              short: 'd/M/yy',
            },
            timeFormats: {
              full: 'H:mm:ss (zzzz)',
              long: 'H:mm:ss z',
              medium: 'H:mm:ss',
              short: 'H:mm',
            },
            dateTimeFormats: {
              full: '{1}, {0}',
              long: '{1}, {0}',
              medium: '{1} {0}',
              short: '{1} {0}',
              availableFormats: {
                Bh: 'h B',
                Bhm: 'h:mm B',
                Bhms: 'h:mm:ss B',
                d: 'd',
                E: 'ccc',
                EBhm: 'E h:mm B',
                EBhms: 'E h:mm:ss B',
                Ed: 'E d',
                Ehm: 'E, h:mm a',
                EHm: 'E, H:mm',
                Ehms: 'E, h:mm:ss a',
                EHms: 'E, H:mm:ss',
                Gy: 'y G',
                GyMMM: 'MMM y G',
                GyMMMd: 'd MMM y G',
                GyMMMEd: 'E, d MMM y G',
                GyMMMM: "MMMM 'de' y G",
                GyMMMMd: "d 'de' MMMM 'de' y G",
                GyMMMMEd: "E, d 'de' MMMM 'de' y G",
                h: 'h a',
                H: 'H',
                hm: 'h:mm a',
                Hm: 'H:mm',
                hms: 'h:mm:ss a',
                Hms: 'H:mm:ss',
                hmsv: 'h:mm:ss a v',
                Hmsv: 'H:mm:ss v',
                hmsvvvv: 'h:mm:ss a (vvvv)',
                Hmsvvvv: 'H:mm:ss (vvvv)',
                hmv: 'h:mm a v',
                Hmv: 'H:mm v',
                M: 'L',
                Md: 'd/M',
                MEd: 'E, d/M',
                MMd: 'd/M',
                MMdd: 'd/M',
                MMM: 'LLL',
                MMMd: 'd MMM',
                MMMEd: 'E, d MMM',
                MMMMd: "d 'de' MMMM",
                MMMMEd: "E, d 'de' MMMM",
                'MMMMW-count-one': "'semana' W 'de' MMMM",
                'MMMMW-count-other': "'semana' W 'de' MMMM",
                ms: 'mm:ss',
                y: 'y',
                yM: 'M/y',
                yMd: 'd/M/y',
                yMEd: 'EEE, d/M/y',
                yMM: 'M/y',
                yMMM: 'MMM y',
                yMMMd: 'd MMM y',
                yMMMEd: 'EEE, d MMM y',
                yMMMM: "MMMM 'de' y",
                yMMMMd: "d 'de' MMMM 'de' y",
                yMMMMEd: "EEE, d 'de' MMMM 'de' y",
                yQQQ: 'QQQ y',
                yQQQQ: "QQQQ 'de' y",
                'yw-count-one': "'semana' w 'de' Y",
                'yw-count-other': "'semana' w 'de' Y",
              },
              appendItems: {
                Day: '{0} ({2}: {1})',
                'Day-Of-Week': '{0} {1}',
                Era: '{1} {0}',
                Hour: '{0} ({2}: {1})',
                Minute: '{0} ({2}: {1})',
                Month: '{0} ({2}: {1})',
                Quarter: '{0} ({2}: {1})',
                Second: '{0} ({2}: {1})',
                Timezone: '{0} {1}',
                Week: '{0} ({2}: {1})',
                Year: '{1} {0}',
              },
              intervalFormats: {
                intervalFormatFallback: '{0}–{1}',
                Bh: {
                  B: 'h B – h B',
                  h: 'h–h B',
                },
                Bhm: {
                  B: 'h:mm B – h:mm B',
                  h: 'h:mm–h:mm B',
                  m: 'h:mm–h:mm B',
                },
                d: {
                  d: 'd–d',
                },
                Gy: {
                  G: 'y G – y G',
                  y: 'y–y G',
                },
                GyM: {
                  G: 'y-MM GGGGG – y-MM GGGGG',
                  M: 'y-MM – y-MM GGGGG',
                  y: 'y-MM – y-MM GGGGG',
                },
                GyMd: {
                  d: 'y-MM-dd – y-MM-dd GGGGG',
                  G: 'y-MM-dd GGGGG – y-MM-dd GGGGG',
                  M: 'y-MM-dd – y-MM-dd GGGGG',
                  y: 'y-MM-dd – y-MM-dd GGGGG',
                },
                GyMEd: {
                  d: 'E y-MM-dd – E y-MM-dd GGGGG',
                  G: 'E y-MM-dd GGGGG – E y-MM-dd GGGGG',
                  M: 'E y-MM-dd – E y-MM-dd GGGGG',
                  y: 'E y-MM-dd – E y-MM-dd GGGGG',
                },
                GyMMM: {
                  G: 'MMM y G – MMM y G',
                  M: 'MMM–MMM y G',
                  y: 'MMM y – MMM y G',
                },
                GyMMMd: {
                  d: 'MMM d–d y G',
                  G: 'MMM d y G – MMM d y G',
                  M: 'MMM d – MMM d y G',
                  y: 'MMM d y – MMM d y G',
                },
                GyMMMEd: {
                  d: 'E d MMM – E d MMM, y G',
                  G: 'E, MMM d, y G – E, MMM d, y G',
                  M: 'E d MMM – E d MMM, y G',
                  y: 'E d MMM, y – E d MMM, y G',
                },
                h: {
                  a: 'h a – h a',
                  h: 'h–h a',
                },
                H: {
                  H: 'H–H',
                },
                hm: {
                  a: 'h:mm a – h:mm a',
                  h: 'h:mm – h:mm a',
                  m: 'h:mm – h:mm a',
                },
                Hm: {
                  H: 'H:mm–H:mm',
                  m: 'H:mm–H:mm',
                },
                hmv: {
                  a: 'h:mm a – h:mm a v',
                  h: 'h:mm–h:mm a v',
                  m: 'h:mm–h:mm a v',
                },
                Hmv: {
                  H: 'H:mm–H:mm v',
                  m: 'H:mm–H:mm v',
                },
                hv: {
                  a: 'h a – h a v',
                  h: 'h–h a v',
                },
                Hv: {
                  H: 'H–H v',
                },
                M: {
                  M: 'M–M',
                },
                Md: {
                  d: 'd/M–d/M',
                  M: 'd/M–d/M',
                },
                MEd: {
                  d: 'E, d/M – E, d/M',
                  M: 'E, d/M – E, d/M',
                },
                MMM: {
                  M: 'MMM–MMM',
                },
                MMMd: {
                  d: 'd–d MMM',
                  M: 'd MMM – d MMM',
                },
                MMMEd: {
                  d: 'E, d MMM – E, d MMM',
                  M: 'E, d MMM – E, d MMM',
                },
                MMMMd: {
                  d: "d–d 'de' MMMM",
                  M: "d 'de' MMMM–d 'de' MMMM",
                },
                MMMMEd: {
                  d: "E, d 'de' MMMM–E, d 'de' MMMM",
                  M: "E, d 'de' MMMM–E, d 'de' MMMM",
                },
                y: {
                  y: 'y–y',
                },
                yM: {
                  M: 'M/y–M/y',
                  y: 'M/y–M/y',
                },
                yMd: {
                  d: 'd/M/y–d/M/y',
                  M: 'd/M/y–d/M/y',
                  y: 'd/M/y–d/M/y',
                },
                yMEd: {
                  d: 'E, d/M/y – E, d/M/y',
                  M: 'E, d/M/y – E, d/M/y',
                  y: 'E, d/M/y – E, d/M/y',
                },
                yMMM: {
                  M: 'MMM–MMM y',
                  y: 'MMM y – MMM y',
                },
                yMMMd: {
                  d: 'd–d MMM y',
                  M: 'd MMM – d MMM y',
                  y: 'd MMM y – d MMM y',
                },
                yMMMEd: {
                  d: 'E, d MMM – E, d MMM y',
                  M: 'E, d MMM – E, d MMM y',
                  y: 'E, d MMM y – E, d MMM y',
                },
                yMMMM: {
                  M: "MMMM–MMMM 'de' y",
                  y: "MMMM 'de' y – MMMM 'de' y",
                },
                yMMMMd: {
                  d: "d–d 'de' MMMM 'de' y",
                  M: "d 'de' MMMM–d 'de' MMMM 'de' y",
                  y: "d 'de' MMMM 'de' y–d 'de' MMMM 'de' y",
                },
                yMMMMEd: {
                  d: "E, d 'de' MMMM–E, d 'de' MMMM 'de' y",
                  M: "E, d 'de' MMMM–E, d 'de' MMMM 'de' y",
                  y: "E, d 'de' MMMM 'de' y–E, d 'de' MMMM 'de' y",
                },
              },
            },
          },
        },
      },
    },
  },
};
