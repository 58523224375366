<div class="main-container">
  <core
    *ngIf="(isLoading$ | async) === false; else initialLoadProgressBar"
  ></core>
</div>
<ng-template #initialLoadProgressBar>
  <div id="app-loader" class="logo-container">
    <div class="logo-container_image">
      <img src="../assets/img/pepper-advantage.png" />
    </div>
    <div class="logo-container_animation"></div>
    <div class="logo-container_label">
      <label>{{ "loading.title" | translate }}</label>
    </div>
  </div>
</ng-template>
