export const unitsEN = {
  main: {
    'en-GB': {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'en',
        territory: 'GB',
      },
      units: {
        long: {
          per: {
            compoundUnitPattern: '{0} per {1}',
          },
          times: {
            compoundUnitPattern: '{0}⋅{1}',
          },
          'acceleration-g-force': {
            displayName: 'g-force',
            'unitPattern-count-one': '{0} g-force',
            'unitPattern-count-other': '{0} g-force',
          },
          'acceleration-meter-per-second-squared': {
            displayName: 'metres per second squared',
            'unitPattern-count-one': '{0} metre per second squared',
            'unitPattern-count-other': '{0} metres per second squared',
          },
          'angle-revolution': {
            displayName: 'revolution',
            'unitPattern-count-one': '{0} revolution',
            'unitPattern-count-other': '{0} revolutions',
          },
          'angle-radian': {
            displayName: 'radians',
            'unitPattern-count-one': '{0} radian',
            'unitPattern-count-other': '{0} radians',
          },
          'angle-degree': {
            displayName: 'degrees',
            'unitPattern-count-one': '{0} degree',
            'unitPattern-count-other': '{0} degrees',
          },
          'angle-arc-minute': {
            displayName: 'arcminutes',
            'unitPattern-count-one': '{0} arcminute',
            'unitPattern-count-other': '{0} arcminutes',
          },
          'angle-arc-second': {
            displayName: 'arcseconds',
            'unitPattern-count-one': '{0} arcsecond',
            'unitPattern-count-other': '{0} arcseconds',
          },
          'area-square-kilometer': {
            displayName: 'square kilometres',
            'unitPattern-count-one': '{0} square kilometre',
            'unitPattern-count-other': '{0} square kilometres',
            perUnitPattern: '{0} per square kilometre',
          },
          'area-hectare': {
            displayName: 'hectares',
            'unitPattern-count-one': '{0} hectare',
            'unitPattern-count-other': '{0} hectares',
          },
          'area-square-meter': {
            displayName: 'square metres',
            'unitPattern-count-one': '{0} square metre',
            'unitPattern-count-other': '{0} square metres',
            perUnitPattern: '{0} per square metre',
          },
          'area-square-centimeter': {
            displayName: 'square centimetres',
            'unitPattern-count-one': '{0} square centimetre',
            'unitPattern-count-other': '{0} square centimetres',
            perUnitPattern: '{0} per square centimetre',
          },
          'area-square-mile': {
            displayName: 'square miles',
            'unitPattern-count-one': '{0} square mile',
            'unitPattern-count-other': '{0} square miles',
            perUnitPattern: '{0} per square mile',
          },
          'area-acre': {
            displayName: 'acres',
            'unitPattern-count-one': '{0} acre',
            'unitPattern-count-other': '{0} acres',
          },
          'area-square-yard': {
            displayName: 'square yards',
            'unitPattern-count-one': '{0} square yard',
            'unitPattern-count-other': '{0} square yards',
          },
          'area-square-foot': {
            displayName: 'square feet',
            'unitPattern-count-one': '{0} square foot',
            'unitPattern-count-other': '{0} square feet',
          },
          'area-square-inch': {
            displayName: 'square inches',
            'unitPattern-count-one': '{0} square inch',
            'unitPattern-count-other': '{0} square inches',
            perUnitPattern: '{0} per square inch',
          },
          'area-dunam': {
            displayName: 'dunams',
            'unitPattern-count-one': '{0} dunam',
            'unitPattern-count-other': '{0} dunams',
          },
          'concentr-karat': {
            displayName: 'carat',
            'unitPattern-count-one': '{0} carat',
            'unitPattern-count-other': '{0} carats',
          },
          'concentr-milligram-per-deciliter': {
            displayName: 'milligrams per decilitre',
            'unitPattern-count-one': '{0} milligram per decilitre',
            'unitPattern-count-other': '{0} milligrams per decilitre',
          },
          'concentr-millimole-per-liter': {
            displayName: 'millimoles per litre',
            'unitPattern-count-one': '{0} millimole per litre',
            'unitPattern-count-other': '{0} millimoles per litre',
          },
          'concentr-part-per-million': {
            displayName: 'parts per million',
            'unitPattern-count-one': '{0} part per million',
            'unitPattern-count-other': '{0} parts per million',
          },
          'concentr-percent': {
            displayName: 'per cent',
            'unitPattern-count-one': '{0} per cent',
            'unitPattern-count-other': '{0} per cent',
          },
          'concentr-permille': {
            displayName: 'per mille',
            'unitPattern-count-one': '{0} per mille',
            'unitPattern-count-other': '{0} per mille',
          },
          'concentr-permyriad': {
            displayName: 'permyriad',
            'unitPattern-count-one': '{0} permyriad',
            'unitPattern-count-other': '{0} permyriad',
          },
          'concentr-mole': {
            displayName: 'moles',
            'unitPattern-count-one': '{0} mole',
            'unitPattern-count-other': '{0} moles',
          },
          'consumption-liter-per-kilometer': {
            displayName: 'litres per kilometre',
            'unitPattern-count-one': '{0} litre per kilometre',
            'unitPattern-count-other': '{0} litres per kilometre',
          },
          'consumption-liter-per-100kilometers': {
            displayName: 'litres per 100 kilometres',
            'unitPattern-count-one': '{0} litre per 100 kilometres',
            'unitPattern-count-other': '{0} litres per 100 kilometres',
          },
          'consumption-mile-per-gallon': {
            displayName: 'miles per US gallon',
            'unitPattern-count-one': '{0} mile per US gallon',
            'unitPattern-count-other': '{0} miles per US gallon',
          },
          'consumption-mile-per-gallon-imperial': {
            displayName: 'miles per gallon',
            'unitPattern-count-one': '{0} mile per gallon',
            'unitPattern-count-other': '{0} miles per gallon',
          },
          'digital-petabyte': {
            displayName: 'petabytes',
            'unitPattern-count-one': '{0} petabyte',
            'unitPattern-count-other': '{0} petabytes',
          },
          'digital-terabyte': {
            displayName: 'terabytes',
            'unitPattern-count-one': '{0} terabyte',
            'unitPattern-count-other': '{0} terabytes',
          },
          'digital-terabit': {
            displayName: 'terabits',
            'unitPattern-count-one': '{0} terabit',
            'unitPattern-count-other': '{0} terabits',
          },
          'digital-gigabyte': {
            displayName: 'gigabytes',
            'unitPattern-count-one': '{0} gigabyte',
            'unitPattern-count-other': '{0} gigabytes',
          },
          'digital-gigabit': {
            displayName: 'gigabits',
            'unitPattern-count-one': '{0} gigabit',
            'unitPattern-count-other': '{0} gigabits',
          },
          'digital-megabyte': {
            displayName: 'megabytes',
            'unitPattern-count-one': '{0} megabyte',
            'unitPattern-count-other': '{0} megabytes',
          },
          'digital-megabit': {
            displayName: 'megabits',
            'unitPattern-count-one': '{0} megabit',
            'unitPattern-count-other': '{0} megabits',
          },
          'digital-kilobyte': {
            displayName: 'kilobytes',
            'unitPattern-count-one': '{0} kilobyte',
            'unitPattern-count-other': '{0} kilobytes',
          },
          'digital-kilobit': {
            displayName: 'kilobits',
            'unitPattern-count-one': '{0} kilobit',
            'unitPattern-count-other': '{0} kilobits',
          },
          'digital-byte': {
            displayName: 'bytes',
            'unitPattern-count-one': '{0} byte',
            'unitPattern-count-other': '{0} bytes',
          },
          'digital-bit': {
            displayName: 'bits',
            'unitPattern-count-one': '{0} bit',
            'unitPattern-count-other': '{0} bits',
          },
          'duration-century': {
            displayName: 'centuries',
            'unitPattern-count-one': '{0} century',
            'unitPattern-count-other': '{0} centuries',
          },
          'duration-decade': {
            displayName: 'decades',
            'unitPattern-count-one': '{0} decade',
            'unitPattern-count-other': '{0} decades',
          },
          'duration-year': {
            displayName: 'years',
            'unitPattern-count-one': '{0} year',
            'unitPattern-count-other': '{0} years',
            perUnitPattern: '{0} per year',
          },
          'duration-month': {
            displayName: 'months',
            'unitPattern-count-one': '{0} month',
            'unitPattern-count-other': '{0} months',
            perUnitPattern: '{0} per month',
          },
          'duration-week': {
            displayName: 'weeks',
            'unitPattern-count-one': '{0} week',
            'unitPattern-count-other': '{0} weeks',
            perUnitPattern: '{0} per week',
          },
          'duration-day': {
            displayName: 'days',
            'unitPattern-count-one': '{0} day',
            'unitPattern-count-other': '{0} days',
            perUnitPattern: '{0} per day',
          },
          'duration-hour': {
            displayName: 'hours',
            'unitPattern-count-one': '{0} hour',
            'unitPattern-count-other': '{0} hours',
            perUnitPattern: '{0} per hour',
          },
          'duration-minute': {
            displayName: 'minutes',
            'unitPattern-count-one': '{0} minute',
            'unitPattern-count-other': '{0} minutes',
            perUnitPattern: '{0} per minute',
          },
          'duration-second': {
            displayName: 'seconds',
            'unitPattern-count-one': '{0} second',
            'unitPattern-count-other': '{0} seconds',
            perUnitPattern: '{0} per second',
          },
          'duration-millisecond': {
            displayName: 'milliseconds',
            'unitPattern-count-one': '{0} millisecond',
            'unitPattern-count-other': '{0} milliseconds',
          },
          'duration-microsecond': {
            displayName: 'microseconds',
            'unitPattern-count-one': '{0} microsecond',
            'unitPattern-count-other': '{0} microseconds',
          },
          'duration-nanosecond': {
            displayName: 'nanoseconds',
            'unitPattern-count-one': '{0} nanosecond',
            'unitPattern-count-other': '{0} nanoseconds',
          },
          'electric-ampere': {
            displayName: 'amperes',
            'unitPattern-count-one': '{0} ampere',
            'unitPattern-count-other': '{0} amperes',
          },
          'electric-milliampere': {
            displayName: 'milliamperes',
            'unitPattern-count-one': '{0} milliampere',
            'unitPattern-count-other': '{0} milliamperes',
          },
          'electric-ohm': {
            displayName: 'ohms',
            'unitPattern-count-one': '{0} ohm',
            'unitPattern-count-other': '{0} ohms',
          },
          'electric-volt': {
            displayName: 'volts',
            'unitPattern-count-one': '{0} volt',
            'unitPattern-count-other': '{0} volts',
          },
          'energy-kilocalorie': {
            displayName: 'kilocalories',
            'unitPattern-count-one': '{0} kilocalorie',
            'unitPattern-count-other': '{0} kilocalories',
          },
          'energy-calorie': {
            displayName: 'calories',
            'unitPattern-count-one': '{0} calorie',
            'unitPattern-count-other': '{0} calories',
          },
          'energy-foodcalorie': {
            displayName: 'Calories',
            'unitPattern-count-one': '{0} Calorie',
            'unitPattern-count-other': '{0} Calories',
          },
          'energy-kilojoule': {
            displayName: 'kilojoules',
            'unitPattern-count-one': '{0} kilojoule',
            'unitPattern-count-other': '{0} kilojoules',
          },
          'energy-joule': {
            displayName: 'joules',
            'unitPattern-count-one': '{0} joule',
            'unitPattern-count-other': '{0} joules',
          },
          'energy-kilowatt-hour': {
            displayName: 'kilowatt-hours',
            'unitPattern-count-one': '{0} kilowatt hour',
            'unitPattern-count-other': '{0} kilowatt-hours',
          },
          'energy-electronvolt': {
            displayName: 'electronvolts',
            'unitPattern-count-one': '{0} electronvolt',
            'unitPattern-count-other': '{0} electronvolts',
          },
          'energy-british-thermal-unit': {
            displayName: 'British thermal units',
            'unitPattern-count-one': '{0} British thermal unit',
            'unitPattern-count-other': '{0} British thermal units',
          },
          'energy-therm-us': {
            displayName: 'US therms',
            'unitPattern-count-one': '{0} US therm',
            'unitPattern-count-other': '{0} US therms',
          },
          'force-pound-force': {
            displayName: 'pounds of force',
            'unitPattern-count-one': '{0} pound of force',
            'unitPattern-count-other': '{0} pounds of force',
          },
          'force-newton': {
            displayName: 'newtons',
            'unitPattern-count-one': '{0} newton',
            'unitPattern-count-other': '{0} newtons',
          },
          'frequency-gigahertz': {
            displayName: 'gigahertz',
            'unitPattern-count-one': '{0} gigahertz',
            'unitPattern-count-other': '{0} gigahertz',
          },
          'frequency-megahertz': {
            displayName: 'megahertz',
            'unitPattern-count-one': '{0} megahertz',
            'unitPattern-count-other': '{0} megahertz',
          },
          'frequency-kilohertz': {
            displayName: 'kilohertz',
            'unitPattern-count-one': '{0} kilohertz',
            'unitPattern-count-other': '{0} kilohertz',
          },
          'frequency-hertz': {
            displayName: 'hertz',
            'unitPattern-count-one': '{0} hertz',
            'unitPattern-count-other': '{0} hertz',
          },
          'graphics-em': {
            displayName: 'typographic em',
            'unitPattern-count-one': '{0} em',
            'unitPattern-count-other': '{0} ems',
          },
          'graphics-pixel': {
            displayName: 'pixels',
            'unitPattern-count-one': '{0} pixel',
            'unitPattern-count-other': '{0} pixels',
          },
          'graphics-megapixel': {
            displayName: 'megapixels',
            'unitPattern-count-one': '{0} megapixel',
            'unitPattern-count-other': '{0} megapixels',
          },
          'graphics-pixel-per-centimeter': {
            displayName: 'pixels per centimetre',
            'unitPattern-count-one': '{0} pixel per centimetre',
            'unitPattern-count-other': '{0} pixels per centimetre',
          },
          'graphics-pixel-per-inch': {
            displayName: 'pixels per inch',
            'unitPattern-count-one': '{0} pixel per inch',
            'unitPattern-count-other': '{0} pixels per inch',
          },
          'graphics-dot-per-centimeter': {
            displayName: 'dots per centimetre',
            'unitPattern-count-one': '{0} dot per centimetre',
            'unitPattern-count-other': '{0} dots per centimetre',
          },
          'graphics-dot-per-inch': {
            displayName: 'dots per inch',
            'unitPattern-count-one': '{0} dot per inch',
            'unitPattern-count-other': '{0} dots per inch',
          },
          'length-kilometer': {
            displayName: 'kilometres',
            'unitPattern-count-one': '{0} kilometre',
            'unitPattern-count-other': '{0} kilometres',
            perUnitPattern: '{0} per kilometre',
          },
          'length-meter': {
            displayName: 'metres',
            'unitPattern-count-one': '{0} metre',
            'unitPattern-count-other': '{0} metres',
            perUnitPattern: '{0} per metre',
          },
          'length-decimeter': {
            displayName: 'decimetre',
            'unitPattern-count-one': '{0} decimetre',
            'unitPattern-count-other': '{0} decimetres',
          },
          'length-centimeter': {
            displayName: 'centimetres',
            'unitPattern-count-one': '{0} centimetre',
            'unitPattern-count-other': '{0} centimetres',
            perUnitPattern: '{0} per centimetre',
          },
          'length-millimeter': {
            displayName: 'millimetres',
            'unitPattern-count-one': '{0} millimetre',
            'unitPattern-count-other': '{0} millimetres',
          },
          'length-micrometer': {
            displayName: 'micrometre',
            'unitPattern-count-one': '{0} micrometre',
            'unitPattern-count-other': '{0} micrometres',
          },
          'length-nanometer': {
            displayName: 'nanometres',
            'unitPattern-count-one': '{0} nanometre',
            'unitPattern-count-other': '{0} nanometres',
          },
          'length-picometer': {
            displayName: 'picometres',
            'unitPattern-count-one': '{0} picometre',
            'unitPattern-count-other': '{0} picometres',
          },
          'length-mile': {
            displayName: 'miles',
            'unitPattern-count-one': '{0} mile',
            'unitPattern-count-other': '{0} miles',
          },
          'length-yard': {
            displayName: 'yards',
            'unitPattern-count-one': '{0} yard',
            'unitPattern-count-other': '{0} yards',
          },
          'length-foot': {
            displayName: 'feet',
            'unitPattern-count-one': '{0} foot',
            'unitPattern-count-other': '{0} feet',
            perUnitPattern: '{0} per foot',
          },
          'length-inch': {
            displayName: 'inches',
            'unitPattern-count-one': '{0} inch',
            'unitPattern-count-other': '{0} inches',
            perUnitPattern: '{0} per inch',
          },
          'length-parsec': {
            displayName: 'parsecs',
            'unitPattern-count-one': '{0} parsec',
            'unitPattern-count-other': '{0} parsecs',
          },
          'length-light-year': {
            displayName: 'light years',
            'unitPattern-count-one': '{0} light year',
            'unitPattern-count-other': '{0} light years',
          },
          'length-astronomical-unit': {
            displayName: 'astronomical units',
            'unitPattern-count-one': '{0} astronomical unit',
            'unitPattern-count-other': '{0} astronomical units',
          },
          'length-furlong': {
            displayName: 'furlongs',
            'unitPattern-count-one': '{0} furlong',
            'unitPattern-count-other': '{0} furlongs',
          },
          'length-fathom': {
            displayName: 'fathoms',
            'unitPattern-count-one': '{0} fathom',
            'unitPattern-count-other': '{0} fathoms',
          },
          'length-nautical-mile': {
            displayName: 'nautical miles',
            'unitPattern-count-one': '{0} nautical mile',
            'unitPattern-count-other': '{0} nautical miles',
          },
          'length-mile-scandinavian': {
            displayName: 'mile-scandinavian',
            'unitPattern-count-one': '{0} mile-scandinavian',
            'unitPattern-count-other': '{0} miles-scandinavian',
          },
          'length-point': {
            displayName: 'points',
            'unitPattern-count-one': '{0} point',
            'unitPattern-count-other': '{0} points',
          },
          'length-solar-radius': {
            displayName: 'solar radii',
            'unitPattern-count-one': '{0} solar radius',
            'unitPattern-count-other': '{0} solar radii',
          },
          'light-lux': {
            displayName: 'lux',
            'unitPattern-count-one': '{0} lux',
            'unitPattern-count-other': '{0} lux',
          },
          'light-solar-luminosity': {
            displayName: 'solar luminosities',
            'unitPattern-count-one': '{0} solar luminosity',
            'unitPattern-count-other': '{0} solar luminosities',
          },
          'mass-metric-ton': {
            displayName: 'tonnes',
            'unitPattern-count-one': '{0} tonne',
            'unitPattern-count-other': '{0} tonnes',
          },
          'mass-kilogram': {
            displayName: 'kilograms',
            'unitPattern-count-one': '{0} kilogram',
            'unitPattern-count-other': '{0} kilograms',
            perUnitPattern: '{0} per kilogram',
          },
          'mass-gram': {
            displayName: 'grams',
            'unitPattern-count-one': '{0} gram',
            'unitPattern-count-other': '{0} grams',
            perUnitPattern: '{0} per gram',
          },
          'mass-milligram': {
            displayName: 'milligrams',
            'unitPattern-count-one': '{0} milligram',
            'unitPattern-count-other': '{0} milligrams',
          },
          'mass-microgram': {
            displayName: 'micrograms',
            'unitPattern-count-one': '{0} microgram',
            'unitPattern-count-other': '{0} micrograms',
          },
          'mass-ton': {
            displayName: 'tons',
            'unitPattern-count-one': '{0} ton',
            'unitPattern-count-other': '{0} tons',
          },
          'mass-stone': {
            displayName: 'stone',
            'unitPattern-count-one': '{0} stone',
            'unitPattern-count-other': '{0} stone',
          },
          'mass-pound': {
            displayName: 'pounds',
            'unitPattern-count-one': '{0} pound',
            'unitPattern-count-other': '{0} pounds',
            perUnitPattern: '{0} per pound',
          },
          'mass-ounce': {
            displayName: 'ounces',
            'unitPattern-count-one': '{0} ounce',
            'unitPattern-count-other': '{0} ounces',
            perUnitPattern: '{0} per ounce',
          },
          'mass-ounce-troy': {
            displayName: 'troy ounces',
            'unitPattern-count-one': '{0} troy ounce',
            'unitPattern-count-other': '{0} troy ounces',
          },
          'mass-carat': {
            displayName: 'carats',
            'unitPattern-count-one': '{0} carat',
            'unitPattern-count-other': '{0} carats',
          },
          'mass-dalton': {
            displayName: 'daltons',
            'unitPattern-count-one': '{0} dalton',
            'unitPattern-count-other': '{0} daltons',
          },
          'mass-earth-mass': {
            displayName: 'Earth masses',
            'unitPattern-count-one': '{0} Earth mass',
            'unitPattern-count-other': '{0} Earth masses',
          },
          'mass-solar-mass': {
            displayName: 'solar masses',
            'unitPattern-count-one': '{0} solar mass',
            'unitPattern-count-other': '{0} solar masses',
          },
          'power-gigawatt': {
            displayName: 'gigawatts',
            'unitPattern-count-one': '{0} gigawatt',
            'unitPattern-count-other': '{0} gigawatts',
          },
          'power-megawatt': {
            displayName: 'megawatts',
            'unitPattern-count-one': '{0} megawatt',
            'unitPattern-count-other': '{0} megawatts',
          },
          'power-kilowatt': {
            displayName: 'kilowatts',
            'unitPattern-count-one': '{0} kilowatt',
            'unitPattern-count-other': '{0} kilowatts',
          },
          'power-watt': {
            displayName: 'watts',
            'unitPattern-count-one': '{0} watt',
            'unitPattern-count-other': '{0} watts',
          },
          'power-milliwatt': {
            displayName: 'milliwatts',
            'unitPattern-count-one': '{0} milliwatt',
            'unitPattern-count-other': '{0} milliwatts',
          },
          'power-horsepower': {
            displayName: 'horsepower',
            'unitPattern-count-one': '{0} horsepower',
            'unitPattern-count-other': '{0} horsepower',
          },
          'pressure-millimeter-of-mercury': {
            displayName: 'millimetres of mercury',
            'unitPattern-count-one': '{0} millimetre of mercury',
            'unitPattern-count-other': '{0} millimetres of mercury',
          },
          'pressure-pound-per-square-inch': {
            displayName: 'pounds per square inch',
            'unitPattern-count-one': '{0} pound per square inch',
            'unitPattern-count-other': '{0} pounds per square inch',
          },
          'pressure-inch-hg': {
            displayName: 'inches of mercury',
            'unitPattern-count-one': '{0} inch of mercury',
            'unitPattern-count-other': '{0} inches of mercury',
          },
          'pressure-bar': {
            displayName: 'bars',
            'unitPattern-count-one': '{0} bar',
            'unitPattern-count-other': '{0} bars',
          },
          'pressure-millibar': {
            displayName: 'millibars',
            'unitPattern-count-one': '{0} millibar',
            'unitPattern-count-other': '{0} millibars',
          },
          'pressure-atmosphere': {
            displayName: 'atmospheres',
            'unitPattern-count-one': '{0} atmosphere',
            'unitPattern-count-other': '{0} atmospheres',
          },
          'pressure-pascal': {
            displayName: 'pascals',
            'unitPattern-count-one': '{0} pascal',
            'unitPattern-count-other': '{0} pascals',
          },
          'pressure-hectopascal': {
            displayName: 'hectopascals',
            'unitPattern-count-one': '{0} hectopascal',
            'unitPattern-count-other': '{0} hectopascals',
          },
          'pressure-kilopascal': {
            displayName: 'kilopascals',
            'unitPattern-count-one': '{0} kilopascal',
            'unitPattern-count-other': '{0} kilopascals',
          },
          'pressure-megapascal': {
            displayName: 'megapascals',
            'unitPattern-count-one': '{0} megapascal',
            'unitPattern-count-other': '{0} megapascals',
          },
          'speed-kilometer-per-hour': {
            displayName: 'kilometres per hour',
            'unitPattern-count-one': '{0} kilometre per hour',
            'unitPattern-count-other': '{0} kilometres per hour',
          },
          'speed-meter-per-second': {
            displayName: 'metres per second',
            'unitPattern-count-one': '{0} metre per second',
            'unitPattern-count-other': '{0} metres per second',
          },
          'speed-mile-per-hour': {
            displayName: 'miles per hour',
            'unitPattern-count-one': '{0} mile per hour',
            'unitPattern-count-other': '{0} miles per hour',
          },
          'speed-knot': {
            displayName: 'knots',
            'unitPattern-count-one': '{0} knot',
            'unitPattern-count-other': '{0} knots',
          },
          'temperature-generic': {
            displayName: '°',
            'unitPattern-count-other': '{0}°',
          },
          'temperature-celsius': {
            displayName: 'degrees Celsius',
            'unitPattern-count-one': '{0} degree Celsius',
            'unitPattern-count-other': '{0} degrees Celsius',
          },
          'temperature-fahrenheit': {
            displayName: 'degrees Fahrenheit',
            'unitPattern-count-one': '{0} degree Fahrenheit',
            'unitPattern-count-other': '{0} degrees Fahrenheit',
          },
          'temperature-kelvin': {
            displayName: 'kelvin',
            'unitPattern-count-one': '{0} kelvin',
            'unitPattern-count-other': '{0} kelvin',
          },
          'torque-pound-foot': {
            displayName: 'pound-feet',
            'unitPattern-count-one': '{0} pound-foot',
            'unitPattern-count-other': '{0} pound-feet',
          },
          'torque-newton-meter': {
            displayName: 'newton metres',
            'unitPattern-count-one': '{0} newton metre',
            'unitPattern-count-other': '{0} newton metres',
          },
          'volume-cubic-kilometer': {
            displayName: 'cubic kilometres',
            'unitPattern-count-one': '{0} cubic kilometre',
            'unitPattern-count-other': '{0} cubic kilometres',
          },
          'volume-cubic-meter': {
            displayName: 'cubic metres',
            'unitPattern-count-one': '{0} cubic metre',
            'unitPattern-count-other': '{0} cubic metres',
            perUnitPattern: '{0} per cubic metre',
          },
          'volume-cubic-centimeter': {
            displayName: 'cubic centimetres',
            'unitPattern-count-one': '{0} cubic centimetre',
            'unitPattern-count-other': '{0} cubic centimetres',
            perUnitPattern: '{0} per cubic centimetre',
          },
          'volume-cubic-mile': {
            displayName: 'cubic miles',
            'unitPattern-count-one': '{0} cubic mile',
            'unitPattern-count-other': '{0} cubic miles',
          },
          'volume-cubic-yard': {
            displayName: 'cubic yards',
            'unitPattern-count-one': '{0} cubic yard',
            'unitPattern-count-other': '{0} cubic yards',
          },
          'volume-cubic-foot': {
            displayName: 'cubic feet',
            'unitPattern-count-one': '{0} cubic foot',
            'unitPattern-count-other': '{0} cubic feet',
          },
          'volume-cubic-inch': {
            displayName: 'cubic inches',
            'unitPattern-count-one': '{0} cubic inch',
            'unitPattern-count-other': '{0} cubic inches',
          },
          'volume-megaliter': {
            displayName: 'megalitres',
            'unitPattern-count-one': '{0} megalitre',
            'unitPattern-count-other': '{0} megalitres',
          },
          'volume-hectoliter': {
            displayName: 'hectolitres',
            'unitPattern-count-one': '{0} hectolitre',
            'unitPattern-count-other': '{0} hectolitres',
          },
          'volume-liter': {
            displayName: 'litres',
            'unitPattern-count-one': '{0} litre',
            'unitPattern-count-other': '{0} litres',
            perUnitPattern: '{0} per litre',
          },
          'volume-deciliter': {
            displayName: 'decilitres',
            'unitPattern-count-one': '{0} decilitre',
            'unitPattern-count-other': '{0} decilitres',
          },
          'volume-centiliter': {
            displayName: 'centilitres',
            'unitPattern-count-one': '{0} centilitre',
            'unitPattern-count-other': '{0} centilitres',
          },
          'volume-milliliter': {
            displayName: 'millilitres',
            'unitPattern-count-one': '{0} millilitre',
            'unitPattern-count-other': '{0} millilitres',
          },
          'volume-pint-metric': {
            displayName: 'metric pints',
            'unitPattern-count-one': '{0} metric pint',
            'unitPattern-count-other': '{0} metric pints',
          },
          'volume-cup-metric': {
            displayName: 'metric cups',
            'unitPattern-count-one': '{0} metric cup',
            'unitPattern-count-other': '{0} metric cups',
          },
          'volume-acre-foot': {
            displayName: 'acre-feet',
            'unitPattern-count-one': '{0} acre-foot',
            'unitPattern-count-other': '{0} acre-feet',
          },
          'volume-bushel': {
            displayName: 'bushels',
            'unitPattern-count-one': '{0} bushel',
            'unitPattern-count-other': '{0} bushels',
          },
          'volume-gallon': {
            displayName: 'US gallons',
            'unitPattern-count-one': '{0} US gallon',
            'unitPattern-count-other': '{0} US gallons',
            perUnitPattern: '{0} per US gallon',
          },
          'volume-gallon-imperial': {
            displayName: 'gallons',
            'unitPattern-count-one': '{0} gallon',
            'unitPattern-count-other': '{0} gallons',
            perUnitPattern: '{0} per gallon',
          },
          'volume-quart': {
            displayName: 'quarts',
            'unitPattern-count-one': '{0} quart',
            'unitPattern-count-other': '{0} quarts',
          },
          'volume-pint': {
            displayName: 'pints',
            'unitPattern-count-one': '{0} pint',
            'unitPattern-count-other': '{0} pints',
          },
          'volume-cup': {
            displayName: 'cups',
            'unitPattern-count-one': '{0} cup',
            'unitPattern-count-other': '{0} cups',
          },
          'volume-fluid-ounce': {
            displayName: 'US fluid ounces',
            'unitPattern-count-one': '{0} US fluid ounce',
            'unitPattern-count-other': '{0} US fluid ounces',
          },
          'volume-fluid-ounce-imperial': {
            displayName: 'fluid ounces',
            'unitPattern-count-one': '{0} fluid ounce',
            'unitPattern-count-other': '{0} fluid ounces',
          },
          'volume-tablespoon': {
            displayName: 'tablespoons',
            'unitPattern-count-one': '{0} tablespoon',
            'unitPattern-count-other': '{0} tablespoons',
          },
          'volume-teaspoon': {
            displayName: 'teaspoons',
            'unitPattern-count-one': '{0} teaspoon',
            'unitPattern-count-other': '{0} teaspoons',
          },
          'volume-barrel': {
            displayName: 'barrels',
            'unitPattern-count-one': '{0} barrel',
            'unitPattern-count-other': '{0} barrels',
          },
          coordinateUnit: {
            displayName: 'cardinal direction',
            east: '{0} east',
            north: '{0} north',
            south: '{0} south',
            west: '{0} west',
          },
        },
        short: {
          per: {
            compoundUnitPattern: '{0}/{1}',
          },
          times: {
            compoundUnitPattern: '{0}⋅{1}',
          },
          'acceleration-g-force': {
            displayName: 'g-force',
            'unitPattern-count-one': '{0} G',
            'unitPattern-count-other': '{0} G',
          },
          'acceleration-meter-per-second-squared': {
            displayName: 'metres/sec²',
            'unitPattern-count-one': '{0} m/s²',
            'unitPattern-count-other': '{0} m/s²',
          },
          'angle-revolution': {
            displayName: 'rev',
            'unitPattern-count-one': '{0} rev',
            'unitPattern-count-other': '{0} rev',
          },
          'angle-radian': {
            displayName: 'radians',
            'unitPattern-count-one': '{0} rad',
            'unitPattern-count-other': '{0} rad',
          },
          'angle-degree': {
            displayName: 'degrees',
            'unitPattern-count-one': '{0} deg',
            'unitPattern-count-other': '{0} deg',
          },
          'angle-arc-minute': {
            displayName: 'arcmins',
            'unitPattern-count-one': '{0} arcmin',
            'unitPattern-count-other': '{0} arcmins',
          },
          'angle-arc-second': {
            displayName: 'arcsecs',
            'unitPattern-count-one': '{0} arcsec',
            'unitPattern-count-other': '{0} arcsecs',
          },
          'area-square-kilometer': {
            displayName: 'km²',
            'unitPattern-count-one': '{0} km²',
            'unitPattern-count-other': '{0} km²',
            perUnitPattern: '{0}/km²',
          },
          'area-hectare': {
            displayName: 'hectares',
            'unitPattern-count-one': '{0} ha',
            'unitPattern-count-other': '{0} ha',
          },
          'area-square-meter': {
            displayName: 'metres²',
            'unitPattern-count-one': '{0} m²',
            'unitPattern-count-other': '{0} m²',
            perUnitPattern: '{0}/m²',
          },
          'area-square-centimeter': {
            displayName: 'cm²',
            'unitPattern-count-one': '{0} cm²',
            'unitPattern-count-other': '{0} cm²',
            perUnitPattern: '{0}/cm²',
          },
          'area-square-mile': {
            displayName: 'sq miles',
            'unitPattern-count-one': '{0} sq mi',
            'unitPattern-count-other': '{0} sq mi',
            perUnitPattern: '{0}/mi²',
          },
          'area-acre': {
            displayName: 'acres',
            'unitPattern-count-one': '{0} ac',
            'unitPattern-count-other': '{0} ac',
          },
          'area-square-yard': {
            displayName: 'yards²',
            'unitPattern-count-one': '{0} yd²',
            'unitPattern-count-other': '{0} yd²',
          },
          'area-square-foot': {
            displayName: 'sq feet',
            'unitPattern-count-one': '{0} sq ft',
            'unitPattern-count-other': '{0} sq ft',
          },
          'area-square-inch': {
            displayName: 'inches²',
            'unitPattern-count-one': '{0} in²',
            'unitPattern-count-other': '{0} in²',
            perUnitPattern: '{0}/in²',
          },
          'area-dunam': {
            displayName: 'dunams',
            'unitPattern-count-one': '{0} dunam',
            'unitPattern-count-other': '{0} dunam',
          },
          'concentr-karat': {
            displayName: 'carats',
            'unitPattern-count-one': '{0} ct',
            'unitPattern-count-other': '{0} ct',
          },
          'concentr-milligram-per-deciliter': {
            displayName: 'mg/dl',
            'unitPattern-count-one': '{0} mg/dl',
            'unitPattern-count-other': '{0} mg/dl',
          },
          'concentr-millimole-per-liter': {
            displayName: 'millimol/litre',
            'unitPattern-count-one': '{0} mmol/l',
            'unitPattern-count-other': '{0} mmol/l',
          },
          'concentr-part-per-million': {
            displayName: 'parts/million',
            'unitPattern-count-one': '{0} ppm',
            'unitPattern-count-other': '{0} ppm',
          },
          'concentr-percent': {
            displayName: 'per cent',
            'unitPattern-count-one': '{0}%',
            'unitPattern-count-other': '{0}%',
          },
          'concentr-permille': {
            displayName: 'per mille',
            'unitPattern-count-one': '{0}‰',
            'unitPattern-count-other': '{0}‰',
          },
          'concentr-permyriad': {
            displayName: 'permyriad',
            'unitPattern-count-one': '{0}‱',
            'unitPattern-count-other': '{0}‱',
          },
          'concentr-mole': {
            displayName: 'mole',
            'unitPattern-count-one': '{0} mol',
            'unitPattern-count-other': '{0} mol',
          },
          'consumption-liter-per-kilometer': {
            displayName: 'litres/km',
            'unitPattern-count-one': '{0} l/km',
            'unitPattern-count-other': '{0} l/km',
          },
          'consumption-liter-per-100kilometers': {
            displayName: 'l/100 km',
            'unitPattern-count-one': '{0} l/100 km',
            'unitPattern-count-other': '{0} l/100 km',
          },
          'consumption-mile-per-gallon': {
            displayName: 'miles/gal US',
            'unitPattern-count-one': '{0} mpg US',
            'unitPattern-count-other': '{0} mpg US',
          },
          'consumption-mile-per-gallon-imperial': {
            displayName: 'miles/gal',
            'unitPattern-count-one': '{0} mpg',
            'unitPattern-count-other': '{0} mpg',
          },
          'digital-petabyte': {
            displayName: 'PByte',
            'unitPattern-count-one': '{0} PB',
            'unitPattern-count-other': '{0} PB',
          },
          'digital-terabyte': {
            displayName: 'TByte',
            'unitPattern-count-one': '{0} TB',
            'unitPattern-count-other': '{0} TB',
          },
          'digital-terabit': {
            displayName: 'Tbit',
            'unitPattern-count-one': '{0} Tb',
            'unitPattern-count-other': '{0} Tb',
          },
          'digital-gigabyte': {
            displayName: 'GByte',
            'unitPattern-count-one': '{0} GB',
            'unitPattern-count-other': '{0} GB',
          },
          'digital-gigabit': {
            displayName: 'Gbit',
            'unitPattern-count-one': '{0} Gb',
            'unitPattern-count-other': '{0} Gb',
          },
          'digital-megabyte': {
            displayName: 'MByte',
            'unitPattern-count-one': '{0} MB',
            'unitPattern-count-other': '{0} MB',
          },
          'digital-megabit': {
            displayName: 'Mbit',
            'unitPattern-count-one': '{0} Mb',
            'unitPattern-count-other': '{0} Mb',
          },
          'digital-kilobyte': {
            displayName: 'kByte',
            'unitPattern-count-one': '{0} kB',
            'unitPattern-count-other': '{0} kB',
          },
          'digital-kilobit': {
            displayName: 'kbit',
            'unitPattern-count-one': '{0} kb',
            'unitPattern-count-other': '{0} kb',
          },
          'digital-byte': {
            displayName: 'byte',
            'unitPattern-count-one': '{0} byte',
            'unitPattern-count-other': '{0} byte',
          },
          'digital-bit': {
            displayName: 'bit',
            'unitPattern-count-one': '{0} bit',
            'unitPattern-count-other': '{0} bit',
          },
          'duration-century': {
            displayName: 'c',
            'unitPattern-count-one': '{0} c',
            'unitPattern-count-other': '{0} c',
          },
          'duration-decade': {
            displayName: 'dec',
            'unitPattern-count-one': '{0} dec',
            'unitPattern-count-other': '{0} dec',
          },
          'duration-year': {
            displayName: 'years',
            'unitPattern-count-one': '{0} yr',
            'unitPattern-count-other': '{0} yrs',
            perUnitPattern: '{0}/y',
          },
          'duration-month': {
            displayName: 'months',
            'unitPattern-count-one': '{0} mth',
            'unitPattern-count-other': '{0} mths',
            perUnitPattern: '{0}/m',
          },
          'duration-week': {
            displayName: 'weeks',
            'unitPattern-count-one': '{0} wk',
            'unitPattern-count-other': '{0} wks',
            perUnitPattern: '{0}/w',
          },
          'duration-day': {
            displayName: 'days',
            'unitPattern-count-one': '{0} day',
            'unitPattern-count-other': '{0} days',
            perUnitPattern: '{0}/d',
          },
          'duration-hour': {
            displayName: 'hours',
            'unitPattern-count-one': '{0} hr',
            'unitPattern-count-other': '{0} hrs',
            perUnitPattern: '{0}/h',
          },
          'duration-minute': {
            displayName: 'mins',
            'unitPattern-count-one': '{0} min',
            'unitPattern-count-other': '{0} mins',
            perUnitPattern: '{0}/min',
          },
          'duration-second': {
            displayName: 'secs',
            'unitPattern-count-one': '{0} sec',
            'unitPattern-count-other': '{0} secs',
            perUnitPattern: '{0}/s',
          },
          'duration-millisecond': {
            displayName: 'millisecs',
            'unitPattern-count-one': '{0} ms',
            'unitPattern-count-other': '{0} ms',
          },
          'duration-microsecond': {
            displayName: 'μsecs',
            'unitPattern-count-one': '{0} μs',
            'unitPattern-count-other': '{0} μs',
          },
          'duration-nanosecond': {
            displayName: 'nanosecs',
            'unitPattern-count-one': '{0} ns',
            'unitPattern-count-other': '{0} ns',
          },
          'electric-ampere': {
            displayName: 'amps',
            'unitPattern-count-one': '{0} A',
            'unitPattern-count-other': '{0} A',
          },
          'electric-milliampere': {
            displayName: 'milliamps',
            'unitPattern-count-one': '{0} mA',
            'unitPattern-count-other': '{0} mA',
          },
          'electric-ohm': {
            displayName: 'ohms',
            'unitPattern-count-one': '{0} Ω',
            'unitPattern-count-other': '{0} Ω',
          },
          'electric-volt': {
            displayName: 'volts',
            'unitPattern-count-one': '{0} V',
            'unitPattern-count-other': '{0} V',
          },
          'energy-kilocalorie': {
            displayName: 'kcal',
            'unitPattern-count-one': '{0} kcal',
            'unitPattern-count-other': '{0} kcal',
          },
          'energy-calorie': {
            displayName: 'cal',
            'unitPattern-count-one': '{0} cal',
            'unitPattern-count-other': '{0} cal',
          },
          'energy-foodcalorie': {
            displayName: 'Cal',
            'unitPattern-count-one': '{0} Cal',
            'unitPattern-count-other': '{0} Cal',
          },
          'energy-kilojoule': {
            displayName: 'kilojoule',
            'unitPattern-count-one': '{0} kJ',
            'unitPattern-count-other': '{0} kJ',
          },
          'energy-joule': {
            displayName: 'joules',
            'unitPattern-count-one': '{0} J',
            'unitPattern-count-other': '{0} J',
          },
          'energy-kilowatt-hour': {
            displayName: 'kW-hour',
            'unitPattern-count-one': '{0} kWh',
            'unitPattern-count-other': '{0} kWh',
          },
          'energy-electronvolt': {
            displayName: 'electronvolt',
            'unitPattern-count-one': '{0} eV',
            'unitPattern-count-other': '{0} eV',
          },
          'energy-british-thermal-unit': {
            displayName: 'BTU',
            'unitPattern-count-one': '{0} Btu',
            'unitPattern-count-other': '{0} Btu',
          },
          'energy-therm-us': {
            displayName: 'US therm',
            'unitPattern-count-one': '{0} US therm',
            'unitPattern-count-other': '{0} US therms',
          },
          'force-pound-force': {
            displayName: 'pound-force',
            'unitPattern-count-one': '{0} lbf',
            'unitPattern-count-other': '{0} lbf',
          },
          'force-newton': {
            displayName: 'newton',
            'unitPattern-count-one': '{0} N',
            'unitPattern-count-other': '{0} N',
          },
          'frequency-gigahertz': {
            displayName: 'GHz',
            'unitPattern-count-one': '{0} GHz',
            'unitPattern-count-other': '{0} GHz',
          },
          'frequency-megahertz': {
            displayName: 'MHz',
            'unitPattern-count-one': '{0} MHz',
            'unitPattern-count-other': '{0} MHz',
          },
          'frequency-kilohertz': {
            displayName: 'kHz',
            'unitPattern-count-one': '{0} kHz',
            'unitPattern-count-other': '{0} kHz',
          },
          'frequency-hertz': {
            displayName: 'Hz',
            'unitPattern-count-one': '{0} Hz',
            'unitPattern-count-other': '{0} Hz',
          },
          'graphics-em': {
            displayName: 'em',
            'unitPattern-count-one': '{0} em',
            'unitPattern-count-other': '{0} em',
          },
          'graphics-pixel': {
            displayName: 'pixels',
            'unitPattern-count-one': '{0} px',
            'unitPattern-count-other': '{0} px',
          },
          'graphics-megapixel': {
            displayName: 'megapixels',
            'unitPattern-count-one': '{0} MP',
            'unitPattern-count-other': '{0} MP',
          },
          'graphics-pixel-per-centimeter': {
            displayName: 'ppcm',
            'unitPattern-count-one': '{0} ppcm',
            'unitPattern-count-other': '{0} ppcm',
          },
          'graphics-pixel-per-inch': {
            displayName: 'ppi',
            'unitPattern-count-one': '{0} ppi',
            'unitPattern-count-other': '{0} ppi',
          },
          'graphics-dot-per-centimeter': {
            displayName: 'dpcm',
            'unitPattern-count-one': '{0} dpcm',
            'unitPattern-count-other': '{0} dpcm',
          },
          'graphics-dot-per-inch': {
            displayName: 'dpi',
            'unitPattern-count-one': '{0} dpi',
            'unitPattern-count-other': '{0} dpi',
          },
          'length-kilometer': {
            displayName: 'km',
            'unitPattern-count-one': '{0} km',
            'unitPattern-count-other': '{0} km',
            perUnitPattern: '{0}/km',
          },
          'length-meter': {
            displayName: 'metres',
            'unitPattern-count-one': '{0} m',
            'unitPattern-count-other': '{0} m',
            perUnitPattern: '{0}/m',
          },
          'length-decimeter': {
            displayName: 'dm',
            'unitPattern-count-one': '{0} dm',
            'unitPattern-count-other': '{0} dm',
          },
          'length-centimeter': {
            displayName: 'cm',
            'unitPattern-count-one': '{0} cm',
            'unitPattern-count-other': '{0} cm',
            perUnitPattern: '{0}/cm',
          },
          'length-millimeter': {
            displayName: 'mm',
            'unitPattern-count-one': '{0} mm',
            'unitPattern-count-other': '{0} mm',
          },
          'length-micrometer': {
            displayName: 'µmetres',
            'unitPattern-count-one': '{0} µm',
            'unitPattern-count-other': '{0} µm',
          },
          'length-nanometer': {
            displayName: 'nm',
            'unitPattern-count-one': '{0} nm',
            'unitPattern-count-other': '{0} nm',
          },
          'length-picometer': {
            displayName: 'pm',
            'unitPattern-count-one': '{0} pm',
            'unitPattern-count-other': '{0} pm',
          },
          'length-mile': {
            displayName: 'miles',
            'unitPattern-count-one': '{0} mi',
            'unitPattern-count-other': '{0} mi',
          },
          'length-yard': {
            displayName: 'yards',
            'unitPattern-count-one': '{0} yd',
            'unitPattern-count-other': '{0} yd',
          },
          'length-foot': {
            displayName: 'feet',
            'unitPattern-count-one': '{0} ft',
            'unitPattern-count-other': '{0} ft',
            perUnitPattern: '{0}/ft',
          },
          'length-inch': {
            displayName: 'inches',
            'unitPattern-count-one': '{0} in',
            'unitPattern-count-other': '{0} in',
            perUnitPattern: '{0}/in',
          },
          'length-parsec': {
            displayName: 'parsecs',
            'unitPattern-count-one': '{0} pc',
            'unitPattern-count-other': '{0} pc',
          },
          'length-light-year': {
            displayName: 'light yrs',
            'unitPattern-count-one': '{0} ly',
            'unitPattern-count-other': '{0} ly',
          },
          'length-astronomical-unit': {
            displayName: 'au',
            'unitPattern-count-one': '{0} au',
            'unitPattern-count-other': '{0} au',
          },
          'length-furlong': {
            displayName: 'furlongs',
            'unitPattern-count-one': '{0} fur',
            'unitPattern-count-other': '{0} fur',
          },
          'length-fathom': {
            displayName: 'fathoms',
            'unitPattern-count-one': '{0} fth',
            'unitPattern-count-other': '{0} fth',
          },
          'length-nautical-mile': {
            displayName: 'nmi',
            'unitPattern-count-one': '{0} nmi',
            'unitPattern-count-other': '{0} nmi',
          },
          'length-mile-scandinavian': {
            displayName: 'smi',
            'unitPattern-count-one': '{0} smi',
            'unitPattern-count-other': '{0} smi',
          },
          'length-point': {
            displayName: 'points',
            'unitPattern-count-one': '{0} pt',
            'unitPattern-count-other': '{0} pt',
          },
          'length-solar-radius': {
            displayName: 'solar radii',
            'unitPattern-count-one': '{0} R☉',
            'unitPattern-count-other': '{0} R☉',
          },
          'light-lux': {
            displayName: 'lux',
            'unitPattern-count-one': '{0} lx',
            'unitPattern-count-other': '{0} lx',
          },
          'light-solar-luminosity': {
            displayName: 'solar luminosities',
            'unitPattern-count-one': '{0} L☉',
            'unitPattern-count-other': '{0} L☉',
          },
          'mass-metric-ton': {
            displayName: 't',
            'unitPattern-count-one': '{0} t',
            'unitPattern-count-other': '{0} t',
          },
          'mass-kilogram': {
            displayName: 'kg',
            'unitPattern-count-one': '{0} kg',
            'unitPattern-count-other': '{0} kg',
            perUnitPattern: '{0}/kg',
          },
          'mass-gram': {
            displayName: 'grams',
            'unitPattern-count-one': '{0} g',
            'unitPattern-count-other': '{0} g',
            perUnitPattern: '{0}/g',
          },
          'mass-milligram': {
            displayName: 'mg',
            'unitPattern-count-one': '{0} mg',
            'unitPattern-count-other': '{0} mg',
          },
          'mass-microgram': {
            displayName: 'µg',
            'unitPattern-count-one': '{0} µg',
            'unitPattern-count-other': '{0} µg',
          },
          'mass-ton': {
            displayName: 'tons',
            'unitPattern-count-one': '{0} tn',
            'unitPattern-count-other': '{0} tn',
          },
          'mass-stone': {
            displayName: 'stone',
            'unitPattern-count-one': '{0} st',
            'unitPattern-count-other': '{0} st',
          },
          'mass-pound': {
            displayName: 'pounds',
            'unitPattern-count-one': '{0} lb',
            'unitPattern-count-other': '{0} lb',
            perUnitPattern: '{0}/lb',
          },
          'mass-ounce': {
            displayName: 'oz',
            'unitPattern-count-one': '{0} oz',
            'unitPattern-count-other': '{0} oz',
            perUnitPattern: '{0}/oz',
          },
          'mass-ounce-troy': {
            displayName: 'oz troy',
            'unitPattern-count-one': '{0} oz t',
            'unitPattern-count-other': '{0} oz t',
          },
          'mass-carat': {
            displayName: 'carats',
            'unitPattern-count-one': '{0} CD',
            'unitPattern-count-other': '{0} CD',
          },
          'mass-dalton': {
            displayName: 'daltons',
            'unitPattern-count-one': '{0} Da',
            'unitPattern-count-other': '{0} Da',
          },
          'mass-earth-mass': {
            displayName: 'Earth masses',
            'unitPattern-count-one': '{0} M⊕',
            'unitPattern-count-other': '{0} M⊕',
          },
          'mass-solar-mass': {
            displayName: 'solar masses',
            'unitPattern-count-one': '{0} M☉',
            'unitPattern-count-other': '{0} M☉',
          },
          'power-gigawatt': {
            displayName: 'GW',
            'unitPattern-count-one': '{0} GW',
            'unitPattern-count-other': '{0} GW',
          },
          'power-megawatt': {
            displayName: 'MW',
            'unitPattern-count-one': '{0} MW',
            'unitPattern-count-other': '{0} MW',
          },
          'power-kilowatt': {
            displayName: 'kW',
            'unitPattern-count-one': '{0} kW',
            'unitPattern-count-other': '{0} kW',
          },
          'power-watt': {
            displayName: 'watts',
            'unitPattern-count-one': '{0} W',
            'unitPattern-count-other': '{0} W',
          },
          'power-milliwatt': {
            displayName: 'mW',
            'unitPattern-count-one': '{0} mW',
            'unitPattern-count-other': '{0} mW',
          },
          'power-horsepower': {
            displayName: 'hp',
            'unitPattern-count-one': '{0} hp',
            'unitPattern-count-other': '{0} hp',
          },
          'pressure-millimeter-of-mercury': {
            displayName: 'mmHg',
            'unitPattern-count-one': '{0} mmHg',
            'unitPattern-count-other': '{0} mmHg',
          },
          'pressure-pound-per-square-inch': {
            displayName: 'psi',
            'unitPattern-count-one': '{0} psi',
            'unitPattern-count-other': '{0} psi',
          },
          'pressure-inch-hg': {
            displayName: 'inHG',
            'unitPattern-count-one': '{0} inHg',
            'unitPattern-count-other': '{0} inHg',
          },
          'pressure-bar': {
            displayName: 'bar',
            'unitPattern-count-one': '{0} bar',
            'unitPattern-count-other': '{0} bars',
          },
          'pressure-millibar': {
            displayName: 'mbar',
            'unitPattern-count-one': '{0} mbar',
            'unitPattern-count-other': '{0} mbar',
          },
          'pressure-atmosphere': {
            displayName: 'atm',
            'unitPattern-count-one': '{0} atm',
            'unitPattern-count-other': '{0} atm',
          },
          'pressure-pascal': {
            displayName: 'Pa',
            'unitPattern-count-one': '{0} Pa',
            'unitPattern-count-other': '{0} Pa',
          },
          'pressure-hectopascal': {
            displayName: 'hPa',
            'unitPattern-count-one': '{0} hPa',
            'unitPattern-count-other': '{0} hPa',
          },
          'pressure-kilopascal': {
            displayName: 'kPa',
            'unitPattern-count-one': '{0} kPa',
            'unitPattern-count-other': '{0} kPa',
          },
          'pressure-megapascal': {
            displayName: 'MPa',
            'unitPattern-count-one': '{0} MPa',
            'unitPattern-count-other': '{0} MPa',
          },
          'speed-kilometer-per-hour': {
            displayName: 'km/hour',
            'unitPattern-count-one': '{0} km/h',
            'unitPattern-count-other': '{0} km/h',
          },
          'speed-meter-per-second': {
            displayName: 'metres/sec',
            'unitPattern-count-one': '{0} m/s',
            'unitPattern-count-other': '{0} m/s',
          },
          'speed-mile-per-hour': {
            displayName: 'miles/hour',
            'unitPattern-count-one': '{0} mph',
            'unitPattern-count-other': '{0} mph',
          },
          'speed-knot': {
            displayName: 'kn',
            'unitPattern-count-one': '{0} kn',
            'unitPattern-count-other': '{0} kn',
          },
          'temperature-generic': {
            displayName: '°',
            'unitPattern-count-other': '{0}°',
          },
          'temperature-celsius': {
            displayName: 'deg. C',
            'unitPattern-count-one': '{0}°C',
            'unitPattern-count-other': '{0}°C',
          },
          'temperature-fahrenheit': {
            displayName: 'deg. F',
            'unitPattern-count-one': '{0}°F',
            'unitPattern-count-other': '{0}°F',
          },
          'temperature-kelvin': {
            displayName: 'K',
            'unitPattern-count-one': '{0} K',
            'unitPattern-count-other': '{0} K',
          },
          'torque-pound-foot': {
            displayName: 'lbf⋅ft',
            'unitPattern-count-one': '{0} lbf⋅ft',
            'unitPattern-count-other': '{0} lbf⋅ft',
          },
          'torque-newton-meter': {
            displayName: 'N⋅m',
            'unitPattern-count-one': '{0} N⋅m',
            'unitPattern-count-other': '{0} N⋅m',
          },
          'volume-cubic-kilometer': {
            displayName: 'km³',
            'unitPattern-count-one': '{0} km³',
            'unitPattern-count-other': '{0} km³',
          },
          'volume-cubic-meter': {
            displayName: 'm³',
            'unitPattern-count-one': '{0} m³',
            'unitPattern-count-other': '{0} m³',
            perUnitPattern: '{0}/m³',
          },
          'volume-cubic-centimeter': {
            displayName: 'cm³',
            'unitPattern-count-one': '{0} cm³',
            'unitPattern-count-other': '{0} cm³',
            perUnitPattern: '{0}/cm³',
          },
          'volume-cubic-mile': {
            displayName: 'mi³',
            'unitPattern-count-one': '{0} mi³',
            'unitPattern-count-other': '{0} mi³',
          },
          'volume-cubic-yard': {
            displayName: 'yards³',
            'unitPattern-count-one': '{0} yd³',
            'unitPattern-count-other': '{0} yd³',
          },
          'volume-cubic-foot': {
            displayName: 'feet³',
            'unitPattern-count-one': '{0} ft³',
            'unitPattern-count-other': '{0} ft³',
          },
          'volume-cubic-inch': {
            displayName: 'inches³',
            'unitPattern-count-one': '{0} in³',
            'unitPattern-count-other': '{0} in³',
          },
          'volume-megaliter': {
            displayName: 'Ml',
            'unitPattern-count-one': '{0} Ml',
            'unitPattern-count-other': '{0} Ml',
          },
          'volume-hectoliter': {
            displayName: 'hl',
            'unitPattern-count-one': '{0} hl',
            'unitPattern-count-other': '{0} hl',
          },
          'volume-liter': {
            displayName: 'litres',
            'unitPattern-count-one': '{0} l',
            'unitPattern-count-other': '{0} l',
            perUnitPattern: '{0}/l',
          },
          'volume-deciliter': {
            displayName: 'dl',
            'unitPattern-count-one': '{0} dl',
            'unitPattern-count-other': '{0} dl',
          },
          'volume-centiliter': {
            displayName: 'cl',
            'unitPattern-count-one': '{0} cl',
            'unitPattern-count-other': '{0} cl',
          },
          'volume-milliliter': {
            displayName: 'ml',
            'unitPattern-count-one': '{0} ml',
            'unitPattern-count-other': '{0} ml',
          },
          'volume-pint-metric': {
            displayName: 'mpt',
            'unitPattern-count-one': '{0} mpt',
            'unitPattern-count-other': '{0} mpt',
          },
          'volume-cup-metric': {
            displayName: 'mcup',
            'unitPattern-count-one': '{0} mc',
            'unitPattern-count-other': '{0} mc',
          },
          'volume-acre-foot': {
            displayName: 'acre ft',
            'unitPattern-count-one': '{0} ac ft',
            'unitPattern-count-other': '{0} ac ft',
          },
          'volume-bushel': {
            displayName: 'bushels',
            'unitPattern-count-one': '{0} bu',
            'unitPattern-count-other': '{0} bu',
          },
          'volume-gallon': {
            displayName: 'US gal',
            'unitPattern-count-one': '{0} gal US',
            'unitPattern-count-other': '{0} gal US',
            perUnitPattern: '{0}/gal US',
          },
          'volume-gallon-imperial': {
            displayName: 'gal',
            'unitPattern-count-one': '{0} gal',
            'unitPattern-count-other': '{0} gal',
            perUnitPattern: '{0}/gal',
          },
          'volume-quart': {
            displayName: 'qts',
            'unitPattern-count-one': '{0} qt',
            'unitPattern-count-other': '{0} qt',
          },
          'volume-pint': {
            displayName: 'pints',
            'unitPattern-count-one': '{0} pt',
            'unitPattern-count-other': '{0} pt',
          },
          'volume-cup': {
            displayName: 'cups',
            'unitPattern-count-one': '{0} c',
            'unitPattern-count-other': '{0} c',
          },
          'volume-fluid-ounce': {
            displayName: 'US fl oz',
            'unitPattern-count-one': '{0} US fl oz',
            'unitPattern-count-other': '{0} US fl oz',
          },
          'volume-fluid-ounce-imperial': {
            displayName: 'fl oz',
            'unitPattern-count-one': '{0} fl oz',
            'unitPattern-count-other': '{0} fl oz',
          },
          'volume-tablespoon': {
            displayName: 'tbsp',
            'unitPattern-count-one': '{0} tbsp',
            'unitPattern-count-other': '{0} tbsp',
          },
          'volume-teaspoon': {
            displayName: 'tsp',
            'unitPattern-count-one': '{0} tsp',
            'unitPattern-count-other': '{0} tsp',
          },
          'volume-barrel': {
            displayName: 'barrel',
            'unitPattern-count-one': '{0} bbl',
            'unitPattern-count-other': '{0} bbl',
          },
          coordinateUnit: {
            displayName: 'direction',
            east: '{0} E',
            north: '{0} N',
            south: '{0} S',
            west: '{0} W',
          },
        },
        narrow: {
          per: {
            compoundUnitPattern: '{0}/{1}',
          },
          times: {
            compoundUnitPattern: '{0}⋅{1}',
          },
          'acceleration-g-force': {
            displayName: 'g-force',
            'unitPattern-count-one': '{0}G',
            'unitPattern-count-other': '{0}Gs',
          },
          'acceleration-meter-per-second-squared': {
            displayName: 'm/s²',
            'unitPattern-count-one': '{0}m/s²',
            'unitPattern-count-other': '{0}m/s²',
          },
          'angle-revolution': {
            displayName: 'rev',
            'unitPattern-count-one': '{0}rev',
            'unitPattern-count-other': '{0}rev',
          },
          'angle-radian': {
            displayName: 'rad',
            'unitPattern-count-one': '{0}rad',
            'unitPattern-count-other': '{0}rad',
          },
          'angle-degree': {
            displayName: 'deg',
            'unitPattern-count-one': '{0}°',
            'unitPattern-count-other': '{0}°',
          },
          'angle-arc-minute': {
            displayName: 'arcmin',
            'unitPattern-count-one': '{0}′',
            'unitPattern-count-other': '{0}′',
          },
          'angle-arc-second': {
            displayName: 'arcsec',
            'unitPattern-count-one': '{0}″',
            'unitPattern-count-other': '{0}″',
          },
          'area-square-kilometer': {
            displayName: 'km²',
            'unitPattern-count-one': '{0} km²',
            'unitPattern-count-other': '{0} km²',
            perUnitPattern: '{0}/km²',
          },
          'area-hectare': {
            displayName: 'hectare',
            'unitPattern-count-one': '{0}ha',
            'unitPattern-count-other': '{0}ha',
          },
          'area-square-meter': {
            displayName: 'metres²',
            'unitPattern-count-one': '{0} m²',
            'unitPattern-count-other': '{0} m²',
            perUnitPattern: '{0}/m²',
          },
          'area-square-centimeter': {
            displayName: 'cm²',
            'unitPattern-count-one': '{0}cm²',
            'unitPattern-count-other': '{0}cm²',
            perUnitPattern: '{0}/cm²',
          },
          'area-square-mile': {
            displayName: 'mi²',
            'unitPattern-count-one': '{0}mi²',
            'unitPattern-count-other': '{0}mi²',
            perUnitPattern: '{0}/mi²',
          },
          'area-acre': {
            displayName: 'acre',
            'unitPattern-count-one': '{0}ac',
            'unitPattern-count-other': '{0}ac',
          },
          'area-square-yard': {
            displayName: 'yd²',
            'unitPattern-count-one': '{0}yd²',
            'unitPattern-count-other': '{0}yd²',
          },
          'area-square-foot': {
            displayName: 'ft²',
            'unitPattern-count-one': '{0}ft²',
            'unitPattern-count-other': '{0}ft²',
          },
          'area-square-inch': {
            displayName: 'in²',
            'unitPattern-count-one': '{0}in²',
            'unitPattern-count-other': '{0}in²',
            perUnitPattern: '{0}/in²',
          },
          'area-dunam': {
            displayName: 'dunam',
            'unitPattern-count-one': '{0}dunam',
            'unitPattern-count-other': '{0}dunam',
          },
          'concentr-karat': {
            displayName: 'karat',
            'unitPattern-count-one': '{0}kt',
            'unitPattern-count-other': '{0}kt',
          },
          'concentr-milligram-per-deciliter': {
            displayName: 'mg/dl',
            'unitPattern-count-one': '{0}mg/dl',
            'unitPattern-count-other': '{0}mg/dl',
          },
          'concentr-millimole-per-liter': {
            displayName: 'mmol/l',
            'unitPattern-count-one': '{0}mmol/l',
            'unitPattern-count-other': '{0}mmol/l',
          },
          'concentr-part-per-million': {
            displayName: 'ppm',
            'unitPattern-count-one': '{0}ppm',
            'unitPattern-count-other': '{0}ppm',
          },
          'concentr-percent': {
            displayName: '%',
            'unitPattern-count-one': '{0}%',
            'unitPattern-count-other': '{0}%',
          },
          'concentr-permille': {
            displayName: '‰',
            'unitPattern-count-one': '{0}‰',
            'unitPattern-count-other': '{0}‰',
          },
          'concentr-permyriad': {
            displayName: '‱',
            'unitPattern-count-one': '{0}‱',
            'unitPattern-count-other': '{0}‱',
          },
          'concentr-mole': {
            displayName: 'mol',
            'unitPattern-count-one': '{0}mol',
            'unitPattern-count-other': '{0}mol',
          },
          'consumption-liter-per-kilometer': {
            displayName: 'l/km',
            'unitPattern-count-one': '{0}l/km',
            'unitPattern-count-other': '{0}l/km',
          },
          'consumption-liter-per-100kilometers': {
            displayName: 'l/100km',
            'unitPattern-count-one': '{0}l/100km',
            'unitPattern-count-other': '{0}l/100km',
          },
          'consumption-mile-per-gallon': {
            displayName: 'mpg US',
            'unitPattern-count-one': '{0}mpgUS',
            'unitPattern-count-other': '{0}mpgUS',
          },
          'consumption-mile-per-gallon-imperial': {
            displayName: 'mpg',
            'unitPattern-count-one': '{0}mpg',
            'unitPattern-count-other': '{0}mpg',
          },
          'digital-petabyte': {
            displayName: 'PByte',
            'unitPattern-count-one': '{0}PB',
            'unitPattern-count-other': '{0}PB',
          },
          'digital-terabyte': {
            displayName: 'TByte',
            'unitPattern-count-one': '{0}TB',
            'unitPattern-count-other': '{0}TB',
          },
          'digital-terabit': {
            displayName: 'Tbit',
            'unitPattern-count-one': '{0}Tb',
            'unitPattern-count-other': '{0}Tb',
          },
          'digital-gigabyte': {
            displayName: 'GByte',
            'unitPattern-count-one': '{0}GB',
            'unitPattern-count-other': '{0}GB',
          },
          'digital-gigabit': {
            displayName: 'Gbit',
            'unitPattern-count-one': '{0}Gb',
            'unitPattern-count-other': '{0}Gb',
          },
          'digital-megabyte': {
            displayName: 'MByte',
            'unitPattern-count-one': '{0}MB',
            'unitPattern-count-other': '{0}MB',
          },
          'digital-megabit': {
            displayName: 'Mbit',
            'unitPattern-count-one': '{0}Mb',
            'unitPattern-count-other': '{0}Mb',
          },
          'digital-kilobyte': {
            displayName: 'kByte',
            'unitPattern-count-one': '{0}kB',
            'unitPattern-count-other': '{0}kB',
          },
          'digital-kilobit': {
            displayName: 'kbit',
            'unitPattern-count-one': '{0}kb',
            'unitPattern-count-other': '{0}kb',
          },
          'digital-byte': {
            displayName: 'byte',
            'unitPattern-count-one': '{0}B',
            'unitPattern-count-other': '{0}B',
          },
          'digital-bit': {
            displayName: 'bit',
            'unitPattern-count-one': '{0}bit',
            'unitPattern-count-other': '{0}bit',
          },
          'duration-century': {
            displayName: 'c',
            'unitPattern-count-one': '{0} c',
            'unitPattern-count-other': '{0} c',
          },
          'duration-decade': {
            displayName: 'dec',
            'unitPattern-count-one': '{0}dec',
            'unitPattern-count-other': '{0}dec',
          },
          'duration-year': {
            displayName: 'yr',
            'unitPattern-count-one': '{0}y',
            'unitPattern-count-other': '{0}y',
            perUnitPattern: '{0}/y',
          },
          'duration-month': {
            displayName: 'month',
            'unitPattern-count-one': '{0}m',
            'unitPattern-count-other': '{0}m',
            perUnitPattern: '{0}/m',
          },
          'duration-week': {
            displayName: 'wk',
            'unitPattern-count-one': '{0}w',
            'unitPattern-count-other': '{0}w',
            perUnitPattern: '{0}/w',
          },
          'duration-day': {
            displayName: 'day',
            'unitPattern-count-one': '{0}d',
            'unitPattern-count-other': '{0}d',
            perUnitPattern: '{0}/d',
          },
          'duration-hour': {
            displayName: 'hour',
            'unitPattern-count-one': '{0}h',
            'unitPattern-count-other': '{0}h',
            perUnitPattern: '{0}/h',
          },
          'duration-minute': {
            displayName: 'min',
            'unitPattern-count-one': '{0}m',
            'unitPattern-count-other': '{0}m',
            perUnitPattern: '{0}/min',
          },
          'duration-second': {
            displayName: 'sec',
            'unitPattern-count-one': '{0}s',
            'unitPattern-count-other': '{0}s',
            perUnitPattern: '{0}/s',
          },
          'duration-millisecond': {
            displayName: 'msec',
            'unitPattern-count-one': '{0}ms',
            'unitPattern-count-other': '{0}ms',
          },
          'duration-microsecond': {
            displayName: 'μsec',
            'unitPattern-count-one': '{0}μs',
            'unitPattern-count-other': '{0}μs',
          },
          'duration-nanosecond': {
            displayName: 'ns',
            'unitPattern-count-one': '{0}ns',
            'unitPattern-count-other': '{0}ns',
          },
          'electric-ampere': {
            displayName: 'amp',
            'unitPattern-count-one': '{0}A',
            'unitPattern-count-other': '{0}A',
          },
          'electric-milliampere': {
            displayName: 'mA',
            'unitPattern-count-one': '{0}mA',
            'unitPattern-count-other': '{0}mA',
          },
          'electric-ohm': {
            displayName: 'ohm',
            'unitPattern-count-one': '{0}Ω',
            'unitPattern-count-other': '{0}Ω',
          },
          'electric-volt': {
            displayName: 'volt',
            'unitPattern-count-one': '{0}V',
            'unitPattern-count-other': '{0}V',
          },
          'energy-kilocalorie': {
            displayName: 'kcal',
            'unitPattern-count-one': '{0}kcal',
            'unitPattern-count-other': '{0}kcal',
          },
          'energy-calorie': {
            displayName: 'cal',
            'unitPattern-count-one': '{0}cal',
            'unitPattern-count-other': '{0}cal',
          },
          'energy-foodcalorie': {
            displayName: 'Cal',
            'unitPattern-count-one': '{0}Cal',
            'unitPattern-count-other': '{0}Cal',
          },
          'energy-kilojoule': {
            displayName: 'kJ',
            'unitPattern-count-one': '{0}kJ',
            'unitPattern-count-other': '{0}kJ',
          },
          'energy-joule': {
            displayName: 'joule',
            'unitPattern-count-one': '{0}J',
            'unitPattern-count-other': '{0}J',
          },
          'energy-kilowatt-hour': {
            displayName: 'kWh',
            'unitPattern-count-one': '{0}kWh',
            'unitPattern-count-other': '{0}kWh',
          },
          'energy-electronvolt': {
            displayName: 'eV',
            'unitPattern-count-one': '{0}eV',
            'unitPattern-count-other': '{0}eV',
          },
          'energy-british-thermal-unit': {
            displayName: 'BTU',
            'unitPattern-count-one': '{0}Btu',
            'unitPattern-count-other': '{0}Btu',
          },
          'energy-therm-us': {
            displayName: 'US therm',
            'unitPattern-count-one': '{0}US therm',
            'unitPattern-count-other': '{0}US therms',
          },
          'force-pound-force': {
            displayName: 'lbf',
            'unitPattern-count-one': '{0}lbf',
            'unitPattern-count-other': '{0}lbf',
          },
          'force-newton': {
            displayName: 'N',
            'unitPattern-count-one': '{0}N',
            'unitPattern-count-other': '{0}N',
          },
          'frequency-gigahertz': {
            displayName: 'GHz',
            'unitPattern-count-one': '{0}GHz',
            'unitPattern-count-other': '{0}GHz',
          },
          'frequency-megahertz': {
            displayName: 'MHz',
            'unitPattern-count-one': '{0}MHz',
            'unitPattern-count-other': '{0}MHz',
          },
          'frequency-kilohertz': {
            displayName: 'kHz',
            'unitPattern-count-one': '{0}kHz',
            'unitPattern-count-other': '{0}kHz',
          },
          'frequency-hertz': {
            displayName: 'Hz',
            'unitPattern-count-one': '{0}Hz',
            'unitPattern-count-other': '{0}Hz',
          },
          'graphics-em': {
            displayName: 'em',
            'unitPattern-count-one': '{0}em',
            'unitPattern-count-other': '{0}em',
          },
          'graphics-pixel': {
            displayName: 'px',
            'unitPattern-count-one': '{0}px',
            'unitPattern-count-other': '{0}px',
          },
          'graphics-megapixel': {
            displayName: 'MP',
            'unitPattern-count-one': '{0}MP',
            'unitPattern-count-other': '{0}MP',
          },
          'graphics-pixel-per-centimeter': {
            displayName: 'ppcm',
            'unitPattern-count-one': '{0}ppcm',
            'unitPattern-count-other': '{0}ppcm',
          },
          'graphics-pixel-per-inch': {
            displayName: 'ppi',
            'unitPattern-count-one': '{0}ppi',
            'unitPattern-count-other': '{0}ppi',
          },
          'graphics-dot-per-centimeter': {
            displayName: 'dpcm',
            'unitPattern-count-one': '{0}dpcm',
            'unitPattern-count-other': '{0}dpcm',
          },
          'graphics-dot-per-inch': {
            displayName: 'dpi',
            'unitPattern-count-one': '{0}dpi',
            'unitPattern-count-other': '{0}dpi',
          },
          'length-kilometer': {
            displayName: 'km',
            'unitPattern-count-one': '{0}km',
            'unitPattern-count-other': '{0}km',
            perUnitPattern: '{0}/km',
          },
          'length-meter': {
            displayName: 'metre',
            'unitPattern-count-one': '{0}m',
            'unitPattern-count-other': '{0}m',
            perUnitPattern: '{0}/m',
          },
          'length-decimeter': {
            displayName: 'dm',
            'unitPattern-count-one': '{0}dm',
            'unitPattern-count-other': '{0}dm',
          },
          'length-centimeter': {
            displayName: 'cm',
            'unitPattern-count-one': '{0}cm',
            'unitPattern-count-other': '{0}cm',
            perUnitPattern: '{0}/cm',
          },
          'length-millimeter': {
            displayName: 'mm',
            'unitPattern-count-one': '{0}mm',
            'unitPattern-count-other': '{0}mm',
          },
          'length-micrometer': {
            displayName: 'µm',
            'unitPattern-count-one': '{0}µm',
            'unitPattern-count-other': '{0}µm',
          },
          'length-nanometer': {
            displayName: 'nm',
            'unitPattern-count-one': '{0}nm',
            'unitPattern-count-other': '{0}nm',
          },
          'length-picometer': {
            displayName: 'pm',
            'unitPattern-count-one': '{0}pm',
            'unitPattern-count-other': '{0}pm',
          },
          'length-mile': {
            displayName: 'mi',
            'unitPattern-count-one': '{0}mi',
            'unitPattern-count-other': '{0}mi',
          },
          'length-yard': {
            displayName: 'yd',
            'unitPattern-count-one': '{0}yd',
            'unitPattern-count-other': '{0}yd',
          },
          'length-foot': {
            displayName: 'ft',
            'unitPattern-count-one': '{0}′',
            'unitPattern-count-other': '{0}′',
            perUnitPattern: '{0}/ft',
          },
          'length-inch': {
            displayName: 'in',
            'unitPattern-count-one': '{0}″',
            'unitPattern-count-other': '{0}″',
            perUnitPattern: '{0}/in',
          },
          'length-parsec': {
            displayName: 'parsec',
            'unitPattern-count-one': '{0}pc',
            'unitPattern-count-other': '{0}pc',
          },
          'length-light-year': {
            displayName: 'ly',
            'unitPattern-count-one': '{0}ly',
            'unitPattern-count-other': '{0}ly',
          },
          'length-astronomical-unit': {
            displayName: 'au',
            'unitPattern-count-one': '{0}au',
            'unitPattern-count-other': '{0}au',
          },
          'length-furlong': {
            displayName: 'furlong',
            'unitPattern-count-one': '{0}fur',
            'unitPattern-count-other': '{0}fur',
          },
          'length-fathom': {
            displayName: 'fathom',
            'unitPattern-count-one': '{0}fth',
            'unitPattern-count-other': '{0}fth',
          },
          'length-nautical-mile': {
            displayName: 'nmi',
            'unitPattern-count-one': '{0}nmi',
            'unitPattern-count-other': '{0}nmi',
          },
          'length-mile-scandinavian': {
            displayName: 'smi',
            'unitPattern-count-one': '{0}smi',
            'unitPattern-count-other': '{0}smi',
          },
          'length-point': {
            displayName: 'pts',
            'unitPattern-count-one': '{0}pt',
            'unitPattern-count-other': '{0}pt',
          },
          'length-solar-radius': {
            displayName: 'R☉',
            'unitPattern-count-one': '{0}R☉',
            'unitPattern-count-other': '{0}R☉',
          },
          'light-lux': {
            displayName: 'lux',
            'unitPattern-count-one': '{0}lx',
            'unitPattern-count-other': '{0}lx',
          },
          'light-solar-luminosity': {
            displayName: 'L☉',
            'unitPattern-count-one': '{0}L☉',
            'unitPattern-count-other': '{0}L☉',
          },
          'mass-metric-ton': {
            displayName: 't',
            'unitPattern-count-one': '{0}t',
            'unitPattern-count-other': '{0}t',
          },
          'mass-kilogram': {
            displayName: 'kg',
            'unitPattern-count-one': '{0}kg',
            'unitPattern-count-other': '{0}kg',
            perUnitPattern: '{0}/kg',
          },
          'mass-gram': {
            displayName: 'gram',
            'unitPattern-count-one': '{0}g',
            'unitPattern-count-other': '{0}g',
            perUnitPattern: '{0}/g',
          },
          'mass-milligram': {
            displayName: 'mg',
            'unitPattern-count-one': '{0}mg',
            'unitPattern-count-other': '{0}mg',
          },
          'mass-microgram': {
            displayName: 'µg',
            'unitPattern-count-one': '{0}µg',
            'unitPattern-count-other': '{0}µg',
          },
          'mass-ton': {
            displayName: 'ton',
            'unitPattern-count-one': '{0}tn',
            'unitPattern-count-other': '{0}tn',
          },
          'mass-stone': {
            displayName: 'stone',
            'unitPattern-count-one': '{0}st',
            'unitPattern-count-other': '{0}st',
          },
          'mass-pound': {
            displayName: 'lb',
            'unitPattern-count-one': '{0}lb',
            'unitPattern-count-other': '{0}lb',
            perUnitPattern: '{0}/lb',
          },
          'mass-ounce': {
            displayName: 'oz',
            'unitPattern-count-one': '{0}oz',
            'unitPattern-count-other': '{0}oz',
            perUnitPattern: '{0}/oz',
          },
          'mass-ounce-troy': {
            displayName: 'oz t',
            'unitPattern-count-one': '{0}oz t',
            'unitPattern-count-other': '{0}oz t',
          },
          'mass-carat': {
            displayName: 'carat',
            'unitPattern-count-one': '{0}CD',
            'unitPattern-count-other': '{0}CD',
          },
          'mass-dalton': {
            displayName: 'Da',
            'unitPattern-count-one': '{0}Da',
            'unitPattern-count-other': '{0}Da',
          },
          'mass-earth-mass': {
            displayName: 'M⊕',
            'unitPattern-count-one': '{0}M⊕',
            'unitPattern-count-other': '{0}M⊕',
          },
          'mass-solar-mass': {
            displayName: 'M☉',
            'unitPattern-count-one': '{0}M☉',
            'unitPattern-count-other': '{0}M☉',
          },
          'power-gigawatt': {
            displayName: 'GW',
            'unitPattern-count-one': '{0}GW',
            'unitPattern-count-other': '{0}GW',
          },
          'power-megawatt': {
            displayName: 'MW',
            'unitPattern-count-one': '{0}MW',
            'unitPattern-count-other': '{0}MW',
          },
          'power-kilowatt': {
            displayName: 'kW',
            'unitPattern-count-one': '{0}kW',
            'unitPattern-count-other': '{0}kW',
          },
          'power-watt': {
            displayName: 'watt',
            'unitPattern-count-one': '{0}W',
            'unitPattern-count-other': '{0}W',
          },
          'power-milliwatt': {
            displayName: 'mW',
            'unitPattern-count-one': '{0}mW',
            'unitPattern-count-other': '{0}mW',
          },
          'power-horsepower': {
            displayName: 'hp',
            'unitPattern-count-one': '{0}hp',
            'unitPattern-count-other': '{0}hp',
          },
          'pressure-millimeter-of-mercury': {
            displayName: 'mmHg',
            'unitPattern-count-one': '{0}mmHg',
            'unitPattern-count-other': '{0}mmHg',
          },
          'pressure-pound-per-square-inch': {
            displayName: 'psi',
            'unitPattern-count-one': '{0}psi',
            'unitPattern-count-other': '{0}psi',
          },
          'pressure-inch-hg': {
            displayName: '″ Hg',
            'unitPattern-count-one': '{0}″ Hg',
            'unitPattern-count-other': '{0}″ Hg',
          },
          'pressure-bar': {
            displayName: 'bar',
            'unitPattern-count-one': '{0}bar',
            'unitPattern-count-other': '{0}bars',
          },
          'pressure-millibar': {
            displayName: 'mbar',
            'unitPattern-count-one': '{0}mb',
            'unitPattern-count-other': '{0}mb',
          },
          'pressure-atmosphere': {
            displayName: 'atm',
            'unitPattern-count-one': '{0}atm',
            'unitPattern-count-other': '{0}atm',
          },
          'pressure-pascal': {
            displayName: 'Pa',
            'unitPattern-count-one': '{0}Pa',
            'unitPattern-count-other': '{0}Pa',
          },
          'pressure-hectopascal': {
            displayName: 'hPa',
            'unitPattern-count-one': '{0}hPa',
            'unitPattern-count-other': '{0}hPa',
          },
          'pressure-kilopascal': {
            displayName: 'kPa',
            'unitPattern-count-one': '{0}kPa',
            'unitPattern-count-other': '{0}kPa',
          },
          'pressure-megapascal': {
            displayName: 'MPa',
            'unitPattern-count-one': '{0}MPa',
            'unitPattern-count-other': '{0}MPa',
          },
          'speed-kilometer-per-hour': {
            displayName: 'km/h',
            'unitPattern-count-one': '{0}km/h',
            'unitPattern-count-other': '{0}km/h',
          },
          'speed-meter-per-second': {
            displayName: 'm/s',
            'unitPattern-count-one': '{0}m/s',
            'unitPattern-count-other': '{0}m/s',
          },
          'speed-mile-per-hour': {
            displayName: 'mi/hr',
            'unitPattern-count-one': '{0}mph',
            'unitPattern-count-other': '{0}mph',
          },
          'speed-knot': {
            displayName: 'kn',
            'unitPattern-count-one': '{0}kn',
            'unitPattern-count-other': '{0}kn',
          },
          'temperature-generic': {
            displayName: '°',
            'unitPattern-count-other': '{0}°',
          },
          'temperature-celsius': {
            displayName: '°C',
            'unitPattern-count-one': '{0}°',
            'unitPattern-count-other': '{0}°',
          },
          'temperature-fahrenheit': {
            displayName: '°F',
            'unitPattern-count-one': '{0}°F',
            'unitPattern-count-other': '{0}°F',
          },
          'temperature-kelvin': {
            displayName: 'K',
            'unitPattern-count-one': '{0}K',
            'unitPattern-count-other': '{0}K',
          },
          'torque-pound-foot': {
            displayName: 'lbf⋅ft',
            'unitPattern-count-one': '{0}lbf⋅ft',
            'unitPattern-count-other': '{0}lbf⋅ft',
          },
          'torque-newton-meter': {
            displayName: 'N⋅m',
            'unitPattern-count-one': '{0}N⋅m',
            'unitPattern-count-other': '{0}N⋅m',
          },
          'volume-cubic-kilometer': {
            displayName: 'km³',
            'unitPattern-count-one': '{0}km³',
            'unitPattern-count-other': '{0}km³',
          },
          'volume-cubic-meter': {
            displayName: 'm³',
            'unitPattern-count-one': '{0}m³',
            'unitPattern-count-other': '{0}m³',
            perUnitPattern: '{0}/m³',
          },
          'volume-cubic-centimeter': {
            displayName: 'cm³',
            'unitPattern-count-one': '{0}cm³',
            'unitPattern-count-other': '{0}cm³',
            perUnitPattern: '{0}/cm³',
          },
          'volume-cubic-mile': {
            displayName: 'mi³',
            'unitPattern-count-one': '{0}mi³',
            'unitPattern-count-other': '{0}mi³',
          },
          'volume-cubic-yard': {
            displayName: 'yd³',
            'unitPattern-count-one': '{0}yd³',
            'unitPattern-count-other': '{0}yd³',
          },
          'volume-cubic-foot': {
            displayName: 'ft³',
            'unitPattern-count-one': '{0}ft³',
            'unitPattern-count-other': '{0}ft³',
          },
          'volume-cubic-inch': {
            displayName: 'in³',
            'unitPattern-count-one': '{0}in³',
            'unitPattern-count-other': '{0}in³',
          },
          'volume-megaliter': {
            displayName: 'Ml',
            'unitPattern-count-one': '{0}Ml',
            'unitPattern-count-other': '{0}Ml',
          },
          'volume-hectoliter': {
            displayName: 'hl',
            'unitPattern-count-one': '{0}hl',
            'unitPattern-count-other': '{0}hl',
          },
          'volume-liter': {
            displayName: 'litre',
            'unitPattern-count-one': '{0}l',
            'unitPattern-count-other': '{0}l',
            perUnitPattern: '{0}/l',
          },
          'volume-deciliter': {
            displayName: 'dl',
            'unitPattern-count-one': '{0}dl',
            'unitPattern-count-other': '{0}dl',
          },
          'volume-centiliter': {
            displayName: 'cl',
            'unitPattern-count-one': '{0}cl',
            'unitPattern-count-other': '{0}cl',
          },
          'volume-milliliter': {
            displayName: 'ml',
            'unitPattern-count-one': '{0}ml',
            'unitPattern-count-other': '{0}ml',
          },
          'volume-pint-metric': {
            displayName: 'pt',
            'unitPattern-count-one': '{0}mpt',
            'unitPattern-count-other': '{0}mpt',
          },
          'volume-cup-metric': {
            displayName: 'mcup',
            'unitPattern-count-one': '{0}mc',
            'unitPattern-count-other': '{0}mc',
          },
          'volume-acre-foot': {
            displayName: 'acre ft',
            'unitPattern-count-one': '{0}ac ft',
            'unitPattern-count-other': '{0}ac ft',
          },
          'volume-bushel': {
            displayName: 'bushel',
            'unitPattern-count-one': '{0}bu',
            'unitPattern-count-other': '{0}bu',
          },
          'volume-gallon': {
            displayName: 'US gal',
            'unitPattern-count-one': '{0}galUS',
            'unitPattern-count-other': '{0}galUS',
            perUnitPattern: '{0}/galUS',
          },
          'volume-gallon-imperial': {
            displayName: 'gal',
            'unitPattern-count-one': '{0}gal',
            'unitPattern-count-other': '{0}gal',
            perUnitPattern: '{0}/gal',
          },
          'volume-quart': {
            displayName: 'qt',
            'unitPattern-count-one': '{0}qt',
            'unitPattern-count-other': '{0}qt',
          },
          'volume-pint': {
            displayName: 'pt',
            'unitPattern-count-one': '{0}pt',
            'unitPattern-count-other': '{0}pt',
          },
          'volume-cup': {
            displayName: 'cup',
            'unitPattern-count-one': '{0}c',
            'unitPattern-count-other': '{0}c',
          },
          'volume-fluid-ounce': {
            displayName: 'fl oz',
            'unitPattern-count-one': '{0}fl oz',
            'unitPattern-count-other': '{0}fl oz',
          },
          'volume-fluid-ounce-imperial': {
            displayName: 'Imp fl oz',
            'unitPattern-count-one': '{0}fl oz Im',
            'unitPattern-count-other': '{0}fl oz Im',
          },
          'volume-tablespoon': {
            displayName: 'tbsp',
            'unitPattern-count-one': '{0}tbsp',
            'unitPattern-count-other': '{0}tbsp',
          },
          'volume-teaspoon': {
            displayName: 'tsp',
            'unitPattern-count-one': '{0}tsp',
            'unitPattern-count-other': '{0}tsp',
          },
          'volume-barrel': {
            displayName: 'bbl',
            'unitPattern-count-one': '{0}bbl',
            'unitPattern-count-other': '{0}bbl',
          },
          coordinateUnit: {
            displayName: 'direction',
            east: '{0}E',
            north: '{0}N',
            south: '{0}S',
            west: '{0}W',
          },
        },
        'durationUnit-type-hm': {
          durationUnitPattern: 'h:mm',
        },
        'durationUnit-type-hms': {
          durationUnitPattern: 'h:mm:ss',
        },
        'durationUnit-type-ms': {
          durationUnitPattern: 'm:ss',
        },
      },
    },
  },
};
