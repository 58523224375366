import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlBuilder } from 'http-url-builder';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchApiService {
  public baseUrl: UrlBuilder = new UrlBuilder(environment.apim.baseUrl)

    .addPath(environment.apim.apiSlugs.searchApi)

    .addPathVariable('/');

  public baseUrlServerTableau: UrlBuilder = new UrlBuilder(
    environment.apim.baseUrl
  )

    .addPath(environment.apim.apiSlugs.tableauApi)

    .addPathVariable('/');

  public baseUrlServerHubspot: UrlBuilder = new UrlBuilder(
    environment.hubspoturl
  );

  constructor(private http: HttpClient) {}

  public getAccountsSearch(): Observable<any[]> {
    const fullUrl: string = this.baseUrl.addPath('AccountsSearch').build();

    return this.http.get<any[]>(fullUrl);
  }

  public postAccountSearch(item): Observable<object> {
    const fullUrl = this.baseUrl.addPath('AccountsSearch').build();

    return this.http.post(fullUrl, item);
  }

  public getPostsHubspot(lang: string) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPath('post')
      .addQueryParam('lang', lang)
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }

  public getLatestEnhancementsHubspot(lang: string) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPath('latestenhancement')
      .addQueryParam('lang', lang)
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }

  // Get Data
  public queryViewData(id) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPathVariable('chart')
      .addPathVariable('data')
      .addPath(id)
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }

  // Get Data
  public queryEmbed(id) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPathVariable('tableau')
      .addPathVariable('view-trusted-url')
      .addPath(id)
      .build();
    return this.http.get(fullUrl, {
      responseType: 'text',
    });
  }

  // Get Welcome
  public getWelcomeData() {
    const fullUrl: string = this.baseUrlServerTableau
      .addPath('welcome')
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }

  // Get Faqs
  public getFaqsHubspot(lang: string) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPathVariable('faqs')
      .addQueryParam('lang', lang)
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }

  // Get My User Config
  public getMyUserConfig() {
    const fullUrl: string = this.baseUrlServerTableau
      .addPath('configuration')
      .addPath('my-config')
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }

  // Get Menu Item Tabs
  public getMenuItemTabs(id) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPath('configuration')
      .addPath('tab')
      .addPath(id + '')
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }

  // Get Dashboards Tab
  public getTabDasboards(id) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPath('configuration')
      .addPath('dashboard')
      .addPath(id + '')
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }

  // Get Embed Dashboard URL
  public getEmbedDashboardURL(id) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPathVariable('chart')
      .addPathVariable('url')
      .addPath(id + '')
      .build();
    return this.http.get(fullUrl, {
      responseType: 'text',
    });
  }

  // Get Legal page
  public getLegalPage(lang: string, page: string) {
    const fullUrl: string = this.baseUrlServerTableau
      .addPathVariable('legalterms')
      .addQueryParam('lang', lang)
      .addQueryParam('actionFilter', page)
      .build();
    return this.http.get(fullUrl, {
      responseType: 'json',
    });
  }
}
